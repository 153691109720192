import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTable,
  MatTableDataSource
} from '@angular/material';
import { finalize } from 'rxjs/operators';

import { UserRoleService } from 'app/_services/user-role.service';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';

import { UserFunction } from 'app/models/userFunction';
import { UserData } from 'app/models/userData';

@Component({
  selector: 'fury-user-functions-form',
  templateUrl: 'user-functions-form.component.html',
  styleUrls: ['./user-functions-form.component.scss']
})
export class UserFunctionsFormComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<UserFunction>;
  dataSource: MatTableDataSource<UserFunction>;
  displayedColumns: string[] = ['label', 'active', 'star'];

  allFunctionsList: any[];

  functionEnabled: any[];

  user: UserData;

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<UserFunctionsFormComponent>,
    private userRoleService: UserRoleService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit() {
    if (this.data.user) {
      this.user = this.data.user;
    }
    this.getUserFunctions();
  }

  private getUserFunctions(): void {
    this.loading = true;
    this.userRoleService
      .getUserFunctions()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(functions => {
        if (!!functions) {
          this.dataSource = new MatTableDataSource(functions);
        }
      });
  }

  private getUserDetail(): void {
    this.userRoleService
      .getUserDetail(this.user.username)
      .subscribe(resp => {
        if (!!resp) {
          this.user = resp;
        }
      });
  }

  isFunctionEnabled(func): boolean {
    return this.user.function.some(funct => funct.function === func.id);
  }

  addUserFunction(func): void {
    this.loading = true;
    this.userRoleService
      .addUserFunction(func.id, this.user.username)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(resp => {
        this.confirmationDialogService.confirm(
          'Funzione aggiunta correttamente',
          'Conferma operazione',
          'OK',
          ''
        );
        this.getUserDetail();
      }
      );
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  MatSnackBar
} from '@angular/material';
import { PolicyBiasing } from '../../models/policy-biasing';
import { PolicyService } from '../../_services/policy.service';
import { ConfirmationDialogService } from '../../_services/confirmation-dialog.service';

import { PolicyBiasingFormComponent } from './components/policy-biasing-form/policy-biasing-form.component';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-policy-biasing',
  templateUrl: 'policy-biasing.component.html',
  styleUrls: ['policy-biasing.component.scss']
})
export class PolicyBiasingComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['idBiasing', 'description', 'star'];

  dataSource: MatTableDataSource<PolicyBiasing>;
  loading = false;

  policyFormRef: MatDialogRef<PolicyBiasingFormComponent>;

  constructor(
    private policyService: PolicyService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    private router: Router,
    public languageService: LanguageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getPolicyBiasingList();
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  getPolicyBiasingList(): void {
    this.policyService.getPolicyBiasingList().subscribe(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  addPolicyBiasing(): void {
    this.policyFormRef = this.dialog.open(PolicyBiasingFormComponent, {
      width: '500px'
    });

    this.policyFormRef.afterClosed().subscribe(resp => {
      if (!!resp) {
        // Salvataggio policy
        this.policyService.addPolicyBiasing(resp).subscribe(response => {
          this.showSuccessAndGetPolicyBiasingList(response);
        });
      }
    });
  }

  showSuccessAndGetPolicyBiasingList(response){
    if (response) {
      this.snackBar.open(
        this.languageService.getLabel('general.OperationSuccess'),
        'OK',
        {
          duration: 60000,
          horizontalPosition: 'center'
        }
      );
      this.getPolicyBiasingList();
    }
  }

  editPolicyBiasing(policy: PolicyBiasing): void {
    this.policyFormRef = this.dialog.open(PolicyBiasingFormComponent, {
      width: '500px',
      data: { policy: policy }
    });

    this.policyFormRef.afterClosed().subscribe(policyRes => {
      if (!!policyRes) {
        // Aggiornamento policy
        this.policyService.editPolicyBiasing(policyRes).subscribe(response => {
          this.showSuccessAndGetPolicyBiasingList(response);
        });
      }
    });
  }

  deletePolicyBiasing(policy: PolicyBiasing): void {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'policyBiasing.EliminaPolicyMessage'
        )} ${policy.idBiasing}?`
      )
      .then(res => {
        if (res) {
          this.snackBar.open(
            this.languageService.getLabel('general.OperationSuccess'),
            'OK',
            {
              duration: 60000,
              horizontalPosition: 'center'
            }
          );
          this.policyService.deletePolicyBiasing(policy).subscribe(response => {
            this.getPolicyBiasingList();
          });
        }
      });
  }

  showPolicyBiasing(policy: PolicyBiasing): void {
    this.router.navigate(['policy-detail/' + policy.idBiasing]);
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }
}

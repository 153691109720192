import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import 'hammerjs'; // Needed for Touch functionality of Material Components
import { environment } from '../environments/environment';
import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import {
  MatInputModule,
  MatButtonModule,
  MatSelectModule,
  MatIconModule,
  MatCardModule,
  MatTableModule,
  MatDialogModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatButtonToggleModule,
  MatSortModule,
  MatTabsModule,
  MatDividerModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatListModule,
  MatRadioModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatTooltipModule,
} from '@angular/material';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { LanguageSelectorModule } from './n4s/language-selector/language-selector.module';

import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';

import {
  MomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';

import { MatGridListModule } from '@angular/material/grid-list';

// AZERO
import { Dash4searchComponent } from './n4s/dash4search/dash4search.component';
import { FrontEndsComponent } from './n4s/front-ends/front-ends.component';
import { FrontEndService } from './_services/front-end.service';
import { ClientService } from './_services/client.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { DictionariesService } from './_services/dictionaries.service';
import { FrontEndFormComponent } from './n4s/front-end-form/front-end-form.component';
import { HeaderInterceptor } from './_helpers/auth-interceptor';
import { ClientsComponent } from './n4s/clients/clients.component';
import { AppConfig } from './app.config';

import { ReportsComponent } from './n4s/reports/reports/reports.component';
import { GlobalReportsComponent } from './n4s/reports/global-reports/global-reports.component';
import { ReportService } from './_services/report.service';
import { TemplateViewReportComponent } from './n4s/reports/template-report-view/template-report-view.component';

import { ClientFormComponent } from './n4s/client-form/client-form.component';
import { ClientFiltersComponent } from './n4s/client-filters/client-filters.component';
import { ClientDetailComponent } from './n4s/client-detail/client-detail.component';
import { ClientRankingsComponent } from './n4s/client-rankings/client-rankings.component';
import { ClientKeymatchComponent } from './n4s/client-keymatch/client-keymatch.component';
import { FilterFormComponent } from './n4s/filter-form/filter-form.component';
import { TextWeightFormComponent } from './n4s/text-weight-form/text-weight-form.component';
import { KeymatchFormComponent } from './n4s/keymatch-form/keymatch-form.component';
import { DatePipe } from '@angular/common';
import { NodeComponent } from './n4s/dashboard/node/node.component';
import { IndiciAliasComponent } from './n4s/indici-alias/indici-alias.component';
import { IndiciComponent } from './n4s/indici/indici.component';
import { AliasComponent } from './n4s/alias/alias.component';
import { DiagnosticComponent } from './n4s/diagnostic/diagnostic.component';
import { DiagnosticDetailComponent } from './n4s/diagnostic-detail/diagnostic-detail.component';
import { IndexAliasService } from './_services/index-alias.service';
import { ConfigurationService } from './_services/configuration.service';
import { OneboxService } from './_services/onebox.service';
import { IndexFormComponent } from './n4s/index-form/index-form.component';
import { IndiciMetaFormComponent } from './n4s/indici-meta-form/indici-meta-form.component';
import { AliasFormComponent } from './n4s/alias-form/alias-form.component';
import { AliasDetailComponent } from './n4s/alias-detail/alias-detail.component';
import { RankingFormComponent } from './n4s/ranking-form/ranking-form.component';
import { ConfigurationComponent } from './n4s/configuration/configuration/configuration.component';
import { SysPropsComponent } from './n4s/configuration/sys-props/sys-props.component';
import { TemplateSearchComponent } from './n4s/configuration/template-search/template-search.component';
import { IncludeFreemarkerComponent } from './n4s/configuration/include-freemarker/include-freemarker.component';
import { IncludeXlstComponent } from './n4s/configuration/include-xlst/include-xlst.component';
import { PropsFormComponent } from './n4s/configuration/props-form/props-form.component';
import { FreemarkerFormComponent } from './n4s/configuration/freemarker-form/freemarker-form.component';
import { TemplateSearchFormComponent } from './n4s/configuration/template-search-form/template-search-form.component';
import { OneboxListComponent } from './n4s/onebox/onebox-list/onebox-list.component';
import { OneboxFormComponent } from './n4s/onebox/onebox-form/onebox-form.component';
import { ClientOneboxComponent } from './n4s/client-onebox/client-onebox.component';
import { ClientOneboxFormComponent } from './n4s/client-onebox-form/client-onebox-form.component';
import { ConfirmationDialogComponent } from './n4s/confirmation-dialog/confirmation-dialog.component';
import { UserRoleComponent } from './n4s/user-role/user-role.component';
import { UserComponent } from './n4s/user/user.component';
import { UserFormComponent } from './n4s/user-form/user-form.component';
import { UserFunctionsFormComponent } from './n4s/user-functions-form/user-functions-form.component';
import { TemplateFreeHelpComponent } from './n4s/template-free-help/template-free-help.component';
import { ClientSearchDiffComponent } from './n4s/client-search-diff/client-search-diff.component';
import { ClientExcludeUrlComponent } from './n4s/client-exclude-url/client-exclude-url.component';
import { ClientExcludeUrlFormComponent } from './n4s/client-exclude-url-form/client-exclude-url-form.component';
import { ClientTemplateElasticComponent } from './n4s/client-template-elastic/client-template-elastic.component';
import { ClientTemplateElasticFormComponent } from './n4s/client-template-elastic-form/client-template-elastic-form.component';
import { ClientTemplateElasticDetailComponent } from './n4s/client-template-elastic-detail/client-template-elastic-detail.component';
import { ClientRelatedQueryComponent } from './n4s/client-related-query/client-related-query.component';
import { ClientRelatedQueryFormComponent } from './n4s/client-related-query-form/client-related-query-form.component';
import { PolicyBiasingComponent } from './n4s/policy-biasing/policy-biasing.component';
import { PolicyService } from './_services/policy.service';
import { PolicyBiasingFormComponent } from './n4s/policy-biasing/components/policy-biasing-form/policy-biasing-form.component';
import { PolicyBiasingDetailComponent } from './n4s/policy-biasing/components/policy-biasing-detail/policy-biasing-detail.component';
import { PolicyAliasFormComponent } from './n4s/policy-biasing/components/policy-alias-form/policy-alias-form.component';
import { PolicyMetaFormComponent } from './n4s/policy-biasing/components/policy-meta-form/policy-meta-form.component';
import { ClientPolicyComponent } from './n4s/client-policy/client-policy.component';
import { ClientPolicyAssociationFormComponent } from './n4s/client-policy-association-form/client-policy-association-form.component';
import {
  DictionariesComponent,
  SinonimiComponent,
  StopwordsComponent,
  BlacklistComponent
} from './n4s/dictionaries';
import { GestioneCrawlingComponent } from './n4s/gestione-crawling/gestione-crawling.component';
import { GestioneCrawlingWebComponent } from './n4s/gestione-crawling-web/gestione-crawling-web.component';
import { GestioneCrawlingWebFormComponent } from './n4s/gestione-crawling-web-form/gestione-crawling-web-form.component';
import { GestioneCrawlingWebDetailComponent } from './n4s/gestione-crawling-web-detail/gestione-crawling-web-detail.component';
import { CrawlingWebUrlsComponent } from './n4s/crawling-web-urls/crawling-web-urls.component';
import { GestioneCrawlingDbComponent } from './n4s/gestione-crawling-db/gestione-crawling-db.component';
import { CrawlingWebSchedulazioneComponent } from './n4s/crawling-web-schedulazione/crawling-web-schedulazione.component';
import { CrawlingWebSchedulazioneFormComponent } from './n4s/crawling-web-schedulazione-form/crawling-web-schedulazione-form.component';
import { CrawlingWebUrlsFormComponent } from './n4s/crawling-web-urls-form/crawling-web-urls-form.component';
import { CrawlingWebPathsFormComponent } from './n4s/crawling-web-paths-form/crawling-web-paths-form.component';
import { CrawlingWebMappingComponent } from './n4s/crawling-web-mapping/crawling-web-mapping.component';
import { CrawlingWebMappingFormComponent } from './n4s/crawling-web-mapping-form/crawling-web-mapping-form.component';
import { RestoreCrawlingWebFormComponent } from './n4s/restore-crawling-web-form/restore-crawling-web-form.component';
import { GestioneCrawlingFeedXMLComponent } from './n4s/gestione-crawling-feed-xml/gestione-crawling-feed-xml.component';
import { GestioneCrawlingFeedXMLFormComponent } from './n4s/gestione-crawling-feed-xml-form/gestione-crawling-feed-xml-form.component';
import { GestioneCrawlingOperazioniComponent } from './n4s/gestione-crawling-operazioni/gestione-crawling-operazioni.component';
import {
  GsaConfigurationImporterComponent,
  GsaConfigurationImporterKeymatchComponent,
  ConfirmKeymatchImportModalComponent
} from './n4s/gsa-configuration-importer/';
import { LanguageService } from './_services/language.service';
import { XlstFormComponent } from './n4s/configuration/xlst-form/xlst-form.component';
import { SuggestionComponent } from './n4s/suggestion/suggestion.component';
import { SuggestionFormComponent } from './n4s/suggestion-form/suggestion-form.component';
// tslint:disable-next-line:max-line-length
import { GsaConfigurationImporterXsltComponent } from './n4s/gsa-configuration-importer/components/gsa-configuration-importer-xslt/gsa-configuration-importer-xslt.component';
import { LanguageConfigurationComponent } from './n4s/configuration/language-configuration/language-configuration.component';
import { CrawlingWebStatoDocumentoComponent } from './n4s/crawling-web-stato-documento/crawling-web-stato-documento.component';
import { ErrorCrawlingWebFormComponent } from './n4s/error-crawling-web-form/error-crawling-web-form.component';
import { CrawlingWebStatoCrawlingComponent } from './n4s/crawling-web-stato-crawling/crawling-web-stato-crawling.component';
import { UserAreaComponent } from './n4s/user-area/user-area.component';
// tslint:disable-next-line:max-line-length
import { GsaConfigurationImporterRelatedQueriesComponent } from './n4s/gsa-configuration-importer/components/gsa-configuration-importer-related-queries/gsa-configuration-importer-related-queries.component';
import { IndiciCancellazioneDocumentiComponent } from './n4s/indici-cancellazione-documenti/indici-cancellazione-documenti.component';
import { CentroPreviewComponent } from './n4s/centro-preview/centro-preview.component';
import { UserGroupsComponent } from './n4s/user-groups/user-groups.component';
import { UserGroupsDataFormComponent } from './n4s/user-groups-data-form/user-groups-data-form.component';
import { UserGroupsPermissionsModalComponent } from './n4s/user-groups-permissions-modal/user-groups-permissions-modal.component';
import { UserGroupsPermissionsAreasComponent } from './n4s/user-groups-permissions-areas/user-groups-permissions-areas.component';
import { UserGroupsPermissionsContentsComponent } from './n4s/user-groups-permissions-contents/user-groups-permissions-contents.component';
import { UserGroupsPermissionsSubareasComponent } from './n4s/user-groups-permissions-subareas/user-groups-permissions-subareas.component';
import { IndiciMetaComponent } from './n4s/indici-meta/indici-meta.component';
import { AliasMetaFormComponent } from './n4s/alias-meta-form/alias-meta-form.component';
import { NotificationsService } from './_services/notifications.service';
import { SpeechToTextComponent } from './n4s/configuration/speech-to-text/speech-to-text.component';
import { ReportsHistoryComponent } from './n4s/reports/reports-history/reports-history.component';
import { GestioneCrawlingLogsComponent } from './n4s/gestione-crawling-logs/gestione-crawling-logs.component';
import { UpdateLicenseFormComponent } from './n4s/update-license-form/update-license-form.component';
import { SystemEmailComponent } from './n4s/configuration/system-email/system-email.component';
import { SystemEmailTemplateFormComponent } from './n4s/configuration/system-email-template-form/system-email-template-form.component';
import { ReplaceParametersPipe } from './pipes/replace-parameters.pipe';
import { GestioneMonitoraggioKenticoComponent } from './n4s/gestione-monitoraggio-kentico/gestione-monitoraggio-kentico.component';
import { GestioneCrawlingJsoningesterComponent } from './n4s/gestione-crawling-json-ingester/gestione-crawling-json-ingester.component';
import { IndexingAudioComponent } from './n4s/indexing-audio/indexing-audio.component';
import { LoadingFileDialogComponent } from './n4s/loading-file-dialog/loading-file-dialog.component';
import { AudioConfComponent } from './n4s/configuration/audio-conf/audio-conf.component';
import { DocPreviewComponent } from './n4s/configuration/doc-preview/doc-preview.component';
import { IndexingImagesComponent } from './n4s/indexing-images/indexing-images.component';
import { ServiceAccountCodeModalComponent } from './n4s/service-account-code-modal/service-account-code-modal.component';
import { WrapperServiceAccountComponent } from './n4s/wrapper-service-account/wrapper-service-account.component';
import { IndexingDocsComponent } from './n4s/indexing-docs/indexing-docs.component';
import { AnalyticsReportComponent } from './reports/analytics-report/analytics-report.component';
import { ReportsChartsComponent } from './n4s/reports/reports-charts/reports-charts.component';
import { IndexingVideoComponent } from './n4s/indexing-video/indexing-video.component';
import { VideoConfComponent } from './n4s/configuration/video-conf/video-conf.component';
import { AnalyticsReportActionComponent } from './reports/analytics-report-action/analytics-report-action.component';
import { ReportsTableComponent } from './n4s/reports/reports/reports-table/reports-table.component';
import { Helpers } from './_helpers/helpers';
import { GeoConfComponent } from './n4s/configuration/geo-conf/geo-conf.component';
import { ChronologyConfComponent } from './n4s/configuration/chronology-conf/chronology-conf.component';
import { VersionPageComponent } from './n4s/version-page/version-page.component';
import { NotificationHistoryComponent } from './n4s/notification-history/notification-history.component';
import { SharedModule } from './core/shared/shared.module';

export function loadMonaco() {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: false
  });
}

const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: loadMonaco
};

export function initConfig(config: AppConfig) {
  return () => config.load();
}

export function initLanguages(language: LanguageService) {
  return () => language.initLanguages();
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'M/D/YYYY'
  },
  display: {
    dateInput: 'D/M/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    Dash4searchComponent,
    FrontEndsComponent,
    FrontEndFormComponent,
    ClientsComponent,
    ReportsComponent,
    GlobalReportsComponent,
    TemplateViewReportComponent,
    ClientFormComponent,
    ClientFiltersComponent,
    ClientDetailComponent,
    ClientRankingsComponent,
    ClientKeymatchComponent,
    FilterFormComponent,
    TextWeightFormComponent,
    KeymatchFormComponent,
    NodeComponent,
    IndiciAliasComponent,
    IndiciComponent,
    AliasComponent,
    DiagnosticComponent,
    DiagnosticDetailComponent,
    IndexFormComponent,
    IndiciMetaFormComponent,
    AliasFormComponent,
    AliasDetailComponent,
    RankingFormComponent,
    ConfigurationComponent,
    SysPropsComponent,
    TemplateSearchComponent,
    IncludeFreemarkerComponent,
    IncludeXlstComponent,
    PropsFormComponent,
    FreemarkerFormComponent,
    TemplateSearchFormComponent,
    OneboxListComponent,
    OneboxFormComponent,
    ClientOneboxComponent,
    ClientOneboxFormComponent,
    ConfirmationDialogComponent,
    UserRoleComponent,
    UserComponent,
    UserFormComponent,
    UserFunctionsFormComponent,
    TemplateFreeHelpComponent,
    ClientSearchDiffComponent,
    ClientExcludeUrlComponent,
    ClientExcludeUrlFormComponent,
    ClientTemplateElasticComponent,
    ClientTemplateElasticFormComponent,
    ClientTemplateElasticDetailComponent,
    ClientRelatedQueryComponent,
    ClientRelatedQueryFormComponent,
    PolicyBiasingComponent,
    PolicyBiasingFormComponent,
    PolicyBiasingDetailComponent,
    PolicyAliasFormComponent,
    PolicyMetaFormComponent,
    ClientPolicyComponent,
    ClientPolicyAssociationFormComponent,
    DictionariesComponent,
    SinonimiComponent,
    StopwordsComponent,
    BlacklistComponent,
    GestioneCrawlingComponent,
    GestioneCrawlingWebComponent,
    GestioneCrawlingWebFormComponent,
    GestioneCrawlingWebDetailComponent,
    CrawlingWebUrlsComponent,
    GestioneCrawlingDbComponent,
    CrawlingWebSchedulazioneComponent,
    CrawlingWebSchedulazioneFormComponent,
    CrawlingWebUrlsFormComponent,
    CrawlingWebPathsFormComponent,
    CrawlingWebMappingComponent,
    CrawlingWebMappingFormComponent,
    CrawlingWebStatoDocumentoComponent,
    GestioneCrawlingOperazioniComponent,
    RestoreCrawlingWebFormComponent,
    GestioneCrawlingFeedXMLComponent,
    GestioneCrawlingFeedXMLFormComponent,
    GsaConfigurationImporterComponent,
    GsaConfigurationImporterKeymatchComponent,
    ConfirmKeymatchImportModalComponent,
    XlstFormComponent,
    SuggestionComponent,
    SuggestionFormComponent,
    GsaConfigurationImporterXsltComponent,
    LanguageConfigurationComponent,
    ErrorCrawlingWebFormComponent,
    CrawlingWebStatoCrawlingComponent,
    UserAreaComponent,
    GsaConfigurationImporterRelatedQueriesComponent,
    IndiciCancellazioneDocumentiComponent,
    CentroPreviewComponent,
    UserGroupsComponent,
    UserGroupsDataFormComponent,
    UserGroupsPermissionsModalComponent,
    UserGroupsPermissionsAreasComponent,
    UserGroupsPermissionsContentsComponent,
    UserGroupsPermissionsSubareasComponent,
    UserGroupsPermissionsSubareasComponent,
    IndiciMetaComponent,
    AliasMetaFormComponent,
    SpeechToTextComponent,
    ReportsHistoryComponent,
    GestioneCrawlingLogsComponent,
    UpdateLicenseFormComponent,
    SystemEmailComponent,
    SystemEmailTemplateFormComponent,
    GestioneMonitoraggioKenticoComponent,
    GestioneCrawlingJsoningesterComponent,
    IndexingAudioComponent,
    LoadingFileDialogComponent,
    AudioConfComponent,
    DocPreviewComponent,
    IndexingImagesComponent,
    ServiceAccountCodeModalComponent,
    WrapperServiceAccountComponent,
    IndexingDocsComponent,
    AnalyticsReportComponent,
    ReportsChartsComponent,
    IndexingVideoComponent,
    VideoConfComponent,
    ReportsTableComponent,
    AnalyticsReportActionComponent,
    GeoConfComponent,
    ChronologyConfComponent,
    VersionPageComponent,
    NotificationHistoryComponent

    
  ],

  imports: [
    // Angular Core Module // Don't remove!
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    // Material Modules
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatGridListModule,
    MatTableModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatSortModule,
    MatTabsModule,
    MatDividerModule,
    FlexLayoutModule,
    ScrollDispatchModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    TextFieldModule,
    MatTooltipModule,
    // Fury Core Modules
    CoreModule,
    RoutingModule,
    MonacoEditorModule.forRoot(monacoConfig),
    LanguageSelectorModule,
    MomentDateModule,
    //Shared Module
    SharedModule
  ],
  providers: [
    AuthenticationService,
    AlertService,
    FrontEndService,
    ClientService,
    DatePipe,
    IndexAliasService,
    ConfigurationService,
    OneboxService,
    ReportService,
    PolicyService,
    DictionariesService,
    NotificationsService,
    Helpers,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguages,
      deps: [LanguageService],
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en' }, // you can change useValue
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent],
  entryComponents: [
    FrontEndFormComponent,
    ClientFormComponent,
    FilterFormComponent,
    TextWeightFormComponent,
    KeymatchFormComponent,
    IndexFormComponent,
    IndiciMetaFormComponent,
    AliasFormComponent,
    RankingFormComponent,
    PropsFormComponent,
    FreemarkerFormComponent,
    XlstFormComponent,
    TemplateSearchFormComponent,
    OneboxFormComponent,
    ClientOneboxFormComponent,
    ConfirmationDialogComponent,
    GlobalReportsComponent,
    TemplateViewReportComponent,
    UserFormComponent,
    UserFunctionsFormComponent,
    ClientExcludeUrlFormComponent,
    ClientTemplateElasticFormComponent,
    ClientTemplateElasticDetailComponent,
    ClientRelatedQueryFormComponent,
    PolicyBiasingFormComponent,
    PolicyAliasFormComponent,
    PolicyMetaFormComponent,
    ClientPolicyAssociationFormComponent,
    GestioneCrawlingWebFormComponent,
    CrawlingWebSchedulazioneFormComponent,
    CrawlingWebUrlsFormComponent,
    CrawlingWebPathsFormComponent,
    CrawlingWebMappingFormComponent,
    RestoreCrawlingWebFormComponent,
    GestioneCrawlingFeedXMLFormComponent,
    ConfirmKeymatchImportModalComponent,
    SuggestionFormComponent,
    DiagnosticDetailComponent,
    ErrorCrawlingWebFormComponent,
    UserGroupsDataFormComponent,
    UserGroupsPermissionsModalComponent,
    UserGroupsPermissionsAreasComponent,
    UserGroupsPermissionsContentsComponent,
    UserGroupsPermissionsSubareasComponent,
    AliasMetaFormComponent,
    UpdateLicenseFormComponent,
    SystemEmailTemplateFormComponent,
    LoadingFileDialogComponent,
    ServiceAccountCodeModalComponent,
    WrapperServiceAccountComponent
  ],
  exports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class AppModule { }

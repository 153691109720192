import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../_services/client.service';
import { TemplateGenericType } from '../../models/template-generic-type';
import { TemplateElasticClientAssociation } from '../../models/template-elastic-client-association';
import { finalize, tap } from 'rxjs/operators';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-template-elastic-form',
  templateUrl: './client-template-elastic-form.component.html',
  styleUrls: ['./client-template-elastic-form.component.scss']
})
export class ClientTemplateElasticFormComponent implements OnInit {
  form: FormGroup;
  formMode: number;

  idclient: string;
  // currentTemplate: TemplateElastic;

  public loading = false;

  // Oggetto associazione
  obj: {
    idClient: string;
    idTemplate: string;
  };
  // Entrambi sono una coppia formata da id - label
  templateTypes: TemplateGenericType[];
  templates: TemplateGenericType[];

  // Forzo il tipo di template al tipo 'SEARCH' perchè gli altri tipo sono gestiti a BE
  selectedTemplateType: TemplateGenericType = { id: 1, label: 'SEARCH' };

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientTemplateElasticFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private clientService: ClientService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.dialogRef.close(false);
      return;
    }

    this.idclient = this.data.idclient;

    this.clientService
      .getTemplateByTypes(this.selectedTemplateType.label)
      .subscribe(templates => {
        this.templates = templates;
        this.buildForm();
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      templateId: [
        this.data.templateId ? this.data.templateId : null,
        Validators.required
      ]
    });
  }

  submit() {
    const saveTemplate = <TemplateElasticClientAssociation>{
      idClient: this.idclient,
      idTemplate: this.form.controls.templateId.value
    };

    this.loading = true;

    if (this.data.requestId) {
      this.clientService
        .updateClientTemplateElastic(
          this.data.requestId,
          this.form.controls.templateId.value
        )
        .pipe(
          finalize(() => (this.loading = false)),
          tap(_ => this.notificationsService.showDefaultSuccessMessage()))
        .subscribe(resp => this.dialogRef.close(true));
    } else {
      this.clientService
        .saveNewClientTemplateElastic(saveTemplate)
        .pipe(finalize(() => (this.loading = false)), tap(_ => this.notificationsService.showDefaultSuccessMessage()))
        .subscribe(resp => this.dialogRef.close(true));
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatTable,
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { finalize, tap } from '../../../../node_modules/rxjs/operators';

import { ConfirmationDialogService } from '../../_services/confirmation-dialog.service';
import { LanguageService } from '../../_services/language.service';
import { SuggestionService } from '../../_services/suggestion.service';

import { Suggestion } from '../../models/suggestion';

import { SuggestionFormComponent } from '../suggestion-form/suggestion-form.component';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss']
})
export class SuggestionComponent implements OnInit {
  @ViewChild('suggestionTable') suggestionTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<Suggestion>;
  suggestionFormRef: MatDialogRef<SuggestionFormComponent>;
  loading = false;

  displayedColumns: string[] = ['term', 'client', 'enable', 'star'];

  constructor(
    private suggestionService: SuggestionService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getSuggestions();
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  private getSuggestions(): void {
    this.suggestionService
      .getSuggestions()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(suggestions => {
        this.dataSource = new MatTableDataSource(suggestions);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  addSuggestion() {
    this.suggestionFormRef = this.dialog.open(SuggestionFormComponent, {
      width: '500px',
      data: { suggestion: new Suggestion() }
    });

    this.suggestionFormRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.suggestionService
          .saveSuggestion(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getSuggestions());
      }
    });
  }

  deleteSuggestion(suggestion: Suggestion) {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel('suggestion.list.DomandaElimina')} ${
        suggestion.term
        }?`
      )
      .then(res => {
        if (res) {
          this.suggestionService
            .deleteSuggestion(suggestion.id)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getSuggestions());
        }
      });
  }

  editSuggestion(suggestion: Suggestion) {
    this.suggestionFormRef = this.dialog.open(SuggestionFormComponent, {
      width: '500px',
      data: { suggestion: suggestion }
    });

    this.suggestionFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.suggestionService
          .editSuggestion(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getSuggestions());
      }
    });
  }

  resetSuggestion(): void {
    this.suggestionService.resetSuggestion().subscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { ConfigurationService } from 'app/_services/configuration.service';
import { MatSnackBar } from '@angular/material';
import { Type } from 'app/models';

@Component({
  selector: 'fury-language-configuration',
  templateUrl: 'language-configuration.component.html',
  styleUrls: ['./language-configuration.scss']
})
export class LanguageConfigurationComponent implements OnInit {
  languagesList: Type[] = [{ id: '', label: '--' }];
  autodetect: boolean;
  defaultLanguage: string;

  constructor(
    public languageService: LanguageService,
    private configurationService: ConfigurationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.configurationService.getLanguagesList().subscribe(resp => {
      this.languagesList = [...this.languagesList, ...resp];
    });

    this.configurationService.getLanguageConfiguration().subscribe(resp => {
      this.autodetect = resp.autodetect;
      this.defaultLanguage = resp.defaultLang;
      this.setDefaultLanguage();

    });
  }

  setDefaultLanguage(): void {
    if (this.autodetect) {
      this.defaultLanguage = '';
    }
  }

  saveLanguageConfiguration(): void {
    const configuration: any = {
      autodetect: this.autodetect,
      defaultLang: this.defaultLanguage
    };
    this.configurationService
      .saveLanguageConfiguration(configuration)
      .subscribe(resp => {
        this.snackBar.open(
          this.languageService.getLabel('configuration.Language.SaveCompleted'),
          this.languageService.getLabel('general.Chiudi'),
          {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          }
        );
      });
  }
}

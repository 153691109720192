export class Helpers {
  constructor() {
    //this is intentionally
  }

  colorsPalette = ['#6E3413',
    '#FBFF44',
    '#571C70',
    '#DB6827',
    '#98CDE5',
    '#C81D35',
    '#BFBC81',
    '#7F7E80',
    '#61A547',
    '#FF9BD3',
    '#4578B4',
    '#DE8365',
    '#483896',
    '#FFAF23',
    '#B622AE',
    '#2B3616',
    '#6B1212',
    '#90AC3C']

  getRandomRgbaColor(size: number): string[] {
    let colors = [];
    for (let i = 0; i < size; i++) {
      let randomColor = this.hexToRgbA(this.colorsPalette[i % this.colorsPalette.length])
       colors.push(randomColor);
    }
    return colors;
  }
  getSingleRandomRgbaColor() {
    return this.hexToRgbA(this.colorsPalette[Math.floor(Math.random() * this.colorsPalette.length)]);
  }
  getIsoStringDate(date): string {
    try {
      let splitted = date.split('/')
      splitted.forEach((_el, i) => {
        if (i !== 2) {
          if (_el.length < 2) {
            splitted[i] = '0' + _el;
          }
        }
      })
      let day = splitted[0];
      let month = splitted[1];
      splitted[0] = parseInt(month);
      splitted[1] = parseInt(day) + 1;
      let dateObj = new Date(splitted.join('/'));
      let isoString = dateObj.toISOString()
      return isoString.substring(0, isoString.length - 1)
    } catch (e) {
      console.log(date)
      console.error(e)
    }
  }
  getCleanDataStructure(dataset) {
    dataset.datasets.forEach((singleDataset) => {
      delete singleDataset.classi
    })
    return dataset;
  }
  customizeChartsColors(data, chartType?: string) {
    data = this.setColorsForDataset(data, chartType);
    return data;
  }

  setColorsForDataset(data, chartType?: string) {
    switch (chartType) {
      case ('bar'): {
        data.datasets.forEach(singleDataset => {
          let colorsArray = this.getRandomRgbaColor(singleDataset.data.length);
          singleDataset['borderColor'] = colorsArray;
          singleDataset['backgroundColor'] = colorsArray;
        })
      }
        break;
      case ('bar-stacked'): {
        data.datasets.forEach((singleDataset, i) => {
          let color = this.getSingleRandomRgbaColor();
          singleDataset['borderColor'] = color;
          singleDataset['backgroundColor'] = color;
          singleDataset['stack'] = data['labels'][i]
        })
      }
        break;
      case ('line'): {
        data.datasets.forEach(singleDataset => {
          let currentDatasetColor = this.getSingleRandomRgbaColor();
          singleDataset['borderColor'] = currentDatasetColor;
          singleDataset['backgroundColor'] = this.convertInHalfOpacity(currentDatasetColor);
          singleDataset['fill'] = true
          singleDataset['pointBackgroundColor'] = this.getColorByClass(singleDataset)
          singleDataset['pointStyle'] = this.getPointStyleByClass(singleDataset)
          singleDataset['pointRadius'] =  this.getPointRadiusByClass(singleDataset)
        })
      }
        break;
      case ('line-fixed'): {
        data.datasets.forEach((singleDataset, i) => {
          singleDataset['pointRadius'] =  this.getPointRadiusByClass(singleDataset)
          if(i < 2){
            let currentDatasetColor = this.getSingleRandomRgbaColor();
            singleDataset['borderColor'] = currentDatasetColor;
            singleDataset['backgroundColor'] = 'rgba(255,255,255,0)';
            singleDataset['fill'] = true
            singleDataset['pointBackgroundColor'] = this.getColorByClass(singleDataset)
            this.removeNonePointFromDataset(singleDataset)
          }else{
            singleDataset['borderColor'] =  'rgba(255,255,255,0)';
            singleDataset['backgroundColor'] = 'rgba(255,255,255,0)';
            singleDataset['fill'] = true;
            singleDataset['pointStyle'] = 'triangle';
            singleDataset['pointBackgroundColor'] = this.getColorByClass(singleDataset)
            singleDataset['pointRadius'] =  this.getPointRadiusByClass(singleDataset)
          }
        })
      }
      break;  
      case ('pie'): {
        data.datasets.forEach(singleDataset => {
          let colorsArray = this.getRandomRgbaColor(singleDataset.data.length)
          singleDataset['backgroundColor'] = colorsArray;
          singleDataset['borderColor'] = colorsArray;
        })
      }
        break;
      case ('scatter'): {
        data.datasets.forEach(singleDataset => {
          let singleColor = this.getSingleRandomRgbaColor();
          singleDataset['borderColor'] = singleColor;
          singleDataset['backgroundColor'] = singleColor;
        })
      }
        break;
      default: {
        data.datasets.forEach(singleDataset => {
          singleDataset['borderColor'] = this.getSingleRandomRgbaColor();
        })
      }
    }

    return data;
  }

  getColorByClass(dataset) {
    let currentColorData = [];
    dataset.classi.forEach((singleClass) => {
      switch (singleClass) {
        case ('data'): {
          currentColorData.push(this.hexToRgbA('#61A547'))
          break;
        }
        case ('prediction'): {
          currentColorData.push(this.hexToRgbA('#6DD2FF'))
          break;
        }
        case ('anomaly'): {
          currentColorData.push(this.hexToRgbA('#C81D35'))
          break;
        }
         case ('none'): {
          currentColorData.push('rgba(255,255,255,0)')
          break;
        } 
        default: {
          currentColorData.push('rgba(99,155,150,1)')
        }
      }
    })
    return currentColorData;
  }
  getPointRadiusByClass(dataset) : string[]{
    let pointRadius = []
    dataset.classi.forEach((singleClass, i) => {
      if(singleClass === 'anomaly'){
        pointRadius[i] = '6';
      }else{
        pointRadius[i] = '4';
      }
    })
      
    return pointRadius;
  }
  getPointStyleByClass(dataset){
    let currentPointStyle = [];
    dataset.classi.forEach((singleClass) => {
      switch (singleClass) {
   
        case ('prediction'): {
          currentPointStyle.push('circle')
          break;
        }
        case ('anomaly'): {
          currentPointStyle.push('triangle')
          break;
        }
        default: {
          currentPointStyle.push('circle')
        }
      }
    })
    return currentPointStyle;
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    }
    throw new Error('Bad Hex');
  }
  convertInHalfOpacity(currentColor) {
    let splitted = currentColor.split(',')
    splitted[3] = splitted[3].replace('1','0.5')
    return splitted.join(',');
  }
  haveData(data){
    try{
      return (data.datasets && data.datasets.length > 0 && data.datasets[0].data.length > 0) || data.dataset && data.dataset.length > 0;
    }catch(error){
      console.error(error)
    }
  }
  removeNonePointFromDataset(dataset){
    dataset.data.forEach((singleData, i) => {
      if(singleData === -1 && dataset.classi[i] === 'none'){
        delete dataset.data[i];
      }
    })
  }
}

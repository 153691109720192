import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/catch';

import { ApplicationConfig } from './models/application-config';

export const ConfigData = <ApplicationConfig>{
  googleMapsApiKey: '',
  apiUrl: '',
  loginUrl: '',
  useToken: false,
  enablePdfPrint: false,
  clientName: '',
  cloudSearch: false,
  clientLogoPath: '',
  excludeAreas: [],
  authenticationMode: '',
  regexId: '',
  monitoringKentico : false
};

@Injectable()
export class AppConfig {
  constructor(private httpClient: HttpClient) { }

  public load() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get('./assets/env.json')
        .map((res: ApplicationConfig) => res)
        .catch((error: any): any => {
          console.log(`Configuration file 'env.json' could not be read`);
          resolve(true);
          return throwError(error.json().error || 'Server error');
        })
        .subscribe((configResponse: ApplicationConfig) => {
          ConfigData.googleMapsApiKey = configResponse.googleMapsApiKey;
          ConfigData.apiUrl = configResponse.apiUrl;
          ConfigData.loginUrl = configResponse.loginUrl;
          ConfigData.useToken = configResponse.useToken;
          ConfigData.enablePdfPrint = configResponse.enablePdfPrint;
          ConfigData.clientName = configResponse.clientName;
          ConfigData.cloudSearch = configResponse.cloudSearch;
          ConfigData.clientLogoPath = configResponse.clientLogoPath;
          if (configResponse.excludeAreas == undefined) {
            ConfigData.excludeAreas = []
          } else {
            ConfigData.excludeAreas = configResponse.excludeAreas;
          }
          ConfigData.authenticationMode = configResponse.authenticationMode;
          ConfigData.biasingBoostMin = configResponse.biasingBoostMin;
          ConfigData.biasingBoostMax = configResponse.biasingBoostMax;
          ConfigData.regexId = configResponse.regexId;
          ConfigData.monitoringKentico = configResponse.monitoringKentico;
          resolve(true);
        });
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../models/client';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent implements OnInit {
  currentClient: Client = {
    idclient: '',
    description: ''
  };

  isEdit = false;
  form: FormGroup;

  constructor(
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    this.isEdit = this.data;

    if (this.isEdit) {
      this.currentClient = this.data.client;
    }
    this.form = this.formBuilder.group({
      idclient: [this.currentClient.idclient, Validators.compose([Validators.pattern('^[a-z0-9_-]+$'), Validators.required])],
      description: [this.currentClient.description, Validators.required]
    });
  }

  submit(form) {
    this.dialogRef.close({
      idclient: form.value.idclient.toLowerCase(),
      description: form.value.description
    });
  }
}

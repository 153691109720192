import { Component, OnInit, OnDestroy } from '@angular/core';

import { LanguageService } from '../../_services/language.service';

import { NavLink } from '../../models/nav-link';
import { AuthenticationService } from 'app/_services/authentication.service';
import { Router } from '@angular/router';
import { PERMISSION_CODES } from 'app/constants/permission-codes';
import { ConfigData } from './../../app.config';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fury-user-role',
  templateUrl: 'user-role.component.html'
})
export class UserRoleComponent implements OnInit, OnDestroy {
  navLinks: NavLink[];

  selectedRoute: string;

  private destroy$ = new Subject();

  constructor(public languageService: LanguageService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());

  }

  private buildTabs(): void {
    this.navLinks = [];

    this.selectedRoute = this.router.url.split('/')[2] || null;

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_UTENTI_TABS.UTENTI)
      && ConfigData.authenticationMode !== 'LDAP') {
      this.navLinks.push({
        path: 'user',
        label: this.languageService.getLabel('userRole.Utenti'),
        visible: true,
        disabled: false
      });
      this.selectedRoute = this.selectedRoute || 'user';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_UTENTI_TABS.GRUPPI)) {
      this.navLinks.push({
        path: 'groups',
        label: this.languageService.getLabel('userRole.Gruppi'),
        visible: true,
        disabled: false
      });

      this.selectedRoute = this.selectedRoute || 'groups';
    }

    if (this.selectedRoute) {
      this.router.navigate(['user-role/' + this.selectedRoute]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { ClientRelatedQuery } from './../models/client-related-query';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { removeEmpty } from '../_lib/global';

import { ConfigData } from '../app.config';

import { Client } from '../models/client';
import { Filter } from '../models/filter';
import { Ranking } from '../models/ranking';
import { FilterMeta } from '../models/filter-meta';
import { FilterType } from '../models/filter-type';
import { FilterOrder } from '../models/filter-order';
import { TextWeight } from '../models/text-weight';
import { Keymatch } from '../models/keymatch';
import { ClusterHealth } from '../models/cluster-health';
import { UrlExclusion } from '../models/url-exclusion';
import { TemplateGenericType } from '../models/template-generic-type';
import { TemplateElasticClientAssociation } from '../models/template-elastic-client-association';
import { TemplateElasticRequest } from '../models/template-elastic-request';
import { ClientPolicyBiasing } from 'app/models/client-policy-biasing';
import { License } from 'app/models/license';

export interface ClientFilters {
  dateWeight: number;
  dynamicNavigation: Filter[];
  ranking: Ranking[];
  textWeight: any[];
}

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  clientUrl = `${ConfigData.apiUrl}/client`;
  clusterUrl = `${ConfigData.apiUrl}/cluster/health`;
  clientExportUrl = `${ConfigData.apiUrl}/gsa/admin`;
  licenseUrl = `${ConfigData.apiUrl}/license`;

  constructor(private http: HttpClient) { }

  getClusterHealth(): Observable<ClusterHealth> {
    return this.http.get<ClusterHealth>(this.clusterUrl);
  }

  getLicense(): Observable<License> {
    return this.http.get<License>(this.licenseUrl);
  }

  updateLicense(licenseKey: string): Observable<any> {
    const request = { licenseKey };

    return this.http.post(this.licenseUrl, request);
  }

  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this.clientUrl);
  }

  saveClient(client: Client): Observable<Client> {
    return this.http.post<Client>(this.clientUrl, client);
  }

  editClient(client: Client): Observable<Client> {
    return this.http.put<Client>(
      `${this.clientUrl}/${client.idclient}`,
      client
    );
  }

  deleteClient(client: Client): Observable<Client> {
    return this.http.delete<Client>(`${this.clientUrl}/${client.idclient}`);
  }

  deleteFilter(idClient: string, filter: Filter): Observable<Filter> {
    return this.http.delete<Filter>(
      `${this.clientUrl}/${idClient}/dynamic-navigation/${filter.id}`
    );
  }

  getFilters(idClient: string) {
    return this.http.get<ClientFilters>(
      `${this.clientUrl}/${idClient}/dynamic-navigation`
    );
  }

  getFiltersMetaList() {
    return this.http.get<FilterMeta[]>(`${ConfigData.apiUrl}/meta`);
  }

  getFiltersTypeList() {
    return this.http.get<FilterType[]>(
      `${this.clientUrl}/dynamic-navigation/types`
    );
  }

  getFiltersOrderList(idclient: string) {
    return this.http.get<FilterOrder[]>(
      `${this.clientUrl}/${idclient}/filters/sorting`
    );
  }

  addFilter(idClient: string, filter: Filter) {
    const obj = {
      label: filter.label,
      meta: filter.meta.id,
      size: filter.size,
      orderType: filter.order.orderType,
      orderSort: filter.order.orderSort,
    };
    return this.http.post<ClientFilters>(
      `${this.clientUrl}/${idClient}/dynamic-navigation`,
      obj
    );
  }

  editFilter(idclient: string, filter: Filter): Observable<Filter> {
    const obj = {
      label: filter.label,
      meta: filter.meta.id,
      size: filter.size,
      orderType: filter.order.orderType,
      orderSort: filter.order.orderSort,
    };
    return this.http.put<Filter>(
      `${this.clientUrl}/${idclient}/dynamic-navigation/${filter.id}`,
      obj
    );
  }

  /**
   * Switch elements order in the list
   * @param idMoveUp Element to move up
   * @param idMoveDown Element to move down
   */
  moveElement(idMoveUp: number, idMoveDown: number): Observable<any> {
    return this.http.get<any>(
      `${this.clientUrl}/dynamic-navigation/${idMoveUp}/${idMoveDown}/orderchange`
    );
  }

  editDateWeight(idclient: string, dateWeight: number) {
    return this.http.put<any>(`${this.clientUrl}/${idclient}/date-weight`, {
      dateWeight: dateWeight,
    });
  }

  getKeyMatch(idclient: string) {
    return this.http.get<Keymatch[]>(`${this.clientUrl}/${idclient}/keymatch`);
  }

  addKeyMatch(idclient: string, keymatch: Keymatch) {
    const keypost = {
      keyword: keymatch.keyword,
      thumbImgUrl: keymatch.thumbImgUrl,
      url: keymatch.url,
      title: keymatch.title,
      description: keymatch.description,
      triggerType: keymatch.triggerType,
      date: keymatch.date,
    };
    removeEmpty(keypost);
    return this.http.post<Keymatch>(
      `${this.clientUrl}/${idclient}/keymatch`,
      keypost
    );
  }

  editKeyMatch(idclient: string, keymatch: Keymatch) {
    return this.http.put<any>(
      `${this.clientUrl}/${idclient}/keymatch/${keymatch.id}`,
      keymatch
    );
  }

  deleteKeyMatch(idclient: string, keymatch: Keymatch): Observable<Keymatch> {
    return this.http.delete<Keymatch>(
      `${this.clientUrl}/${idclient}/keymatch/${keymatch.id}`
    );
  }

  exportKeyMatch(clientID: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(
      `${this.clientExportUrl}/keymatch/${clientID}/export`,
      httpOptions
    );
  }

  getTriggerTypesList() {
    return this.http.get<any[]>(`${this.clientUrl}/keymatch/trigger-type`);
  }

  addTextWeight(idClient: string, textWeight: TextWeight) {
    const keypost = {
      idMeta: textWeight.field,
      textRegex: textWeight.textRegex,
      boost: textWeight.boost,
    };

    return this.http.post<TextWeight>(
      this.clientUrl + '/' + idClient + '/text-weight',
      keypost
    );
  }

  editTextWeight(
    idClient: string,
    textWeight: TextWeight
  ): Observable<TextWeight> {
    return this.http.put<TextWeight>(
      `${this.clientUrl}/${idClient}/text-weight/${textWeight.id}`,
      {
        boost: textWeight.boost,
      }
    );
  }

  deleteTextWeight(
    idclient: string,
    textWeight: TextWeight
  ): Observable<TextWeight> {
    return this.http.delete<TextWeight>(
      `${this.clientUrl}/${idclient}/text-weight/${textWeight.id}`
    );
  }

  addRanking(idClient: string, ranking: Ranking) {
    const keypost = {
      idMeta: ranking.meta.id,
      boost: ranking.boost,
    };

    return this.http.post<Ranking>(
      `${this.clientUrl}/${idClient}/ranking`,
      keypost
    );
  }

  editRanking(idClient: string, ranking: Ranking): Observable<Ranking> {
    return this.http.put<TextWeight>(
      `${this.clientUrl}/${idClient}/ranking/${ranking.id}`,
      {
        boost: ranking.boost,
      }
    );
  }

  deleteRanking(idclient: string, ranking: Ranking): Observable<Ranking> {
    return this.http.delete<Ranking>(
      `${this.clientUrl}/${idclient}/ranking/${ranking.id}`
    );
  }

  getOnebox(idclient: string) {
    return this.http.get<any[]>(`${this.clientUrl}/${idclient}/onebox`);
  }

  deleteOnebox(idclient: string, onebox: any): Observable<any> {
    return this.http.delete<Ranking>(
      `${this.clientUrl}/${idclient}/onebox/${onebox.id}`
    );
  }

  addOnebox(idclient: string, onebox: any) {
    const obj = {
      id: onebox.id,
    };
    return this.http.post<any>(
      this.clientUrl + '/' + idclient + '/onebox',
      obj
    );
  }

  /* Url Exclusions */

  getClientUrlExclusions(idclient: string): Observable<UrlExclusion[]> {
    return this.http.get<UrlExclusion[]>(
      `${this.clientUrl}/${idclient}/url-exclusion`
    );
  }

  saveNewClientUrlExclusion(
    idclient: string,
    urlExclusion: UrlExclusion
  ): Observable<UrlExclusion> {
    return this.http.post<UrlExclusion>(
      `${this.clientUrl}/${idclient}/url-exclusion`,
      urlExclusion
    );
  }

  updateClientUrlExclusion(
    idclient: string,
    urlExclusionId: number,
    urlExclusion: UrlExclusion
  ): Observable<UrlExclusion> {
    return this.http.put<UrlExclusion>(
      `${this.clientUrl}/${idclient}/url-exclusion/${urlExclusionId}`,
      urlExclusion
    );
  }

  deleteClientUrlExclusions(
    idclient: string,
    urlExclusion: UrlExclusion
  ): Observable<any> {
    return this.http.delete(
      `${this.clientUrl}/${idclient}/url-exclusion/${urlExclusion.id}`
    );
  }

  /** Template search elastic */
  getClientTemplateElastic(
    idclient: string
  ): Observable<TemplateElasticRequest[]> {
    return this.http.get<TemplateElasticRequest[]>(
      `${this.clientUrl}/${idclient}/elastic-request`
    );
  }

  /**
   * Crea una nuova associazione fra template elastic e client
   */
  saveNewClientTemplateElastic(
    association: TemplateElasticClientAssociation
  ): Observable<TemplateElasticClientAssociation> {
    return this.http.post<TemplateElasticClientAssociation>(
      `${this.clientUrl}/elastic-request`,
      association
    );
  }

  updateClientTemplateElastic(
    idRequest: string,
    idTemplate: number
  ): Observable<boolean> {
    return this.http.get<any>(
      `${this.clientUrl}/elastic-request/${idRequest}/${idTemplate}`
    );
  }

  deleteClientTemplateElastic(idRequest: number): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.clientUrl}/elastic-request/${idRequest}`
    );
  }

  // GET AVAILABLE ELASTIC TEMPLATE TYPE BY CLIENT
  getAvailableElasticTemplateTypes(
    idclient: string
  ): Observable<TemplateGenericType[]> {
    return this.http.get<TemplateGenericType[]>(
      `${this.clientUrl}/${idclient}/elastic-template-types`
    );
  }

  /**
   * Recupera i template in base alla tipologia
   */
  getTemplateByTypes(labelType: string): Observable<TemplateGenericType[]> {
    return this.http.get<TemplateGenericType[]>(
      `${this.clientUrl}/${labelType}/elastic-templates`
    );
  }

  setTemplateDefault(idTemplate: number): Observable<any> {
    return this.http.get<any>(
      `${this.clientUrl}/elastic-templates/${idTemplate}/default`
    );
  }

  getElasticRequest(
    idElasticRequest: number
  ): Observable<TemplateElasticRequest> {
    return this.http.get<TemplateElasticRequest>(
      `${this.clientUrl}/elastic-request/${idElasticRequest}`
    );
  }

  /*    RELATED QUERY     */
  getRelatedQuery(idClient: string): Observable<ClientRelatedQuery[]> {
    return this.http.get<ClientRelatedQuery[]>(
      `${this.clientUrl}/${idClient}/related-query`
    );
  }

  updateRelatedQuery(
    idClient: string,
    relatedQuery: ClientRelatedQuery
  ): Observable<ClientRelatedQuery> {
    return this.http.put<ClientRelatedQuery>(
      `${this.clientUrl}/${idClient}/related-query/${relatedQuery.idQuery}`,
      relatedQuery
    );
  }

  addRelatedQuery(
    idClient: string,
    relatedQuery: ClientRelatedQuery
  ): Observable<ClientRelatedQuery> {
    return this.http.post<ClientRelatedQuery>(
      `${this.clientUrl}/${idClient}/related-query`,
      relatedQuery
    );
  }

  deleteRelatedQuery(
    idClient: string,
    relatedQuery: ClientRelatedQuery
  ): Observable<ClientRelatedQuery> {
    return this.http.delete<ClientRelatedQuery>(
      `${this.clientUrl}/${idClient}/related-query/${relatedQuery.idQuery}`
    );
  }

  exportRelatedQuery(clientID: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(
      `${this.clientExportUrl}/relatedquery/${clientID}/export`,
      httpOptions
    );
  }

  /** Client Policies */
  getClientPolicies(idClient: string): Observable<ClientPolicyBiasing> {
    return this.http.get<ClientPolicyBiasing>(
      `${this.clientUrl}/${idClient}/biasing`
    );
  }

  getAllPolicies(): Observable<TemplateGenericType[]> {
    return this.http.get<TemplateGenericType[]>(`${this.clientUrl}/biasing`);
  }

  setPolicyAssociation(id: string, idClient: string): Observable<any> {
    return this.http.put<any>(`${this.clientUrl}/${idClient}/biasing`, {
      idBiasing: id,
    });
  }

  deletePolicyAssociation(
    client: Client,
    policy: ClientPolicyBiasing
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.clientUrl}/${client.idclient}/biasing/${policy.idBiasing}`
    );
  }

  // GSA Configuration Importer
  validateKeymatchFile(
    idClient: string,
    file: File
  ): Observable<{ count: number }> {
    const formData = new FormData();
    formData.append('keymatchesFile', file);
    return this.http.post<{ count: number }>(
      `${ConfigData.apiUrl}/gsa/admin/keymatch/${idClient}/import/count`,
      formData
    );
  }

  validateRelatedQueriesFile(
    idClient: string,
    file: File
  ): Observable<{ count: number }> {
    const formData = new FormData();
    formData.append('relatedqueriesFile', file);
    return this.http.post<{ count: number }>(
      `${ConfigData.apiUrl}/gsa/admin/relatedquery/${idClient}/import/count`,
      formData
    );
  }

  importKeymatch(
    idClient: string,
    file: File,
    override: boolean
  ): Observable<any> {
    const formData = new FormData();
    formData.append('keymatchesFile', file);
    formData.append('override', `${override}`);

    return this.http.post<any>(
      `${ConfigData.apiUrl}/gsa/admin/keymatch/${idClient}/import`,
      formData
    );
  }

  importRelatedQueries(
    idClient: string,
    file: File,
    override: boolean
  ): Observable<any> {
    const formData = new FormData();
    formData.append('relatedqueriesFile', file);
    formData.append('override', `${override}`);

    return this.http.post<any>(
      `${ConfigData.apiUrl}/gsa/admin/relatedquery/${idClient}/import`,
      formData
    );
  }

  importXslt(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('xsltFile', file);
    return this.http.post<any>(
      `${ConfigData.apiUrl}/gsa/admin/xslt/import`,
      formData
    );
  }
}

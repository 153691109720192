import { Type } from './../models/index';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ConfigData } from '../app.config';

// Models
import { Index, Mapping, IndexHistory, Meta } from '../models/index';
import { Alias, Pattern } from '../models/alias';
import { AliasList, ActiveAlias, AliasUrls } from 'app/models/alias-list';
import { Diagnostic } from 'app/models/diagnostic/diagnostic';

@Injectable({
  providedIn: 'root'
})
export class IndexAliasService {
  private apiIndexUrl = `${ConfigData.apiUrl}/index`;
  private apiAliasUrl = `${ConfigData.apiUrl}/alias`;
  private apiMetaUrl = `${ConfigData.apiUrl}/meta`;

  constructor(private http: HttpClient) { }

  getIndexes(): Observable<Index[]> {
    return this.http.get<Index[]>(`${this.apiIndexUrl}/short`);
  }

  getIndexesHistory(): Observable<IndexHistory[]> {
    return this.http.get<IndexHistory[]>(`${this.apiIndexUrl}/history`);
  }

  getAliasIndexes(idAlias: string): Observable<Index[]> {
    return this.http.get<Index[]>(`${this.apiIndexUrl}/short/${idAlias}`);
  }

  saveIndex(index: Index): Observable<Index> {
    return this.http.post<Index>(this.apiIndexUrl, index);
  }

  deleteIndex(idIndex: string, idElasticIndex: string): Observable<boolean> {
    const params = new HttpParams().append('idIndexElastic', idElasticIndex);

    return this.http.delete<boolean>(`${this.apiIndexUrl}/${idIndex}`, { params });
  }

  reindexIndex(indexId: string): Observable<any> {
    return this.http.get<any>(`${this.apiIndexUrl}/${indexId}/reindex`);
  }

  refreshIndex(indexId: string): Observable<any> {
    return this.http.get<any>(`${this.apiIndexUrl}/${indexId}/refresh`);
  }

  refreshAllIndexes(): Observable<any> {
    return this.http.get<any>(`${this.apiIndexUrl}/refresh`);
  }

  getMetaForAlias(): Observable<Type[]> {
    return this.http.get<Type[]>(`${this.apiMetaUrl}`);
  }

  getMetaList(): Observable<Meta[]> {
    return this.http.get<Meta[]>(`${this.apiMetaUrl}/list`);
  }

  createMeta(meta: Meta): Observable<Meta> {
    return this.http.post<Meta>(`${this.apiMetaUrl}`, meta);
  }

  updateMeta(meta: Meta): Observable<Meta> {
    return this.http.put<Meta>(`${this.apiMetaUrl}/${meta.id}`, meta);
  }

  deleteMeta(meta: Meta): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiMetaUrl}/${meta.id}`);
  }

  // saveMapping(indexId: string, mapping: Mapping): Observable<Mapping> {
  //   return this.http.post<Mapping>(this.apiUrl + '/' + indexId + '/' + 'mapping', mapping);
  // }

  saveMapping(indexId: string, mapping: Mapping): Observable<Mapping> {
    const mm = {
      meta: {
        field: mapping.meta.field,
        type: {
          id: mapping.meta.type.id,
          label: mapping.meta.type.label['label']
        }
      }
    };

    return this.http.post<any>(`${this.apiIndexUrl}/${indexId}/mapping`, mm);
  }

  getAliases(): Observable<Alias[]> {
    return this.http.get<Alias[]>(this.apiAliasUrl);
  }

  saveAlias(alias: Alias): Observable<Alias> {
    return this.http.post<Alias>(this.apiAliasUrl, alias);
  }

  deleteAlias(alias: Alias): Observable<Alias> {
    return this.http.delete<Alias>(`${this.apiAliasUrl}/${alias.id}`);
  }

  getAlias(aliasId: string): Observable<Alias> {
    return this.http.get<Alias>(`${this.apiAliasUrl}/${aliasId}`);
  }

  getAliasesShortList(): Observable<AliasList> {
    return this.http.get<AliasList>(`${this.apiAliasUrl}/short-list`);
  }


  getActiveAliases(): Observable<ActiveAlias[]> {
    return this.http
      .get<ActiveAlias[]>(`${this.apiAliasUrl}/active`)
      .map((response) => {
          let sortedList = [...response];
          sortedList.sort((curr, next) =>{
          let resp;
          if(curr.id < next.id){
            resp = -1;
          }else if(curr.id > next.id){
            resp = 1
          }else{
            resp = 0;
          }
          return resp;
        });
        return sortedList;
      });
  }

  saveIndexAlias(aliasId: string, indexId: string): Observable<Alias> {
    return this.http.post<Alias>(`${this.apiAliasUrl}/${aliasId}/index`, {
      id: indexId
    });
  }

  savePatternAlias(aliasId: string, pattern: any): Observable<Pattern> {
    return this.http.post<Pattern>(
      `${this.apiAliasUrl}/${aliasId}/pattern`,
      pattern
    );
  }

  saveMetaAlias(aliasId: string, req: { idMeta: number; value: string; }): Observable<Alias> {
    return this.http.post<Alias>(
      `${this.apiAliasUrl}/${aliasId}/meta`,
      req
    );
  }

  deleteMetaAlias(aliasId: string, meta: Meta): Observable<Alias> {
    return this.http.delete<Alias>(
      `${this.apiAliasUrl}/${aliasId}/meta/${meta.id}`,
    );
  }

  deleteIndexAlias(aliasId: string, indexId: string): Observable<Alias> {
    return this.http.delete<Alias>(
      `${this.apiAliasUrl}/${aliasId}/index/${indexId}`
    );
  }

  deletePatternAlias(aliasId: string, patternId: string): Observable<Alias> {
    return this.http.delete<Alias>(
      `${this.apiAliasUrl}/${aliasId}/pattern/${patternId}`
    );
  }

  getAliasUrls(
    aliasId: string,
    direction: string,
    urlPlaceCard: string,
    urlFilterTerm: string
  ): Observable<AliasUrls> {
    if (urlFilterTerm) {
      return this.http.post<AliasUrls>(
        `${this.apiAliasUrl}/${aliasId}/listUrlSearch/${direction}`,
        {
          id: urlPlaceCard,
          label: urlFilterTerm
        }
      );
    } else {
      return this.http.post<AliasUrls>(
        `${this.apiAliasUrl}/${aliasId}/listAllUrl/${direction}`,
        {
          id: urlPlaceCard
        }
      );
    }
  }

  getSingleDoc(aliasId: string, docId: string): Observable<Diagnostic> {
    return this.http.post<Diagnostic>(
      `${this.apiAliasUrl}/${aliasId}/getSingleDoc`,
      {
        id: docId
      }
    );
  }

  deleteDocuments(documents: string[]): Observable<any> {
    return this.http.post<Diagnostic>(
      `${this.apiAliasUrl}/documents/delete`,
      {
        documents
      }
    );

    // http://localhost:8080/noovle4search-admin-rest-service/rest/auth/alias/documents/delete
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FrontEnd } from '../../models/front-end';
import { LanguageService } from 'app/_services/language.service';
import { FrontEndService } from 'app/_services/front-end.service';
import { Type } from 'app/models';
import { ConfigData } from 'app/app.config';
export interface FrontEndType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'fury-front-end-form',
  templateUrl: './front-end-form.component.html',
  styleUrls: ['./front-end-form.component.scss']
})
export class FrontEndFormComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  isFreemaker = false;
  isXslt = false;
  regexId = '';
  // Setto come lingua di default l'italiano
  currentFrontEnd: FrontEnd = {
    idfrontend: '',
    type: '',
    lang: '',
    description: '',
    output_mime_type: '',
    content: ''
  };

  feTypes: FrontEndType[] = [
    { value: 'FREEMARKER', viewValue: 'FREEMARKER' },
    { value: 'XML_GSA', viewValue: 'XML GSA' },
    { value: 'XSLT', viewValue: 'XSLT' },
    { value: 'JSON_GSA', viewValue: 'JSON GSA' }
  ];

  outputTypes: string[] = ['application/json', 'application/xml', 'text/html'];

  // Editor
  languages = [
    { key: 'json', value: 'JSON' },
    { key: 'html', value: 'HTML' },
    { key: 'xml', value: 'XML' }
  ];

  editorOptions = {
    theme: 'vs',
    language: 'html',
    minimap: {
      enabled: false
    }
  };

  langList: Type[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FrontEndFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService,
    private frontendService: FrontEndService
  ) { }

  ngOnInit() {
    this.regexId = ConfigData.regexId;
    this.isEdit = this.data && this.data.idfrontend;
    if(!this.regexId){
      this.regexId = '^[a-z0-9_-]+$'
    }
    this.frontendService.getLanguagesList().subscribe(resp => {
      this.langList = resp;

      if (this.isEdit) {
        this.getFrontendDetail(this.data.idfrontend, this.data.language);
        return;
      } else {
        this.currentFrontEnd.lang = this.data.language || 'it';
      }

      this.buildForm();
    });


  }

  private buildForm(): void {
    this.isFreemaker = this.currentFrontEnd.type === 'FREEMARKER';
    this.isXslt = this.currentFrontEnd.type === 'XSLT';

    this.form = this.formBuilder.group({
      idfrontend: [this.currentFrontEnd.idfrontend, Validators.compose([Validators.pattern(this.regexId), Validators.required])],
      type: [this.currentFrontEnd.type, Validators.required],
      description: [this.currentFrontEnd.description],
      output_mime_type: [this.currentFrontEnd.output_mime_type],
      content: [this.currentFrontEnd.content],
      lang: [
        {
          value: this.currentFrontEnd.lang.trim(),
          disabled: this.isEdit || !!this.data.language
        }
      ],
      languageType: [this.editorOptions.language]
    });

    this.form.valueChanges.subscribe(data => {
      this.isFreemaker = data.type === 'FREEMARKER';
      this.isXslt = data.type === 'XSLT';
    });
  }

  getFrontendDetail(idFrontend: string, language: string): void {
    this.frontendService.getFrontEndDetail(idFrontend, language).subscribe(
      resp => {
        this.currentFrontEnd = resp;
        this.buildForm();
      },
      error => this.buildForm()
    );
  }

  submit() {
    const req = {
      idfrontend: this.form.value.idfrontend,
      type: this.form.value.type,
      description: this.form.value.description ? this.form.value.description.trim() : null,
      output_mime_type: this.form.value.output_mime_type,
      content: this.form.value.content ? this.form.value.content.trim() : null,
      lang: this.isEdit
        ? this.currentFrontEnd.lang
        : this.form.get('lang').value
    } as FrontEnd;

    this.dialogRef.close(req);
  }

  editorLanguageChanged(value: any): void {
    const newConfig = JSON.parse(JSON.stringify(this.editorOptions));
    newConfig.language = value;

    this.editorOptions = newConfig;
  }
}

import { License } from './../../models/license';
import { ConfigData } from './../../app.config';
import { Component, OnInit } from '@angular/core';

// Services
import { ClientService } from '../../_services/client.service';
import { AlertService } from '../../_services/alert.service';
import { LanguageService } from '../../_services/language.service';

// Models
import { ClusterHealth } from '../../models/cluster-health';
import { ApplicationConfig } from 'app/models/application-config';
import { MatDialog } from '@angular/material';
import { UpdateLicenseFormComponent } from '../update-license-form/update-license-form.component';

@Component({
  selector: 'fury-dash4search',
  templateUrl: './dash4search.component.html',
  styleUrls: ['./dash4search.component.scss']
})
export class Dash4searchComponent implements OnInit {
  clusterHealth = new ClusterHealth();

  ConfigData: ApplicationConfig = ConfigData;

  license: License;
  errorLicense : boolean;

  licenseExpiryValidityClass = 'green';
  licenseExpirationTooltip = '';

  hideExpirationDate = false;
  constructor(
    private clientService: ClientService,
    private alertService: AlertService,
    public languageService: LanguageService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getLicense();
    this.getClusterHealth();
  }

  private getClusterHealth(): void {
    this.clientService.getClusterHealth().subscribe(
      response => {
        this.alertService.subj_notification.next(
          this.languageService.getLabel('dashboard.sincronizzazioneCompletata'));
        this.clusterHealth = response;
      }
    );
  }

  private getLicense(): void {
    this.clientService.getLicense().subscribe(
      license => {
        this.errorLicense = false;
        this.license = license;
        this.setLicenseExpirationFlag(license.status);
      },
      error => {
        console.log('error', error)
         this.errorLicense = true;
      }
     )}

  private setLicenseExpirationFlag(status: string): void {

    switch (status) {
      case 'VALID':
        this.licenseExpiryValidityClass = 'green';
        return;
      case 'EXPIRING':
        this.licenseExpiryValidityClass = 'orange';
        return;
      default:
        this.licenseExpiryValidityClass = 'red';
    }
  }

  public openUpdateLicenseModal(): void {
    const dialogRef = this.dialog.open(UpdateLicenseFormComponent, { width: '500px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLicense();
      }
    })
  }
}

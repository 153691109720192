import { Component, Inject, OnInit } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'fury-service-account-code-modal',
  templateUrl: './service-account-code-modal.component.html',
  styleUrls: ['./service-account-code-modal.component.scss']
})
export class ServiceAccountCodeModalComponent implements OnInit {

  public serviceAccountJSON : string = '';
  public serviceAccountStringified;
  constructor(@Inject(MAT_DIALOG_DATA)
  public languageService: LanguageService,
  private dialogRef: MatDialogRef<ServiceAccountCodeModalComponent>,

) { }

  ngOnInit() {
    //thi is intentional
  }

  save() {
    this.dialogRef.close(this.serviceAccountJSON);
  }

  stringifyServiceAccount(){
    let myObj = JSON.parse(this.serviceAccountJSON);
    this.serviceAccountStringified = JSON.stringify(myObj,undefined, 2);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CrawlingWebUrl } from './../../models/crawling-web-url';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-crawling-web-urls-form',
  templateUrl: 'crawling-web-urls-form.component.html',
  styleUrls: ['crawling-web-urls-form.component.scss']
})
export class CrawlingWebUrlsFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CrawlingWebUrlsFormComponent>,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      url: [null, Validators.required]
    });
  }

  submit() {
    this.dialogRef.close(this.form.get('url').value.trim());
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

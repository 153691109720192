import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'fury-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  /**
   * Se viene passato true l'icona viene wrappata in un bottone - default = false
   */
  @Input() wrapButton?: boolean;

  constructor(
    public languageService: LanguageService,
    private authenticationService: AuthenticationService
  ) { }

  public changeLanguage(language: string): void {
    this.languageService.changeLanguage(language).subscribe(res => {
      this.authenticationService.configureMenu();
    });
  }
}

import { ConfigData } from 'app/app.config';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { PolicyService } from 'app/_services/policy.service';

import { MetaBiasing } from '../../../../models/meta-biasing';
import { Meta, Type } from '../../../../models/index';
import { LanguageService } from 'app/_services/language.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertService } from 'app/_services/alert.service';

@Component({
  selector: 'fury-policy-meta-form',
  templateUrl: 'policy-meta-form.component.html',
  styleUrls: ['policy-meta-form.component.scss']
})
export class PolicyMetaFormComponent implements OnInit {
  metaList: Type[] = [];

  currentMeta: MetaBiasing = {
    idBiasing: '',
    boost: 0,
    idMeta: '',
    textRegex: '',
    field: ''
  };
  isEdit = false;
  form: FormGroup;
  filterMeta: Type[];
  filteredMetas: Observable<Type[]>;

  boostMin = -100;
  boostMax = 100;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PolicyMetaFormComponent>,
    private policyService: PolicyService,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    if (!!ConfigData.biasingBoostMin) {
      this.boostMin = ConfigData.biasingBoostMin;
    }

    if (!!ConfigData.biasingBoostMax) {
      this.boostMax = ConfigData.biasingBoostMax;
    }

    this.isEdit = !!this.data;

    if (this.isEdit) {
      this.currentMeta = this.data.meta;
    }
    this.getMetaList();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      idMeta: [
        { value: this.currentMeta.idMeta, disabled: this.isEdit },
        Validators.required
      ],
      text: [this.currentMeta.textRegex],
      boost: [this.currentMeta.boost]
    });

    this.form.get('boost').valueChanges.subscribe(value => {
      this.currentMeta.boost = Math.round(value * 10) / 10;
    });
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    return Math.round(value * 10) / 10;
  }

  sliderChange(event): void {
    this.form.get('boost').patchValue(Math.round(event.value * 10) / 10);
  }

  getMetaList(): void {
    this.policyService.getMetaList().subscribe(list => {
      if (list) {
        this.metaList = list;

        // Compongo i dati per l'autocomplete dei meta
        this.filterMeta = this.metaList;
        this.buildForm();
        this.setupAutocomplete();
      }
    });
  }

  private setupAutocomplete(): void {
    if (this.isEdit) {
      return;
    }

    this.filteredMetas = this.form
      .get('idMeta')
      .valueChanges.pipe(
        startWith(''),
        map(val => <string>val),
        map(value =>
          this.filterMeta.filter(meta =>
            meta.label.toLowerCase().indexOf(value.toLowerCase()) > -1
          )
        )
      );

    this.form.get('idMeta').valueChanges.subscribe(value => {
      const metaSrc = this.filterMeta.find(meta => meta.label === value);
      this.currentMeta.idMeta = metaSrc ? +metaSrc.id : null;
    });
  }

  submit() {
    if (this.currentMeta.boost < this.boostMin || this.currentMeta.boost > this.boostMax) {
      this.alertService.subj_notification.next(
        this.languageService.getLabel(
          'policyBiasingDetail.ErroreBoostMetaAlias'
        ).replace('{min}', this.boostMin.toString()).replace('{max}', this.boostMax.toString())
      );
      return;
    }

    this.dialogRef.close({
      id: this.currentMeta.id || null,
      idMeta: this.currentMeta.idMeta,
      boost: this.currentMeta.boost,
      textRegex: this.form.get('text').value ? this.form.get('text').value.trim() : null,
      idBiasing: this.isEdit ? this.currentMeta.idBiasing : null,
      field: this.isEdit ? this.currentMeta.field : null
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { FilterMeta } from '../../models/filter-meta';
import { Ranking } from '../../models/ranking';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../_services/client.service';

@Component({
  selector: 'fury-ranking-form',
  templateUrl: './ranking-form.component.html',
  styleUrls: ['./ranking-form.component.scss']
})
export class RankingFormComponent implements OnInit {
  currentIdClient: string;
  form: FormGroup;

  filterMeta: FilterMeta[];
  isEdit = false;

  curRanking: Ranking = {
    id: null,
    meta: null,
    boost: 0
  };

  constructor(
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<RankingFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit() {
    this.currentIdClient = this.data.idclient;

    this.clientService
      .getFiltersMetaList()
      .subscribe(res => (this.filterMeta = res));

    this.form = this.formBuilder.group({
      field: [this.curRanking.meta, Validators.required],
      boost: [this.curRanking.boost],
      id: [this.curRanking.id]
    });
  }

  public formatLabel(value: number | null): number {
    if (!value) {
      return 0;
    }

    return value;
  }

  submit(form) {
    this.dialogRef.close({
      id: form.value.id,
      boost: form.value.boost,
      meta: this.getMeta(form.value.field)
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  getMeta(idmeta): FilterMeta {
    return this.filterMeta.find(m => m.id === idmeta);
  }
}

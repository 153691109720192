import { ConfigData } from './../../../../app.config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';

import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatTable,
  MatSnackBar
} from '@angular/material';

import { DictionariesService } from '../../../../_services/dictionaries.service';
import { ConfirmationDialogService } from '../../../../_services/confirmation-dialog.service';
import { LanguageService } from '../../../../_services/language.service';

import { DictionaryFile } from '../../../../models/dictionary-file';
import { Type } from '../../../../models/index';

@Component({
  selector: 'fury-sinonimi',
  templateUrl: './sinonimi.component.html',
  styleUrls: ['./sinonimi.component.scss']
})
export class SinonimiComponent implements OnInit {
  loading = false;
  synonymsList: DictionaryFile[] = [];
  languages: Type[];
  displayedColumns = ['nome'];

  form: FormGroup;
  file: File;

  @ViewChild('table') table: MatTable<DictionaryFile>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<DictionaryFile>;
  hasSentFile: boolean;
  isUploadingFile: boolean;

  CONFIG_DATA = ConfigData;

  constructor(
    private fb: FormBuilder,
    private dictionariesService: DictionariesService,
    private confirmationDialogService: ConfirmationDialogService,
    public languageService: LanguageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loading = true;
    this.initPage();
  }

  private initPage(): void {
    const synonymsListApi = this.dictionariesService.getSynonymsList();
    const synonymsLangApi = this.dictionariesService.getLanguages();
    forkJoin([synonymsListApi, synonymsLangApi])
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(response => {
        if (response[0]) {
          this.synonymsList = response[0];
        }
        if (response[1]) {
          this.languages = response[1];
        }

        this.initTable();
        this.initForm();
      });
  }

  private initTable(): void {
    this.dataSource = new MatTableDataSource(this.synonymsList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  private initForm(): void {
    this.file = null;

    this.form = this.fb.group({
      file: [null, Validators.required],
      language: [null, Validators.required]
    });
  }

  onFileChanged(event): void {
    if (event.target.files && event.target.files.length) {
      this.file = <File>event.target.files[0];
    }
  }

  sendForm(): void {
    this.confirmationDialogService
      .confirm(
        `${this.languageService.getLabel(
          'dictionaries.SendFormPrimoMessaggio'
        )}. ` +
        `${this.languageService.getLabel(
          'dictionaries.SendFormPrimoTitolo'
        )}?`,
        `${this.languageService.getLabel(
          'dictionaries.SendFormPrimoBottoneConferma'
        )}`
      )
      .then(res => {
        if (!res) {
          return;
        }

        this.confirmationDialogService
          .confirm(
            `${this.languageService.getLabel(
              'dictionaries.SendFormSecondoMessaggio'
            )}`,
            `${this.languageService.getLabel(
              'dictionaries.SendFormSecondoTitolo'
            )}`,
            `${this.languageService.getLabel('general.Conferma')}`,
            `${this.languageService.getLabel('general.Annulla')}`,
            'sm',
            `${this.languageService.getLabel(
              'dictionaries.SendFormSecondoPattern'
            )}`
          )
          .then(textRes => {
            if (!textRes) {
              return;
            }

            this.isUploadingFile = true;

            this.dictionariesService
              .saveSynonymsFile(this.form, this.file)
              .pipe(finalize(() => (this.isUploadingFile = false)))
              .subscribe(response => {
                if (response) {
                  if (!this.CONFIG_DATA.cloudSearch) {
                    this.confirmationDialogService.confirm(
                      `${this.languageService.getLabel(
                        'dictionaries.ApplicaModificheMessaggio'
                      )}`,
                      '',
                      `${this.languageService.getLabel('general.OK')}`,
                      '',
                      'sm',
                      null,
                      false
                    );
                  } else {
                    this.snackBar.open(
                      this.languageService.getLabel('general.OperationSuccess'),
                      'OK',
                      {
                        duration: 10000,
                        horizontalPosition: 'center'
                      }
                    );
                  }
                  this.hasSentFile = true;
                  this.initPage();
                }
              });
          });
      });
  }

  downloadFile(file: DictionaryFile): void {
    this.dictionariesService.downloadFile(file.id).subscribe(response => {
      const blob = new Blob([response], { type: 'txt' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = file.filename;
      link.click();
    });
  }

  applyChanges(): void {
    this.confirmationDialogService.confirm(
      `${this.languageService.getLabel(
        'dictionaries.ApplicaModificheConfermaMessage'
      )}`,
      '',
      `${this.languageService.getLabel('general.OK')}`,
      null,
      'sm',
      null,
      false
    );
  }
}

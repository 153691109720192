import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-restore-crawling-web-form',
  templateUrl: './restore-crawling-web-form.component.html'
})
export class RestoreCrawlingWebFormComponent implements OnInit {

  constructor() { 
     // This is intentional
  }

  ngOnInit() {
     // This is intentional
  }

}

export const GlobalVariable = Object.freeze({
  BASE_API_URL:
    'http://35.233.59.16/noovle4search-admin-rest-service/rest/auth',
  BASE_LOGIN_URL: 'http://35.233.59.16/noovle4search-admin-rest-service/rest',
  USE_TOKEN: false
});

export const removeEmpty = obj =>
  Object.keys(obj).forEach(function(key) {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] == null || obj[key] === '') {
      delete obj[key];
    }
  });

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LanguageService } from "app/_services/language.service";

@Component({
  selector: 'fury-wrapper-service-account',
  templateUrl: './wrapper-service-account.component.html',
  styleUrls: ['./wrapper-service-account.component.scss']
})
export class WrapperServiceAccountComponent implements OnInit {

  isPrivateBucket: boolean = false
  @Output() public isPrivateBucketEvent = new EventEmitter<boolean>();
  @Output() public showModalAccountServiceEvent = new EventEmitter<boolean>();

  constructor(public languageService : LanguageService) {
    //this is intentional
   }

  ngOnInit() {
    //this is intentional
  }
  modelChangeFn() {
    this.isPrivateBucketEvent.emit(this.isPrivateBucket);
    if(this.isPrivateBucket){
      this.showServiceAccountModal();
    }
  }
  showServiceAccountModal() {
    this.showModalAccountServiceEvent.emit();
  }
}

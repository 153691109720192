import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ConfigData } from '../app.config';

// Models
import {
  OneboxList,
  Onebox,
  OneboxTimeout,
  OneboxMaxResults
} from 'app/models/onebox';

@Injectable({
  providedIn: 'root'
})
export class OneboxService {
  oneboxUrl = `${ConfigData.apiUrl}/onebox`;
  oneboxConfigurationUrl = `${ConfigData.apiUrl}/configuration/properties`;

  constructor(private http: HttpClient) {}

  getOneboxList(): Observable<OneboxList[]> {
    return this.http.get<OneboxList[]>(this.oneboxUrl);
  }

  getOneboxDetail(idOnebox: string): Observable<Onebox> {
    return this.http.get<Onebox>(`${this.oneboxUrl}/${idOnebox}`);
  }

  addOnebox(onebox: Onebox) {
    return this.http.post<Onebox>(this.oneboxUrl, onebox);
  }

  addKeyword(keyword: string) {
    const obj = {
      keyword: keyword
    };

    return this.http.post<any>(`${this.oneboxUrl}/{oneboxId}keyword`, obj);
  }

  deleteKeyword(keywordId: any): Observable<any> {
    return this.http.delete<any>(`${this.oneboxUrl}/keyword/${keywordId}`);
  }

  deleteOnebox(onebox: any): Observable<any> {
    return this.http.delete<any>(`${this.oneboxUrl}/${onebox.id}`);
  }

  editOnebox(onebox: Onebox): Observable<Onebox> {
    return this.http.put<Onebox>(`${this.oneboxUrl}/${onebox.id}`, onebox);
  }

  // Gestione timeout e maxResults
  getOneBoxTimeout(): Observable<OneboxTimeout> {
    return this.http.get<OneboxTimeout>(
      `${this.oneboxConfigurationUrl}/onebox-timeout`
    );
  }

  getOneBoxMaxResults(): Observable<OneboxMaxResults> {
    return this.http.get<OneboxMaxResults>(
      `${this.oneboxConfigurationUrl}/onebox-maxresult`
    );
  }

  setOneBoxTimeout(timeout: OneboxTimeout): Observable<OneboxTimeout> {
    return this.http.put<OneboxTimeout>(
      `${this.oneboxConfigurationUrl}/onebox-timeout`,
      timeout
    );
  }

  setOneBoxMaxResults(
    maxResults: OneboxMaxResults
  ): Observable<OneboxMaxResults> {
    return this.http.put<OneboxMaxResults>(
      `${this.oneboxConfigurationUrl}/onebox-maxresult`,
      maxResults
    );
  }
}

import { ConfigData } from './../../app.config';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavLink } from 'app/models/nav-link';
import { LanguageService } from 'app/_services/language.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { Router } from '@angular/router';
import { PERMISSION_CODES } from 'app/constants/permission-codes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fury-dictionaries',
  templateUrl: './dictionaries.component.html'
})
export class DictionariesComponent implements OnInit, OnDestroy {
  navLinks: NavLink[];

  selectedRoute: string;

  private destroy$ = new Subject();

  constructor(public languageService: LanguageService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }

  private buildTabs(): void {
    this.navLinks = [];

    this.selectedRoute = this.router.url.split('/')[2] || null;

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.IMPOSTAZIONI_QUERY_TABS.SINONIMI)) {
      this.navLinks.push({
        path: 'sinonimi',
        label: this.languageService.getLabel('dictionaries.Sinonimi'),
        visible: true,
        disabled: false
      });
      this.selectedRoute = this.selectedRoute || 'sinonimi';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.IMPOSTAZIONI_QUERY_TABS.STOPWORDS)) {
      this.navLinks.push({
        path: 'stopwords',
        label: this.languageService.getLabel('dictionaries.IgnoredKeywords'),
        visible: true,
        disabled: false
      });

      this.selectedRoute = this.selectedRoute || 'stopwords';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.IMPOSTAZIONI_QUERY_TABS.BLACKLIST)) {
      this.navLinks.push({
        path: 'blacklist',
        label: this.languageService.getLabel('dictionaries.NonExpandingKeywords'),
        visible: true,
        disabled: false
      });

      this.selectedRoute = this.selectedRoute || 'blacklist';
    }

    if (this.selectedRoute) {
      this.router.navigate(['dictionaries/' + this.selectedRoute]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

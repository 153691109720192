import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fury-template-free-help',
    templateUrl: 'template-free-help.component.html'
})

export class TemplateFreeHelpComponent implements OnInit {
    constructor() {
         // This is intentional
     }

    ngOnInit() { 
         // This is intentional
    }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize, map, tap, startWith } from 'rxjs/operators';

import { ClientService } from '../../../../_services/client.service';
import { LanguageService } from '../../../../_services/language.service';

import { ConfirmKeymatchImportModalComponent } from '../confirm-keymatch-import-modal/confirm-keymatch-import-modal.component';

import { Client } from '../../../../models/client';

@Component({
  selector: 'fury-gsa-configuration-importer-keymatch',
  templateUrl: './gsa-configuration-importer-keymatch.component.html',
  styleUrls : ['./gsa-configuration-importer-keymatch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GsaConfigurationImporterKeymatchComponent implements OnInit {
  clientControl: FormControl = new FormControl('', Validators.required);
  clients: Client[];
  filteredClients: Observable<Client[]>;
  file: File;
  inProgress = false;
  override = false;

  constructor(
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.getClients();
  }

  private getClients(): void {
    this.clientService.getClients().subscribe(response => {
      this.clients = response;

      this.filteredClients = this.clientControl.valueChanges.pipe(
        startWith(''),
        tap(value => {
          if (this.isClient(value)) {
            this.clientControl.setErrors(null);
          } else {
            this.clientControl.setErrors({
              isNotAlias: true
            });
          }
        }),
        map(value => this.filterClients(value))
      );
    });

  }

  /**
   * Ritorna true se la stringa passata è presente tra i clients.
   * E' case insensitive
   * @param value stringa da ricercare nella lista dei clients
   */
  isClient(value: string): boolean {
    return !!this.clients.find(
      client => client.idclient.toLowerCase() === value.toLowerCase()
    );
  }

  /**
   * Ritorna una lista di alias filtrata in base al valore ricevuto
   * @param value Valore da ricercare tra gli alias
   */
  private filterClients(value: string): Client[] {
    const filterValue = value.toLowerCase();
    return this.clients.filter(
      client => client.idclient.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  /**
   * Prende un eventuale file passato dal frontend
   * @param event Evento del bottone scelta file
   */
  onFileChanged(event): void {
    if (event.target.files && event.target.files.length) {
      this.file = <File>event.target.files[0];
    }
  }

  validateFile(): void {
    this.inProgress = true;
    // Prende esattamente il client che serve dalla lista.
    // Altrimenti ad esempio se non viene digitato case sensitive la chiamata ha esito negativo
    this.clientControl.setValue(
      this.clients.find(
        client =>
          client.idclient.toLowerCase() ===
          this.clientControl.value.toLowerCase()
      ).idclient
    );

    this.clientService
      .validateKeymatchFile(this.clientControl.value, this.file)
      .subscribe(
        response => {
          if (response && response.count >= 0) {
            this.openDialog(response.count);
          } else {
            this.inProgress = false;
          }
        },
        () => {
          this.snackBar.open(
            this.languageService.getLabel(
              'gsaConfigurationImporterKeymatch.FormatoFile'
            ),
            this.languageService.getLabel('general.OK')
          );
          this.inProgress = false;
        }
      );
  }
  private openDialog(count: number): void {
    const dialogRef = this.dialog.open(ConfirmKeymatchImportModalComponent, {
      width: '500px',
      data: {
        count: count,
        isKeymatch: true
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.importKeymatch();
      } else {
        this.inProgress = false;
      }
    });
  }

  importKeymatch(): void {
    this.clientService
      .importKeymatch(this.clientControl.value, this.file, this.override)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe(
        res => {
          this.clientControl.setValue('');
          this.file = null;
          this.snackBar.open(
            this.languageService.getLabel(
              'gsaConfigurationImporterKeymatch.SuccessoCaricamento'
            ),
            this.languageService.getLabel('general.OK')
          );
        },
        () =>
          this.snackBar.open(
            this.languageService.getLabel(
              'gsaConfigurationImporterKeymatch.ErroreCaricamento'
            ),
            this.languageService.getLabel('general.OK')
          )
      );
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  MatDialog,
  MatTable,
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { ActivatedRoute } from '@angular/router';

// Serivzi
import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';

// Modelli
import { CrawlingMapping } from 'app/models/crawling-mapping';
import { CrawlingWebMappingFormComponent } from '../crawling-web-mapping-form/crawling-web-mapping-form.component';
import { Mapping } from 'app/models/index';
import { LanguageService } from 'app/_services/language.service';
import { tap } from 'rxjs/operators';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-crawling-web-mapping',
  templateUrl: 'crawling-web-mapping.component.html',
  styleUrls: ['crawling-web-mapping.component.scss']
})
export class CrawlingWebMappingComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<CrawlingMapping>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<CrawlingMapping>;

  loading = false;
  connectionBroken = false;

  displayedColumns: string[] = ['campo_crawler', 'campo_indice', 'star'];

  private paramIDJob: string;

  private mappingFormRef: MatDialogRef<
    CrawlingWebMappingFormComponent,
    CrawlingMapping
  >;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private gestioneCrawlingService: GestioneCrawlingService,
    private changeDetectionRef: ChangeDetectorRef,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.activatedRoute.parent.paramMap.subscribe(res => {
      const paramIDJob = res.get('idJob');
      if (paramIDJob) {
        this.paramIDJob = paramIDJob;
        this.loadMapping();
      }
    });
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  private loadMapping(): void {
    this.gestioneCrawlingService
      .getCrawlingWebMapping(this.paramIDJob)
      .subscribe(
        result => {
          if (result) {
            this.dataSource = new MatTableDataSource(result);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.changeDetectionRef.detectChanges();
          }
        },
        error => (this.connectionBroken = true)
      );
  }

  addMapping(): void {
    this.gestioneCrawlingService.getIndexMapping().subscribe(response => {
      if (response) {
        this.openAddMappingModal(response);
      }
    });
  }

  private openAddMappingModal(metaIndexes: Mapping[]): void {
    this.mappingFormRef = this.dialog.open(CrawlingWebMappingFormComponent, {
      width: '500px',
      data: {
        metaIndexes: metaIndexes
      }
    });
    this.mappingFormRef.afterClosed().subscribe(result => {
      if (!!result && !this.connectionBroken) {
        this.saveMapping(result);
      }
    });
  }

  private saveMapping(mapping: CrawlingMapping): void {
    this.gestioneCrawlingService
      .saveCrawlingWebMapping(this.paramIDJob, mapping)
      .subscribe(result => {
        if (result) {
          this.notificationsService.showDefaultSuccessMessage();
          this.loadMapping();
        }
      });
  }

  deleteMapping(mapping: CrawlingMapping): void {
    this.gestioneCrawlingService
      .deleteCrawlingWebMapping(this.paramIDJob, mapping.id.toString())
      .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.loadMapping());
  }
}

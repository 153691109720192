import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fury-gestione-crawling-db',
    templateUrl: 'gestione-crawling-db.component.html'
})

export class GestioneCrawlingDbComponent implements OnInit {
    constructor() { 
        // This is intentional
    }

    ngOnInit() {
        // This is intentional
     }
}

import { AliasMetaFormComponent } from './../alias-meta-form/alias-meta-form.component';
import { Meta } from 'app/models';
import { ConfigData } from 'app/app.config';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MatTableDataSource,
  MatTable,
  MatSort,
  MatPaginator,
  MatSnackBar,
} from '@angular/material';
import { finalize, tap } from 'rxjs/operators';

import { IndexAliasService } from '../../_services/index-alias.service';
import { LanguageService } from '../../_services/language.service';
import { AlertService } from '../../_services/alert.service';

import { AliasFormComponent } from '../alias-form/alias-form.component';

import { Alias, Pattern } from '../../models/alias';
import { Index } from '../../models/index';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-alias-detail',
  templateUrl: './alias-detail.component.html',
  styleUrls: ['./alias-detail.component.scss'],
})
export class AliasDetailComponent implements OnInit {
  public ConfigData = ConfigData;
  private paramId: string;
  public loading = false;
  alias: Alias;

  // Expansion panels
  indexesPanel = false;
  inclusionPatternsPanel = false;
  exclusionPatternPanel = false;

  indexesList: Index[] = [];
  indexesSource: MatTableDataSource<Index>;
  @ViewChild('indexesTable') indexesTable: MatTable<Index>;
  @ViewChild('idexesSort') indexesSort: MatSort;
  @ViewChild('indexesPaginator', { read: MatPaginator })
  indexesPaginator: MatPaginator;
  indexesDisplayedColumns: string[] = ['id', 'star'];

  inclusionPatternsList: Pattern[] = [];
  inclusionPatternsSource: MatTableDataSource<Pattern>;
  @ViewChild('inclusionPatternsTable') inclusionPatternsTable: MatTable<
    Pattern
  >;
  @ViewChild('includePatternsSort') inclusionPatternsSort: MatSort;
  @ViewChild('inclusionPatternsPaginator', { read: MatPaginator })
  inclusionPatternsPaginator: MatPaginator;
  inclusionPatternsDisplayedColumns: string[] = ['pattern', 'star'];

  exclusionPatternsList: Pattern[] = [];
  exclusionPatternsSource: MatTableDataSource<Pattern>;
  @ViewChild('exclusionPatternsTable') exclusionPatternsTable: MatTable<
    Pattern
  >;
  @ViewChild('exclusionPatternSort') exclusionPatternsSort: MatSort;
  @ViewChild('exclusionPatternsPaginator', { read: MatPaginator })
  exclusionPatternsPaginator: MatPaginator;
  exclusionPatternsDisplayedColumns: string[] = ['pattern', 'star'];

  inclusionMetasList: Meta[] = [];
  inclusionMetasSource: MatTableDataSource<Meta>;
  @ViewChild('inclusionMetasTable') inclusionMetasTable: MatTable<Meta>;
  @ViewChild('inclusionMetasSort') inclusionMetasSort: MatSort;
  @ViewChild('inclusionMetasPaginator', { read: MatPaginator })
  inclusionMetasPaginator: MatPaginator;
  inclusionMetasDisplayedColumns: string[] = ['field', 'value', 'star'];

  exclusionMetasList: Meta[] = [];
  exclusionMetasSource: MatTableDataSource<Meta>;
  @ViewChild('exclusionMetasTable') exclusionMetasTable: MatTable<Meta>;
  @ViewChild('exclusionMetasSort') exclusionMetasSort: MatSort;
  @ViewChild('exclusionMetasPaginator', { read: MatPaginator })
  exclusionMetasPaginator: MatPaginator;
  exclusionMetasDisplayedColumns: string[] = ['field', 'value', 'star'];

  aliasFormRef: MatDialogRef<AliasFormComponent>;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private indexAliasService: IndexAliasService,
    private changeDetectionRef: ChangeDetectorRef,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((res) => {
      this.paramId = res.get('id');
      if (this.paramId) {
        this.getData();
      }
    });
  }

  private getData(): void {
    this.loading = true;
    this.indexAliasService
      .getAlias(this.paramId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((alias) => {
        this.alias = alias;
        this.indexesList = this.alias.indexes;
        this.inclusionPatternsList = this.alias.inclusionPatterns;
        this.exclusionPatternsList = this.alias.exclusionPatterns;
        this.inclusionMetasList = this.alias.inclusionMetas;
        this.exclusionMetasList = this.alias.exclusionMetas;
        this.loadTables();
      });
  }

  private loadTables(): void {
    this.indexesSource = new MatTableDataSource(this.indexesList);
    this.indexesSource.sort = this.indexesSort;
    this.indexesSource.paginator = this.indexesPaginator;

    this.inclusionPatternsSource = new MatTableDataSource(
      this.inclusionPatternsList
    );
    this.inclusionPatternsSource.sort = this.inclusionPatternsSort;
    this.inclusionPatternsSource.paginator = this.inclusionPatternsPaginator;

    this.exclusionPatternsSource = new MatTableDataSource(
      this.exclusionPatternsList
    );
    this.exclusionPatternsSource.sort = this.exclusionPatternsSort;
    this.exclusionPatternsSource.paginator = this.exclusionPatternsPaginator;
    this.exclusionPatternsSource.filter = '';

    this.inclusionMetasSource = new MatTableDataSource(this.inclusionMetasList);
    this.inclusionMetasSource.sort = this.inclusionMetasSort;
    this.inclusionMetasSource.paginator = this.inclusionMetasPaginator;

    this.exclusionMetasSource = new MatTableDataSource(this.exclusionMetasList);
    this.exclusionMetasSource.sort = this.exclusionMetasSort;
    this.exclusionMetasSource.paginator = this.exclusionMetasPaginator;
  }

  backToList(): void {
    this.router.navigate(['indici-alias', 'alias']);
  }

  /* INDEXES */
  public indexesSortData(): void {
    this.indexesSource.sort = this.indexesSort;
  }

  public openModalAddIndex(): void {
    this.aliasFormRef = this.dialog.open(AliasFormComponent, {
      width: '500px',
      data: { mode: 1, currentAlias: this.alias },
    });
    this.aliasFormRef.afterClosed().subscribe((result) => {
      if (result) {
        this.indexAliasService
          .saveIndexAlias(this.alias.id, result.id)
          .pipe(
            tap((_) => this.notificationsService.showDefaultSuccessMessage())
          )
          .subscribe(() => this.getData());
      }
    });
  }

  public deleteIndex(index: Index): void {
    this.indexAliasService
      .deleteIndexAlias(this.alias.id, index.id)
      .pipe(tap((_) => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.getData());
  }

  /* INCLUDE PATTERN */
  public inclusionPatternsSortData(): void {
    this.inclusionPatternsSource.sort = this.inclusionPatternsSort;
  }

  public openModalAddIncludePattern(): void {
    this.aliasFormRef = this.dialog.open(AliasFormComponent, {
      width: '500px',
      data: { mode: 2 },
    });
    this.aliasFormRef.afterClosed().subscribe((result) => {
      if (result) {
        result.type = 'inclusion';
        this.indexAliasService
          .savePatternAlias(this.alias.id, result)
          .pipe(
            tap((_) => this.notificationsService.showDefaultSuccessMessage())
          )
          .subscribe(() => this.getData());
      }
    });
  }

  public deleteIncludePattern(pattern: Pattern): void {
    this.indexAliasService
      .deletePatternAlias(this.alias.id, pattern.id)
      .pipe(tap((_) => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.getData());
  }

  /* EXCLUDE PATTERN */
  public exclusionPatternsSortData(): void {
    this.exclusionPatternsSource.sort = this.exclusionPatternsSort;
  }

  public openModalAddExcludePattern(): void {
    this.aliasFormRef = this.dialog.open(AliasFormComponent, {
      width: '500px',
      data: { mode: 2 },
    });
    this.aliasFormRef.afterClosed().subscribe((result) => {
      if (result) {
        result.type = 'exclusion';
        this.indexAliasService
          .savePatternAlias(this.alias.id, result)
          .pipe(
            tap((_) => this.notificationsService.showDefaultSuccessMessage())
          )
          .subscribe(() => this.getData());
      }
    });
  }

  public deleteExcludePattern(pattern: Pattern): void {
    this.indexAliasService
      .deletePatternAlias(this.alias.id, pattern.id)
      .pipe(tap((_) => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => {
        this.getData();
      });
  }

  /* META */
  public inclusionMetasSortData(): void {
    this.inclusionMetasSource.sort = this.inclusionMetasSort;
  }

  public exclusionMetasSortData(): void {
    this.exclusionMetasSource.sort = this.exclusionMetasSort;
  }

  public addNewMeta(type: 'inclusion' | 'exclusion'): void {
    const metaDialog = this.dialog.open(AliasMetaFormComponent, {
      width: '500px',
    });

    metaDialog.afterClosed().subscribe((result) => {
      if (result) {
        const req = {
          idMeta: result.idMeta,
          value: result.textRegex,
          type
        };

        this.indexAliasService
          .saveMetaAlias(this.alias.id, req)
          .pipe(
            tap((_) => this.notificationsService.showDefaultSuccessMessage())
          )
          .subscribe(() => this.getData());
      }
    });
  }

  public deleteMeta(meta: Meta): void {
    this.indexAliasService
      .deleteMetaAlias(this.alias.id, meta)
      .pipe(tap((_) => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.getData());
  }

  // General Method

  applyActiveFilter(tableCod: string, filter: string): void {
    switch (tableCod) {
      case 'index':
        this.indexesSource.filter = filter.trim().toLowerCase();
        break;
      case 'includePath':
        this.inclusionPatternsSource.filter = filter.trim().toLowerCase();
        break;
      case 'excludePath':
        this.exclusionPatternsSource.filter = filter.trim().toLowerCase();
        break;
      case 'inclusionMeta':
        this.inclusionMetasSource.filter = filter.trim().toLowerCase();
        break;
      case 'exclusionMeta':
        this.exclusionMetasSource.filter = filter.trim().toLowerCase();
        break;
    }
  }
}

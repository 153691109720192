import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { OneboxService } from '../../_services/onebox.service';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-client-onebox-form',
  templateUrl: './client-onebox-form.component.html',
  styleUrls: ['./client-onebox-form.component.scss']
})
export class ClientOneboxFormComponent implements OnInit {
  form: FormGroup;

  oneboxes: any[];

  constructor(
    private formBuilder: FormBuilder,
    private oneboxService: OneboxService,
    private dialogRef: MatDialogRef<ClientOneboxFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.oneboxService.getOneboxList().subscribe(result => {
      this.oneboxes = result;
    });

    this.form = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  submit(form) {
    const onebox = this.getOnebox(this.form.value.name);
    this.dialogRef.close({
      id: this.form.value.name,
      name: onebox.name,
      activation: onebox.activation
    });
  }

  getOnebox(id): any {
    return this.oneboxes.find(m => m.id === id);
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'fury-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input() dataset: any;
  displayedColumns: string[] = [];
  orderedArray = [];
  dataSource: MatTableDataSource<any>;
  loading = false;

  constructor() {
    //this is intentionally
  }

  ngOnInit() {
    this.getReportsTable();
  }

  getReportsTable(): void {
    this.getDataFrom();
  }


  getDataFrom() {
    this.displayedColumns = this.dataset.labels;
    if (this.dataset !== 'error') {
      this.dataset.dataset.forEach((element: any, index, array) => {
        element.forEach((item: any) => {
          const secondLevelindex = element.findIndex((x, indexOfX) => {
            if (x === item) {
              return true
            }
          })
          let section = this.displayedColumns[secondLevelindex];
  
  
          if (secondLevelindex === 0) {
            this.orderedArray.push({ [section]: item });
          } else {
            let object = this.orderedArray[index];
            object[section] = item;
          }
        });
        if (index === array.length - 1) {
          this.dataSource = new MatTableDataSource(this.orderedArray);
          this.dataSource.sort = this.sort;
        }
      });
    }
  }


  sortData(): void {
    this.dataSource.sort = this.sort;
  }
}

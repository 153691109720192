import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReportService } from 'app/_services/report.service';
import * as Chart from 'chart.js';
import { ChartConfiguration } from 'chart.js';
import { Helpers } from 'app/_helpers/helpers';
import { LanguageService } from 'app/_services/language.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'analytics-report-action',
  templateUrl: './analytics-report-action.component.html',
  styleUrls: ['./analytics-report-action.component.scss']

})
export class AnalyticsReportActionComponent implements OnInit {
  @ViewChild('queryCounts', { read: ElementRef }) queryCounts: ElementRef;
  @ViewChild('queryPercentage', { read: ElementRef }) queryPercentage: ElementRef;
  @ViewChild('dailySeasonalQuery', { read: ElementRef }) dailySeasonalQuery: ElementRef;
  @ViewChild('computePerformanceDelta', { read: ElementRef }) computePerformanceDelta: ElementRef;
  @ViewChild('scatterQueries', { read: ElementRef }) scatterQueries: ElementRef;
  @ViewChild('pageClicks', { read: ElementRef }) pageClicks: ElementRef;
  @Output() credentials = new EventEmitter<any>();  
  @Input() data;
  payloadReq;
  datasetComputePerformanceDelta; datasetAnomalyDetectionQueryContentTable; datasetPageFarClicks;
  queryCountsGraph; queryPercentageGraph; dailySeasonalQueryGraph; scatterQueriesGraph; pageClicksGraph;
  constructor(private reportService: ReportService, private helpers: Helpers, private languageService : LanguageService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.payloadReq = {
      "site": this.data.site,
      "from_date": this.helpers.getIsoStringDate(this.data.start),
      "to_date": this.helpers.getIsoStringDate(this.data.end),
      "frequency": "1D"
    }


    this.generateQueryCountsGraph();
    this.generateQueryPercentageGraph();
    this.generateDailySeasonalQueryGraph();
    this.generateComputePerformanceDeltaGraph();
    this.generateScatterQueriesGraph();
    this.generateAnomalyDetectionQueryContentTable();
    this.generatepageClicks();
    this.generatePageFarClicksTable();
  }


  generateQueryCountsGraph() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, { 'top_n': 10 });
    this.reportService.get_query_counts(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'bar');
        this.queryCountsGraph = new Chart(
          this.queryCounts.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'bar',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function (value) {
                      console.log(1);
                      return value.toString().substring(0, 10);//truncate
                    },
                  }
                }],
                yAxes: [{}]
              },
            }
          }
        );
      }else{
        this.queryCountsGraph = 'error';
      }
    }, error => {
      this.queryCountsGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateQueryPercentageGraph() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, { 'top_n': 5 });
    this.reportService.get_query_percentage_actions(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'pie');
        this.queryPercentageGraph = new Chart(
          this.queryPercentage.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'pie',
            data: res
          }
        );
      }else{
        this.queryPercentageGraph = 'error';
      }
    }, error => {
      this.queryPercentageGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateDailySeasonalQueryGraph() {
    this.reportService.addReportCount();
    this.reportService.get_daily_seasonal_query(this.payloadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'bar-stacked');
        this.dailySeasonalQueryGraph = new Chart(
          this.dailySeasonalQuery.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'bar',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: false
                }]
              }
            }
          }
        );
      }else{
        this.dailySeasonalQueryGraph = 'error';
      }
    }, error => {
      this.dailySeasonalQueryGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateComputePerformanceDeltaGraph() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, { 'top_n': 3 });
    this.reportService.compute_performance_delta(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        this.datasetComputePerformanceDelta = res;
      }else{
        this.datasetComputePerformanceDelta = 'error';
      }
    }, error => {
      this.datasetComputePerformanceDelta = 'error';
      this.reportService.removeReportCount();
    })
  }

  generateAnomalyDetectionQueryContentTable() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, { 'top_n': 3 });
    this.reportService.anomaly_detection_query_content(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        this.datasetAnomalyDetectionQueryContentTable = res;
      }else{
        this.datasetAnomalyDetectionQueryContentTable = 'error';
      }
    }, error => {
      this.datasetAnomalyDetectionQueryContentTable = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateScatterQueriesGraph() {
    this.reportService.addReportCount();
    this.reportService.get_scatter_queries(this.payloadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {

        res = this.helpers.customizeChartsColors(res, 'scatter');
        this.scatterQueriesGraph = new Chart(
          this.scatterQueries.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'scatter',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  type: 'linear',
                  position: 'bottom'
                }]
              }
            }
          }
        );
      }else{
        this.scatterQueriesGraph = 'error';
      }
    }, error => {
      this.scatterQueriesGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generatepageClicks () {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, { 'top_n': 10 });
    this.reportService.get_page_clicks(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'bar');
        this.pageClicksGraph = new Chart(
          this.pageClicks.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'bar',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function (value) {
                      return value.toString().substring(0, 10);//truncate
                    },
                  }
                }],
                yAxes: [{}]
              },
            }
          }
        );
      }else{
        this.pageClicksGraph = 'error';
      }
    }, error => {
      this.pageClicksGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generatePageFarClicksTable() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, { 'top_n': 5 });
    this.reportService.get_page_far_clicks(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        this.datasetPageFarClicks = res;
     }else{
      this.datasetPageFarClicks = 'error';
     }
    }, error => {
      this.datasetPageFarClicks = 'error';
      this.reportService.removeReportCount();
    })
  }
  showInfoChartAction(infoTemplate){
    this.dialog.open(infoTemplate, {
      width: '500px',
    });
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';

import { ReportService } from '../../../_services/report.service';
import { LanguageService } from '../../../_services/language.service';
import { IndexAliasService } from '../../../_services/index-alias.service';

import { TemplateViewReportComponent } from '../template-report-view/template-report-view.component';

import { ConfigData } from '../../../app.config';

import { Type } from '../../../models/index';
import { ActiveAlias } from '../../../models/alias-list';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'app/_services/alert.service';
import { ConfirmationDialogComponent } from 'app/n4s/confirmation-dialog/confirmation-dialog.component';

/**
 * Formati file disponibili con relative classi css
 */
type FileFormat = {
  value: 'csv' | 'pdf';
  class: 'fal fa-file-csv' | 'fal fa-file-pdf';
};

/**
 * Modello di configuraizone per il comportamento dei campi del form
 */
type ReportConfig = {
  /**
   * Se abilitare o meno il campo data esatta
   */
  exactDateDisabled: boolean;
  /**
   * Se abilitare o meno il campo data da
   */
  fromDateDisabled: boolean;
  /**
   * Se abilitare o meno il campo data a
   */
  toDateDisabled: boolean;
  /**
   * Se forzare a true o meno il checkbox
   */
  aliasRequired: boolean;
  /**
   * Se il download formato csv è disponibile
   */
  csvNotDownloadable: boolean;
  /**
   * Se il download formato pdf è disponibile
   */
  pdfNotDownloadable: boolean;
};

/**
 * Report disponibili
 */
const enum Report {
  TSD = 'TSD',
  TSH = 'TSH',
  TWU = 'TWU',
  WNS = 'WNS',
  FULL = 'FULL',
  FHC = 'FHC',
  FULL_NO_CLICK = 'FULL_NO_CLICK',
  ART = 'ART',
  NFP = 'NFP',
  TPC = 'TPC',
  NCR = 'NCR',
  NIC = 'NIC',
  TRC = 'TRC',
  RES = 'RES'
}

// TODO: Riaguardare le configurazioni per ciascun report
/**
 * Configurazione comportamento form per ciuascun form
 */
const formConfig: { [key in Report]: ReportConfig } = {
  [Report.ART]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.FHC]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.FULL]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: true,
    pdfNotDownloadable: false
  },
  [Report.FULL_NO_CLICK]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: true,
    pdfNotDownloadable: false
  },
  [Report.NCR]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: true,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.NFP]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: true,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.NIC]: {
    exactDateDisabled: true,
    fromDateDisabled: true,
    toDateDisabled: true,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.TPC]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: true,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.TSD]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.TSH]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.TWU]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.WNS]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.TRC]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: true,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  },
  [Report.RES]: {
    exactDateDisabled: false,
    fromDateDisabled: false,
    toDateDisabled: false,
    aliasRequired: false,
    csvNotDownloadable: false,
    pdfNotDownloadable: false
  }
};

@Component({
  selector: 'fury-global-reports',
  templateUrl: './global-reports.component.html',
  styleUrls: ['./global-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalReportsComponent implements OnInit {
  isDownloading = false;
  reportForm: FormGroup;
  reports: Type[] = [];
  activeAliases: ActiveAlias[] = [];
  filteredActiveAliases: Observable<ActiveAlias[]>;
  formConfig = formConfig;
  lastDate : Date = new Date(); 
  fileFormats: FileFormat[] = [
    {
      value: 'csv',
      class: 'fal fa-file-csv'
    }
  ];

  /**
   * Lista degli id dei reports che devono essere nascosti nella tendina di scelta se
   * il cliente è cloudSearch.
   */
  cloudSearchDisabledReportsID: string[] = ['NIC', 'NFP', 'NCR', 'TPC', 'TRC'];

  excludedTermsList: string[] = [];
  // Flag che serve a capire quando l'utente ha il focus sul campo excludedTerms.
  // Quando il campo non è ancora stato toccato sono presenti delle parole di esempio
  // per far capire all'utente come deve inserire le parole, ma appena viene emesso
  // l'evento focus viene cancellato il contenuto del campo, cosa che non deve
  // avvenire le volte successive che l'utente inserisce del testo nel campo.
  firstTimeFocus = true;
  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    public languageService: LanguageService,
    private indexAliasService: IndexAliasService,
  ) {
    // Verifico se il download del PDF è disponibile
    if (ConfigData.enablePdfPrint) {
      this.fileFormats = [
        ...this.fileFormats,
        {
          value: 'pdf',
          class: 'fal fa-file-pdf'
        }
      ];
    }
  }

  ngOnInit() {
    this.initForm();
    this.setFormSubscriptions();
    this.getReports();
    this.getActiveAliases();
  }

  private initForm(): void {
    this.reportForm = this.fb.group({
      report: ['', Validators.required],
      title: ['', Validators.required],
      dates: this.fb.group(
        {
          exactDate: [''],
          fromDate: [''],
          toDate: ['']
        },
        { validator: this.atLeastOne(Validators.required) }
      ),
      enableAlias: [false],
      activeAlias: [{ value: '', disabled: true }],
      hasExcludedTerms: [false],
      excludedTerms: ['test,'],
      fileFormat: ['csv']
    });
  }

  /**
   * Validatore per FormControl che verifica che almeno un campo sia valido
   */
  private atLeastOne = (validator: ValidatorFn) => (
    group: FormGroup
  ): Validators | null => {
    // Il controllo è valido solo se è valorizzato exactDate o se sono valorizzati
    // entrambi fromDate e toDate

    let hasAtLeastOne = false;

    if (!!group.value.fromDate || !!group.value.toDate) {
      hasAtLeastOne = !!group.value.fromDate && !!group.value.toDate;
    } else {
      hasAtLeastOne = !!group.value.exactDate;
    }

    return hasAtLeastOne ? null : { atLastOne: true };
  };


  setValueDate(value){
    const exactDate = this.reportForm.get('dates.exactDate');
    if (value) {
      exactDate.setValue('');
    }
  }
  /**
   * Inizializzazione di tutte le subscriptions relative ai campi del form
   */
  private setFormSubscriptions(): void {
    const report = this.reportForm.get('report');
    const exactDate = this.reportForm.get('dates.exactDate');
    const fromDate = this.reportForm.get('dates.fromDate');
    const toDate = this.reportForm.get('dates.toDate');
    const enableAlias = this.reportForm.get('enableAlias');
    const activeAlias = this.reportForm.get('activeAlias');
    const hasExcludedTerms = this.reportForm.get('hasExcludedTerms');
    const excludedTerms = this.reportForm.get('excludedTerms');
    // Quando cambia il report effettuo i controlli su tutti i campi
    report.valueChanges.pipe(map(value => <Type>value)).subscribe(value => {
      // Azzero tutto perché ho cambiato il report
      // Gestione campo exactData
      formConfig[value.id].exactDateDisabled
        ? exactDate.disable()
        : exactDate.enable();
      // Gestione campo fromData
      formConfig[value.id].fromDateDisabled
        ? fromDate.disable()
        : fromDate.enable();
      // Gestione campo toData
      formConfig[value.id].toDateDisabled ? toDate.disable() : toDate.enable();
      // Gestione checkbox
      enableAlias.setValue(formConfig[value.id].aliasRequired);
      formConfig[value.id].aliasRequired
        ? enableAlias.disable()
        : enableAlias.enable();
    });
    // Cambio contenuto exactDate
    exactDate.valueChanges.subscribe((value: any) => {
      if (value) {
        fromDate.setValue('');
        toDate.setValue('');
      }
    });
    // Cambio contenuto fromDate
    fromDate.valueChanges.subscribe((value: any) => {
      this.setValueDate(value);
    });
    // Cambio contenuto toDate
    toDate.valueChanges.subscribe((value: any) => {
      this.setValueDate(value);
    });
    // Cambio contenuto checbox alias
    enableAlias.valueChanges
      .pipe(map(value => <boolean>value))
      .subscribe(value => {
        if (value) {
          activeAlias.enable();
          activeAlias.setValidators(Validators.required);
          activeAlias.updateValueAndValidity();
        } else {
          activeAlias.disable();
          activeAlias.setValue('');
          activeAlias.clearValidators();
        }
      });
    // Cambio contenuto alias
    this.filteredActiveAliases = activeAlias.valueChanges.pipe(
      map(value => <string>value),
      tap(value => {
        // Se l'alias inserito è presente in lista allora tolgo gli errori
        if (!!this.getActiveAliasFromList(value)) {
          activeAlias.setErrors(null);
        } else {
          // Finchè c'è un valore lascio il campo con errore
          // else non c'è bisogno perché non essendo required quando
          // non c'è valore in automatico vengono tolti gli errori
          if (activeAlias.value) {
            activeAlias.setErrors({
              isNotAlias: true
            });
          }
        }
      }),
      map(value => this.filterActiveAliases(value))
    );

    // Gestione termini da escludere
    hasExcludedTerms.valueChanges.subscribe(value => {
      if (this.firstTimeFocus) {
        this.excludedTermsList = ['test'];
      }
    });

    excludedTerms.valueChanges.subscribe((value: string) => {
      if (value && value.indexOf(',') > 0) {
        this.buildExcludedTerms(value.trim());
      }
    });
  }

  clearExcludedTermsExamples(): void {
    if (this.firstTimeFocus) {
      this.reportForm.get('excludedTerms').patchValue('');
      this.excludedTermsList = [];
      this.firstTimeFocus = false;
    }
  }

  private buildExcludedTerms(term: string): void {
    this.excludedTermsList.push(term.replace(',', ''));
    this.reportForm.get('excludedTerms').patchValue('');
  }

  clearTerm(term: string): void {
    const index = this.excludedTermsList.indexOf(term);
    this.excludedTermsList.splice(index, 1);
  }

  cleanResearch(): void {
    this.initForm();
    this.setFormSubscriptions();
  }

  /**
   * Richiama la lista di reports
   */
  private getReports(): void {
    this.reportService.getReportList().subscribe(response => {
      if (!ConfigData.cloudSearch) {
        this.reports = response;
      } else {
        this.reports = response.filter(
          rep => this.cloudSearchDisabledReportsID.indexOf(rep.id) < 0
        );
      }
    });
  }

  /**
   * Richiama la lista di collezioni
   */
  private getActiveAliases(): void {
    this.indexAliasService
      .getActiveAliases()
      .subscribe(response => (this.activeAliases = response));
  }

  /**
   * Ritorna l'alias se l'id inserito viene trovato in lista.
   * E' case insensitive
   * @param value id dell'alias da ricercare
   */
  private getActiveAliasFromList(value: string): ActiveAlias {
    return this.activeAliases.find(
      alias => alias.id.toLowerCase() === value.toLowerCase()
    );
  }

  /**
   * Ritorna una lista di alias filtrata in base al valore ricevuto
   * @param value Valore da ricercare tra gli alias
   */
  private filterActiveAliases(value: string): ActiveAlias[] {
    const filterValue = value.toLowerCase();
    return this.activeAliases.filter(
      alias => alias.id.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  /**
   * Costruisce il template prendendo le informazioni dal form
   */
  private getReportTemplate(): any {
    const title = this.reportForm.get('title').value;
    const exactDate = this.reportForm.get('dates.exactDate').value
      ? this.reportForm.get('dates.exactDate').value.format('D/M/YYYY')
      : null;
    const fromDate = this.reportForm.get('dates.fromDate').value
      ? this.reportForm.get('dates.fromDate').value.format('D/M/YYYY')
      : null;
    const toDate = this.reportForm.get('dates.toDate').value
      ? this.reportForm.get('dates.toDate').value.format('D/M/YYYY')
      : null;
    const activeAlias = this.reportForm.get('activeAlias').value;
    return {
      title: title,
      exactDate: exactDate || null,
      start: fromDate || null,
      end: toDate || null,
      site: activeAlias || null,
      excludeTerms: this.excludedTermsList.length
        ? this.excludedTermsList
        : null
    };
  }

  /**
   * Richiama il componente per visualizzare il report
   */
  viewReport(): void {
    const codiceReport = this.reportForm.get('report').value.id;
    const payload = this.getReportTemplate();
    const descrizioneReport = this.reportForm.get('report').value.label;

    this.reportService.getReport(codiceReport, payload).subscribe(resp => {
      this.dialog.open(TemplateViewReportComponent, {
        width: '1200px',
        data: {
          template: {
            codiceReport,
            payload,
            descrizioneReport,
            currentReport: resp
          }
        },
        panelClass: 'ReportsDialog'
      });
    }, error => this.handleMaximumRangeError(error));
  }

  private handleMaximumRangeError(error: HttpErrorResponse): void {

    this.snackBar.dismiss();

    if (error.status === 400) {
      const confirmModalRef = this.dialog.open(ConfirmationDialogComponent);
      confirmModalRef.componentInstance.showCancelButton = false;
      confirmModalRef.componentInstance.title = this.languageService.getLabel(
        'general.Attenzione'
      );
      confirmModalRef.componentInstance.message = this.languageService.getLabel('error.range_date_elevato');
    }
  }

  /**
   * Decide quale servizio chiamare per il download
   */
  downloadReport(): void {
    this.isDownloading = true;
    switch (this.reportForm.get('fileFormat').value) {
      case 'csv':
        this.downloadCsv();
        break;
      case 'pdf':
        this.downloadPdf();
        break;
    }
  }

  saveReportForHistory(): void {
    this.snackBar.open(
      this.languageService.getLabel('globalReports.MessaggioGenerazione'),
      'OK',
      { duration: 10000 }
    );

    setTimeout(() => {
      const exportFileFormat = this.reportForm.get('fileFormat').value;
      const reportID = this.reportForm.get('report').value.id;
      const reportReq = this.getReportTemplate();
      reportReq.save = true;

      this.reportService.saveReportForHistory(exportFileFormat, reportID, reportReq).subscribe(() => {
        this.snackBar.open(
          this.languageService.getLabel('globalReports.ReportGenerato'),
          'OK',
          { duration: 10000 }
        );
      });
    }, 3000);

  }

  /**
   * Chiama il servizio per scaricare il CSV
   */
  private downloadCsv(): void {
    this.reportService
      .downloadReportCSV(
        this.reportForm.get('report').value.id,
        this.getReportTemplate()
      )
      .pipe(finalize(() => (this.isDownloading = false)))
      .subscribe(response => {
        this.downloadFile(response, `${this.getReportTemplate().title}.csv`);
      }, error => this.handleMaximumRangeError(error));
  }

  /**
   * Richiama il servizio per scaricare il PDF
   */
  private downloadPdf(): void {
    this.reportService
      .downloadReportPDF(
        this.reportForm.get('report').value.id,
        this.getReportTemplate()
      )
      .pipe(finalize(() => (this.isDownloading = false)))
      .subscribe(response => {
        this.downloadFile(response, `${this.getReportTemplate().title}.pdf`);
      }, error => this.handleMaximumRangeError(error));
  }

  /**
   * Crea un bottone e simula il click per effettuare il download
   * @param stream Stream del PDF
   * @param filename Nome del file
   */
  private downloadFile(stream: any, filename: string): void {
    const downloadURL = window.URL.createObjectURL(stream);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    link.click();
  }
}

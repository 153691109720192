import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'app/_services/language.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { ConfigData } from 'app/app.config';

@Component({
  selector: 'fury-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit {
  isOpen: boolean;
  userName = '';

  isLDAP: boolean;

  constructor(public router: Router, public languageService: LanguageService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = user && user.res ? user.res.username : '-';
    this.userName = this.userName.replace(/^\w/, c => c.toUpperCase());
    this.isLDAP = ConfigData.authenticationMode === 'LDAP';
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
    this.authenticationService.logout();
  }

  goToProfile() {
    this.router.navigate(['/user-area']);
  }
}

import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatTable,
  MatTableDataSource,
  MatSort,
  MatPaginator
} from '@angular/material';
import { ReportService } from '../../../_services/report.service';
import {
  Report,
  WordsNotSearched,
  NotFirstPageClick,
  TopSearchedWords,
  ListNumberItemsCollection,
  ListNumberClicksForResult,
  ListAverageResponseTime,
  UrlCountList,
  UrlWithErrorStatus
} from '../../../models/report';
import * as Chart from 'chart.js';
import { ChartConfiguration } from 'chart.js';
import defaultsDeep from 'lodash-es/defaultsDeep';
import { LanguageService } from 'app/_services/language.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'fury-template-report-view',
  templateUrl: './template-report-view.component.html',
  styleUrls: ['./template-report-view.component.scss']
})
export class TemplateViewReportComponent implements OnInit {
  titlePage = '';
  searchTotal = '';
  totalColor = [];

  // listAverageResponseTime
  public readonly listAverageResponseTime = 'ART';
  @ViewChild('listAverageResponseTimeTable')
  listAverageResponseTimeTable: MatTable<ListAverageResponseTime>;
  @ViewChild('listAverageResponseTimeSort')
  listAverageResponseTimeSort: MatSort;
  @ViewChild('listAverageResponseTimePaginator')
  listAverageResponseTimePaginator: MatPaginator;
  listAverageResponseTimeDisplayedColumns: string[] = [
    'query',
    'client',
    'min',
    'average',
    'max'
  ];
  listAverageResponseTimeDataSource: MatTableDataSource<
    ListAverageResponseTime
  >;
  listAverageResponseTimeOBJ = {
    visibilityBox: true,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // listDailySearch
  public readonly listDailySearchKey = 'TSD';
  @ViewChild('listDailySearchChart', { read: ElementRef })
  listDailySearchChart: ElementRef;
  listDailySearchOBJ = {
    visibilityBox: true,
    chart: null,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // perHourSearch
  public readonly perHourSearchKey = 'TSH';
  @ViewChild('perHourSearchChart', { read: ElementRef })
  perHourSearchChart: ElementRef;
  perHourSearchOBJ = {
    visibilityBox: true,
    chart: null,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // listTopSearchedWords
  public readonly listTopSearchedWordsKey = 'TWU';
  @ViewChild('listTopSearchedWordsChart', { read: ElementRef })
  listTopSearchedWordsChart: ElementRef;
  @ViewChild('listTopSearchedWordsTable') listTopSearchedWordsTable: MatTable<
    TopSearchedWords
  >;
  @ViewChild('listTopSearchedWordsSort') listTopSearchedWordsSort: MatSort;
  @ViewChild('listTopSearchedWordsPaginator')
  listTopSearchedWordsPaginator: MatPaginator;
  listTopSearchedWordsDisplayedColumns: string[] = [
    'searchedWord',
    'recurrenceNumber'
  ];
  listTopSearchedWordsDataSource: MatTableDataSource<TopSearchedWords>;
  listTopSearchedWordsOBJ = {
    visibilityBox: true,
    chart: null,
    truncateData: 15,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // wordsNotSearched
  public readonly wordsNotSearchedKey = 'WNS';
  @ViewChild('wordsNotSearchedTable') wordsNotSearchedTable: MatTable<
    WordsNotSearched
  >;
  @ViewChild('wordsNotSearchedSort') wordsNotSearchedSort: MatSort;
  @ViewChild('wordsNotSearchedPaginator')
  wordsNotSearchedPaginator: MatPaginator;
  wordsNotSearchedDisplayedColumns: string[] = ['query', 'client', 'total'];
  wordsNotSearchedDataSource: MatTableDataSource<WordsNotSearched>;
  wordsNotSearchedOBJ = {
    visibilityBox: true,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // urlCountList
  public readonly urlCountListKey = 'FHC';
  @ViewChild('urlCountListChart', { read: ElementRef })
  urlCountListChart: ElementRef;
  @ViewChild('urlCountListTable') urlCountListTable: MatTable<UrlCountList>;
  @ViewChild('urlCountListSort') urlCountListSort: MatSort;
  @ViewChild('urlCountListPaginator')
  urlCountListPaginator: MatPaginator;
  urlCountListDisplayedColumns: string[] = ['url', 'recurrenceNumber'];
  urlCountListDataSource: MatTableDataSource<UrlCountList>;
  urlCountListOBJ = {
    visibilityBox: true,
    chart: null,
    truncateData: 15,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // notFirstPageClickList
  public readonly notFirstPageClickListKey = 'NFP';
  @ViewChild('notFirstPageClickListTable') notFirstPageClickListTable: MatTable<
    NotFirstPageClick
  >;
  @ViewChild('notFirstPageClickListSort') notFirstPageClickListSort: MatSort;
  @ViewChild('notFirstPageClickListPaginator')
  notFirstPageClickListPaginator: MatPaginator;
  notFirstPageClickListDisplayedColumns: string[] = ['query', 'page'];
  notFirstPageClickListDataSource: MatTableDataSource<NotFirstPageClick>;
  notFirstPageClickListOBJ = {
    visibilityBox: true,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // listNumberItemsCollection
  public readonly listNumberItemsCollection = 'NIC';
  @ViewChild('listNumberItemsCollectionTable')
  listNumberItemsCollectionTable: MatTable<NotFirstPageClick>;
  @ViewChild('listNumberItemsCollectionSort')
  listNumberItemsCollectionSort: MatSort;
  @ViewChild('listNumberItemsCollectionPaginator')
  listNumberItemsCollectionPaginator: MatPaginator;
  listNumberItemsCollectionDisplayedColumns: string[] = [
    'collection',
    'numberItems'
  ];
  listNumberItemsCollectionDataSource: MatTableDataSource<
    ListNumberItemsCollection
  >;
  listNumberItemsCollectionOBJ = {
    visibilityBox: true,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // pagesClickList
  public readonly pagesClickListKey = 'TPC';
  @ViewChild('pagesClickListChart', { read: ElementRef })
  pagesClickListChart: ElementRef;
  pagesClickListOBJ = {
    visibilityBox: true,
    chart: null,
    truncateData: 15,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // listNumberClicksForResult
  public readonly listNumberClicksForResult = 'NCR';
  @ViewChild('listNumberClicksForResultTable')
  listNumberClicksForResultTable: MatTable<NotFirstPageClick>;
  @ViewChild('listNumberClicksForResultSort')
  listNumberClicksForResultSort: MatSort;
  @ViewChild('listNumberClicksForResultPaginator')
  listNumberClicksForResultPaginator: MatPaginator;
  listNumberClicksForResultDisplayedColumns: string[] = [
    'query',
    'rank',
    'numClick',
    'numPage'
  ];
  listNumberClicksForResultDataSource: MatTableDataSource<
    ListNumberClicksForResult
  >;
  listNumberClicksForResultOBJ = {
    visibilityBox: true,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // rankedClickList
  public readonly rankedClickListKey = 'TRC';
  @ViewChild('rankedClickListChart', { read: ElementRef })
  rankedClickListChart: ElementRef;
  rankedClickListOBJ = {
    visibilityBox: true,
    chart: null,
    truncateData: 15,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // fullReport
  public readonly fullReport = 'FULL';
  // fullReportNoClick
  public readonly fullReportNoClick = 'FULL_NO_CLICK';

  public readonly urlsWithErrorStatusListKey = 'RES';
  @ViewChild('urlsWithErrorStatusListTable')
  urlsWithErrorStatusListTable: MatTable<UrlWithErrorStatus>;
  @ViewChild('urlsWithErrorStatusListSort')
  urlsWithErrorStatusListSort: MatSort;
  @ViewChild('urlsWithErrorStatusListPaginator')
  urlsWithErrorStatusListPaginator: MatPaginator;
  urlsWithErrorStatusListDisplayedColumns: string[] = [
    'url',
    'dateString',
    'status'
  ];
  urlsWithErrorStatusListDataSource: MatTableDataSource<UrlWithErrorStatus>;
  urlsWithErrorStatusListOBJ = {
    visibilityBox: true,
    msgEmpty: this.languageService.getLabel(
      'templateReportView.NessunRisultato'
    )
  };

  // Input data
  payload: Report = {
    title: '',
    site: '',
    exactDate: '',
    start: '',
    end: '',
    excludeTerms: null
  };

  currentTemplate = {
    codiceReport: '',
    payload: this.payload,
    descrizioneReport: ''
  };

  loadingResponse = false;

  constructor(
    private reportService: ReportService,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.loadingResponse = true;
    this.computeTotalColor();

    if (this.data !== null) {
      this.currentTemplate = this.data.template;
      this.payload = this.currentTemplate.payload;

      // Se il template è generato non specificando la collezione aggiungo alle tabelle
      // interessate la colonna 'site' che rappresenta la collezione.
      if (
        this.data.template &&
        this.data.template.payload &&
        !this.data.template.payload.site
      ) {
        this.wordsNotSearchedDisplayedColumns.splice(2, 0, 'site');
        this.notFirstPageClickListDisplayedColumns.push('site');
      }

      // Inserisco un delay per consentire ai viewChilds di recuperare i riferimenti nel template
      of(null).pipe(delay(1000))
        .subscribe(_ => {
          // Impagina titolo
          this.titlePage = this.data.template.currentReport.title;
          // Impagina totale
          this.searchTotal = this.data.template.currentReport.searchTotal;
          // listAverageResponseTime
          this.listAverageResponseTimeFn();
          // listDailySearch
          this.listDailySearchFn();
          // perHourSearch
          this.perHourSearchFn();
          // listTopSearchedWords
          this.listTopSearchedWordsFn();
          // wordsNotSearched
          this.wordsNotSearchedFn();
          // urlCountList
          this.urlCountListFn();
          // rankedClickList
          this.rankedClickListFn();
          // pagesClickList
          this.pagesClickListFn();
          // notFirstPageClickList
          this.notFirstPageClickListFn()
          // listNumberClicksForResult
          this.listNumberClicksForResultFn();
          // listNumberItemsCollection
          this.listNumberItemsCollectionFn();
          // listUrlsWithErrorStatus
          this.listUrlsWithErrorStatusFn();
        });
    }
  }
  computeTotalColor(){
    for (let r = 0; r < 255; r = r + 4) {
      for (let g = 0; g < 255; g = g + 4) {
        for (let b = 0; b < 255; b = b + 4) {
          const color = 'rgb(' + r + ',' + g + ',' + b + ')';
          this.totalColor.push(color);
        }
      }
    }
  }
  listAverageResponseTimeFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('listAverageResponseTime') &&
      this.data.template.currentReport.listAverageResponseTime !== null &&
      this.data.template.currentReport.listAverageResponseTime !== '' &&
      this.data.template.currentReport.listAverageResponseTime.length &&
      typeof this.data.template.currentReport['listAverageResponseTime'] !== typeof undefined
    ) {
      this.initListAverageResponseTimeChart(this.data.template.currentReport.listAverageResponseTime);
    } else {
      this.loadingResponse = false;
      this.listAverageResponseTimeOBJ.visibilityBox = false;
    }
  }
  listDailySearchFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('listDailySearch') &&
      this.data.template.currentReport.listDailySearch !== null &&
      this.data.template.currentReport.listDailySearch !== '' &&
      this.data.template.currentReport.listDailySearch.length &&
      typeof this.data.template.currentReport.listDailySearch !== typeof undefined
    ) {
      this.initListDailySearchChart(this.data.template.currentReport.listDailySearch);
    } else {
      this.loadingResponse = false;
      this.listDailySearchOBJ.visibilityBox = false;
    }
  }
  perHourSearchFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('perHourSearch') &&
      this.data.template.currentReport.perHourSearch !== null &&
      this.data.template.currentReport.perHourSearch !== '' &&
      this.data.template.currentReport.perHourSearch.length &&
      typeof this.data.template.currentReport['perHourSearch'] !== typeof undefined
    ) {
      this.initPerHourSearchChart(this.data.template.currentReport.perHourSearch);
    } else {
      this.loadingResponse = false;
      this.perHourSearchOBJ.visibilityBox = false;
    }
  }
  listTopSearchedWordsFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('listTopSearchedWords') &&
      this.data.template.currentReport.listTopSearchedWords !== null &&
      this.data.template.currentReport.listTopSearchedWords !== '' &&
      this.data.template.currentReport.listTopSearchedWords.length &&
      typeof this.data.template.currentReport['listTopSearchedWords'] !== typeof undefined
    ) {
      this.initListTopSearchedWordsChart(this.data.template.currentReport.listTopSearchedWords);
    } else {
      this.loadingResponse = false;
      this.listTopSearchedWordsOBJ.visibilityBox = false;
    }
  }
  wordsNotSearchedFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('wordsNotSearched') &&
      this.data.template.currentReport.wordsNotSearched !== null &&
      this.data.template.currentReport.wordsNotSearched !== '' &&
      this.data.template.currentReport.wordsNotSearched.length &&
      typeof this.data.template.currentReport['wordsNotSearched'] !== typeof undefined
    ) {
      this.initWordsNotSearchedChart(this.data.template.currentReport.wordsNotSearched);
    } else {
      this.loadingResponse = false;
      this.wordsNotSearchedOBJ.visibilityBox = false;
    }
  }
  urlCountListFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('urlCountList') &&
      this.data.template.currentReport.urlCountList !== null &&
      this.data.template.currentReport.urlCountList !== '' &&
      this.data.template.currentReport.urlCountList.length &&
      typeof this.data.template.currentReport['urlCountList'] !== typeof undefined
    ) {
      this.initUrlCountListChart(this.data.template.currentReport.urlCountList);
    } else {
      this.loadingResponse = false;
      this.urlCountListOBJ.visibilityBox = false;
    }
  }
  rankedClickListFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('rankedClickList') &&
      this.data.template.currentReport.rankedClickList !== null &&
      this.data.template.currentReport.rankedClickList !== '' &&
      this.data.template.currentReport.rankedClickList.length &&
      typeof this.data.template.currentReport['rankedClickList'] !== typeof undefined
    ) {
      this.initRankedClickListChart(this.data.template.currentReport.rankedClickList);
    } else {
      this.loadingResponse = false;
      this.rankedClickListOBJ.visibilityBox = false;
    }
  }
  pagesClickListFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('pagesClickList') &&
      this.data.template.currentReport.pagesClickList !== null &&
      this.data.template.currentReport.pagesClickList !== '' &&
      this.data.template.currentReport.pagesClickList.length &&
      typeof this.data.template.currentReport['pagesClickList'] !== typeof undefined
    ) {
      this.initPagesClickListChart(this.data.template.currentReport.pagesClickList);
    } else {
      this.loadingResponse = false;
      this.pagesClickListOBJ.visibilityBox = false;
    }
  }

  notFirstPageClickListFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('notFirstPageClickList') &&
      this.data.template.currentReport.notFirstPageClickList !== null &&
      this.data.template.currentReport.notFirstPageClickList !== '' &&
      this.data.template.currentReport.notFirstPageClickList.length &&
      typeof this.data.template.currentReport['notFirstPageClickList'] !== typeof undefined
    ) {
      this.initNotFirstPageClickListChart(this.data.template.currentReport.notFirstPageClickList);
    } else {
      this.loadingResponse = false;
      this.notFirstPageClickListOBJ.visibilityBox = false;
    }
  }
  listNumberClicksForResultFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('listNumberClicksForResult') &&
      this.data.template.currentReport.listNumberClicksForResult !== null &&
      this.data.template.currentReport.listNumberClicksForResult !== '' &&
      this.data.template.currentReport.listNumberClicksForResult.length &&
      typeof this.data.template.currentReport['listNumberClicksForResult'] !== typeof undefined
    ) {
      this.initListNumberClicksForResultChart(
        this.data.template.currentReport.listNumberClicksForResult
      );
    } else {
      this.loadingResponse = false;
      this.listNumberClicksForResultOBJ.visibilityBox = false;
    }
  }
  listNumberItemsCollectionFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('listNumberItemsCollection') &&
      this.data.template.currentReport.listNumberItemsCollection !== null &&
      this.data.template.currentReport.listNumberItemsCollection !== '' &&
      this.data.template.currentReport.listNumberItemsCollection.length &&
      typeof this.data.template.currentReport['listNumberItemsCollection'] !== typeof undefined
    ) {
      this.initListNumberItemsCollectionChart(
        this.data.template.currentReport.listNumberItemsCollection
      );
    } else {
      this.loadingResponse = false;
      this.listNumberItemsCollectionOBJ.visibilityBox = false;
    }
  }
  listUrlsWithErrorStatusFn(){
    if (
      this.data.template.currentReport.hasOwnProperty('listReportWithErrors') &&
      this.data.template.currentReport.listReportWithErrors !== null &&
      this.data.template.currentReport.listReportWithErrors !== '' &&
      this.data.template.currentReport.listReportWithErrors.length &&
      typeof this.data.template.currentReport['listReportWithErrors'] !== typeof undefined
    ) {
      this.initListUrlsWithErrorStatusChart(this.data.template.currentReport.listReportWithErrors);
    } else {
      this.loadingResponse = false;
      this.urlsWithErrorStatusListOBJ.visibilityBox = false;
    }
  }

  getRandomColorForChartElse(min,max,v){
    let nuovo = false;
    let ris = null;
    while (!nuovo) {
      ris = parseInt(Math.random() * (max - min) + min, null);
      nuovo = true;
      for (let i = 0; i < v.length; i = i + 1) {
        if (v[i] === ris) {
          nuovo = false;
        }
      }
    }
    return ris;
  }

  getRandomColorForChart(min, max, v) {
    let ris = null;
    if (v !== null) {
      if (v.length === 0) {
        ris = parseInt(Math.random() * (max - min) + min, null);
      } else {
        ris = this.getRandomColorForChartElse(min,max,v);
      }
    }
    return ris;
  }

  // listAverageResponseTime
  initListAverageResponseTimeChart(data: any) {
    if (data !== null) {
      data = data as ListAverageResponseTime;
      this.listAverageResponseTimeDataSource = new MatTableDataSource(data);
      this.listAverageResponseTimeDataSource.sort = this.listAverageResponseTimeSort;
      this.listAverageResponseTimeDataSource.paginator = this.listAverageResponseTimePaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.listAverageResponseTimeDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }
  sortListAverageResponseTimeList() {
    this.listAverageResponseTimeDataSource.sort = this.listAverageResponseTimeSort;
  }
  applyListAverageResponseTimeFilter(filter: string): void {
    this.listAverageResponseTimeDataSource.filter = filter.trim().toLowerCase();
  }

  // listDailySearch
  initListDailySearchChart(data: any) {
    const labels = [];
    const datasets = [];
    if (data !== null) {
      const colorDifferent = [];
      for (let j = 0; j < data.length; j = j + 1) {
        const datas = [];

        // Assegna colore
        const indexcolor = this.getRandomColorForChart(
          0,
          this.totalColor.length,
          colorDifferent
        );
        colorDifferent.push(indexcolor);
        const backgroundColor = this.totalColor[indexcolor];
        const borderColor = backgroundColor;

        for (let i = 0; i < data[j].dayAndSearchList.length; i = i + 1) {
          labels.push(data[j].dayAndSearchList[i].time);
          datas.push(parseInt(data[j].dayAndSearchList[i].searchNumber, null));
        }
        datasets.push({
          data: datas,
          fill: false,
          label: data[j].month + ' ' + data[j].year,
          backgroundColor: backgroundColor,
          borderColor: borderColor
        });
      }
    }

    this.listDailySearchOBJ.chart = new Chart(
      this.listDailySearchChart.nativeElement.getContext('2d'),
      <ChartConfiguration>{
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: defaultsDeep({
          responsive: true,
          title: {
            display: true
            // ,text: 'ListDailySearchChart'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Time'
                }
              }
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'searchNumber'
                }
              }
            ]
          }
        })
      }
    );

    this.loadingResponse = false;
  }

  // listPerHourSearch
  initPerHourSearchChart(data: any) {
    const labels = [];
    const datasets = [];
    if (data !== null) {
      const datas = [];
      const b = [];
      const bb = [];
      const colorDifferent = [];
      for (let j = 0; j < data.length; j = j + 1) {
        // Assegna colore
        const indexcolor = this.getRandomColorForChart(
          0,
          this.totalColor.length,
          colorDifferent
        );
        colorDifferent.push(indexcolor);
        const backgroundColor = this.totalColor[indexcolor];
        const borderColor = backgroundColor;

        labels.push(data[j].time);
        datas.push(parseInt(data[j].searchNumber, null));
        b.push(backgroundColor);
        bb.push(borderColor);
      }
      datasets.push({
        data: datas,
        fill: false,
        backgroundColor: b,
        borderColor: bb,
        borderWidth: 1
      });

      this.perHourSearchOBJ.chart = new Chart(
        this.perHourSearchChart.nativeElement.getContext('2d'),
        <ChartConfiguration>{
          type: 'bar',
          data: {
            labels: labels,
            datasets: datasets // datasets
          },
          options: defaultsDeep({
            responsive: true,
            legend: {
              display: false
            },
            title: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  barPercentage: 0.5,
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Time'
                  }
                }
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'searchNumber'
                  }
                }
              ]
            },
            tooltips: {
              mode: 'index',
              intersect: false
            },
            hover: {
              mode: 'nearest',
              intersect: true
            }
          })
        }
      );
    }
    this.loadingResponse = false;
  }
  initListTopSearchedWordsChartFirstPart(data: any){
    if (data !== null) {
      data = data as WordsNotSearched;
      this.listTopSearchedWordsDataSource = new MatTableDataSource(data);
      this.listTopSearchedWordsDataSource.sort = this.listTopSearchedWordsSort;
      this.listTopSearchedWordsDataSource.paginator = this.listTopSearchedWordsPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.listTopSearchedWordsDataSource = new MatTableDataSource(data);
    }
  }
  // listTopSearchedWords
  initListTopSearchedWordsChart(data: any) {
    this.initListTopSearchedWordsChartFirstPart(data);
    this.loadingResponse = false;
    const labels = [];
    const datasets = [];
    if (data !== null) {
      const datas = [];
      const b = [];
      const colorDifferent = [];

      if (
        this.listTopSearchedWordsOBJ.truncateData !== null &&
        data.length > this.listTopSearchedWordsOBJ.truncateData
      ) {
        for (
          let j = 0;
          j < this.listTopSearchedWordsOBJ.truncateData;
          j = j + 1
        ) {
          // Assegna colore
          const indexcolorData = this.getRandomColorForChart(
            0,
            this.totalColor.length,
            colorDifferent
          );
          colorDifferent.push(indexcolorData);
          const backgroundColorData = this.totalColor[indexcolorData];
          labels.push(data[j].searchedWord);
          datas.push(parseInt(data[j].recurrenceNumber, null));
          b.push(backgroundColorData);
        }
        const otherLabel = 'Other';
        let otherVal = 0;
        for (
          let j = this.listTopSearchedWordsOBJ.truncateData;
          j < data.length;
          j = j + 1
        ) {
          otherVal = otherVal + parseInt(data[j].recurrenceNumber, null);
        }
        // Assegna colore
        const indexcolor = this.getRandomColorForChart(
          0,
          this.totalColor.length,
          colorDifferent
        );
        colorDifferent.push(indexcolor);
        const backgroundColor = this.totalColor[indexcolor];
        labels.push(otherLabel);
        datas.push(otherVal);
        b.push(backgroundColor);
      } else {
        for (let j = 0; j < data.length; j = j + 1) {
          // Assegna colore
          const indexcolor = this.getRandomColorForChart(
            0,
            this.totalColor.length,
            colorDifferent
          );
          colorDifferent.push(indexcolor);
          const backgroundColor = this.totalColor[indexcolor];
          labels.push(data[j].searchedWord);
          datas.push(data[j].recurrenceNumber);
          b.push(backgroundColor);
        }
      }

      datasets.push({
        data: datas,
        fill: false,
        backgroundColor: b,
        borderWidth: 1
      });
      this.listTopSearchedWordsOBJ.chart = new Chart(
        this.listTopSearchedWordsChart.nativeElement.getContext('2d'),
        <ChartConfiguration>{
          type: 'pie',
          data: {
            labels: labels,
            datasets: datasets
          },
          options: defaultsDeep({
            responsive: true,
            legend: {
              position: 'bottom',
              display: true,
              fullWidth: true
            },
            title: {
              display: false
            },
            tooltips: {
              mode: 'index',
              intersect: true
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            animation: {
              animateRotate: true,
              animateScale: true
            }
          })
        }
      );
    }
    this.loadingResponse = false;
  }
  sortListTopSearchedWordsData() {
    this.listTopSearchedWordsDataSource.sort = this.listTopSearchedWordsSort;
  }
  applyListTopSearchedWordsFilter(filter: string): void {
    this.listTopSearchedWordsDataSource.filter = filter.trim().toLowerCase();
  }

  // wordNotSearched
  initWordsNotSearchedChart(data: any) {
    if (data !== null) {
      data = data as WordsNotSearched;
      this.wordsNotSearchedDataSource = new MatTableDataSource(data);
      this.wordsNotSearchedDataSource.sort = this.wordsNotSearchedSort;
      this.wordsNotSearchedDataSource.paginator = this.wordsNotSearchedPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.wordsNotSearchedDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }
  sortWordsNotSearchedData() {
    this.wordsNotSearchedDataSource.sort = this.wordsNotSearchedSort;
  }
  applyWordsNotSearchedFilter(filter: string): void {
    this.wordsNotSearchedDataSource.filter = filter.trim().toLowerCase();
  }

  // urlCountList
  initUrlCountListChart(data: any) {
    /*if (data !== null) {
      data = data as UrlCountList;
      this.urlCountListDataSource = new MatTableDataSource(data);
      this.urlCountListDataSource.sort = this.urlCountListSort;
      this.urlCountListDataSource.paginator = this.urlCountListPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.urlCountListDataSource = new MatTableDataSource(data);
    }*/
    const labels = [];
    const datasets = [];
    if (data !== null) {

      data = data as UrlCountList;
      this.urlCountListDataSource = new MatTableDataSource(data);
      this.urlCountListDataSource.sort = this.urlCountListSort;
      this.urlCountListDataSource.paginator = this.urlCountListPaginator;
      this.loadingResponse = false;


      const datas = [];
      const b = [];
      const colorDifferent = [];

      if (
        this.urlCountListOBJ.truncateData !== null &&
        data.length > this.urlCountListOBJ.truncateData
      ) {
        for (let j = 0; j < this.urlCountListOBJ.truncateData; j = j + 1) {
          // Assegna colore
          const indexcolorData = this.getRandomColorForChart(
            0,
            this.totalColor.length,
            colorDifferent
          );
          colorDifferent.push(indexcolorData);
          const backgroundColorData = this.totalColor[indexcolorData];
          labels.push(data[j].url);
          datas.push(parseInt(data[j].recurrenceNumber, null));
          b.push(backgroundColorData);
        }
        const otherLabel = 'Other';
        let otherVal = 0;
        for (
          let j = this.urlCountListOBJ.truncateData;
          j < data.length;
          j = j + 1
        ) {
          otherVal = otherVal + parseInt(data[j].recurrenceNumber, null);
        }
        // Assegna colore
        const indexcolor = this.getRandomColorForChart(
          0,
          this.totalColor.length,
          colorDifferent
        );
        colorDifferent.push(indexcolor);
        const backgroundColor = this.totalColor[indexcolor];
        labels.push(otherLabel);
        datas.push(otherVal);
        b.push(backgroundColor);
      } else {
        for (let j = 0; j < data.length; j = j + 1) {
          // Assegna colore
          const indexcolor = this.getRandomColorForChart(
            0,
            this.totalColor.length,
            colorDifferent
          );
          colorDifferent.push(indexcolor);
          const backgroundColor = this.totalColor[indexcolor];
          labels.push(data[j].url);
          datas.push(data[j].recurrenceNumber);
          b.push(backgroundColor);
        }
      }

      datasets.push({
        data: datas,
        fill: false,
        backgroundColor: b,
        borderWidth: 1
      });
      this.urlCountListOBJ.chart = new Chart(
        this.urlCountListChart.nativeElement.getContext('2d'),
        <ChartConfiguration>{
          type: 'pie',
          data: {
            labels: labels,
            datasets: datasets
          },
          options: defaultsDeep({
            responsive: true,
            legend: {
              position: 'bottom',
              display: true,
              fullWidth: true
            },
            title: {
              display: false
            },
            tooltips: {
              mode: 'index',
              intersect: true
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            animation: {
              animateRotate: true,
              animateScale: true
            }
          })
        }
      );
    }else{
      data = [];
      this.urlCountListDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }

  sortUrlCountListData() {
    this.urlCountListDataSource.sort = this.urlCountListSort;
  }
  applyUrlCountListFilter(filter: string): void {
    this.urlCountListDataSource.filter = filter.trim().toLowerCase();
  }

  // rankedClickList
  initRankedClickListChart(data: any): any {
    const labels = [];
    const datasets = [];
    if (data !== null) {
      const datas = [];
      const b = [];
      const bb = [];
      const colorDifferent = [];
      for (let j = 0; j < data.length; j = j + 1) {
        // Assegna colore
        const indexcolor = this.getRandomColorForChart(
          0,
          this.totalColor.length,
          colorDifferent
        );
        colorDifferent.push(indexcolor);
        const backgroundColor = this.totalColor[indexcolor];
        const borderColor = backgroundColor;

        labels.push(data[j].time);
        datas.push(parseInt(data[j].searchNumber, null));
        b.push(backgroundColor);
        bb.push(borderColor);
      }
      datasets.push({
        data: datas,
        fill: false,
        backgroundColor: b,
        borderColor: bb,
        borderWidth: 1
      });

      this.rankedClickListOBJ.chart = new Chart(
        this.rankedClickListChart.nativeElement.getContext('2d'),
        <ChartConfiguration>{
          type: 'bar',
          data: {
            labels: labels,
            datasets: datasets // datasets
          },
          options: defaultsDeep({
            responsive: true,
            legend: {
              display: false
            },
            title: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  barPercentage: 0.5,
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Rank'
                  }
                }
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'searchNumber'
                  }
                }
              ]
            },
            tooltips: {
              mode: 'index',
              intersect: false
            },
            hover: {
              mode: 'nearest',
              intersect: true
            }
          })
        }
      );
    }
    this.loadingResponse = false;
  }

  // pagesClickList
  initPagesClickListChart(data: any): any {
    const labels = [];
    const datasets = [];
    if (data !== null) {
      const datas = [];
      const b = [];
      const bb = [];
      const colorDifferent = [];
      for (let j = 0; j < data.length; j = j + 1) {
        // Assegna colore
        const indexcolor = this.getRandomColorForChart(
          0,
          this.totalColor.length,
          colorDifferent
        );
        colorDifferent.push(indexcolor);
        const backgroundColor = this.totalColor[indexcolor];
        const borderColor = backgroundColor;

        labels.push(data[j].time);
        datas.push(parseInt(data[j].searchNumber, null));
        b.push(backgroundColor);
        bb.push(borderColor);
      }
      datasets.push({
        data: datas,
        fill: false,
        backgroundColor: b,
        borderColor: bb,
        borderWidth: 1
      });

      this.pagesClickListOBJ.chart = new Chart(
        this.pagesClickListChart.nativeElement.getContext('2d'),
        <ChartConfiguration>{
          type: 'bar',
          data: {
            labels: labels,
            datasets: datasets // datasets
          },
          options: defaultsDeep({
            responsive: true,
            legend: {
              display: false
            },
            title: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  barPercentage: 0.5,
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Time'
                  }
                }
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'searchNumber'
                  }
                }
              ]
            },
            tooltips: {
              mode: 'index',
              intersect: false
            },
            hover: {
              mode: 'nearest',
              intersect: true
            }
          })
        }
      );
    }
    this.loadingResponse = false;
  }

  // notFirstPageClickList
  initNotFirstPageClickListChart(data: any) {
    if (data !== null) {
      data = data as NotFirstPageClick;
      this.notFirstPageClickListDataSource = new MatTableDataSource(data);
      this.notFirstPageClickListDataSource.sort = this.notFirstPageClickListSort;
      this.notFirstPageClickListDataSource.paginator = this.notFirstPageClickListPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.notFirstPageClickListDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }
  sortNotFirstPageClickList() {
    this.notFirstPageClickListDataSource.sort = this.notFirstPageClickListSort;
  }
  applyNotFirstPageClickListFilter(filter: string): void {
    this.notFirstPageClickListDataSource.filter = filter.trim().toLowerCase();
  }

  // listNumberClicksForResult
  initListNumberClicksForResultChart(data: any) {
    if (data !== null) {
      data = data as ListNumberClicksForResult;
      this.listNumberClicksForResultDataSource = new MatTableDataSource(data);
      this.listNumberClicksForResultDataSource.sort = this.listNumberClicksForResultSort;
      this.listNumberClicksForResultDataSource.paginator = this.listNumberClicksForResultPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.listNumberClicksForResultDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }
  sortListNumberClicksForResultList() {
    this.listNumberClicksForResultDataSource.sort = this.listNumberClicksForResultSort;
  }
  applyListNumberClicksForResultFilter(filter: string): void {
    this.listNumberClicksForResultDataSource.filter = filter
      .trim()
      .toLowerCase();
  }

  // listNumberClicksForResult
  initListUrlsWithErrorStatusChart(data: any) {
    if (data !== null) {
      data = data as UrlWithErrorStatus[];
      this.urlsWithErrorStatusListDataSource = new MatTableDataSource(data);
      this.urlsWithErrorStatusListDataSource.sort = this.urlsWithErrorStatusListSort;
      this.urlsWithErrorStatusListDataSource.paginator = this.urlsWithErrorStatusListPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.urlsWithErrorStatusListDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }
  sortUrlsWithErrorStatusList() {
    this.urlsWithErrorStatusListDataSource.sort = this.urlsWithErrorStatusListSort;
  }
  applyUrlsWithErrorStatusFilter(filter: string): void {
    this.urlsWithErrorStatusListDataSource.filter = filter.trim().toLowerCase();
  }

  // listNumberItemsCollection
  initListNumberItemsCollectionChart(data: any) {
    if (data !== null) {
      data = data as NotFirstPageClick;
      this.listNumberItemsCollectionDataSource = new MatTableDataSource(data);
      this.listNumberItemsCollectionDataSource.sort = this.listNumberItemsCollectionSort;
      this.listNumberItemsCollectionDataSource.paginator = this.listNumberItemsCollectionPaginator;
      this.loadingResponse = false;
    } else {
      data = [];
      this.listNumberItemsCollectionDataSource = new MatTableDataSource(data);
    }
    this.loadingResponse = false;
  }
  sortListNumberItemsCollectionList() {
    this.listNumberItemsCollectionDataSource.sort = this.listNumberItemsCollectionSort;
  }
  applyListNumberItemsCollectionFilter(filter: string): void {
    this.listNumberItemsCollectionDataSource.filter = filter
      .trim()
      .toLowerCase();
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Index } from '../../models';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.scss']
})
export class IndexFormComponent implements OnInit {
  isEdit = false;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<IndexFormComponent>,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null, Validators.required]
    });
  }

  submit(form) {
    this.dialogRef.close({ id: form.value.id } as Index);
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { CrawlingMapping } from "app/models/crawling-mapping";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Mapping } from "app/models";
import { LanguageService } from "app/_services/language.service";

@Component({
  selector: "fury-crawling-web-mapping-form",
  templateUrl: "crawling-web-mapping-form.component.html",
  styleUrls: ["crawling-web-mapping-form.component.scss"]
})
export class CrawlingWebMappingFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CrawlingWebMappingFormComponent>,
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      metaIndexes: Mapping;
    }
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      campoCrawler: ["", Validators.required],
      campoIndice: [null, Validators.required]
    });
  }

  submit() {
    const saveMapping = <CrawlingMapping>{
      campoCrawling: this.form.value.campoCrawler,
      campoIndice: this.form.value.campoIndice.field
    };

    this.dialogRef.close(saveMapping);
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatTable,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { Client } from 'app/models/client';
import { ClientService } from 'app/_services/client.service';
import { ClientDetailComponent } from '../client-detail/client-detail.component';
import { ClientPolicyAssociationFormComponent } from '../client-policy-association-form/client-policy-association-form.component';
import { ClientPolicyBiasing } from 'app/models/client-policy-biasing';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-policy',
  templateUrl: './client-policy.component.html',
  styleUrls: ['./client-policy.component.scss']
})
export class ClientPolicyComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<ClientPolicyBiasing>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<ClientPolicyBiasing>;
  loading = false;
  connectionBroken = false;
  displayedColumns: string[] = ['idBiasing', 'description', 'star'];

  public parentClient: Client;
  policyAssociationsRef: MatDialogRef<ClientPolicyAssociationFormComponent>;

  constructor(
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.parentClient = this.parentComponent.currentClient;
    this.dataSource = new MatTableDataSource();
    this.getClientPolicies();
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  getClientPolicies(): void {
    this.clientService
      .getClientPolicies(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(policies => {
        if (!policies) {
          return;
        }

        this.dataSource = new MatTableDataSource([policies]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (
          data: ClientPolicyBiasing,
          filter: string
        ) => {
          return (
            data.idBiasing
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) >= 0 ||
            data.description
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) >= 0
          );
        };
      });
  }

  deletePolicyAssociation(policy: ClientPolicyBiasing): void {
    this.confirmationDialog
      .confirm(
        `Confermare l'eliminazione dell'associazione policy?`,
        `Conferma eliminazione`
      )
      .then(res => {
        if (res) {
          this.loading = true;
          this.clientService
            .deletePolicyAssociation(this.parentClient, policy)
            .pipe(
              finalize(() => (this.loading = false)),
              tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(
              () => this.getClientPolicies());
        }
      });
  }

  openModalPolicyAssociation(): void {
    if (
      this.dataSource.data.length > 0 &&
      this.dataSource.data[0].idBiasing != null
    ) {
      this.confirmationDialog.confirm(
        'Non è possibile aggiungere ulteriori associazioni a policy di differenziazione dei risultati',
        'Attenzione',
        'OK',
        ''
      );
      return;
    }

    this.policyAssociationsRef = this.dialog.open(
      ClientPolicyAssociationFormComponent,
      {
        data: { idclient: this.parentClient.idclient }
      }
    );
    this.policyAssociationsRef.afterClosed().subscribe(policyAssociated => {
      if (policyAssociated) {
        this.notificationsService.showDefaultSuccessMessage();
        this.getClientPolicies();
      }
    });
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }
}

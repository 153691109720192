import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserRoleService } from 'app/_services/user-role.service';
import { LanguageService } from 'app/_services/language.service';
import { UserGroup } from 'app/models/user-group';

@Component({
  selector: 'fury-user-groups-data-form',
  templateUrl: './user-groups-data-form.component.html'
})
export class UserGroupsDataFormComponent implements OnInit {

  currentGroup: UserGroup;

  isEdit: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private userRoleService: UserRoleService,
    public languageService: LanguageService,
    private dialogRef: MatDialogRef<UserGroupsDataFormComponent>,
  ) { }

  ngOnInit() {
    this.isEdit = (this.data && !!this.data.group);
    this.currentGroup = this.isEdit ? this.data.group : new UserGroup();

  }

  submit() {

    if (this.isEdit) {
      this.userRoleService
        .updateGroup(this.currentGroup)
        .subscribe(resp => {
          this.dialogRef.close(true);
        });
    } else {
      this.userRoleService.addGroup(this.currentGroup).subscribe(resp => {
        this.dialogRef.close(true);

      });
    }

  }

  cancel() {
    this.dialogRef.close();
  }

}

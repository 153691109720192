import { ConfigData } from 'app/app.config';
import {
  Component,
  OnInit,
  Inject,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { PolicyService } from '../../../../_services/policy.service';
import { LanguageService } from '../../../../_services/language.service';

import { AliasBiasing } from '../../../../models/alias-biasing';
import { Alias } from 'app/models/alias';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { AlertService } from 'app/_services/alert.service';

@Component({
  selector: 'fury-policy-alias-form',
  templateUrl: 'policy-alias-form.component.html',
  styleUrls: ['policy-alias-form.component.scss'],
})
export class PolicyAliasFormComponent implements OnInit {
  aliasList: string[] = [];

  currentAlias: AliasBiasing = {
    idBiasing: '',
    boost: 0,
    idAlias: '',
  };

  isEdit = false;
  form: FormGroup;

  filteredAlias: Observable<Alias[]>;
  filterAlias: Alias[];

  boostMin = -100;
  boostMax = 100;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PolicyAliasFormComponent>,
    private policyService: PolicyService,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.isEdit = !!this.data;
    this.getAliasList();

    if (!!ConfigData.biasingBoostMin) {
      this.boostMin = ConfigData.biasingBoostMin;
    }

    if (!!ConfigData.biasingBoostMax) {
      this.boostMax = ConfigData.biasingBoostMax;
    }

    if (this.isEdit) {
      this.currentAlias = this.data.alias;
    }

    this.form = this.formBuilder.group({
      idAlias: [this.currentAlias.idAlias, Validators.required],
      boost: [this.currentAlias.boost],
    });

    this.form.get('boost').valueChanges.subscribe((value) => {
      this.currentAlias.boost = Math.round(value * 10) / 10;
    });
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    return Math.round(value * 10) / 10;
  }

  sliderChange(event): void {
    this.form.get('boost').patchValue(Math.round(event.value * 10) / 10);
  }

  submit() {
    if (this.currentAlias.boost < this.boostMin || this.currentAlias.boost > this.boostMax) {
      this.alertService.subj_notification.next(
        this.languageService.getLabel(
          'policyBiasingDetail.ErroreBoostMetaAlias'
        ).replace('{min}', this.boostMin.toString()).replace('{max}', this.boostMax.toString())
      );
      return;
    }

    this.dialogRef.close({
      idAlias: this.isEdit
        ? this.currentAlias.idAlias
        : this.form.value.idAlias,
      boost: this.currentAlias.boost,
    });
  }

  addBoost(event, boostToAdd: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.currentAlias.boost += boostToAdd;
  }

  getAliasList(): void {
    this.policyService.getAliasList().subscribe((res) => {
      if (res) {
        this.filterAlias = this.sortList(res);
        this.setupAutocomplete();
      }
    });
  }

  sortList(res) {
    return res.sort((a, b) => {
      const textA = a.id.toUpperCase();
      const textB = b.id.toUpperCase();
      if(textA < textB){
        return -1;
      }else if(textA > textB){
        return 1;
      }else{
        return 0;
      }
    });
  }

  private setupAutocomplete(): void {
    if (this.isEdit) {
      return;
    }

    this.filteredAlias = this.form.get('idAlias').valueChanges.pipe(
      startWith(''),
      map((val) => <string>val),
      map((value) =>
        this.filterAlias.filter(
          (alias) => alias.id.toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      )
    );
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
  MatTable,
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  PageEvent
} from '@angular/material';
import { FeedXML } from 'app/models/feed-xml';
import { FeedXMLFilters } from 'app/models/feed-xml-filters';
import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';
import { GestioneCrawlingFeedXMLFormComponent } from '../gestione-crawling-feed-xml-form/gestione-crawling-feed-xml-form.component';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-gestione-crawling-feed-xml',
  templateUrl: 'gestione-crawling-feed-xml.component.html',
  styleUrls: ['gestione-crawling-feed-xml.component.scss']
})
export class GestioneCrawlingFeedXMLComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loading = false;
  connectionBroken = false;
  feedParams: FeedXMLFilters = new FeedXMLFilters();
  public pageSize : number = 10; public currentPage : number = 0; resultsSize : number;


  displayedColumns: string[] = [
    'id',
    'url',
    'status',
    'insertDate',
    'feedType'
  ];
  dataSource: MatTableDataSource<FeedXML>;

  gestioneCrawlingFeedFormRef: MatDialogRef<
    GestioneCrawlingFeedXMLFormComponent
  >;

  constructor(
    private gestioneCrawlingService: GestioneCrawlingService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private changeDetectorRef: ChangeDetectorRef,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.getFeedXML();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  private getFeedXML(): void {
    this.loading = true;
    this.gestioneCrawlingService
      .getFeedXML([{key : 'size', value : this.pageSize},{key : 'page', value : this.currentPage}])
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        feed => {
          this.actionBeforeSubscribeFeed(feed);
        },
        error => {
          this.connectionBroken = true;
        }
      );
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.filtraDati();
  }
  
  goToDetail(feed: FeedXML): void {
    this.gestioneCrawlingFeedFormRef = this.dialog.open(
      GestioneCrawlingFeedXMLFormComponent,
      {
        width: '800px',
        data: { currentFeedId: feed.id }
      }
    );
  }

  filtraDati(resetPage? : boolean): void {
    this.loading = true;
    this.feedParams.size = this.pageSize;
    this.feedParams.page = resetPage ? 0  : this.currentPage;
    // Controlllo che la data inizio sia valorizzata se lo è la data di fine
    if (
      !this.feedParams.end &&
      !!this.feedParams.start
    ) {
      this.confirmationDialogService.confirm(
        this.languageService.getLabel('gestioneCrawling.FeedXml.InsertStartDate'),
        this.languageService.getLabel('general.Attenzione'),
        'OK',
        ''
      );
      this.loading = false;
      return;
    }

    this.gestioneCrawlingService
      .getFeedXMLFiltered(this.feedParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        feed => {
          this.actionBeforeSubscribeFeed(feed);
        },
        () => {
          this.connectionBroken = true;
        }
      );

  }
  actionBeforeSubscribeFeed(feed){
    this.dataSource = new MatTableDataSource(feed);
    this.dataSource.sort = this.sort;
    this.resultsSize = feed[0] ? feed[0].total : 0;
    this.changeDetectorRef.detectChanges();
  }

  onChangeDay(): void {
    this.feedParams.start = null;
    this.feedParams.end = null;
  }

  onChangeDate(): void {
    this.feedParams.day = null;
  }
}

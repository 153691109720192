import { Client } from './../../models/client';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  MatTable,
  MatSort,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { ClientService } from '../../_services/client.service';
import { Keymatch } from '../../models/keymatch';
import { finalize, tap } from '../../../../node_modules/rxjs/operators';
import { ClientDetailComponent } from '../../n4s/client-detail/client-detail.component';
import { KeymatchFormComponent } from '../keymatch-form/keymatch-form.component';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-keymatch',
  templateUrl: './client-keymatch.component.html',
  styleUrls: ['./client-keymatch.component.scss']
})
export class ClientKeymatchComponent implements OnInit {
  pageSize = 10;
  dataSource: MatTableDataSource<Keymatch> | null;
  keymatches: Keymatch[];
  loading = false;
  displayedColumns: string[] = [
    'keyword',
    'title',
    'description',
    'thumbImgUrl',
    'date',
    'url',
    'triggerType',
    'star'
  ];
  keymatchFormRef: MatDialogRef<KeymatchFormComponent>;

  @ViewChild('keymatchTable') keymatchTable: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public parentClient: Client;
  public isDownloading: boolean;

  constructor(
    private dialog: MatDialog,
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.getKeyMatches();
  }

  getKeyMatches(): void {
    this.dataSource = new MatTableDataSource();
    this.parentClient = this.parentComponent.currentClient;
    this.loading = true;
    this.clientService
      .getKeyMatch(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(keys => {
        this.dataSource.data = keys;
        this.keymatches = keys;
        this.dataSource.data = keys;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: Keymatch, filter: string) => {
          let match = false;

          match =
            data.title
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) !== -1;

          if (!match && data.description) {
            match =
              data.description
                .trim()
                .toLowerCase()
                .indexOf(filter.trim().toLowerCase()) !== -1;
          }

          return match;
        };
      });
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  addKeymatch() {
    this.keymatchFormRef = this.dialog.open(KeymatchFormComponent, {
      width: '500px'
    });
    this.keymatchFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .addKeyMatch(this.parentClient.idclient, result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getKeyMatches());
      }
    });
  }

  editKeymatch(keymatch: Keymatch) {
    this.keymatchFormRef = this.dialog.open(KeymatchFormComponent, {
      width: '500px',
      data: keymatch
    });

    this.keymatchFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .editKeyMatch(this.parentClient.idclient, result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(resp => this.getKeyMatches());
      }
    });
  }

  deleteKeymatch(keymatch: Keymatch) {
    this.confirmationDialog
      .confirm('Eliminare Keymatch ' + keymatch.title + '?')
      .then(res => {
        if (res) {
          this.clientService
            .deleteKeyMatch(this.parentClient.idclient, keymatch)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))

            .subscribe(resp => this.getKeyMatches());
        }
      });
  }

  /**
   * Chiama il servizio per scaricare il CSV
   */
  exportKeymatch(): void {
    this.isDownloading = true;
    this.clientService
      .exportKeyMatch(this.parentClient.idclient)
      .pipe(finalize(() => (this.isDownloading = false)))
      .subscribe(response => {
        this.downloadFile(
          response,
          `${this.parentClient.idclient}-keymatch.csv`
        );
      });
  }

  /**
   * Crea un bottone e simula il click per effettuare il download
   * @param stream Stream del CSV
   * @param filename Nome del file
   */
  private downloadFile(stream: any, filename: string): void {
    const downloadURL = window.URL.createObjectURL(stream);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    link.click();
  }
}

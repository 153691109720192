
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LanguageService } from "app/_services/language.service";

@Component({
  selector: 'fury-loading-file-dialog',
  templateUrl: './loading-file-dialog.component.html',
  styleUrls: ['./loading-file-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingFileDialogComponent implements OnInit {
  
 
  constructor(
    private dialogRef: MatDialogRef<LoadingFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public percentage,
    @Inject(MAT_DIALOG_DATA) public currentFile,
    public languageService : LanguageService
  ) {}

  ngOnInit() {
    // This is intentional
  }

  public decline() {
    this.dialogRef.close(false);
  }

  public accept() {
    this.dialogRef.close(true);
  }

  public dismiss() {
    this.dialogRef.close(false);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Helpers } from 'app/_helpers/helpers';
import { LanguageService } from 'app/_services/language.service';
import { ReportService } from 'app/_services/report.service';
import * as Chart from 'chart.js';
import { ChartConfiguration } from 'chart.js';



@Component({
  selector: 'analytics-report-connection',
  templateUrl: './analytics-report.component.html',
  styleUrls: ['./analytics-report.component.scss']
})
export class AnalyticsReportComponent implements OnInit {
  @ViewChild('tsCountQuery', { read: ElementRef }) tsCountQuery: ElementRef;
  @ViewChild('tsHits', { read: ElementRef }) tsHits: ElementRef;
  @ViewChild('tsTookTime', { read: ElementRef }) tsTookTime: ElementRef;
  @ViewChild('queryByTookTime', { read: ElementRef }) queryByTookTime: ElementRef;
  @ViewChild('queryPercentage', { read: ElementRef }) queryPercentage: ElementRef;
  @ViewChild('anomalousQueryContent', { read: ElementRef }) anomalousQueryContent: ElementRef;
  @ViewChild('queryPerClient', { read: ElementRef }) queryPerClient: ElementRef;
  @Input() data
  datasetQueryByTookTime: any;
  payloadReq;
  anomalousDataset: any;
  datasetAnomalousQueryContent : any;
  tsCountQueryGraph; tsHitsGraph; tookTimeGraph; queryPercentageGraph; queryPerClientGraph;
  tsCountQueryPromise; tsHitsPromise; tookTimePromise; queryPercentagePromise; queryPerClientPromise;
  @Output() credentials = new EventEmitter<any>();


  constructor(private reportService: ReportService, private helpers: Helpers, private languageService : LanguageService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.payloadReq = {
      "site": this.data.site,
      "from_date": this.helpers.getIsoStringDate(this.data.start),
      "to_date": this.helpers.getIsoStringDate(this.data.end),
      "frequency": "1D"
    }

    this.generateTsCountQueryGraph();
    this.generateTsHitsGraph();
    this.generateTookTimeGraph();
    this.generateQueryByTookTimeGraph();
    this.generateQueryPercentageGraph();
    this.generateQueryPerClientGraph();
    this.anomalyDetectionQueryContent();
  }

  

  generateTsCountQueryGraph() {
    this.reportService.addReportCount();
    this.tsCountQueryPromise = this.reportService.get_ts_count_query(this.payloadReq)
    this.tsCountQueryPromise.subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'line');
        this.tsCountQueryGraph = new Chart(
          this.tsCountQuery.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'line',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function (value) {
                      return value.toString().substring(0, 10);//truncate
                    },
                  }
                }],
                yAxes: [{}]
              },
            }
          }
        );
      }else{
        this.tsCountQueryGraph = 'error'
      }
    }, error => {
      this.tsCountQueryGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateTsHitsGraph() {
    this.reportService.addReportCount();
    this.tsHitsPromise = this.reportService.get_ts_hits(this.payloadReq);
    this.tsHitsPromise.subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'line');
        this.tsHitsGraph = new Chart(
          this.tsHits.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'line',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function (value) {
                      return value.toString().substring(0, 10);//truncate
                    },
                  }
                }],
                yAxes: [{}]
              },
            }
          }
        );
      }else{
        this.tsHitsGraph = 'error'
      }
    }, error => {
      this.tsHitsGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateTookTimeGraph() {
    this.reportService.addReportCount();
    this.tookTimePromise = this.reportService.get_ts_took_time(this.payloadReq);
    this.tookTimePromise.subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'line-fixed');
        this.tookTimeGraph = new Chart(
          this.tsTookTime.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'line',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  ticks: {
                    callback: function (value) {
                      return value.toString().substring(0, 10);//truncate
                    },
                  }
                }],
                yAxes: [{}]
              },
            }
          }
        );
      }else{
        this.tookTimeGraph = 'error';
      }
    }, error => {
      this.tookTimeGraph = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateQueryByTookTimeGraph() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, {'top_n' : 10});
    this.reportService.get_query_by_took_time(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        this.datasetQueryByTookTime = res;
      }else{
        this.datasetQueryByTookTime = 'error'
      }
    }, error => {
      this.datasetQueryByTookTime = 'error';
      this.reportService.removeReportCount();
    })
  }
  anomalyDetectionQueryContent() {
    this.reportService.addReportCount();
    let customPayLoadReq = Object.assign(this.payloadReq, {'top_n' : 10});
    this.reportService.connections_anomaly_detection_query_content(customPayLoadReq).subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        this.datasetAnomalousQueryContent = res;
      }else{
        this.datasetAnomalousQueryContent = 'error';
      }
    }, error => {
      this.datasetQueryByTookTime = 'error';
      this.reportService.removeReportCount();
    })
  }
  generateQueryPercentageGraph() {
   this.reportService.addReportCount();
   let customPayLoadReq = Object.assign(this.payloadReq, {'top_n' : 5});
   this.queryPercentagePromise = this.reportService.get_query_percentage(customPayLoadReq);
    this.queryPercentagePromise.subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'pie');
        this.queryPercentageGraph = new Chart(
          this.queryPercentage.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'pie',
            data: res
          }
        );
      }else{
        this.queryPercentageGraph = 'error';
      }
    }, error => {
      this.queryPercentageGraph = 'error';
      this.reportService.removeReportCount();
    })

  }
  generateQueryPerClientGraph() {
    this.reportService.addReportCount();
    this.queryPerClientGraph = this.reportService.get_query_per_client(this.payloadReq);
    this.queryPerClientGraph.subscribe((res) => {
      this.reportService.removeReportCount();
      if (this.helpers.haveData(res)) {
        res = this.helpers.customizeChartsColors(res, 'bar-stacked');
        this.queryPerClientGraph = new Chart(
          this.queryPerClient.nativeElement.getContext('2d'),
          <ChartConfiguration>{
            type: 'bar',
            data: res,
            options: {
              scales: {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    callback: function (value) {
                      return value.toString().substring(0, 10);//truncate
                    },
                  }
                }],
                yAxes: [{
                  stacked: false
                }]
              }
            }
          }
        );
      }else{
        this.queryPerClientGraph = 'error';
      }
    }, error => {
      this.queryPerClientGraph = 'error';
      this.reportService.removeReportCount();
    })
  }

  showInfoChart(infoTemplate){
    this.dialog.open(infoTemplate, {
      width: '500px',
    });
  }
}

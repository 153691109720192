import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatTable,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { finalize, tap } from 'rxjs/operators';

import { ConfigurationService } from '../../../_services/configuration.service';
import { ConfirmationDialogService } from '../../../_services/confirmation-dialog.service';
import { LanguageService } from '../../../_services/language.service';

import { XlstFormComponent } from '../xlst-form/xlst-form.component';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-include-xlst',
  templateUrl: './include-xlst.component.html',
  styleUrls: ['./include-xlst.component.scss']
})
export class IncludeXlstComponent implements OnInit {
  @ViewChild('xlstTable') xlstTable: MatTable<any>;

  loading = false;
  displayedColumns: string[] = ['name', 'star'];
  dataSource: MatTableDataSource<any>;
  xlstFormRef: MatDialogRef<XlstFormComponent>;

  constructor(
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    private router: Router,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.getIncludeXlsts();
  }

  getIncludeXlsts(): void {
    this.loading = true;
    this.configurationService
      .getIncludeXlsts()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        templates => {
          this.dataSource = new MatTableDataSource(templates);
        },
        () => {
          this.languageService.getLabel(
            'configuration.general.NoServerConnection'
          );
        }
      );
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  addTemplate() {
    this.xlstFormRef = this.dialog.open(XlstFormComponent, {
      width: '800px'
    });

    this.xlstFormRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.configurationService.addIncludeXlst(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(ranking => this.getIncludeXlsts());
      }
    });
  }

  editTemplate(template) {
    this.xlstFormRef = this.dialog.open(XlstFormComponent, {
      width: '800px',
      data: { template: template }
    });

    this.xlstFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.configurationService.editIncludeXlst(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getIncludeXlsts());
      }
    });
  }

  deleteTemplate(template) {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'configuration.IncludeXlst.ConfermiEliminazioneTemplate'
        )} ${template.name}?`
      )
      .then(res => {
        if (res) {
          this.configurationService
            .deleteIncludeXlst(template)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getIncludeXlsts());
        }
      });
  }

}

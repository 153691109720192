import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavLink } from 'app/models/nav-link';

import { GestioneCrawlingService } from '../../_services/gestione-crawling.service';
import { CrawlingJob } from 'app/models/crawling-job';
import { LanguageService } from 'app/_services/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fury-gestione-crawling-web-detail',
  templateUrl: 'gestione-crawling-web-detail.component.html'
})
export class GestioneCrawlingWebDetailComponent implements OnInit, OnDestroy {
  navLinks: NavLink[];
  idJob: string;
  job: CrawlingJob;

  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gestioneCrawlingService: GestioneCrawlingService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(response => {
      this.idJob = response.idJob;
      this.buildTabs();
      this.getJobDetail();
    });

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }

  private buildTabs(): void {
    this.navLinks = [
      {
        path: 'urls',
        label: this.languageService.getLabel('gestioneCrawlingWeb-Details.Urls'),
        visible: true,
        disabled: false
      },
      {
        path: 'schedulazione',
        label: this.languageService.getLabel(
          'gestioneCrawlingWeb-Details.Schedulazione'
        ),
        visible: true,
        disabled: false
      },
      // {
      //   path: 'mapping',
      //   label: this.languageService.getLabel(
      //     'gestioneCrawlingWeb-Details.MappaturaCampi'
      //   ),
      //   visible: true,
      //   disabled: false
      // },
      {
        path: 'stato-documento',
        label: this.languageService.getLabel(
          'gestioneCrawlingWeb-Details.StatoDocumento'
        ),
        visible: true,
        disabled: false
      },
      {
        path: 'stato-crawling',
        label: this.languageService.getLabel(
          'gestioneCrawlingWeb-Details.StatoCrawling'
        ),
        visible: true,
        disabled: false
      }
    ];
  }

  private getJobDetail(): void {
    this.gestioneCrawlingService
      .getCrawlingWebJobDetail(this.idJob)
      .subscribe(response => {
        this.job = response;
        this.gestioneCrawlingService.selectedJob = this.job;
      });
  }

  back(): void {
    this.router.navigate(['gestione-crawling', 'web']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import {
  MatPaginator,
  MatSort,
  MatTable,
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  Sort,
  MatSnackBar
} from '@angular/material';

// Components
import { CrawlingWebUrlsFormComponent } from '../crawling-web-urls-form/crawling-web-urls-form.component';

// Services
import { GestioneCrawlingService } from '../../_services/gestione-crawling.service';

// Models
import { CrawlingWebUrl } from '../../models/crawling-web-url';
import { CrawlingJob } from '../../models/crawling-job';
import { LanguageService } from '../../_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-crawling-web-urls',
  templateUrl: 'crawling-web-urls.component.html',
  styleUrls: ['crawling-web-urls.component.scss']
})
export class CrawlingWebUrlsComponent implements OnInit {
  private paramIDJob: string;
  public loading = false;
  readonly deeps: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  job: CrawlingJob;
  deep: number;
  maxDocumentNumber: number;

  @ViewChild('startUrlsTable') startUrlsTable: MatTable<CrawlingWebUrl>;
  @ViewChild('startUrlsSort') startUrlsSort: MatSort;
  @ViewChild('startUrlsPaginator') startUrlsPaginator: MatPaginator;
  startUrlsList: CrawlingWebUrl[] = [];
  startUrlsSource: MatTableDataSource<CrawlingWebUrl>;
  startUrlsDisplayedColumns: string[] = ['url', 'star'];

  crawlingWebUrlsFormRef: MatDialogRef<CrawlingWebUrlsFormComponent>;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private gestioneCrawlingService: GestioneCrawlingService,
    private changeDetectionRef: ChangeDetectorRef,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.activatedRoute.parent.paramMap.subscribe(res => {
      const paramIDJob = res.get('idJob');
      if (paramIDJob) {
        this.paramIDJob = paramIDJob;
        this.getCrawlingWebJobDetail();
        this.loadUrls();
      }
    });
  }

  private getCrawlingWebJobDetail(): void {
    this.gestioneCrawlingService
      .getCrawlingWebJobDetail(this.paramIDJob)
      .subscribe(response => (this.job = response));
  }

  // Save deep
  updateDeep(): void {
    this.gestioneCrawlingService
      .updateDeep(this.paramIDJob, this.deep)
      .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(response => this.deep = response.deep);
  }

  setMaximumDocumentNumber(): void {
    this.gestioneCrawlingService
      .setMaximumDocumentNumber(this.paramIDJob, this.maxDocumentNumber)
      .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(response => {
        this.maxDocumentNumber = response.topn;
      });
  }

  private loadUrls(): void {
    this.loading = true;
    this.gestioneCrawlingService
      .getCrawlingWebUrlsData(this.paramIDJob)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(result => {
        if (result) {
          this.startUrlsList = result.startUrl;
          this.deep = result.deep;
          this.maxDocumentNumber = result.topN;
          this.loadTables();
        }
      });
  }

  private loadTables(): void {
    this.startUrlsSource = new MatTableDataSource(this.startUrlsList);
    this.startUrlsSource.sort = this.startUrlsSort;
    this.startUrlsSource.paginator = this.startUrlsPaginator;

    this.changeDetectionRef.detectChanges();
  }

  /* START URLS */
  public startUrlsSortData(): void {
    this.startUrlsSource.sort = this.startUrlsSort;
  }

  public openModalAddStartUrl(): void {
    this.crawlingWebUrlsFormRef = this.dialog.open(
      CrawlingWebUrlsFormComponent,
      {
        width: '500px'
      }
    );
    this.crawlingWebUrlsFormRef.afterClosed().subscribe(result => {
      if (result) {
        result = this.checkUrlProtocol(result);
        this.gestioneCrawlingService
          .addCrawlingJobStartUrl(this.paramIDJob, result)
          .subscribe(response => {
            if (response) {
              this.notificationsService.showDefaultSuccessMessage();
              this.loadUrls();
            }
          });
      }
    });
  }

  public deleteStartUrl(url: CrawlingWebUrl): void {
    this.gestioneCrawlingService
      .deleteCrawlingJobStartUrl(this.paramIDJob, url.id.toString())
      .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.loadUrls());
  }

  /**
   * If there is no protocol in the URL this function add to it
   * and return the URL with the default protocol http://
   * @param url url to check the protocol
   */
  private checkUrlProtocol(url: string): string {
    return url.match(/^[a-zA-Z]+:\/\//) ? url : 'http://' + url;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-client-search-diff',
  templateUrl: './client-search-diff.component.html'
})
export class ClientSearchDiffComponent implements OnInit {
  public loading = false;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }
}

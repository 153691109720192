import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { ClientService } from '../../_services/client.service';
import { LanguageService } from '../../_services/language.service';

import { Keymatch } from '../../models/keymatch';
// App config
import { ConfigData } from './../../app.config';
@Component({
  selector: 'fury-keymatch-form',
  templateUrl: './keymatch-form.component.html',
  styleUrls: ['./keymatch-form.component.scss']
})
export class KeymatchFormComponent implements OnInit {
  currentIdClient: string;
  form: FormGroup;
  isEdit = false;
  triggerTypes: any[];

  curKeymatch: Keymatch = {
    id: '',
    description: '',
    title: '',
    keyword: '',
    thumbImgUrl: '',
    url: '',
    // Per cloudSearch il tipo trigger è fisso a EXACT_MATCH e non modificabile
    triggerType: ConfigData.cloudSearch ? 'EXACT_MATCH' : '',
    date: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<KeymatchFormComponent>,
    public datepipe: DatePipe,
    private clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    if (this.data) {
      this.isEdit = true;
      this.curKeymatch = this.data;
    }

    this.clientService
      .getTriggerTypesList()
      .subscribe(res => (this.triggerTypes = res));

    this.form = this.formBuilder.group({
      id: [this.curKeymatch.id],
      title: [this.curKeymatch.title, Validators.required],
      keyword: [this.curKeymatch.keyword, Validators.required],
      triggerType: [
        {
          value: this.curKeymatch.triggerType,
          disabled: ConfigData.cloudSearch
        },
        Validators.required
      ],
      url: [this.curKeymatch.url, Validators.required],
      description: [this.curKeymatch.description],
      thumbImgUrl: [this.curKeymatch.thumbImgUrl],
      date: [this.isEdit ? new Date(this.curKeymatch.date) : null]
    });
  }

  submit() {
    this.dialogRef.close({
      id: this.form.value.id,
      title: this.form.value.title ? this.form.value.title.trim() : null,
      description: this.form.value.description ? this.form.value.description.trim() : null,
      url: this.form.value.url ? this.form.value.url.trim() : null,
      thumbImgUrl: this.form.value.thumbImgUrl ? this.form.value.thumbImgUrl.trim() : null,
      keyword: this.form.value.keyword ? this.form.value.keyword.trim() : null,
      triggerType: ConfigData.cloudSearch
        ? 'EXACT_MATCH'
        : this.form.value.triggerType,
      date: this.datepipe.transform(this.form.value.date, 'dd-MM-yyyy')
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}

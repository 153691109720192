import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs';
import { ReportService } from '../../../_services/report.service';
import { LanguageService } from '../../../_services/language.service';
import { ActiveAlias } from '../../../models/alias-list';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import * as html2Canvas from 'html2canvas'


@Component({
  selector: 'fury-reports-charts',
  templateUrl: './reports-charts.component.html',
  styleUrls: ['./reports-charts.component.scss'],
})
export class ReportsChartsComponent implements OnInit {
  isDownloading = false;
  reportForm: FormGroup;
  sites;
  activeAliases: ActiveAlias[] = [];
  filteredActiveAliases: Observable<ActiveAlias[]>;
  fromDateMin: any;
  toDateMin: any;
  fromDateMax: any;
  toDateMax: any;
  toDateMinOg: any;
  fromDateMaxOg: any;
  isAction: boolean = false;
  data: any = null;
  exportButtonBol: any;

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    public languageService: LanguageService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.checkSection();
    this.initForm();
    this.setFormSubscriptions();
    this.getSites();
    this.getDates();
    this.getExportStatus();
  }

  private checkSection() {
    if (this.router.url.indexOf('/reports/reports-charts-action') > -1) {
      this.isAction = true;
    }
  }
  private initForm(): void {
    this.reportForm = this.fb.group({
      sites: ['', Validators.required],
      dates: this.fb.group(
        {
          fromDate: [''],
          toDate: ['']
        },
        { validator: this.atLeastOne(Validators.required) }
      ),
    });
  }

  /**
   * Validatore per FormControl che verifica che almeno un campo sia valido
   */
  private atLeastOne = (validator: ValidatorFn) => (
    group: FormGroup
  ): Validators | null => {
    // Il controllo è valido solo se è valorizzato exactDate o se sono valorizzati
    // entrambi fromDate e toDate

    let hasAtLeastOne = false;

    if (!!group.value.fromDate || !!group.value.toDate) {
      hasAtLeastOne = !!group.value.fromDate && !!group.value.toDate;
    }
    return hasAtLeastOne ? null : { atLastOne: true };
  };


  /**
   * Inizializzazione di tutte le subscriptions relative ai campi del form
   */
  private setFormSubscriptions(): void {
    this.reportForm.get('sites');
    this.reportForm.get('dates.fromDate');
    this.reportForm.get('dates.toDate');
  }

  /**
   * Richiama la lista di reports
   */
  private getSites(): void {
    let reportSiteType = this.isAction ? 'actions' : 'connections';
    this.reportService.getReportSite(reportSiteType).subscribe(response => {
      this.sites = response.sites;
    });
  }


  private getDates(): void {
    let reportSiteType = this.isAction ? 'actions' : 'connections';
    this.reportService.getReportDate(reportSiteType).subscribe(response => {
      let data: any;
      data = response;
      this.fromDateMin = data.first;
      this.toDateMin = data.first;
      this.fromDateMax = data.last;
      this.toDateMax = data.last;
      this.toDateMinOg = this.toDateMin;
      this.fromDateMaxOg = this.fromDateMax;

    });
  }

  /**
   * Costruisce il template prendendo le informazioni dal form
   */
  private getReportTemplate(): any {

    const fromDate = this.reportForm.get('dates.fromDate').value
      ? this.reportForm.get('dates.fromDate').value.format('D/M/YYYY')
      : null;
    const toDate = this.reportForm.get('dates.toDate').value
      ? this.reportForm.get('dates.toDate').value.format('D/M/YYYY')
      : null;
    const siteSelected = this.reportForm.get('sites').value;
    return {
      start: fromDate || null,
      end: toDate || null,
      site: siteSelected
    };
  }

  /**
   * Richiama il componente per visualizzare il report
   */
  viewReport(): void {
    let payload = this.getReportTemplate();
    this.data = null;
    this.exportButtonBol = null;
    setTimeout(() => {
      this.data = payload;
    })
  }

  onDateChange(e) {
    const fromDate = this.reportForm.get('dates.fromDate').value
      ? this.reportForm.get('dates.fromDate').value.format('YYYY-MM-DD')
      : null;
    if (fromDate != null) {
      this.toDateMin = fromDate;
    } else {
      this.toDateMin = this.toDateMinOg;
    }
    const toDate = this.reportForm.get('dates.toDate').value
      ? this.reportForm.get('dates.toDate').value.format('YYYY-MM-DD')
      : null;
    if (toDate != null) {
      this.fromDateMax = toDate;
    } else {
      this.fromDateMax = this.fromDateMaxOg;
    }
  }

  exportCharts() {
    setTimeout(() => {
      this.isDownloading = true;
      this.exportButtonBol = null;
    })

    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px"
    });
    const h2c: any = html2Canvas;
    let wrapperChartsInner = document.querySelectorAll('.report-grid-wrapper-inner')
    let size = wrapperChartsInner.length;
    let processedImages = 0;
    Array.from(wrapperChartsInner).forEach((wrapperCharts: HTMLElement) => {
      if(wrapperCharts.offsetWidth == 0 || wrapperCharts.offsetHeight == 0){
        size--;
      }
      if(wrapperCharts.offsetWidth > 0 && wrapperCharts.offsetHeight > 0){
        wrapperCharts.scrollIntoView();
        h2c(<HTMLElement>wrapperCharts).then(canvas => {
          let cleanCanvas = <HTMLCanvasElement>canvas.cloneNode(true);
          let ctx = cleanCanvas.getContext('2d');
          ctx.fillStyle = "#FFF";
          ctx.fillRect(0, 0, cleanCanvas.width, cleanCanvas.height);
          ctx.drawImage(canvas, 0, 0);
          const pdfWidth = pdf.internal.pageSize.getWidth() - 100;
          const proportion = pdfWidth / canvas.width
          const pdfHeight = canvas.height * proportion;
  
          pdf.addImage(cleanCanvas, 50, 0, pdfWidth, pdfHeight, processedImages.toString(),'NONE')
          processedImages++;
          document.querySelector('.main-container').scrollTo(0,0)
          if (processedImages < size) {
            pdf.addPage();
          } else {
            pdf.save('Report.pdf');
            setTimeout(() => {
              this.isDownloading = false;
              this.exportButtonBol = true;
            })
          }
        })
      }
    })
  }
  getExportStatus() {
    this.reportService.unableButton(null);
    this.reportService.castBtn.subscribe(unableBtn => this.exportButtonBol = unableBtn);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IndexAliasService } from '../../_services/index-alias.service';
import { Alias } from '../../models/alias';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-alias-form',
  templateUrl: './alias-form.component.html',
  styleUrls: ['./alias-form.component.scss']
})
export class AliasFormComponent implements OnInit {
  form: FormGroup;
  formMode: number;
  selectorTitle = '';
  elements = [];

  currentAlias: Alias = {
    id: '',
    indexes: [],
    inclusionPatterns: [],
    exclusionPatterns: []
  };

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AliasFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private aliasService: IndexAliasService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.formMode = this.data.mode;

    if (this.data.currentAlias) {
      this.currentAlias = this.data.currentAlias;
    }

    let fg = {};
    if (this.formMode === 0) {
      fg = {
        nome: [this.currentAlias.id, Validators.compose([Validators.pattern('^[a-z0-9_-]+$'), Validators.required])]
      };
    } else if (this.formMode === 1) {
      // aggiungo un indice
      this.selectorTitle = this.languageService.getLabel(
        'AliasForm.SelezionaIndice'
      );

      this.aliasService.getAliasIndexes(this.currentAlias.id).subscribe(res => {
        this.elements = res;
      });
      fg = {
        selector: ['', Validators.required]
      };
    } else if (this.formMode === 2) {
      // aggiungo un pattern
      fg = {
        pattern: ['', Validators.required]
      };
    }
    this.form = this.formBuilder.group(fg);
  }

  submit() {
    let res = {};

    if (this.formMode === 0) {
      res = { id: this.form.value.nome };
    } else if (this.formMode === 1) {
      // aggiungo un indice
      res = { id: this.form.value.selector };
    } else if (this.formMode === 2) {
      // aggiungo un pattern
      res = { pattern: this.form.value.pattern.trim() };
    }

    this.dialogRef.close(res);
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatTable,
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatSnackBar,
} from '@angular/material';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// Services
import { IndexAliasService } from '../../_services/index-alias.service';
import { ConfirmationDialogService } from '../../_services/confirmation-dialog.service';
import { LanguageService } from '../../_services/language.service';

// Components
import { IndexFormComponent } from '../index-form/index-form.component';

// Models
import { Index, IndexHistory } from '../../models';
import { forkJoin, timer } from 'rxjs';

@Component({
  selector: 'fury-indici',
  templateUrl: './indici.component.html',
  styleUrls: ['./indici.component.scss'],
})
export class IndiciComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<any>;
  @ViewChild('oldIndexTable') oldIndexTable: MatTable<any>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) oldIndexSort: MatSort;
  @ViewChild('indexPaginator') paginator: MatPaginator;
  @ViewChild('oldIndexPaginator') oldIndexPaginator: MatPaginator;

  loading = false;
  connectionBroken = false;
  isReindexing = false;

  constructor(
    private indexAliasService: IndexAliasService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private snackBar: MatSnackBar
  ) { }

  displayedColumns: string[] = ['id', 'reindex', 'refresh', 'refreshAll'];
  oldIndexTableColumns: string[] = [
    'idIndex',
    'idIndexElastic',
    'data',
    'star',
  ];
  dataSource: MatTableDataSource<Index>;
  oldIndexDataSource: MatTableDataSource<IndexHistory>;

  indexFormRef: MatDialogRef<IndexFormComponent>;
  timerUpdateData: any;

  ngOnInit() {
   this.loadData();
  }

  loadData(){
    this.getIndexes();
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  sortOldIndexData() {
    this.oldIndexDataSource.sort = this.sort;
  }

  getIndexes() {
    this.loading = true;

    const obs = [
      this.indexAliasService.getIndexes(),
      this.indexAliasService.getIndexesHistory(),
    ];

    forkJoin(obs)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((responses) => {
        this.checkAreRunningElements(responses);
       
        this.dataSource = new MatTableDataSource(responses[0]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.oldIndexDataSource = new MatTableDataSource<any>(responses[1]);
        this.oldIndexDataSource.sort = this.oldIndexSort;
        this.oldIndexDataSource.paginator = this.oldIndexPaginator;

      });
  }
  checkAreRunningElements(responses){
    let runningElem = responses[0].filter(singleElem => {
      if(singleElem.status == 'running'){
        singleElem.isReindexing = true;
        this.isReindexing = true;
        this.updateData();
      }else{
        singleElem.isReindexing = false;
      }
      return singleElem.status == 'running'
    })

    if(runningElem.lenght === 0){
      this.timerUpdateData.unsubscribe();
    }
  }

  updateData(){
    this.timerUpdateData = timer(20000);
    this.timerUpdateData.subscribe(() => {
      this.loadData();
    })
  }

  addIndex() {
    this.indexFormRef = this.dialog.open(IndexFormComponent, {
      width: '500px',
    });
    this.indexFormRef.afterClosed().subscribe((result) => {
      if (result) {
        this.indexAliasService.saveIndex(result).subscribe((index) => {
          this.getIndexes();
        });
      }
    });
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  applyOldIndexFilter(filter: string): void {
    this.oldIndexDataSource.filter = filter.trim().toLowerCase();
  }

  deleteIndex(index: IndexHistory, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.confirmationDialog
      .confirm(this.languageService.getLabel('index.messaggioElimina'))
      .then((response) => {
        if (response) {
          this.indexAliasService
            .deleteIndex(index.idIndex, index.idIndexElastic)
            .subscribe(() => {
              this.confirmationDialog.confirm(
                `${this.languageService.getLabel(
                  'index.cancellatoCorrettamente'
                )}`,
                '',
                `${this.languageService.getLabel('general.OK')}`,
                '',
                'sm',
                null,
                false
              );
              this.getIndexes();
            });
        }
      });
  }

  confirmReindex(index: Index, event: Event): void {
    
    // Prevengo il click sulla riga
    event.stopPropagation();
    event.preventDefault();

    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel('index.ReindexMessaggio')}`,
        `${this.languageService.getLabel('index.ReindexTitolo')}`,
        `${this.languageService.getLabel('general.Conferma')}`,
        `${this.languageService.getLabel('general.Annulla')}`,
        'sm',
        `${this.languageService.getLabel(
          'dictionaries.SendFormSecondoPattern'
        )}`
      )
      .then((response) => {
        if (!response) {
          return;
        }
        this.isReindexing = true;
        index.isReindexing = true;

        this.snackBar.open(
          this.languageService.getLabel('index.ReindicizzaInCorso'),
          'OK',
          {
            duration: 60000,
            horizontalPosition: 'center',
          }
        );

        this.indexAliasService
          .reindexIndex(index.id)
          .pipe(
            finalize(() => {
              index.isReindexing = false;
              this.isReindexing = false;
            })
          )
          .subscribe(
            result => {
              {
                this.isReindexing = false;
                this.snackBar.open(
                  this.languageService.getLabel('index.ReindexSuccess'),
                  'OK',
                  {
                    duration: 10000,
                    horizontalPosition: 'center',
                  }
                );
                this.getIndexes();
              }
            },
            error => {
              index.isReindexing = false;
              this.isReindexing = false;
            })
      });
  }

  refreshIndex(index: Index, event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel('index.ReindexMessaggio')}`,
        `${this.languageService.getLabel('index.RefreshTitolo')}`,
        `${this.languageService.getLabel('general.Conferma')}`,
        `${this.languageService.getLabel('general.Annulla')}`,
        'sm',
        `${this.languageService.getLabel(
          'dictionaries.SendFormSecondoPattern'
        )}`
      )
      .then((response) => {
        if (response) {
          this.indexAliasService.refreshIndex(index.id).subscribe(() => {
            this.snackBar.open(
              this.languageService.getLabel('index.RefreshSuccess'),
              'OK',
              {
                duration: 10000,
                horizontalPosition: 'center',
              }
            );
            this.getIndexes();
          });
        }
      });
  }

  refreshAllIndexes(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel('index.ReindexMessaggio')}`,
        `${this.languageService.getLabel('index.RefreshTitolo')}`,
        `${this.languageService.getLabel('general.Conferma')}`,
        `${this.languageService.getLabel('general.Annulla')}`,
        'sm',
        `${this.languageService.getLabel(
          'dictionaries.SendFormSecondoPattern'
        )}`
      )
      .then((response) => {
        if (response) {
          this.snackBar.open(
            this.languageService.getLabel('index.ReindicizzaInCorso'),
            'OK',
            {
              duration: 60000,
              horizontalPosition: 'center',
            }
          );

          this.dataSource.data = this.dataSource.data.map(
            (x) => <Index>{ ...x, isReindexing: true }
          );

          this.indexAliasService
            .refreshAllIndexes()
            .pipe(
              finalize(() => {
                this.snackBar.dismiss();
                this.dataSource.data = this.dataSource.data.map(
                  (x) => <Index>{ ...x, isReindexing: false }
                );
              })
            )
            .subscribe((resp) => {
              this.getIndexes();
              this.snackBar.open(
                this.languageService.getLabel('index.RefreshAllSuccess'),
                'OK',
                {
                  duration: 10000,
                  horizontalPosition: 'center',
                }
              );
            });
        }
      });
  }
}

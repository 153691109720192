import { ClusterInfo } from './cluster-info';
import { ServerInfo } from './server-info';

export class ClusterHealth {
  adminInfo: ServerInfo[];
  clusterInfo: ClusterInfo;
  crawlingInfo: ServerInfo[];
  elasticInfo: ServerInfo[];
  searchInfo: ServerInfo[];
  licenseInfo: { type: string; expireDate: string };

  constructor() {
    this.adminInfo = [];
    this.clusterInfo = new ClusterInfo();
    this.crawlingInfo = [];
    this.elasticInfo = [];
    this.searchInfo = [];
    this.licenseInfo = { type: '', expireDate: '' };
  }
}

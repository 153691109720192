import { map, tap, startWith, filter } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { UserRoleService } from 'app/_services/user-role.service';
import { LanguageService } from 'app/_services/language.service';
import { IndexAliasService } from 'app/_services/index-alias.service';
import { ClientService } from 'app/_services/client.service';
import { FrontEndService } from 'app/_services/front-end.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveAlias } from 'app/models/alias-list';
import { FrontEnd } from 'app/models/front-end';
import { Client } from 'app/models/client';
import { Observable, forkJoin } from 'rxjs';
import { UserGroup } from 'app/models/user-group';

@Component({
    selector: 'fury-user-groups-permissions-contents',
    templateUrl: 'user-groups-permissions-contents.component.html',
    styleUrls: ['user-groups-permissions-contents.component.scss']
})

export class UserGroupsPermissionsContentsComponent implements OnInit {

    @Input() currentGroup: UserGroup;

    aliasList: ActiveAlias[] = [];
    frontEndList: FrontEnd[] = [];
    clientsList: Client[] = [];

    aliasForm: FormGroup;
    clientForm: FormGroup;
    frontEndFrom: FormGroup;

    filteredActiveAliases: Observable<ActiveAlias[]>;
    filteredFrontEnds: Observable<FrontEnd[]>;
    filteredClients: Observable<Client[]>;

    selectedAlias: string[] = [];
    selectedFrontends: any[] = [];
    selectedClients: string[] = [];
    constructor(
        private userRoleService: UserRoleService,
        public languageService: LanguageService,
        private fb: FormBuilder,
        private frontEndService: FrontEndService,
        private clientService: ClientService,
        private indexAliasService: IndexAliasService
    ) { }

    ngOnInit() {
        this.recuperaDati();
    }

    private recuperaDati(): void {
        forkJoin(
            [this.indexAliasService.getAliasesShortList(),
            this.clientService.getClients(),
            this.frontEndService.getFrontEnds(),
            this.userRoleService.getSelectedGroupAlias(this.currentGroup.groupName),
            this.userRoleService.getSelectedGroupClients(this.currentGroup.groupName),
            this.userRoleService.getSelectedGroupFrontend(this.currentGroup.groupName)
            ]
        ).subscribe(responses => {
            this.aliasList = responses[0].active;
            this.clientsList = responses[1];
            this.frontEndList = responses[2];
            this.selectedAlias = responses[3].map(el => el.aliasId).sort();
            this.selectedClients = responses[4].map(el => el.clientId).sort();
            this.selectedFrontends = responses[5].sort((a, b) => this.alphabeticSort(a, b, 'frontendId'));
            this.initForms();
        });
    }

    private initForms(): void {

        this.aliasForm = this.fb.group({
            alias: ['', Validators.required]
        });

        this.clientForm = this.fb.group({
            client: ['', Validators.required]
        });

        this.frontEndFrom = this.fb.group({
            frontend: ['', Validators.required]
        });

        this.configureForms();
    }

    private configureForms(): void {
        const activeAlias = this.aliasForm.get('alias');
        const frontEnd = this.frontEndFrom.get('frontend');
        const client = this.clientForm.get('client');

        this.filteredActiveAliases = activeAlias.valueChanges.pipe(
            startWith(''),
            map(value => <string>value),
            tap(value => {
                // Se l'alias inserito è presente in lista allora tolgo gli errori
                if (!!this.getActiveAliasFromList(value)) {
                    activeAlias.setErrors(null);
                } else {
                    // Finchè c'è un valore lascio il campo con errore
                    // else non c'è bisogno perché non essendo required quando
                    // non c'è valore in automatico vengono tolti gli errori
                    if (activeAlias.value) {
                        activeAlias.setErrors({
                            isNotAlias: true
                        });
                    }
                }
            }),
            map(value => this.filterActiveAliases(value))
        );


        this.filteredFrontEnds = frontEnd.valueChanges.pipe(
            startWith(''),
            map(value => <string>value),
            tap(value => {
                // Se l'alias inserito è presente in lista allora tolgo gli errori
                if (!!this.getFrontEndFromList(value)) {
                    frontEnd.setErrors(null);
                } else {
                    // Finchè c'è un valore lascio il campo con errore
                    // else non c'è bisogno perché non essendo required quando
                    // non c'è valore in automatico vengono tolti gli errori
                    if (frontEnd.value) {
                        frontEnd.setErrors({
                            isNotFrontEnd: true
                        });
                    }
                }
            }),
            map(value => this.filterFrontEnd(value))
        );

        this.filteredClients = client.valueChanges.pipe(
            startWith(''),
            map(value => <string>value),
            tap(value => {
                // Se l'alias inserito è presente in lista allora tolgo gli errori
                if (!!this.getClientFromList(value)) {
                    client.setErrors(null);
                } else {
                    // Finchè c'è un valore lascio il campo con errore
                    // else non c'è bisogno perché non essendo required quando
                    // non c'è valore in automatico vengono tolti gli errori
                    if (client.value) {
                        client.setErrors({
                            isNotFrontEnd: true
                        });
                    }
                }
            }),
            map(value => this.filterClients(value))
        );
    }

    /**
  * Ritorna l'alias se l'id inserito viene trovato in lista.
  * E' case insensitive
  * @param value id dell'alias da ricercare
  */
    private getActiveAliasFromList(value: string): ActiveAlias {
        return this.aliasList.find(
            alias => alias.id.toLowerCase() === value.toLowerCase()
        );
    }

    /**
    * Ritorna una lista di alias filtrata in base al valore ricevuto
    * @param value Valore da ricercare tra gli alias
    */
    private filterActiveAliases(value: string): ActiveAlias[] {
        const filterValue = value.toLowerCase();
        return this.aliasList.filter(
            alias => alias.id.toLowerCase().indexOf(filterValue) >= 0
        );
    }

    aggiungiAlias(): void {
        const alias = this.aliasForm.get('alias');
        if (this.selectedAlias.indexOf(alias.value) < 0 && !!alias.value) {

            this.userRoleService.addAliasToGroup(this.currentGroup.groupName, alias.value).subscribe(resp => {
                this.selectedAlias.unshift(alias.value);
                alias.patchValue('');
            });

        }
    }

    removeAlias(aliasId: string): void {
        this.userRoleService.removeAliasFromGroup(this.currentGroup.groupName, aliasId).subscribe(resp => {
            const index = this.selectedAlias.indexOf(aliasId);
            this.selectedAlias.splice(index, 1);
        });
    }

    aggiungiFrontend(): void {
        const frontend = this.frontEndFrom.get('frontend');
        if (this.selectedFrontends.indexOf(frontend.value) < 0 && !!frontend.value) {

            const fe = this.frontEndList.find(el => el.idfrontend === frontend.value);

            this.userRoleService.addFrontendToGroup(
                this.currentGroup.groupName,
                fe.idfrontend,
                fe.lang).subscribe(resp => {
                    this.selectedFrontends.unshift(resp);
                    frontend.patchValue('');
                });

        }
    }

    removeFrontend(frontend): void {
        this.userRoleService.removeFrontendFromGroup(this.currentGroup.groupName, frontend.frontendId,
            frontend.lang.trim()).subscribe(resp => {
                const index = this.selectedFrontends.indexOf(frontend);
                this.selectedFrontends.splice(index, 1);
            });
    }

    aggiungiClient(): void {
        const client = this.clientForm.get('client');
        if (this.selectedClients.indexOf(client.value) < 0 && !!client.value) {

            this.userRoleService.addClientToGroup(
                this.currentGroup.groupName,
                client.value).subscribe(resp => {
                    this.selectedClients.unshift(client.value);
                    client.patchValue('');
                });
        }
    }

    removeClient(clientId: string): void {
        this.userRoleService.removeClientFromGroup(this.currentGroup.groupName, clientId).subscribe(resp => {
            const index = this.selectedClients.indexOf(clientId);
            this.selectedClients.splice(index, 1);
        });
    }

    /**
* Ritorna una lista di alias filtrata in base al valore ricevuto
* @param value Valore da ricercare tra gli alias
*/
    private filterFrontEnd(value: string): FrontEnd[] {
        const filterValue = value.toLowerCase();
        return this.frontEndList.filter(
            fe => fe.idfrontend.toLowerCase().indexOf(filterValue) >= 0
        );
    }

    /**
* Ritorna l'alias se l'id inserito viene trovato in lista.
* E' case insensitive
* @param value id dell'alias da ricercare
*/
    private getFrontEndFromList(value: string): FrontEnd {
        return this.frontEndList.find(
            fe => fe.idfrontend.toLowerCase() === value.toLowerCase()
        );
    }

    /**
* Ritorna l'alias se l'id inserito viene trovato in lista.
* E' case insensitive
* @param value id dell'alias da ricercare
*/
    private getClientFromList(value: string): Client {
        return this.clientsList.find(
            client => client.idclient.toLowerCase() === value.toLowerCase()
        );
    }

    /**
* Ritorna una lista di alias filtrata in base al valore ricevuto
* @param value Valore da ricercare tra gli alias
*/
    private filterClients(value: string): Client[] {
        const filterValue = value.toLowerCase();
        return this.clientsList.filter(
            client => client.idclient.toLowerCase().indexOf(filterValue) >= 0
        );
    }

    private alphabeticSort(a: any, b: any, prop: string): number {
        if (a[prop] > b[prop]) {
            return 1;
        }

        if (b[prop] > a[prop]) {
            return - 1;
        }

        return 0;
    }

}

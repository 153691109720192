import { Component } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'fury-speech-to-text',
    templateUrl: 'speech-to-text.component.html',
    styleUrls: ['speech-to-text.component.scss']
})
export class SpeechToTextComponent {
    public linkCss = '<link rel="stylesheet" type="text/css" href="https://storage.googleapis.com/n4s_static_resources/css/speechtotext.css">';

    public linkScript = '<script src="https://storage.googleapis.com/n4s_static_resources/plugin/scripts.st7fd824d798gf9gfd.js"></script>';

    public configurationExample =
        `var config = {\n
        "containerId": "<${this.languageService.getLabel('configuration.SpeechToText.id-button')}>",\n
        "iconSrc": "<${this.languageService.getLabel('configuration.SpeechToText.url-immagine-microfono')}>",\n
        "inputId": "<${this.languageService.getLabel('configuration.SpeechToText.id-input')}>",\n
        "resultInput": true,\n
        "mediaRecorderConfig":{\n
            "urlEndPointRec": "<${this.languageService.getLabel('configuration.SpeechToText.url-chiave')}>" \n
        }\n}\n\n\ninitSpeechToText(config);`;

    constructor(public languageService: LanguageService) {
        this.languageService.languageChanged$.subscribe(_ =>
            this.configurationExample =
            `var config = {\n
            "containerId": "<${this.languageService.getLabel('configuration.SpeechToText.id-button')}>",\n
            "iconSrc": "<${this.languageService.getLabel('configuration.SpeechToText.url-immagine-microfono')}>",\n
            "inputId": "<${this.languageService.getLabel('configuration.SpeechToText.id-input')}>",\n
            "resultInput": true,\n
            "mediaRecorderConfig":{\n
                "urlEndPointRec": "<${this.languageService.getLabel('configuration.SpeechToText.url-chiave')}>" \n
            }\n}\n\n\ninitSpeechToText(config);`
        );
    }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import {
  MatDialog,
  MatDialogRef,
  MatTable,
  MatTableDataSource,
  MatSort,
  MatPaginator
} from '@angular/material';

import { IndexAliasService } from '../../_services/index-alias.service';
import { LanguageService } from '../../_services/language.service';
import { ConfirmationDialogService } from '../../_services/confirmation-dialog.service';

import { AliasFormComponent } from '../alias-form/alias-form.component';

import { Alias } from '../../models/alias';
import { ActiveAlias, InactiveAlias } from 'app/models/alias-list';
import { AuthenticationService } from 'app/_services/authentication.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-alias',
  templateUrl: './alias.component.html',
  styleUrls: ['./alias.component.scss']
})
export class AliasComponent implements OnInit {
  @ViewChild('activeAliasTable') activeAliasTable: MatTable<ActiveAlias>;
  @ViewChild('activeAliasSort') activeAliasSort: MatSort;
  @ViewChild('activeAliasMatPaginator') activeAliasMatPaginator: MatPaginator;
  activeAliasList: ActiveAlias[] = [];
  activeAliasDataSource: MatTableDataSource<ActiveAlias>;
  activeAliasDisplayedColumns = ['id', 'star'];

  @ViewChild('inactiveAliasTable') inactiveTable: MatTable<InactiveAlias>;
  @ViewChild('inactiveAliasSort') inactiveAliasSort: MatSort;
  @ViewChild('inactiveAliasMatPaginator')
  inactiveAliasMatPaginator: MatPaginator;
  inactiveAliasList: InactiveAlias[] = [];
  inactiveAliasDataSource: MatTableDataSource<InactiveAlias>;
  inactiveAliasDisplayedColumns = ['id', 'star'];

  aliasFormRef: MatDialogRef<AliasFormComponent>;

  editableAlias = true;
  insertableAlias = true;

  loading = false;

  isAdmin: boolean;

  constructor(
    private aliasService: IndexAliasService,
    private dialog: MatDialog,
    private router: Router,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.isAdmin = this.authenticationService.userIsAdmin();

    this.getAliases();
  }

  private getAliases(): void {
    this.loading = true;
    this.aliasService
      .getAliasesShortList()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(aliasResponse => {
        this.editableAlias = aliasResponse.editable;
        this.insertableAlias = aliasResponse.insertable;
        // Active aliases
        this.activeAliasList = aliasResponse.active;
        this.activeAliasDataSource = new MatTableDataSource(
          this.activeAliasList
        );
        this.activeAliasDataSource.sort = this.activeAliasSort;
        this.activeAliasDataSource.paginator = this.activeAliasMatPaginator;
        // Inactive aliases
        if (this.editableAlias) {
          this.inactiveAliasList = aliasResponse.inactive;
          this.inactiveAliasDataSource = new MatTableDataSource(
            this.inactiveAliasList
          );
          this.inactiveAliasDataSource.sort = this.inactiveAliasSort;
          this.inactiveAliasDataSource.paginator = this.inactiveAliasMatPaginator;
        }
      });
  }

  addAlias() {
    this.aliasFormRef = this.dialog.open(AliasFormComponent, {
      width: '500px',
      data: { mode: 0 }
    });
    this.aliasFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.aliasService
          .saveAlias(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() =>
            this.router.navigate(['/alias-detail/' + result.id])
          );
      }
    });
  }

  editAlias(alias: Alias) {
    if (this.editableAlias) {
      this.router.navigate(['/alias-detail/' + alias.id]);
    }
  }

  deleteAlias(alias: Alias) {
    this.confirmationDialog
      .confirm(`Eliminare l'alias ${alias.id}?`)
      .then(result => {
        if (result) {
          this.aliasService
            .deleteAlias(alias)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getAliases());
        }
      });
  }

  sortActiveData() {
    this.activeAliasDataSource.sort = this.activeAliasSort;
  }

  sortInactiveData() {
    this.inactiveAliasDataSource.sort = this.inactiveAliasSort;
  }

  applyActiveFilter(filter: string): void {
    this.activeAliasDataSource.filter = filter.trim().toLowerCase();
  }

  applyInactiveFilter(filter: string): void {
    this.inactiveAliasDataSource.filter = filter.trim().toLowerCase();
  }
}


import { NgModule } from '@angular/core';
import { ReplaceParametersPipe } from 'app/pipes/replace-parameters.pipe';


@NgModule({
    declarations: [ReplaceParametersPipe],
    exports: [ReplaceParametersPipe]
})
export class SharedModule { }

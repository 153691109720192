import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { LanguageService } from '../../../../_services/language.service';

interface DialogData {
  count: number;
  isKeymatch?: boolean;
  isRelatedQueries?: boolean;
}

@Component({
  selector: 'fury-confirm-keymatch-import-modal',
  templateUrl: './confirm-keymatch-import-modal.component.html'
})
export class ConfirmKeymatchImportModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public languageService: LanguageService
  ) {}
}

import { Component, OnInit, Inject } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserGroup } from 'app/models/user-group';


@Component({
  selector: 'fury-user-groups-permissions-modal',
  templateUrl: './user-groups-permissions-modal.component.html'
})
export class UserGroupsPermissionsModalComponent implements OnInit {

  currentGroup: UserGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService,
    private dialogRef: MatDialogRef<UserGroupsPermissionsModalComponent>,
  ) { }

  ngOnInit() {
    this.currentGroup = this.data.group;
  }

  close(): void {
    this.dialogRef.close();
  }
}

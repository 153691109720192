import { Notification } from './../../../models/notification';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsCenterService } from 'app/_services/notifications-center.service';
import { LIST_FADE_ANIMATION } from '../../common/list.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'fury-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION]
})
export class ToolbarNotificationsComponent implements OnInit {

  notifications: Notification[];
  isOpen: boolean;
  currentUser;
  permissionAll : boolean = false;

  public unreadNotificationsNumber: number;

  constructor(
    public languageService: LanguageService,
    private notificationsCenterService: NotificationsCenterService,
    private router: Router
    ) {
  }

  ngOnInit() {
    this.getNotifications();
    
  }

  getNotifications() {
    this.chekIsAdmin();
    this.notificationsCenterService.refreshNotifications();
    this.notifications = this.notificationsCenterService.notifications;
    this.notificationsCenterService.notificationsSub$.subscribe(
      (data) => {
        this.notifications = data;
        this.unreadNotificationsNumber = this.notificationsCenterService.unreadNotificationsNumber;
      }
    );
  }

  markAsRead(notification: Notification) {
    if (notification.read) {
      return;
    }

    this.notificationsCenterService.markNotificationRead(notification).subscribe();

    notification.read = true;
    this.unreadNotificationsNumber--;
  }


  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAllAsRead() {
    this.notifications.filter(x => !x.read).forEach(el => this.markAsRead(el));
  }


  goToNotificationsHistory() {
    this.router.navigate(['notifications-history']);
  }
  chekIsAdmin(){
    this.currentUser = localStorage.getItem('currentUser')
    if(this.currentUser){
      this.currentUser = JSON.parse(this.currentUser)
      let permissions = this.currentUser.res.permissions
      if(permissions.indexOf('all') > -1){
        this.permissionAll = true;
      }
    }
  }
}

export const PERMISSION_CODES = {
    STATO_MACCHINE: 'cluster',
    FRONTEND: 'frontend',
    CLIENT: 'client',
    INDICI_ALIAS: 'index-alias',
    INDICI_META: 'index-meta',
    GESTIONE_CRAWLING: 'crawling',
    GESTIONE_UTENZE: 'user',
    CONFIGURAZIONE: 'configuration',
    ONEBOX: 'onebox',
    DIFFERENZIAZIONE_RISULTATI: 'biasing',
    IMPOSTAZIONI_QUERY: 'query-settings',
    SUGGESTION: 'suggestion',
    GSA_CONFIGURATION_IMPORTER: 'gsa-configuration-importer',
    REPORT: 'report',
    REPORT_TABS: {
        REPORTS : 'report',
        HISTORY_REPORT : 'history-report',
        REPORT_QUERY : 'report-query',
        REPORT_CLICK : 'report-click'
    },
    CENTRO_PREVIEW: 'frontend-preview',
    NOTIFICATIONS: 'notifications',
    AUDIO_INDEX : 'audio-index',
    DOCS_INDEX : 'docs-index',
    IMAGES_INDEX : 'image-index',
    VIDEO_INDEX : 'video-index',
    CLIENT_TABS: {
        NAVIGAZIONE_DINAMICA: 'client-dynamic-navigation',
        KEYMATCH: 'client-keymatch',
        ONEBOX: 'client-onebox',
        QUERY_CORRELATE: 'client-related-query',
        ESCLUDI_URL: 'client-url-exclusion',
        ASSOCIAZIONE_TEMPLATE_ELASTIC: 'client-elastic-template',
        POLICY_DIFF_RISULTATI: 'client-biasing'
    },
    INDICI_ALIAS_TABS: {
        INDICI: 'index-alias-index',
        ALIAS: 'index-alias-alias',
        DIAGNOSTICA: 'index-alias-diagnostics',
        CANCELLAZIONE_DOCUMENTI: 'index-alias-elastic-docs-delete',
        META: 'index-alias-meta'
    },
    GESTIONE_CRAWLING_TABS: {
        WEB: 'crawling-web',
        FEED_XML: 'crawling-feed-xml',
        OPERAZIONI: 'crawling-operations',
        LOGS: 'crawling-logs',
        MONITORING_KENTICO : 'monitoring-kentico',
        MONITORING_JSON_INGESTER : 'monitoring-json-ingester'
    },
    GESTIONE_UTENTI_TABS: {
        UTENTI: 'user-list',
        GRUPPI: 'user-groups'
    },
    CONFIGURAZIONE_TABS: {
        PROPRIETA_SISTEMA: 'configuration-system-property',
        TEMPLATE_SEARCH: 'configuration-elastic-template',
        LINGUA: 'configuration-language',
        INCLUDE_FREEMARKER: 'configuration-freemarker-inclusion',
        INCLUDE_XLST: 'configuration-xslt',
        SPEECH_TO_TEXT: 'configuration-speech-text',
        SYSTEM_EMAIL: 'configuration-email-template',
        AUDIO_INDEX : 'audio-index-customization',
        PREVIEW_DOC : 'preview-doc',
        IMAGES_INDEX : 'images-index',
        VIDEO_INDEX: 'video-index',
        GEO: 'geo-position',
        CHRONOLOGY: 'chronology'
    },
    IMPOSTAZIONI_QUERY_TABS: {
        SINONIMI: 'query-settings-synonyms',
        STOPWORDS: 'query-settings-stopwords',
        BLACKLIST: 'query-settings-blacklist'
    },
    GSA_CONFIGURATION_IMPORTER_TABS: {
        KEYMATCH: 'gsa-configuration-importer-keymatch',
        XSLT: 'gsa-configuration-importer-xslt',
        QUERY_CORRELATE: 'gsa-configuration-importer-related-query'
    }
};

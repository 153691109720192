import { Component, OnInit, Inject } from '@angular/core';
import { PolicyBiasing } from 'app/models/policy-biasing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-policy-biasing-form',
  templateUrl: 'policy-biasing-form.component.html',
  styleUrls: ['policy-biasing-form.component.scss']
})
export class PolicyBiasingFormComponent implements OnInit {
  currentBiasing: PolicyBiasing = {
    idBiasing: '',
    description: '',
    aliasList: null,
    decay: 0,
    offset: '',
    metaList: null,
    scale: ''
  };

  isEdit = false;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PolicyBiasingFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.isEdit = !!this.data;

    if (this.isEdit) {
      this.currentBiasing = this.data.policy;
    }

    this.form = this.formBuilder.group({
      idBiasing: [
        { value: this.currentBiasing.idBiasing, disabled: this.isEdit },
        Validators.compose([Validators.pattern('^[a-zA-Z0-9_-]+$'), Validators.required])
      ],
      description: [this.currentBiasing.description, Validators.required]
    });
  }

  submit() {
    this.dialogRef.close({
      idBiasing: this.isEdit
        ? this.currentBiasing.idBiasing
        : this.form.value.idBiasing,
      description: this.form.value.description,
      aliasList: null,
      decay: this.currentBiasing.decay,
      offset: this.currentBiasing.offset,
      metaList: null,
      scale: this.currentBiasing.scale
    });
  }
}

import { IndexAliasService } from './../../_services/index-alias.service';
import { LanguageService } from './../../_services/language.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'fury-indici-cancellazione-documenti',
  templateUrl: './indici-cancellazione-documenti.component.html',
  styleUrls: ['./indici-cancellazione-documenti.component.scss']
})
export class IndiciCancellazioneDocumentiComponent {

  documentUrl: string;

  documentsList: string[] = [];

  constructor(
    private dialog: MatDialog,
    public languageService: LanguageService,
    private indexService: IndexAliasService,
    private snackBar: MatSnackBar
  ) { }

  addDocument(): void {
    this.documentsList.push(this.documentUrl);
    this.documentUrl = '';
  }

  removeDocument(document: string): void {
    const index = this.documentsList.indexOf(document);
    this.documentsList.splice(index, 1);
  }

  invalidInput() {
    return !this.documentUrl || !this.documentUrl.length;
  }

  deleteDocuments(): void {
    const confirmModalRef = this.dialog.open(
      ConfirmationDialogComponent
    );

    confirmModalRef.componentInstance.showCancelButton = true;
    confirmModalRef.componentInstance.message = this.languageService.getLabel(
      'indici.CancellazioneDocumenti.Warning'
    );

    confirmModalRef.afterClosed().subscribe(hasConfirmed => {
      if (hasConfirmed) {
        this.indexService.deleteDocuments(this.documentsList).subscribe(resp => {
          const totalResultModalRef = this.dialog.open(
            ConfirmationDialogComponent
          );

          totalResultModalRef.componentInstance.showCancelButton = false;
          totalResultModalRef.componentInstance.message = this.languageService.getLabel(
            'indici.CancellazioneDocumenti.Total'
          ).replace('{total}', resp.total);
          this.resetInputs();
        });
      }
    });

  }

  private resetInputs(): void {
    this.documentUrl = '';
    this.documentsList = [];
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  MatTable,
  MatTableDataSource,
  MatDialog,
  MatDialogRef
} from '@angular/material';
import { finalize } from '../../../../node_modules/rxjs/operators';
import { ClientService } from '../../_services/client.service';
import { Ranking } from '../../models/ranking';
import { TextWeight } from '../../models/text-weight';
import { ClientDetailComponent } from '../../n4s/client-detail/client-detail.component';
import { TextWeightFormComponent } from '../text-weight-form/text-weight-form.component';
import { RankingFormComponent } from '../ranking-form/ranking-form.component';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';

@Component({
  selector: 'fury-client-rankings',
  templateUrl: './client-rankings.component.html',
  styleUrls: ['./client-rankings.component.scss']
})
export class ClientRankingsComponent implements OnInit {
  @ViewChild('rankingsTable') feTable: MatTable<any>;
  @ViewChild('textWeightTable') twTable: MatTable<any>;

  dateWeight = 20;

  loading = false;
  displayedColumns: string[] = ['campo', 'boost', 'star'];
  displayedColumnsTextWeight: string[] = [
    'campo',
    'textRegex',
    'boost',
    'star'
  ];
  dataSourceRanking: MatTableDataSource<Ranking>;
  dataSourceTextWeight: MatTableDataSource<TextWeight>;
  textWeightFormRef: MatDialogRef<TextWeightFormComponent>;
  rankingFormRef: MatDialogRef<RankingFormComponent>;

  public parentClient;

  constructor(
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.parentClient = this.parentComponent.currentClient;
    this.getTextWeight();
  }

  getTextWeight(): void {
    this.loading = true;

    this.clientService
      .getFilters(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        clientFilters => {
          this.dateWeight = clientFilters.dateWeight;
          this.dataSourceRanking = new MatTableDataSource(
            clientFilters.ranking
          );
          this.dataSourceTextWeight = new MatTableDataSource(
            clientFilters.textWeight
          );
        },
        () => {
          alert('Impossibile collegarsi al server.');
        }
      );
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    return value;
  }

  dateWeightChange(value) {
    this.clientService
      .editDateWeight(this.parentComponent.currentClient.idclient, value)
      .subscribe();
  }

  addTextWeight() {
    this.textWeightFormRef = this.dialog.open(TextWeightFormComponent, {
      width: '500px',
      data: { idclient: this.parentClient.idclient }
    });

    this.textWeightFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .addTextWeight(this.parentClient.idclient, result)
          .subscribe(res => {
            this.getTextWeight();
          });
      }
    });
  }

  editTextWeight(textWeight: TextWeight) {
    // this.textWeightFormRef = this.dialog.open(TextWeightFormComponent, { width: '500px', data: { idclient: this.parentClient.idclient, textWeight: textWeight } })
  }

  deleteTextWeight(textWeight: TextWeight) {
    this.confirmationDialog
      .confirm(`Eliminare il peso ${textWeight.meta.label}?`)
      .then(res => {
        if (res) {
          this.clientService
            .deleteTextWeight(this.parentClient.idclient, textWeight)
            .subscribe(resp => this.getTextWeight());
        }
      });
  }

  addRanking() {
    // TODO inserimento nuovo ranking
    this.rankingFormRef = this.dialog.open(RankingFormComponent, {
      width: '500px',
      data: { idclient: this.parentClient.idclient }
    });

    this.rankingFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .addRanking(this.parentClient.idclient, result)
          .subscribe(
            ranking => {
              this.getTextWeight();
            },
            error => {
              if (error.status === 409) {
                alert(
                  'Impossibile assegnare un ranking ad un meta già presente.'
                );
              }
            }
          );
      }
    });
  }

  changeBoost(fid, value) {
    const appo = this.dataSourceRanking.data;
    const ranking: Ranking = appo.find(f => f.id === fid);
    ranking.boost = value;

    this.clientService
      .editRanking(this.parentClient.idclient, ranking)
      .subscribe();
  }

  deleteRanking(ranking: Ranking) {
    this.confirmationDialog
      .confirm(`Eliminare il ranking per meta ${ranking.meta.label}?`)
      .then(res => {
        if (res) {
          this.clientService
            .deleteRanking(this.parentClient.idclient, ranking)
            .subscribe(resp => this.getTextWeight());
        }
      });
  }

  changeTextWeightBoost(tid, value) {
    const appo = this.dataSourceTextWeight.data;
    const textWeight: TextWeight = appo.find(f => f.id === tid);
    textWeight.boost = value;

    this.clientService
      .editTextWeight(this.parentClient.idclient, textWeight)
      .subscribe(resp => this.getTextWeight());
  }
}

import { tap } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { UserRoleService } from 'app/_services/user-role.service';
import { GroupAreaPermission } from 'app/models/group-area-permission';
import { UserGroup } from 'app/models/user-group';
import { UserGroupsPermissionsSubareasComponent } from '../user-groups-permissions-subareas/user-groups-permissions-subareas.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Observable, forkJoin, of } from 'rxjs';

@Component({
    selector: 'fury-user-groups-permissions-areas',
    templateUrl: 'user-groups-permissions-areas.component.html',
    styleUrls: ['user-groups-permissions-areas.component.scss']
})

export class UserGroupsPermissionsAreasComponent implements OnInit {

    @Input() currentGroup: UserGroup;

    userAreas: GroupAreaPermission[] = [];

    subAreaFormRef: MatDialogRef<UserGroupsPermissionsSubareasComponent, boolean>;

    constructor(
        private userRoleService: UserRoleService,
        public languageService: LanguageService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getGroupVisibleAreas();
    }

    getGroupVisibleAreas(): void {
        this.userRoleService.getGroupVisibleAreas(this.currentGroup.groupName).subscribe((resp) => {
            let sortedList = resp;
            sortedList.sort((a, b) => this.alphabeticSort(a, b));
            this.userAreas = sortedList;
            this.userAreas.forEach(area => area.childList = this.sortArea(area));
        });
    }

    sortArea(area){
       return area.childList.sort((a, b) => this.alphabeticSort(a, b))
    }

    toggleArea(area: GroupAreaPermission): void {
        if (area.enabled) {
            return this.enableArea(area);
        }

        this.disableArea(area);
    }

    private enableArea(area: GroupAreaPermission): void {
        if (area.childList.length) {
            this.enableAllChildrenList(area)
                .subscribe(res => {
                    this.userRoleService.enableGroupArea(this.currentGroup.groupName, area.function)
                        .pipe(tap(_ => area.enabled = true))
                        .subscribe();
                });
            return;
        }

        this.userRoleService.enableGroupArea(this.currentGroup.groupName, area.function)
            .pipe(tap(_ => area.enabled = true))
            .subscribe();
    }

    private disableArea(area: GroupAreaPermission): void {
        if (area.childList.length) {
            this.disableAreaAndChildren(area).subscribe();
            return;
        }

        this.userRoleService.disableGroupArea(this.currentGroup.groupName, area.function)
            .pipe(tap(_ => area.enabled = false))
            .subscribe();
    }

    private enableAllChildrenList(area: GroupAreaPermission): Observable<GroupAreaPermission[]> {
        const obs = area.childList.filter(x => !x.enabled)
            .map(x => this.userRoleService.enableGroupArea(x.groupName, x.function)
                .pipe(tap(_ => x.enabled = true)));

        return forkJoin(obs);
    }

    private disableAreaAndChildren(area: GroupAreaPermission): Observable<boolean[]> {
        // Agiungo prima tutte le sottoaree
        const obs = area.childList.filter(x => x.enabled)
            .map(x => this.userRoleService.disableGroupArea(x.groupName, x.function)
                .pipe(tap(_ => x.enabled = false)));

        // Aggiungo l'area principale
        obs.push(this.userRoleService.disableGroupArea(this.currentGroup.groupName, area.function)
            .pipe(tap(_ => area.enabled = false)));

        return forkJoin(obs);
    }


    openChildrenList(area: GroupAreaPermission): void {

        this.subAreaFormRef = this.dialog.open(UserGroupsPermissionsSubareasComponent, {
            width: '500px',
            maxHeight: '90vh',
            panelClass: 'groups-permission-modal',
            data: { area, group: this.currentGroup }
        });
    }

    private alphabeticSort(a: GroupAreaPermission, b: GroupAreaPermission): number {
        if (this.languageService.getLabel('userAreaPermission.' + a.function) >
            this.languageService.getLabel('userAreaPermission.' + b.function)) {
            return 1;
        }

        if (this.languageService.getLabel('userAreaPermission.' + b.function) >
            this.languageService.getLabel('userAreaPermission.' + a.function)) {
            return -1;
        }

        return 0;
    }

}

import { ReportHistory } from './../models/report-history';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Report } from 'app/models/report';

import { ConfigData } from '../app.config';
import { Type } from 'app/models/index';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  reportUrl = `${ConfigData.apiUrl}/report`;
  private unableBtn = new BehaviorSubject<any>(null);
  castBtn = this.unableBtn.asObservable();
  reportsCounter: number = 0;


  constructor(private http: HttpClient) { }

  // Restituisce i codici e le descrizioni dei report disponibili da poter generare
  getReportList(): Observable<Type[]> {
    return this.http.get<Type[]>(`${this.reportUrl}/listCode`);
  }

  /**
   * Restituisce tutti i possibili site (o raccolte) per cui sarà possibile filtrare la generazione dei report.
   */
  getReportSite(reportType: string): Observable<any> {
    return this.http.get<any>(`${this.reportUrl}/graphs/${reportType}/get-sites`);
  }

  /**
 * Restituisce range di date per cui sarà possibile filtrare la generazione dei report.
 */
  getReportDate(reportType: string): Observable<Type[]> {
    return this.http.get<Type[]>(`${this.reportUrl}/graphs/${reportType}/get-date-span`);
  }

  // Restituisce un oggetto JSON con dei dati richiesti a seconda del codice passato
  getReport(codiceReport: string, req: Report): Observable<any> {
    return this.http.post<any>(`${this.reportUrl}/${codiceReport}`, req);
  }

  // Restituisce un file .csv con i dati richiesti a seconda del codice passato
  downloadReportCSV(codiceReport: string, req: any): Observable<any> {
    const obj = {
      title: req.title,
      site: req.site,
      exactDate: req.exactDate,
      start: req.start,
      end: req.end,
      excludeTerms: req.excludeTerms
    };
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(
      `${this.reportUrl}/print/csv/${codiceReport}`,
      obj,
      httpOptions
    );
  }

  // Restituisce un file .pdf con i dati richiesti a seconda del codice passato
  downloadReportPDF(codiceReport: string, req: Report): Observable<any> {
    const obj = {
      title: req.title,
      exactDate: req.exactDate,
      start: req.start,
      end: req.end,
      site: req.site,
      excludeTerms: req.excludeTerms
    };
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(
      `${this.reportUrl}/print/pdf/${codiceReport}`,
      obj,
      httpOptions
    );
  }

  saveReportForHistory(exportMediaType: string, reportCode: string, report: Report): Observable<any> {
    return this.http.post<any>(
      `${this.reportUrl}/save/${exportMediaType}/${reportCode}`, report
    )
  }

  getReportsHistory(): Observable<ReportHistory[]> {
    return this.http.get<ReportHistory[]>(
      `${this.reportUrl}/history`
    );
  }

  downloadHistoryReport(idReport: number): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(`${this.reportUrl}/history/download/${idReport}`, httpOptions);
  }

  deleteHistoryReport(idReport: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.reportUrl}/history/${idReport}`);
  }
  //Connections
  get_ts_count_query(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-ts-count-query`, params);
  }
  get_ts_hits(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-ts-hits`, params);
  }
  get_ts_took_time(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-ts-took-time`, params);
  }
  get_query_by_took_time(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-query-by-took-time`, params);
  }
  get_query_percentage(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-query-percentage`, params);
  }
  get_query_per_client(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-query-per-client`, params);
  }
  /*   get_anomalous_query_content(params): Observable<any> {
      return this.http.post(`${this.reportUrl}/graphs/connections/get-anomalous-query-content`,params);
    } */
  connections_anomaly_detection_query_content(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/connections/get-anomalous-query-content`, params);
  }
  //actions
  get_query_counts(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-query-counts`, params);
  }
  get_query_percentage_actions(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-query-percentage`, params);
  }
  get_daily_seasonal_query(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-daily-seasonal-query`, params);
  }
  compute_performance_delta(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/compute-performance-delta`, params);
  }
  get_scatter_queries(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-scatter-queries`, params);
  }
  get_page_clicks(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-page-clicks`, params);
  }
  get_page_far_clicks(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-page-far-clicks`, params);
  }
  anomaly_detection_query_content(params): Observable<any> {
    return this.http.post(`${this.reportUrl}/graphs/actions/get-anomalous-query-content`, params);
  }
  addReportCount(){
    this.reportsCounter++;
  }
  removeReportCount(){
    this.reportsCounter--;
    if (this.reportsCounter == 0) {
      this.unableButton(true);
    }
  }
  unableButton(newStatus){
    this.unableBtn.next(newStatus); 
  }
}

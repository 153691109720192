import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';

import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatTable,
  Sort,
  MatDialog,
  MatDialogRef
} from '@angular/material';

import { UserFormComponent } from './../user-form/user-form.component';

import { UserRoleService } from './../../_services/user-role.service';
import { ConfirmationDialogService } from './../../_services/confirmation-dialog.service';

import { UserData } from 'app/models/userData';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-user',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<UserData>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<UserData>;

  loading = false;

  displayedColumns: string[] = ['username', 'email', 'star'];

  userFormRef: MatDialogRef<UserFormComponent, boolean>;

  constructor(
    private userRoleService: UserRoleService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  private getUsers(): void {
    this.loading = true;
    this.userRoleService
      .getUsers()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        users => {
          this.dataSource = new MatTableDataSource(users);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
  }

  sortData(sort: Sort): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  addUser() {
    this.userFormRef = this.dialog.open(UserFormComponent, { width: '800px' });
    this.userFormRef.afterClosed().subscribe(result => {
      this.getUsers();
    });
  }

  editUser(user: UserData) {
    this.userFormRef = this.dialog.open(UserFormComponent, {
      width: '800px',
      data: { user: user }
    });

    this.userFormRef.afterClosed().subscribe(result => {
      this.getUsers();
    });
  }

  deleteUser(user: UserData): void {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel('user.MessaggioEliminaUtente')}?`,
        this.languageService.getLabel('user.TitoloEliminaUtente')
      )
      .then(res => {
        if (res) {
          this.loading = true;
          this.userRoleService.deleteUser(user).subscribe(() => {
            this.getUsers();
          });
        }
      });
  }
}

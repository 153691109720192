import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { finalize, tap } from '../../../../../node_modules/rxjs/operators';

import { LanguageService } from '../../../_services/language.service';
import { ConfigurationService } from '../../../_services/configuration.service';

import { PropsFormComponent } from '../props-form/props-form.component';

import { SysProp } from '../../../models/sys-prop';
import { NotificationsService } from 'app/_services/notifications.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fury-sys-props',
  templateUrl: './sys-props.component.html',
  styleUrls: ['./sys-props.component.scss']
})
export class SysPropsComponent implements OnInit {
  loading = false;
  propsFormRef: MatDialogRef<PropsFormComponent>;
  properties: SysProp[];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getProperties();
  }

  private getProperties(): void {
    this.configurationService
      .getProperties()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        properties => this.properties = properties);
  }

  editProp(element: SysProp, indice: number) {
    if (element.editable) {
      this.propsFormRef = this.dialog.open(PropsFormComponent, {
        width: '90%',
        data: {
          prop: element
        }
      });

      this.propsFormRef.afterClosed().subscribe(result => {
        this.document.body.classList.remove('modal-is-full');
        if (result) {
          this.configurationService.editProperty(result)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.properties[indice] = result);
        }
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material modules
import { MatMenuModule, MatButtonModule } from '@angular/material';

// Component
import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
  imports: [CommonModule, MatMenuModule, MatButtonModule],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent]
})
export class LanguageSelectorModule {}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ClientService } from 'app/_services/client.service';
import { LanguageService } from 'app/_services/language.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fury-update-license-form',
  templateUrl: './update-license-form.component.html'
})
export class UpdateLicenseFormComponent {
  public licenseKey: string;
  public loading = false;

  constructor(
    public languageService: LanguageService,
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UpdateLicenseFormComponent>) { }

  public updateLicense(): void {
    this.loading = true;
    this.clientService.updateLicense(this.licenseKey)
      .pipe(finalize(() => this.loading = false))
      .subscribe(resp => {

        this.dialogRef.close(true);

        this.snackBar.open(this.languageService.getLabel('licenseForm.LicenzaAggiornata'), 'OK', {
          duration: 10000,
          horizontalPosition: 'center'
        });
      });
  }
}

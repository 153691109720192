import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TextWeight } from '../../models/text-weight';
import { FilterMeta } from '../../models/filter-meta';
import { ClientService } from '../../_services/client.service';

@Component({
  selector: 'fury-text-weight-form',
  templateUrl: './text-weight-form.component.html',
  styleUrls: ['./text-weight-form.component.scss']
})
export class TextWeightFormComponent implements OnInit {
  currentIdClient: string;
  form: FormGroup;

  filterMeta: FilterMeta[];
  isEdit: boolean = false;

  curTextWeight: TextWeight = {
    id: -1,
    textRegex: '',
    boost: 0,
    field: '',
    meta: {
      id: -1,
      label: ''
    }
  };

  constructor(
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TextWeightFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit() {
    this.currentIdClient = this.data.idclient;

    this.clientService
      .getFiltersMetaList()
      .subscribe(res => (this.filterMeta = res));

    if (this.data.textWeight) {
      this.curTextWeight = this.data.textWeight;
      this.isEdit = true;
    }

    this.form = this.formBuilder.group({
      field: [this.curTextWeight.field, Validators.required],
      textRegex: [this.curTextWeight.textRegex, Validators.required],
      boost: [this.curTextWeight.boost],
      id: [this.curTextWeight.id]
    });
  }

  submit(form) {
    this.dialogRef.close({
      id: form.value.id,
      boost: form.value.boost,
      textRegex: form.value.textRegex,
      field: form.value.field,
      meta: this.getMeta(form.value.idmeta)
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  getMeta(idmeta): FilterMeta {
    let res: string;
    return this.filterMeta.find(m => m.id === idmeta);
  }
}

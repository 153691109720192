import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../_services/client.service';
import { TemplateGenericType } from '../../models/template-generic-type';

import { finalize } from 'rxjs/operators';
import { ClientPolicyComponent } from '../client-policy/client-policy.component';


@Component({
  selector: 'fury-client-policy-association-form',
  templateUrl: './client-policy-association-form.component.html'
})
export class ClientPolicyAssociationFormComponent implements OnInit {

  form: FormGroup;
  policiesList: TemplateGenericType[];
  connectionBroken = false;
  loading = true;
  idclient: string;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientPolicyAssociationFormComponent>,
    private clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) private data,
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.dialogRef.close(false);
      return;
    }

    this.idclient = this.data.idclient;
    this.form = this.formBuilder.group({
      policiesSelect: [null, Validators.required]
    });
    this.getAllPolicies();
  }

  private getAllPolicies(): void {
    this.clientService
      .getAllPolicies()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        policies => (this.policiesList = policies),
        () => {
          this.connectionBroken = true;
        }
      );
  }

  submit(): void {
    this.clientService
      .setPolicyAssociation(this.form.get('policiesSelect').value['id'], this.idclient)
      .subscribe(response => {
        this.dialogRef.close(true);
      });
  }
}

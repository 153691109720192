import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { FrontEnd } from '../models/front-end';

import { ConfigData } from '../app.config';
import { ConfigurationService } from './configuration.service';
import { Type } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class FrontEndService {
  frontEndUrl = `${ConfigData.apiUrl}/frontend`;

  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }

  getFrontEnds(selectedLanguage?: string): Observable<FrontEnd[]> {
    let params = new HttpParams();

    if (selectedLanguage) {
      params = params.append('lingua', selectedLanguage);
    }

    return this.http.get<FrontEnd[]>(this.frontEndUrl, { params });
  }

  getFrontEndDetail(
    idFrontend: string,
    language: string
  ): Observable<FrontEnd> {
    const url = `${this.frontEndUrl}/${idFrontend}/${language}`;

    return this.http.get<FrontEnd>(url);
  }

  saveFrontEnd(frontEnd: FrontEnd): Observable<FrontEnd> {
    return this.http.post<FrontEnd>(this.frontEndUrl, frontEnd);
  }

  editFrontEnd(
    frontEnd: FrontEnd,
    selectedlanguage: string
  ): Observable<FrontEnd> {
    return this.http.put<FrontEnd>(
      `${this.frontEndUrl}/${frontEnd.idfrontend}/${selectedlanguage}`,
      frontEnd
    );
  }

  deleteFrontEnd(
    frontEnd: FrontEnd,
    selectedlanguage: string
  ): Observable<FrontEnd> {
    return this.http.delete<FrontEnd>(
      `${this.frontEndUrl}/${frontEnd.idfrontend}/${selectedlanguage}`
    );
  }

  generaUrlPreview(idClient: string, idAlias: string, idFrontEnd: string): Observable<{ url: string; }> {
    const url = `${this.frontEndUrl}/preview`;

    const request = {
      idClient,
      idAlias,
      proxystylesheet: idFrontEnd
    };

    return this.http.post<{ url: string; }>(url, request);
  }

  getLanguagesList(): Observable<Type[]> {
    return this.configurationService.getLanguagesList();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, PageEvent } from '@angular/material';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsCenterService } from 'app/_services/notifications-center.service';
import { NotificationFilters } from 'app/models/notification-details';

@Component({
  selector: 'fury-notification-history',
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.scss']
})


export class NotificationHistoryComponent implements OnInit {

  notifications;
  displayedColumns: string[] = ['idNotification', 'notificationCode', 'username','creationDate'];
  loading : boolean = false;
  notificationParams: NotificationFilters = new NotificationFilters();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private notificationsCenterService: NotificationsCenterService,
    public languageService: LanguageService,
  ) { }

  ngOnInit() {
    // Ottengo la lista delle notifiche
    this.loading = true;
    this.notificationParams.size = 10;
    this.notificationParams.page = 0;
    this.getNotifications();
  }

  pageChanged(event: PageEvent) {
    this.notificationParams.page = event.pageIndex;
    this.getNotifications();
  }

  sortData(): void {
    this.notifications.sort = this.sort;
  }

  onChangeDay(): void {
    this.notificationParams.start = null;
    this.notificationParams.end = null;
  }

  onChangeDate(): void {
    this.notificationParams.day = null;
  }

  getNotifications() {
    this.notificationsCenterService.getNotificationsHistory(this.notificationParams).subscribe((data) => {
      this.notifications = data;
      this.loading = false;
    })
  }
  filterData(){
    this.getNotifications();
  }


}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

// Services
import { IndexAliasService } from '../../../_services/index-alias.service';
import { LanguageService } from '../../../_services/language.service';

// Models
import { Type } from '../../../models/index';
import { Onebox } from '../../../models/onebox';
import { ActiveAlias } from '../../../models/alias-list';
import { ConfigData } from 'app/app.config';

@Component({
  selector: 'fury-onebox-form',
  templateUrl: './onebox-form.component.html',
  styleUrls: ['./onebox-form.component.scss']
})
export class OneboxFormComponent implements OnInit {
  readonly ALWAYS = 'ALWAYS';
  readonly KEYWORDS = 'KEYWORDS';
  readonly REGEX = 'REGEX';
  readonly INTERNAL = 'INTERNAL';
  readonly EXTERNAL = 'EXTERNAL';

  activationTypes: Type[] = [
    {
      id: this.ALWAYS,
      label: this.languageService.getLabel('onebox.Form.Always')
    },
    {
      id: this.KEYWORDS,
      label: this.languageService.getLabel('onebox.Form.Keywords')
    },
    {
      id: this.REGEX,
      label: this.languageService.getLabel('onebox.Form.Regex')
    }
  ];
  providerTypes: Type[] = [
    {
      id: this.INTERNAL,
      label: this.languageService.getLabel('onebox.Form.Internal')
    },
    {
      id: this.EXTERNAL,
      label: this.languageService.getLabel('onebox.Form.External')
    }
  ];

  form: FormGroup;
  aliases: ActiveAlias[] = [];
  onebox = new Onebox();

  ConfigData = ConfigData;
  constructor(
    private formBuilder: FormBuilder,
    private aliasService: IndexAliasService,
    private dialogRef: MatDialogRef<OneboxFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.onebox = this.data || new Onebox();
    this.initForm();
    this.setKeywordsArray();
    this.getAliases();
  }

  /**
   * Inizializza il form
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      name: [this.onebox.name, Validators.required],
      description: [this.onebox.description],
      activationType: [this.onebox.activation.type, Validators.required],
      regex: [this.onebox.activation.regex],
      keywords: this.formBuilder.array([]),
      providerType: [this.getProviderType],
      alias: [this.onebox.provider.collection],
      externalProvider: [this.onebox.provider.externalProvider],
      size: [this.onebox.size, Validators.required],
      includeMeta: [this.onebox.includeMeta]
    });
    // Se cloudSearch imposto il typoProvider a Esterno
    if (ConfigData.cloudSearch) {
      this.form.get('providerType').setValue(this.EXTERNAL);
    }

    this.configureForm();
  }
  
  private getProviderType(){
    let returnValue = [];
    if(this.onebox.provider.collection){
      returnValue.push(this.INTERNAL);
    }else if(this.onebox.provider.externalProvider){
      returnValue.push(this.EXTERNAL)
    }else{
      returnValue.push(null, Validators.required)
    }
    return returnValue;
  }


  private configureForm(): void {
    const activationType = this.form.get('activationType');
    const regex = this.form.get('regex');
    const keywords = <FormArray>this.form.get('keywords');
    const providerType = this.form.get('providerType');
    const alias = this.form.get('alias');
    const externalProvider = this.form.get('externalProvider');

    activationType.valueChanges.subscribe(value => {
      switch (value) {
        case this.REGEX:
          regex.setValidators(Validators.required);
          regex.updateValueAndValidity();
          keywords.controls.forEach((control, index) => {
            keywords.removeAt(index);
          });
          keywords.clearValidators();
          keywords.reset(null);
          keywords.updateValueAndValidity();

          break;
        case this.KEYWORDS:
          regex.clearValidators();
          regex.reset(null);
          regex.updateValueAndValidity();
          keywords.setValidators(Validators.required);
          keywords.updateValueAndValidity();
      }
    });

    providerType.valueChanges.subscribe(value => {
      switch (value) {
        case this.INTERNAL:
          alias.setValidators(Validators.required);
          externalProvider.reset(null);
          externalProvider.clearValidators();
          externalProvider.updateValueAndValidity();
          break;
        case this.EXTERNAL:
          alias.reset(null);
          alias.clearValidators();
          alias.updateValueAndValidity();
          externalProvider.setValidators(Validators.required);
      }
    });
  }

  /**
   * Preleva la lista di aliases
   */
  private getAliases(): void {
    this.aliasService
      .getActiveAliases()
      .subscribe(response => (this.aliases = response));
  }

  /**
   * Setta l'array di keywords se siamo in modifica e l'array è valorizzato
   */
  private setKeywordsArray(): void {
    if (this.inEdit()) {
      // Se l'array è inizializzato allora ciclo
      if (this.onebox.activation.keywords instanceof Array) {
        this.onebox.activation.keywords.forEach(keyword => {
          this.addNewKeyword(keyword);
        });
      } else {
        // Array non inizializzato, lo creo
        this.onebox.activation.keywords = [];
      }
    }
  }

  /**
   * Ritorna true se siamo in edit di un onebox
   */
  private inEdit(): boolean {
    return !!this.onebox.id;
  }

  /**
   * Crea una nuova keyword da inserire nel form
   * @param keyword Se viene ricevuta la keyword valorizza i campi del form
   */
  private getNewFormGroupKeyword(keyword?: string): FormGroup {
    keyword = keyword || '';
    return this.formBuilder.group({
      keyword: [keyword, Validators.required]
    });
  }

  /**
   * Aggiunge una nuova keyword al form
   */
  addNewKeyword(keyword?: string): void {
    const keywordsFormControl = this.getKeywordsFormControl();
    keywordsFormControl.push(this.getNewFormGroupKeyword(keyword));
  }

  /**
   * Rimuove una keyword esistente dal form
   */
  removeKeyword(index: number): void {
    const keywordsFormControl = this.getKeywordsFormControl();
    keywordsFormControl.removeAt(index);
  }

  /**
   * Ritorna il controllo dell'array di keywords del form
   */
  private getKeywordsFormControl(): FormArray {
    return <FormArray>this.form.get('keywords');
  }

  /**
   * Ottiene una lista di interi per riempire la select dei risutlati massimi
   */
  getMaxResultsArray(): number[] {
    return Array.from(new Array(10), (value, index) => index + 1);
  }

  /**
   * Invia il form
   */
  submit(): void {
    this.onebox.description = this.form.get('description').value ? this.form.get('description').value.trim() : null;
    this.onebox.name = this.form.get('name').value.trim();
    this.onebox.size = this.form.get('size').value;
    this.onebox.includeMeta = this.form.get('includeMeta').value;
    // Set providerType
    switch (this.form.get('providerType').value) {
      case this.EXTERNAL:
        this.onebox.provider.externalProvider = this.form.get(
          'externalProvider'
        ).value.trim();
        break;
      case this.INTERNAL:
        this.onebox.provider.collection = this.form.get('alias').value;
        break;
    }
    // Set activation
    this.onebox.activation.type = this.form.get('activationType').value;
    switch (this.onebox.activation.type) {
      case this.REGEX:
        this.onebox.activation.regex = this.form.get('regex').value.trim();
        break;
      case this.KEYWORDS:
        const keywordsFormControlArray = <{ keyword: string }[]>(
          this.getKeywordsFormControl().value
        );
        // Clear keywordsArray
        this.onebox.activation.keywords = [];
        // Fill keywords from form
        keywordsFormControlArray.forEach(keywordFormControl => {
          this.onebox.activation.keywords.push(keywordFormControl.keyword.trim());
        });
        break;
    }
    this.dialogRef.close(this.onebox);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { LanguageService } from '../../_services/language.service';
import { GestioneCrawlingService } from '../../_services/gestione-crawling.service';

import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fury-crawling-web-stato-crawling',
  templateUrl: 'crawling-web-stato-crawling.component.html'
})
export class CrawlingWebStatoCrawlingComponent implements OnInit {
  loading: boolean;
  paramIDJob: string;

  hasResults = false;

  private _crawlingStatusResponse;

  crawlingStatus: any;

  constructor(
    public languageService: LanguageService,
    private gestioneCrawlingService: GestioneCrawlingService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.parent.paramMap.subscribe(res => {
      const paramIDJob = res.get('idJob');
      if (paramIDJob) {
        this.paramIDJob = paramIDJob;
        this.getCrawlingStatus();
      }
    });
  }

  private getCrawlingStatus(): void {
    this.loading = true;

    if (
      !this.gestioneCrawlingService.selectedJob ||
      !this.gestioneCrawlingService.selectedJob.name
    ) {
      this.getJobDetail();
      return;
    }

    this.gestioneCrawlingService
      .getCrawlingStatus(this.gestioneCrawlingService.selectedJob.name)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(result => {
        if (result) {
          this.crawlingStatus = {};
          this._crawlingStatusResponse = result;
          this.crawlingStatus.totalUrls = result.totalUrls;
          this.setCrawlingInfo();
        }
      });
  }

  private getJobDetail(): void {
    this.gestioneCrawlingService
      .getCrawlingWebJobDetail(this.paramIDJob)
      .subscribe(response => {
        this.gestioneCrawlingService.selectedJob = response;
        this.getCrawlingStatus();
      });
  }

  private setCrawlingInfo(): void {
    if (!this._crawlingStatusResponse || !this._crawlingStatusResponse.status) {
      return;
    }

    Object.keys(this._crawlingStatusResponse.status).forEach(statusIndex => {
      this.crawlingStatus[
        this._crawlingStatusResponse.status[statusIndex].statusValue
      ] = this._crawlingStatusResponse.status[statusIndex].count;
    });
  }
}

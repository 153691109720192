import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';
import { Schedulazione } from 'app/models/schedulazione';
import {
  MatPaginator,
  MatSort,
  MatTable,
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  MatSnackBar
} from '@angular/material';
import { CrawlingWebSchedulazioneFormComponent } from '../crawling-web-schedulazione-form/crawling-web-schedulazione-form.component';
import { finalize, tap } from 'rxjs/operators';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-crawling-web-schedulazione',
  templateUrl: 'crawling-web-schedulazione.component.html',
  styleUrls: ['crawling-web-schedulazione.component.scss']
})
export class CrawlingWebSchedulazioneComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<Schedulazione>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<Schedulazione>;

  loading = false;
  connectionBroken = false;

  displayedColumns: string[] = [
    'scansione',
    'oraInizio',
    'minutoInizio',
    'enabled',
    'star'
  ];

  paramIDJob: string;

  schedulazioneFormRef: MatDialogRef<
    CrawlingWebSchedulazioneFormComponent,
    Schedulazione
  >;

  constructor(
    private activatedRoute: ActivatedRoute,
    private gestioneCrawlingService: GestioneCrawlingService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.activatedRoute.parent.paramMap.subscribe(res => {
      const paramIDJob = res.get('idJob');
      if (paramIDJob) {
        this.paramIDJob = paramIDJob;
        this.getSchedules();
      }
    });
  }

  getSchedules(): void {
    this.loading = true;
    this.gestioneCrawlingService
      .getCrawlingWebScheduleList(this.paramIDJob)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        result => {
          if (result) {
            this.dataSource = new MatTableDataSource(result);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.changeDetectorRef.detectChanges();
          }
        },
        () => {
          this.connectionBroken = true;
        }
      );
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  addSchedule(): void {
    this.schedulazioneFormRef = this.dialog.open(
      CrawlingWebSchedulazioneFormComponent,
      { width: '500px' }
    );
    this.schedulazioneFormRef.afterClosed().subscribe(result => {
      if (!!result && !this.connectionBroken) {
        this.saveSchedule(result);
      }
    });
  }

  saveSchedule(schedule: Schedulazione): void {
    this.loading = true;

    // Forzo il campo durata ad avere sempre valore = 3
    schedule.durata = '3';

    this.gestioneCrawlingService
      .addCrawlingWebSchedule(this.paramIDJob, schedule)
      .pipe(
        finalize(() => (this.loading = false)),
        tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.getSchedules());
  }

  deleteSchedule(schedule: Schedulazione): void {
    this.loading = true;
    this.gestioneCrawlingService
      .deleteCrawlingWebSchedule(this.paramIDJob, schedule.id.toString())
      .pipe(finalize(() => (this.loading = false)), tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.getSchedules());
  }

  toggleEnabled(schedule: Schedulazione): void {
    // La funzione viene chiamata al cambio dell'ngModel quindi con il valore active aggiornato
    // però al servizio devo passare il valore vecchio quindi passo l'opposto
    this.gestioneCrawlingService
      .toggleCrawlingWebSchedule(
        this.paramIDJob,
        schedule.id.toString(),
        !schedule.active
      )
      .subscribe(resp => {
        this.getSchedules();
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  /**
   * Lingua di default
   */
  private readonly defaultLanguage = 'it';

  /**
   * Default labels in italiano
   */
  private defaultLabels: any = {};

  /**
   * Lingua selezionata
   */
  private _currentLanguage = 'it';
  public get currentLanguage(): string {
    return this._currentLanguage;
  }
  public set currentLanguage(value: string) {
    this._currentLanguage = value;
  }

  /**
   * Labels in lingua selezionata
   */
  private currentLanguageLabels: any = {};

  /**
   * Subject e relativo Observable per la gestione dell'evento di lingua cambiata
   */
  private _languageChanged: Subject<boolean> = new Subject();
  public languageChanged$: Observable<boolean> = this._languageChanged.asObservable();

  constructor(private http: HttpClient) { }

  public initLanguages(): Promise<any> {
    this.currentLanguage = localStorage.getItem('language') || 'it';
    if (this.defaultLanguage === this.currentLanguage) {
      return this.http
        .get(`../../assets/lang/it.json`)
        .toPromise()
        .then(defaultLabels => {
          this.defaultLabels = defaultLabels;
          this.currentLanguageLabels = defaultLabels;
        });
    } else {
      return forkJoin([
        this.http.get(`../../assets/lang/it.json`),
        this.http.get(`../../assets/lang/${this.currentLanguage}.json`)
      ])
        .toPromise()
        .then(labels => {
          this.defaultLabels = labels[0];
          this.currentLanguageLabels = labels[1];
        });
    }
  }

  /**
   * Imposta la nuova lingua selezionata se il file esiste
   * @param newLanguage Nuova lingua selezionata
   */
  public changeLanguage(newLanguage: string): Observable<Object> {
    // Se la lingua selezionata è sempre la stessa
    if (newLanguage === this.currentLanguage) {
      return Observable.of(true);
    }
    return this.http.get(`../../assets/lang/${newLanguage}.json`).do(
      res => {
        this.currentLanguageLabels = res;
        this.currentLanguage = newLanguage;
        localStorage.setItem('language', this.currentLanguage);
        this._languageChanged.next();
      },
      () => {
        console.log(`Il file di lingua '${newLanguage}' non è stato trovato`);
      }
    );
  }

  /**
   * Ritorna la stringa della labe in base alla key passata
   * @param key Key della label da ricevere
   */
  public getLabel(key: string): string {
    return this.currentLanguageLabels[key] || this.defaultLabels[key];
  }
}

import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment'; 


@Component({
  selector: 'fury-version-page',
  templateUrl: './version-page.component.html',
  styleUrls: ['./version-page.component.scss']
})
export class VersionPageComponent implements OnInit {  
  version: string;

  constructor() {
    this.version = environment.version;  
  }

  ngOnInit() {
  // This is intentional 
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
@Component({
  selector: 'fury-doc-preview',
  templateUrl: './doc-preview.component.html',
  styleUrls: ['./doc-preview.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DocPreviewComponent implements OnInit {

  constructor(public languageService : LanguageService) { }

  ngOnInit() {
    // This is intentional
  }

}

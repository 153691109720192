import { finalize } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material';
import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';
import { FeedXMLDetail } from 'app/models/feed-xml-detail';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-gestione-crawling-feed-xml-form',
  templateUrl: 'gestione-crawling-feed-xml-form.component.html',
  styleUrls: ['gestione-crawling-feed-xml-form.component.scss']
})
export class GestioneCrawlingFeedXMLFormComponent implements OnInit {
  currentFeedId: number;
  currentFeed: FeedXMLDetail;
  loading = false;

  constructor(
    private gestioneCrawlingService: GestioneCrawlingService,
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit() {
    if (!!this.data && this.data.currentFeedId) {
      this.currentFeedId = this.data.currentFeedId;
      this.getFeedDetails();
    }
  }

  private getFeedDetails(): void {
    this.loading = true;
    this.gestioneCrawlingService
      .getFeedXMLDetail(this.currentFeedId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(feed => {
        if (feed) {
          this.currentFeed = feed;
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CrawlingWebUrl } from './../../models/crawling-web-url';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-crawling-web-paths-form',
  templateUrl: 'crawling-web-paths-form.component.html',
  styleUrls: ['crawling-web-paths-form.component.scss']
})
export class CrawlingWebPathsFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CrawlingWebPathsFormComponent>,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      path: [null, Validators.required]
    });
  }

  submit() {
    this.dialogRef.close(this.form.get('path').value.trim());
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ConfigData } from '../app.config';
@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private httpClient: HttpClient) { }

 /*  /noovle4search-admin-rest-service/rest/auth/audio/uploadFileAndIndex/externalUrl */
  uploadAudioFiles(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/audio/uploadFileAndIndex', formData, {
      reportProgress: true
    });
    return this.httpClient.request(req);
  }
  sendStorageLink(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/audio/uploadFileAndIndex/externalUrl', formData);
    return this.httpClient.request(req);
  }
  uploadImagesFiles(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/image/uploadFileAndIndex', formData, {
      reportProgress: true
    });
    return this.httpClient.request(req);
  }
  sendImagesStorageLink(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/image/uploadFileAndIndex/externalUrl', formData);
    return this.httpClient.request(req);
  }
  getFeatureTypes() : Observable<any> {
    const req = new HttpRequest('GET', ConfigData.apiUrl + '/image/getFeatureTypes');
    return this.httpClient.request(req);
  }
  uploadDocFiles(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/docprocessor/uploadFileAndIndex', formData);
    return this.httpClient.request(req);
  }
  getFeatureTypesVideo() : Observable<any> {
    const req = new HttpRequest('GET', ConfigData.apiUrl + '/video/getFeatures');
    return this.httpClient.request(req);
  }
  uploadVideoFiles(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/video/uploadFileAndIndex', formData, {
      reportProgress: true
    });
    return this.httpClient.request(req);
  }
  sendStorageLinkDocuments(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/docprocessor/uploadFileAndIndex/externalUrl', formData);
    return this.httpClient.request(req);
  }
  sendStorageLinkVideo(formData: FormData) : Observable<any> {
    const req = new HttpRequest('POST', ConfigData.apiUrl + '/video/uploadFileAndIndex/externalUrl', formData);
    return this.httpClient.request(req);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserRoleService } from 'app/_services/user-role.service';
import { MatSort, MatPaginator, MatTable, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { UserGroup } from 'app/models/user-group';
import { LanguageService } from 'app/_services/language.service';
import { finalize } from 'rxjs/operators';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { UserGroupsDataFormComponent } from '../user-groups-data-form/user-groups-data-form.component';
import { UserGroupsPermissionsModalComponent } from '../user-groups-permissions-modal/user-groups-permissions-modal.component';

@Component({
  selector: 'fury-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit {

  @ViewChild('ixTable') ixTable: MatTable<UserGroup>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<UserGroup>;

  loading = false;

  displayedColumns: string[] = ['groupName', 'groupDescription', 'admin', 'edit'];
  groups: UserGroup[] = [];

  groupDataFormRef: MatDialogRef<UserGroupsDataFormComponent, boolean>;
  groupPermissionFormRef: MatDialogRef<UserGroupsPermissionsModalComponent, boolean>;

  constructor(private userRoleService: UserRoleService, public languageService: LanguageService, private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService) { }

  ngOnInit() {
    this.getGroups();

  }


  getGroups(): void {
    this.loading = true;
    this.userRoleService.getGroups()
      .pipe(finalize(() => this.loading = false))
      .subscribe(groups => {

        const sortedGroups = this.sortGroups(groups);

        this.dataSource = new MatTableDataSource(sortedGroups);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  sortGroups(unsorteredGroups) {
    return unsorteredGroups.sort((a, b) => this.alphabeticSort(a, b));
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  addGroup() {
    this.groupDataFormRef = this.dialog.open(UserGroupsDataFormComponent, { width: '600px' });
    this.groupDataFormRef.afterClosed().subscribe(result => {
      this.ifResultGetGroups(result);
    });
  }

  editGroup(group: UserGroup) {
    this.groupDataFormRef = this.dialog.open(UserGroupsDataFormComponent, {
      width: '800px',
      data: { group }
    });

    this.groupDataFormRef.afterClosed().subscribe(result => {
      this.ifResultGetGroups(result);
    });
  }

  ifResultGetGroups(result){
    if (result) {
      this.getGroups();
    }
  }

  deleteGroup(group: UserGroup): void {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel('user.MessaggioEliminaGruppo')}?`,
        this.languageService.getLabel('user.TitoloEliminaGruppo')
      )
      .then(res => {
        if (res) {
          this.loading = true;
          this.userRoleService.deleteGroup(group.groupName).subscribe(() => {
            this.getGroups();
          });
        }
      });
  }


  openPermissionsModal(group: UserGroup): void {
    this.groupPermissionFormRef = this.dialog.open(UserGroupsPermissionsModalComponent, {
      width: '500px',
      maxHeight: '90vh',
      panelClass: 'groups-permission-modal',
      data: { group }
    });

    this.groupPermissionFormRef.afterClosed().subscribe(result => {
      this.ifResultGetGroups(result);
    });
  }

  private alphabeticSort(a: UserGroup, b: UserGroup): number {
    if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
      return 1;
    }

    if (b.groupName.toLowerCase() >
      a.groupName.toLowerCase()) {
      return -1;
    }

    return 0;
  }

}

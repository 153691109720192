import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    public snackBar: MatSnackBar,
    private authService: AuthenticationService
  ) {
    this.alertService.subj_notification.subscribe(message => {
      const sb = this.snackBar.open(message, 'OK', {
        duration: 10000,
        horizontalPosition: 'center'
      });
      sb.onAction().subscribe(() => {
        // This is intentional
      });
    });
  }

  ngOnInit() {
    this.authService.configureMenu();
  }
}

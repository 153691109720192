import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NodeInfo } from '../../../models/node-info';
import * as Chart from 'chart.js';
import * as ChartJSPieceLabelPlugin from 'chart.piecelabel.js';
import defaultsDeep from 'lodash-es/defaultsDeep';
import { defaultChartOptions } from '../../../core/common/chart-widget/chart-widget-defaults';
import { ServerInfo } from 'app/models/server-info';

@Component({
  selector: 'az-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnInit {
  @Input() node: ServerInfo;

  @ViewChild('canvasRam', { read: ElementRef }) canvasRam: ElementRef;
  @ViewChild('canvasCpu', { read: ElementRef }) canvasCpu: ElementRef;
  @ViewChild('canvasDisk', { read: ElementRef }) canvasDisk: ElementRef;
  // @ViewChild('canvasJobs', { read: ElementRef }) canvasJobs: ElementRef;

  defOptions = defaultsDeep(
    {
      layout: {
        padding: {
          left: 5,
          right: 5,
          top: 5
        }
      },
      plugins: [ChartJSPieceLabelPlugin],
      pieceLabel: {
        render: 'label',
        shadow: true,
        arc: true,
        position: 'border',
        fontColor: '#FFFFFF'
      },
      tooltips: {
        callbacks: {
          title: function(tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']];
          },
          label: function(tooltipItem, data) {
            const dataset = data['datasets'][0];
            const metaIndex = Object.keys(dataset['_meta'])[0];

            const percent = Math.round(
              (dataset['data'][tooltipItem['index']] /
                dataset['_meta'][metaIndex]['total']) *
                100
            );
            return percent + '%';
          }
        },
        backgroundColor: '#FFF',
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14
      }
    },
    defaultChartOptions
  );

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
    const dataRam = {
      datasets: [
        {
          data: [this.node.ramUsedPerc, 100 - this.node.ramUsedPerc],
          backgroundColor: ['#6168c0', '#6cbc6b']
        }
      ],
      labels: ['Used', 'Free']
    };
    const dataDisk = {
      datasets: [
        {
          data: [this.node.diskUsedPerc, 100 - this.node.diskUsedPerc],
          backgroundColor: ['#6168c0', '#6cbc6b']
        }
      ],
      labels: ['Used', 'Free']
    };
    const dataCpu = {
      datasets: [
        {
          data: [this.node.cpuUsagePerc, 100 - this.node.cpuUsagePerc],
          backgroundColor: ['#6168c0', '#6cbc6b']
        }
      ],
      labels: ['Used', 'Free']
    };

    /*NOSONAR*/const ramChart = new Chart(this.canvasRam.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: dataRam,
      options: this.defOptions
    });
    /*NOSONAR*/const cpuChart = new Chart(this.canvasCpu.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: dataCpu,
      options: this.defOptions
    });
    /*NOSONAR*/const diskChart = new Chart(
      this.canvasDisk.nativeElement.getContext('2d'),
      {
        type: 'doughnut',
        data: dataDisk,
        options: this.defOptions
      }
    );
  }
}

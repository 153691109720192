import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from '../n4s/confirmation-dialog/confirmation-dialog.component';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private dialog: MatDialog,
    public languageService: LanguageService
  ) {}

  public confirm(
    message: string,
    title: string = this.languageService.getLabel('general.Conferma'),
    btnOkText: string = this.languageService.getLabel('general.OK'),
    btnCancelText: string = this.languageService.getLabel('general.Annulla'),
    dialogSize: 'sm' | 'lg' = 'sm',
    confirmMatchText = null,
    showCancelButton = true
  ): Promise<boolean> {
    const dialogPixel: string = 'sm' === dialogSize ? '400px' : '800px';
    this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: dialogPixel,
      data: {}
    });
    this.confirmationDialogRef.componentInstance.title = title;
    this.confirmationDialogRef.componentInstance.message = message;
    this.confirmationDialogRef.componentInstance.btnCancelText = btnCancelText;
    this.confirmationDialogRef.componentInstance.btnOkText = btnOkText;
    this.confirmationDialogRef.componentInstance.showCancelButton = showCancelButton;

    if (!!confirmMatchText) {
      this.confirmationDialogRef.componentInstance.confirmMatchText = confirmMatchText;
    }

    const promise = new Promise<boolean>(resolve => {
      this.confirmationDialogRef.afterClosed().subscribe(closeResult => {
        resolve(closeResult);
      });
    });

    return promise;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavLink } from '../../../models/nav-link';
import { LanguageService } from '../../../_services/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/_services/authentication.service';
import { PERMISSION_CODES } from 'app/constants/permission-codes';

@Component({
  selector: 'fury-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit{
  navLinks: NavLink[] = [];

  private destroy$ = new Subject();


  constructor(public languageService: LanguageService, private authenticationService : AuthenticationService) { }

  ngOnInit() {
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }


  private buildTabs(): void {
    this.navLinks = [
     /* {
        path: 'globalreports',
        label: this.languageService.getLabel('report.ReportGlobale'),
        visible: true,
        disabled: false
      },
      // {
      //   path: 'collectionReports',
      //   label: this.languageService.getLabel('report.ReportPerCollezione'),
      //   visible: false,
      //   disabled: false
      // },
      {
        path: 'reports-history',
        label: this.languageService.getLabel('report.StoricoReportGenerati'),
        visible: true,
        disabled: false
      },
      {
        path: 'reports-charts',
        label: this.languageService.getLabel('report.Grafici'),
        visible: true,
        disabled: false
      },
     {
        path: 'reports-charts-action',
        label: this.languageService.getLabel('report.chartAction'),
        visible: true,
        disabled: false
      }, 
      */
    ]

    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.REPORT_TABS.REPORTS
      )
    ) {
      this.navLinks.push({
        path: 'globalreports',
        label: this.languageService.getLabel('report.ReportGlobale'),
        visible: true,
        disabled: false
      });
    }
    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.REPORT_TABS.REPORT_CLICK
      )
    ) {
      this.navLinks.push({
        path: 'reports-charts-action',
        label: this.languageService.getLabel('report.chartAction'),
        visible: true,
        disabled: false
      });
    }
    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.REPORT_TABS.REPORT_QUERY
      )
    ) {
      this.navLinks.push({
        path: 'reports-charts',
        label: this.languageService.getLabel('report.Grafici'),
        visible: true,
        disabled: false
      });
    }
    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.REPORT_TABS.HISTORY_REPORT
      )
    ) {
      this.navLinks.push({
        path: 'reports-history',
        label: this.languageService.getLabel('report.StoricoReportGenerati'),
        visible: true,
        disabled: false
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTable,
  MatTableDataSource,
  MatSort,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { Router } from '@angular/router';
import { finalize, tap } from '../../../../node_modules/rxjs/operators';

import { ConfirmationDialogService } from '../../_services/confirmation-dialog.service';
import { LanguageService } from '../../_services/language.service';
import { ClientService } from '../../_services/client.service';

import { ClientFormComponent } from '../client-form/client-form.component';

import { Client } from '../../models/client';
import { AuthenticationService } from 'app/_services/authentication.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  @ViewChild('clientTable') clientTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['idclient', 'description', 'star'];

  dataSource: MatTableDataSource<Client>;
  clientFormRef: MatDialogRef<ClientFormComponent>;
  loading = false;

  isAdmin: boolean;

  constructor(
    private clientService: ClientService,
    private dialog: MatDialog,
    private router: Router,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.isAdmin = this.authenticationService.userIsAdmin();
    this.getClients();
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  private getClients(): void {
    this.loading = true;

    this.clientService
      .getClients()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        clients => {
          this.dataSource = new MatTableDataSource(clients);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        () => console.log('Impossibile collegarsi al server.')
      );
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  addClient() {
    this.clientFormRef = this.dialog.open(ClientFormComponent, {
      width: '500px'
    });

    this.clientFormRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.clientService.saveClient(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getClients());
      }
    });
  }

  deleteClient(client: Client) {
    this.confirmationDialog
      .confirm(`Eliminare il Client ${client.idclient}?`)
      .then(res => {
        if (res) {
          this.clientService.deleteClient(client)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getClients());
        }
      });
  }

  showClient(client: Client) {
    this.router.navigate(['/client-detail/' + client.idclient]);
  }

  editClient(client: Client) {
    this.clientFormRef = this.dialog.open(ClientFormComponent, {
      width: '500px',
      data: { client: client }
    });

    this.clientFormRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.clientService.editClient(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getClients());
      }
    });
  }
}

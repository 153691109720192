import { Type } from 'app/models/index';
import { MetaBiasing } from './../models/meta-biasing';
import { AliasBiasing } from './../models/alias-biasing';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigData } from '../app.config';
import { Observable } from 'rxjs';
import { PolicyBiasing } from 'app/models/policy-biasing';
import { Alias } from 'app/models/alias';
import { Meta } from './../models/index';

@Injectable()
export class PolicyService {
  constructor(private httpClient: HttpClient) {}

  private policyUrl = `${ConfigData.apiUrl}/biasing`;
  private customPolicyUrl = `${ConfigData.apiUrl}/biasing-custom`;
  private aliasUrl = `${ConfigData.apiUrl}/alias`;

  getPolicyBiasingList(): Observable<PolicyBiasing[]> {
    return this.httpClient.get<PolicyBiasing[]>(this.policyUrl);
  }

  addPolicyBiasing(policy: PolicyBiasing): Observable<PolicyBiasing> {
    return this.httpClient.post<PolicyBiasing>(this.policyUrl, policy);
  }

  editPolicyBiasing(policy: PolicyBiasing): Observable<PolicyBiasing> {
    return this.httpClient.put<PolicyBiasing>(
      `${this.policyUrl}/${policy.idBiasing}`,
      policy
    );
  }

  deletePolicyBiasing(policy: PolicyBiasing): Observable<string> {
    return this.httpClient.delete<string>(
      `${this.policyUrl}/${policy.idBiasing}`
    );
  }

  getPolicyDetail(idPolicy: string): Observable<PolicyBiasing> {
    return this.httpClient.get<PolicyBiasing>(`${this.policyUrl}/${idPolicy}`);
  }

  getAliasList(): Observable<Alias[]> {
    return this.httpClient.get<Alias[]>(`${this.aliasUrl}/active`);
  }

  addPolicyAlias(
    alias: AliasBiasing,
    idPolicy: string
  ): Observable<AliasBiasing> {
    return this.httpClient.post<AliasBiasing>(
      `${this.customPolicyUrl}/${idPolicy}/biasing-custom-alias`,
      alias
    );
  }

  deletePolicyAlias(
    alias: AliasBiasing,
    idPolicy: string
  ): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.customPolicyUrl}/${idPolicy}/biasing-custom-alias/${alias.idAlias}`
    );
  }

  editPolicyAlias(alias: any): any {
    return this.httpClient.put<AliasBiasing>(
      `${this.customPolicyUrl}/${alias.idBiasing}/biasing-custom-alias`,
      alias
    );
  }

  getMetaList(): Observable<Type[]> {
    return this.httpClient.get<Type[]>(`${ConfigData.apiUrl}/meta`);
  }

  addPolicyMeta(meta: MetaBiasing, idPolicy: string): Observable<MetaBiasing> {
    return this.httpClient.post<MetaBiasing>(
      `${this.customPolicyUrl}/${idPolicy}/biasing-custom-meta`,
      meta
    );
  }

  deletePolicyMeta(meta: MetaBiasing): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.customPolicyUrl}/${meta.idBiasing}/biasing-custom-meta/${meta.id}`
    );
  }

  editPolicyMeta(meta: MetaBiasing): any {
    return this.httpClient.put<MetaBiasing>(
      `${this.customPolicyUrl}/${meta.idBiasing}/biasing-custom-meta/${meta.id}`,
      meta
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../../_services/client.service';
import { TemplateElasticRequest } from '../../models/template-elastic-request';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-client-template-elastic-detail',
  templateUrl: './client-template-elastic-detail.component.html',
  styleUrls: ['./client-template-elastic-detail.component.scss']
})
export class ClientTemplateElasticDetailComponent implements OnInit {
  private idElasticRequest: number;

  public templateData: TemplateElasticRequest;
  public loading = false;

  constructor(
    private clientService: ClientService,
    private dialogRef: MatDialogRef<ClientTemplateElasticDetailComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.idElasticRequest = this.data.idElasticRequest;

      this.loading = true;
      this.clientService
        .getElasticRequest(this.idElasticRequest)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(res => {
          this.templateData = res;
        });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  MatTable,
  MatSort,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { ClientService } from '../../_services/client.service';
import { Filter } from '../../models/filter';
import { ClientDetailComponent } from '../../n4s/client-detail/client-detail.component';
import { finalize, map, tap } from '../../../../node_modules/rxjs/operators';
import { FilterFormComponent } from '../filter-form/filter-form.component';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { LanguageService } from 'app/_services/language.service';
import { FilterMeta } from 'app/models/filter-meta';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-filters',
  templateUrl: './client-filters.component.html',
  styleUrls: ['./client-filters.component.scss']
})
export class ClientFiltersComponent implements OnInit {
  @ViewChild('filtersTable') feTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<Filter>;
  private clientFilters: Filter[];
  displayedColumns: string[] = [
    'label',
    'meta',
    'orderType',
    'orderSort',
    'move',
    'star'
  ];
  loading = false;
  filterFormRef: MatDialogRef<FilterFormComponent>;
  filterMeta: FilterMeta[];
  filteredMetas: Observable<FilterMeta[]>;
  firstSortingOrder : number;
  lastSortingOrder : number;

  readonly ELEMENT_DATA: Filter[] = [];

  public parentClient;

  metaFilter: string;

  metaForm: FormGroup;

  constructor(
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.parentClient = this.parentComponent.currentClient;
    this.loading = true;
    this.metaForm = this.formBuilder.group({
      meta: [null]
    });

    this.setupAutocomplete();

    this.getFilters();
  }

  private setupAutocomplete(): void {
    this.filteredMetas = this.metaForm
      .get('meta')
      .valueChanges.pipe(
        map(value =>
          this.filterMeta.filter(meta =>
            meta.label.toLowerCase().indexOf(value.toLowerCase()) > -1
          )
        )
      );

    this.metaForm.get('meta').valueChanges.subscribe(value => {
      const filteredRows = this.clientFilters.filter(
        f => f.meta.label.toLowerCase().search(value.toLowerCase()) > -1
      );
      this.dataSource = new MatTableDataSource(filteredRows);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  getFilters(): void {
    this.clientService
      .getFilters(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(clientFilters => {
        this.clientFilters = clientFilters.dynamicNavigation;
        this.firstSortingOrder = this.clientFilters[0].sorting;
        this.lastSortingOrder =  this.clientFilters[this.clientFilters.length - 1].sorting;
        this.dataSource = new MatTableDataSource(this.clientFilters);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: Filter, filter: string) => {
          return (
            data.label
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) !== -1
          );
        };

        // Compongo i dati per l'autocomplete dei meta
        this.filterMeta = this.dataSource.data.map(filter => filter.meta);

        this.changeDetectorRef.detectChanges();
      });
  }

  deleteFilter(filter: Filter) {
    this.confirmationDialog
      .confirm(`Eliminare il filtro ${filter.label}?`)
      .then(res => {
        if (res) {
          this.clientService
            .deleteFilter(this.parentClient.idclient, filter)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getFilters());
        }
      });
  }

  addFilter() {
    this.filterFormRef = this.dialog.open(FilterFormComponent, {
      width: '500px',
      data: { idclient: this.parentClient.idclient }
    });
    this.filterFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .addFilter(this.parentClient.idclient, result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getFilters());
      }
    });
  }

  editFilter(filter: Filter) {
    this.filterFormRef = this.dialog.open(FilterFormComponent, {
      width: '500px',
      data: { idclient: this.parentClient.idclient, filter: filter }
    });

    this.filterFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .editFilter(this.parentClient.idclient, result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getFilters());
      }
    });
  }

  moveUp(elementMoveUp: Filter): void {
    const indexMoveDown = this.dataSource.data.indexOf(elementMoveUp) - 1
    const elementMoveDown = this.dataSource.data[indexMoveDown];
    this.clientService
      .moveElement(elementMoveUp.id, elementMoveDown.id)
      .subscribe(() => this.getFilters());
  }

  moveDown(elementMoveDown: Filter): void {
    const indexMoveUp = this.dataSource.data.indexOf(elementMoveDown) + 1
    const elementMoveUp = this.dataSource.data[indexMoveUp];
    this.clientService
      .moveElement(elementMoveUp.id, elementMoveDown.id)
      .subscribe(() => this.getFilters());
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavLink } from '../../models/nav-link';
import { LanguageService } from 'app/_services/language.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { PERMISSION_CODES } from 'app/constants/permission-codes';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fury-indici-alias',
  templateUrl: './indici-alias.component.html'
})
export class IndiciAliasComponent implements OnInit, OnDestroy {
  navLinks: NavLink[];

  selectedRoute: string;

  private destroy$ = new Subject();

  constructor(
    public languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }

  private buildTabs(): void {
    this.navLinks = [];
    this.selectedRoute = this.router.url.split('/')[2] || null;
    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.INDICI_ALIAS_TABS.INDICI
      )
    ) {
      this.navLinks.push({
        path: 'indici',
        label: this.languageService.getLabel('indici.Indici'),
        disabled: false,
        visible: true,
      });

      this.selectedRoute = this.selectedRoute || 'indici';
    }

    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.INDICI_ALIAS_TABS.ALIAS
      )
    ) {
      this.navLinks.push({
        path: 'alias',
        label: this.languageService.getLabel('alias.Alias'),
        disabled: false,
        visible: true,
      });

      this.selectedRoute = this.selectedRoute || 'alias';
    }

    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.INDICI_ALIAS_TABS.DIAGNOSTICA
      )
    ) {
      this.navLinks.push({
        path: 'diagnostics',
        label: this.languageService.getLabel('diagnostica.Diagnostica'),
        disabled: false,
        visible: true,
      });

      this.selectedRoute = this.selectedRoute || 'diagnostics';
    }

    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.INDICI_ALIAS_TABS.CANCELLAZIONE_DOCUMENTI
      )
    ) {
      this.navLinks.push({
        path: 'cancellazione-documenti',
        label: this.languageService.getLabel('indici.CancellazioneDocumenti'),
        disabled: false,
        visible: true,
      });

      this.selectedRoute = this.selectedRoute || 'cancellazione-documenti';
    }

    if (
      this.authenticationService.userHasPermission(
        PERMISSION_CODES.INDICI_ALIAS_TABS.META
      )
    ) {
      this.navLinks.push({
        path: 'meta',
        label: this.languageService.getLabel('general.Meta'),
        disabled: false,
        visible: true,
      });

      this.selectedRoute = this.selectedRoute || 'meta';
    }

    if (this.selectedRoute) {
      this.router.navigate(['indici-alias/' + this.selectedRoute]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatTable,
  MatSort,
  MatPaginator,
  MatDialog,
  MatTableDataSource,
  PageEvent
} from '@angular/material';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

// Services
import { LanguageService } from '../../_services/language.service';
import { IndexAliasService } from '../../_services/index-alias.service';

// Components
import { DiagnosticDetailComponent } from '../diagnostic-detail/diagnostic-detail.component';

// Models
import { ActiveAlias } from '../../models/alias-list';

@Component({
  selector: 'fury-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.scss']
})
export class DiagnosticComponent implements OnInit {
  loading = false;
  @ViewChild('ixTable') ixTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<string>;
  displayedColumns = ['url'];

  /**
   * Lista di alias
   */
  activeAliases: ActiveAlias[] = [];
  /**
   * Controller alias typeahead
   */
  activeAliasesControl = new FormControl('', Validators.required);
  /**
   * Lista alias filtrata in base al typeahead
   */
  filteredActiveAliases: Observable<ActiveAlias[]>;
  /**
   * Lista di urls
   */
  urls: string[] = [];
  /**
   * Numero totale di urls
   */
  totalUrls: number;
  /**
   * Numero di elelmenti da visulaizzare in una singola pagina
   */
  pageSize: number;
  /**
   * Controller per filtrare lista url
   */
  searchedUrlControl = new FormControl(null);
  /**
   * Segnaposto per la paginazione server side
   */
  urlPlaceCard: string = null;
  /**
   * Direction della paginazione
   */
  direction: 'prev' | 'next' = null;

  constructor(
    public languageService: LanguageService,
    private indexAliasService: IndexAliasService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.filteredActiveAliases = this.activeAliasesControl.valueChanges.pipe(
      tap(value => {
        if (this.isActiveAlias(value)) {
          this.activeAliasesControl.setErrors(null);
        } else {
          this.activeAliasesControl.setErrors({
            isNotAlias: true
          });
        }
      }),
      map(value => this.filterActiveAliases(value))
    );
    this.getActiveAliases();
  }

  /**
   * Chiama la lista degli alias attivi
   */
  private getActiveAliases(): void {
    this.indexAliasService
      .getActiveAliases()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(response => {
        this.activeAliases = response;
        this.activeAliasesControl.setValue('');
      });
  }

  /**
   * Ritorna una lista di alias filtrata in base al valore ricevuto
   * @param value Valore da ricercare tra gli alias
   */
  private filterActiveAliases(value: string): ActiveAlias[] {
    const filterValue = value.toLowerCase();
    return this.activeAliases.filter(
      alias => alias.id.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  /**
   * Ritorna true se la stringa passata è presente tra gli alias.
   * E' case insensitive
   * @param value stringa da ricercare nelal lista degli alias
   */
  isActiveAlias(value: string): boolean {
    return !!this.activeAliases.find(
      alias => alias.id.toLowerCase() === value.toLowerCase()
    );
  }

  /**
   * Chiama la lista di url associati ad un alias
   */
  private getAllDocs(): void {
    this.loading = true;
    // Prende esattamente l'alias che serve dalla lista.
    // Altrimenti ad esempio se non viene digitato case sensitive la chiamata ha esito negativo
    this.activeAliasesControl.setValue(
      this.activeAliases.find(
        alias =>
          alias.id.toLowerCase() ===
          this.activeAliasesControl.value.toLowerCase()
      ).id
    );
    this.indexAliasService
      .getAliasUrls(
        this.activeAliasesControl.value,
        this.direction,
        this.urlPlaceCard,
        this.searchedUrlControl.value
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(response => {
        this.urls = response.url || [];
        this.totalUrls = response.total;
        this.pageSize = response.pageSize;
        this.dataSource = new MatTableDataSource(this.urls);
      });
  }

  /**
   * Metodo richiamato alla selezione di un alias
   */
  mostraDocumenti(): void {
    this.direction = null;
    this.searchedUrlControl.setValue(null);
    this.urlPlaceCard = null;
    this.totalUrls = 0;
    this.pageSize = 0;
    this.getAllDocs();
  }

  /**
   * Evento di cambio pagina
   * @param event Oggetto ricevuto dal paginatore
   */
  pageChanged(event: PageEvent): void {
    if (event.pageIndex > event.previousPageIndex) {
      this.direction = 'next';
      this.urlPlaceCard = this.urls[this.urls.length - 1];
    } else {
      this.direction = 'prev';
      this.urlPlaceCard = this.urls[0];
    }
    this.getAllDocs();
  }

  /**
   * Applica le modifiche necessarie per la paginazione con filtro sugli url
   */
  applyUrlsFilter(): void {
    this.direction = null;
    this.urlPlaceCard = null;
    this.searchedUrlControl.setValue(this.searchedUrlControl.value || null);
    this.getAllDocs();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  /**
   * Chiama il servizio per ricevere il dettaglio di un singolo documento
   * @param docId Url del documento
   */
  getSingleDoc(docId: string): void {
    this.indexAliasService
      .getSingleDoc(this.activeAliasesControl.value, docId)
      .subscribe(response => {
        this.dialog.open(DiagnosticDetailComponent, {
          data: response
        });
      });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { LanguageService } from '../../_services/language.service';
import { ClientService } from '../../_services/client.service';

import { Client } from '../../models/client';
import { Suggestion } from '../../models/suggestion';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'app/_services/authentication.service';

@Component({
  selector: 'fury-suggestion-form',
  templateUrl: './suggestion-form.component.html'
})
export class SuggestionFormComponent implements OnInit {
  loading = false;
  suggestion: Suggestion;
  clients: Client[];
  form: FormGroup;

  userIsAdmin: boolean;

  constructor(
    public languageService: LanguageService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SuggestionFormComponent>,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.userIsAdmin = this.authenticationService.userIsAdmin();
    this.loading = true;
    this.getClients();
    this.form = this.formBuilder.group({
      client: [this.data.suggestion.client || this.userIsAdmin ? 'all' : '', Validators.required],
      term: [this.data.suggestion.term, Validators.required],
      enable: [this.data.suggestion.term ? this.data.suggestion.enable : true]
    });
  }

  private getClients(): void {
    this.clientService
      .getClients()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(response => this.clients = response);
  }

  submit() {
    const suggestion: Suggestion = {
      id: this.data.suggestion.id,
      client: this.form.get('client').value,
      term: this.form.get('term').value.trim(),
      enable: this.form.get('enable').value,
      crated: this.data.suggestion.crated
    };
    this.dialogRef.close(suggestion);
  }
}

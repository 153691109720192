import { SystemEmailTemplate } from './../../../models/system-email-template';
import { LanguageService } from 'app/_services/language.service';
import { Component, Inject, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChip, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'fury-system-email-template-form',
    templateUrl: 'system-email-template-form.component.html',
    styleUrls: ['system-email-template-form.component.scss'],
})

export class SystemEmailTemplateFormComponent implements OnInit {
    form: FormGroup;
    isEdit = false;
    isFull : boolean = false;

    currentAddress: string;

    currentEmailTemplate: SystemEmailTemplate;

    // Editor
    languages = ['JSON', 'HTML'];

    editorOptions = {
        theme: 'vs',
        language: 'json',
        automaticLayout: true,
        minimap: {
            enabled: false
        }
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private dialogRef: MatDialogRef<SystemEmailTemplateFormComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        public languageService: LanguageService,
    ) { }

    ngOnInit() {
        this.currentEmailTemplate = this.data.template;

        this.isEdit = !!this.currentEmailTemplate.id;

        if (this.currentEmailTemplate.linguaggioEditor) {
            this.setEditorLanguage(this.currentEmailTemplate.linguaggioEditor);
        }
    }

    setEditorLanguage(language: string): void {
        const newOptions = JSON.parse(JSON.stringify(this.editorOptions));

        newOptions.language = language;

        this.editorOptions = newOptions;
    }

    addAddress(): void {
        this.currentEmailTemplate.emailAddresses.push(this.currentAddress);
        this.currentAddress = null;
    }

    removeAddress(address: string): void {
        this.currentEmailTemplate.emailAddresses = this.currentEmailTemplate.emailAddresses.filter(x => x !== address);
    }

    submit(): void {
        this.dialogRef.close(this.currentEmailTemplate);
    }
    zoomInModal(){
        this.document.body.classList.toggle('modal-is-full');
        this.isFull = !this.isFull;
      }
}
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatTable,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { finalize } from 'rxjs/operators';

import { ConfigurationService } from '../../../_services/configuration.service';
import { ConfirmationDialogService } from '../../../_services/confirmation-dialog.service';

import { FreemarkerFormComponent } from '../freemarker-form/freemarker-form.component';
import { LanguageService } from '../../../_services/language.service';
import { FreemarkerTemplate } from 'app/models/freemarker-template';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fury-include-freemarker',
  templateUrl: './include-freemarker.component.html',
  styleUrls: ['./include-freemarker.component.scss']
})
export class IncludeFreemarkerComponent implements OnInit {
  @ViewChild('freeTable') freeTable: MatTable<any>;

  loading = false;
  displayedColumns: string[] = ['name', 'star'];
  dataSource: MatTableDataSource<FreemarkerTemplate>;
  freemarkerFormRef: MatDialogRef<FreemarkerFormComponent>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configurationService: ConfigurationService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    private router: Router,
    public languageService: LanguageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getFreemarkers();
  }

  getFreemarkers(): void {
    this.loading = true;
    this.configurationService
      .getIncludeFreemarkers()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        templates => {
          this.dataSource = new MatTableDataSource(templates);

          this.dataSource.filterPredicate = (
            data: FreemarkerTemplate,
            filter: string
          ) => {
            return (
              data.name
                .trim()
                .toLowerCase()
                .indexOf(filter.trim().toLowerCase()) !== -1
            );
          };
        }
      );
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  addTemplate() {
    this.freemarkerFormRef = this.dialog.open(FreemarkerFormComponent, {
      width: '800px'
    });

    this.freemarkerFormRef.afterClosed().subscribe(result => {
      this.document.body.classList.remove('modal-is-full');
      if (result !== undefined) {
        this.configurationService
          .addIncludeFreemarker(result)
          .subscribe(() => {
            this.showSuccessSnackAndGetFreemarkers();
          });
      }
    });
  }

  editTemplate(template) {
    this.freemarkerFormRef = this.dialog.open(FreemarkerFormComponent, {
      width: '800px',
      data: { template: template }
    });

    this.freemarkerFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.configurationService
          .editIncludeFreemarker(result)
          .subscribe(() => {
            this.showSuccessSnackAndGetFreemarkers();
          });
      }
    });
  }

  deleteTemplate(template) {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'configuration.IncludeFree.ConfermiEliminazioneTemplate'
        )} ${template.name}?`
      )
      .then(res => {
        if (res) {
          this.configurationService
            .deleteIncludeFreemarker(template)
            .subscribe(() => {
              this.showSuccessSnackAndGetFreemarkers();
            });
        }
      });
  }

  goToHelpPage(): void {
    this.router.navigate(['./template-freemaker-help']);
  }

  showSuccessSnackAndGetFreemarkers(){
    this.snackBar.open(
      this.languageService.getLabel('general.OperationSuccess'),
      'OK',
      {
        duration: 10000,
        horizontalPosition: 'center'
      }
    );
    this.getFreemarkers();
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  MatTable,
  MatSort,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { finalize, tap } from '../../../../node_modules/rxjs/operators';

import { ClientService } from '../../_services/client.service';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';

import { ClientDetailComponent } from '../../n4s/client-detail/client-detail.component';
import { ClientOneboxFormComponent } from '../client-onebox-form/client-onebox-form.component';

import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-onebox',
  templateUrl: './client-onebox.component.html',
  styleUrls: ['./client-onebox.component.scss']
})
export class ClientOneboxComponent implements OnInit {

  pageSize = 10;
  dataSource: MatTableDataSource<any> | null = new MatTableDataSource();
  oneboxes: any[];
  loading = false;
  displayedColumns: string[] = ['name', 'activation', 'star'];
  oneboxFormRef: MatDialogRef<ClientOneboxFormComponent>;

  @ViewChild('oneboxTable') oneboxTable: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public parentClient;

  constructor(
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.parentClient = this.parentComponent.currentClient;
    this.getOneBox();
  }

  getOneBox(): void {
    this.loading = true;

    this.clientService
      .getOnebox(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(keys => {
        this.dataSource.data = keys;
        this.oneboxes = keys;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return (
            data.name
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) !== -1
          );
        };
      });
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  addOnebox() {
    this.oneboxFormRef = this.dialog.open(ClientOneboxFormComponent, {
      width: '530px'
    });

    this.oneboxFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService
          .addOnebox(this.parentClient.idclient, result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getOneBox());
      }
    });
  }

  deleteOnebox(onebox) {
    this.confirmationDialog
      .confirm(`Eliminare Onebox ${onebox.name}?`)
      .then(res => {
        if (res) {
          this.clientService
            .deleteOnebox(this.parentClient.idclient, onebox)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getOneBox());
        }
      });
  }
}

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserData } from 'app/models/userData';
import { Role } from './../models/role';
import { UserFunction } from 'app/models/userFunction';

import { ConfigData } from '../app.config';
import { UserGroup } from 'app/models/user-group';
import { GroupAreaPermission } from 'app/models/group-area-permission';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  apiUserUrl = `${ConfigData.apiUrl}/user`;
  apiUserGroupUrl = `${ConfigData.apiUrl}/user-group`;
  apiRoleUrl = `${ConfigData.apiUrl}/role`;
  apiGroupsUrl = `${ConfigData.apiUrl}/group`;
  apiGroupFunctionsUrl = `${ConfigData.apiUrl}/group-function`;
  apiUserFunctionUrl = `${ConfigData.apiUrl}/user-function`;

  constructor(private http: HttpClient) { }

  // USER SERVICES
  getUsers(): Observable<UserData[]> {
    return this.http.get<UserData[]>(this.apiUserUrl);
  }

  getUserDetail(username: string): Observable<UserData> {
    return this.http.get<UserData>(`${this.apiUserUrl}/${username}`);
  }

  getCurrentUserDetail(): Observable<UserData> {
    return this.http.get<UserData>(`${this.apiUserUrl}/me/info`);
  }

  updateCurrentUserData(userUpdateRequest: {
    email: string;
    password: string;
  }): Observable<any> {
    return this.http.put<any>(`${this.apiUserUrl}/me/info`, userUpdateRequest);
  }

  saveNewUser(user: UserData): Observable<UserData> {
    return this.http.post<UserData>(this.apiUserUrl, user);
  }

  updateUser(userName: string, user: UserData): Observable<UserData> {
    return this.http.put<UserData>(`${this.apiUserUrl}/${userName}`, user);
  }

  deleteUser(user: UserData): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUserUrl}/${user.username}`);
  }

  // USER FUNCTIONS SERVICES

  getUserFunctions(): Observable<UserFunction[]> {
    return this.http.get<UserFunction[]>(this.apiUserFunctionUrl);
  }

  addUserFunction(
    funcName: string,
    username: string
  ): Observable<UserFunction> {
    const requestData = {
      function: funcName,
      userId: username,
      active: true
    };

    return this.http.post<UserFunction>(this.apiUserFunctionUrl, requestData);
  }

  changeFunctionStatus(
    funcName: string,
    username: string,
    active: boolean
  ): Observable<UserFunction> {
    return this.http.put<UserFunction>(
      `${this.apiUserFunctionUrl}/${username}/${funcName}`,
      { active: active }
    );
  }

  removeUserFunction(funcName: string, username: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.apiUserFunctionUrl}/${username}/${funcName}`
    );
  }


  // GESTIONE GRUPPI
  getGroups(): Observable<UserGroup[]> {
    return this.http.get<UserGroup[]>(this.apiGroupsUrl);
  }

  addGroup(group: UserGroup): Observable<UserGroup> {
    return this.http.post<UserGroup>(this.apiGroupsUrl, group);
  }

  updateGroup(group: UserGroup): Observable<UserGroup> {
    return this.http.put<UserGroup>(`${this.apiGroupsUrl}/${group.groupName}`, group);
  }

  deleteGroup(groupName: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiGroupsUrl}/${groupName}`);
  }

  addUserToGroup(userId: string, groupName: string): Observable<any> {
    const req = {
      userId,
      groupName
    };

    return this.http.post<any>(this.apiUserGroupUrl, req);
  }

  deleteUserGroup(userId: string, groupName: string): Observable<any> {
    return this.http.delete(`${this.apiUserGroupUrl}/${userId}/${groupName}`);
  }

  getGroupVisibleAreas(groupName: string): Observable<GroupAreaPermission[]> {
    return this.http.get<GroupAreaPermission[]>(`${this.apiGroupFunctionsUrl}/${groupName}`);
  }

  enableGroupArea(groupName: string, functionName: string): Observable<GroupAreaPermission> {
    const req = {
      groupName,
      function: functionName
    };

    return this.http.post<GroupAreaPermission>(`${this.apiGroupFunctionsUrl}`, req);
  }

  disableGroupArea(groupName: string, functionName: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiGroupFunctionsUrl}/${groupName}/${functionName}`);
  }

  getSelectedGroupAlias(groupName: string): Observable<any> {
    return this.http.get<any>(`${this.apiGroupsUrl}-alias/${groupName}`);
  }

  getSelectedGroupFrontend(groupName: string): Observable<any> {
    return this.http.get<any>(`${this.apiGroupsUrl}-frontend/${groupName}`);
  }

  getSelectedGroupClients(groupName: string): Observable<any> {
    return this.http.get<any>(`${this.apiGroupsUrl}-client/${groupName}`);
  }

  getAlias(): Observable<any> {
    return this.http.get<any>(`${this.apiGroupsUrl}-alias`);
  }

  addAliasToGroup(groupName: string, aliasId: string): Observable<any> {
    const req = {
      groupName,
      aliasId
    };

    return this.http.post<any>(`${this.apiGroupsUrl}-alias`, req);
  }

  removeAliasFromGroup(groupName: string, aliasId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiGroupsUrl}-alias/${groupName}/${aliasId}`);
  }

  getFrontends(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiGroupsUrl}-frontend`);
  }

  addFrontendToGroup(groupName: string, frontendId: string, lang: string): Observable<any> {
    const req = {
      groupName,
      frontendId,
      lang
    };

    return this.http.post<any>(`${this.apiGroupsUrl}-frontend`, req);
  }

  removeFrontendFromGroup(groupName: string, frontendId: string, lang: string): Observable<any> {
    return this.http.delete<any>(`${this.apiGroupsUrl}-frontend/${groupName}/${frontendId}/${lang}`);
  }

  getClients(): Observable<any> {
    return this.http.get<any[]>(`${this.apiGroupsUrl}-client`);
  }


  addClientToGroup(groupName: string, clientId: string): Observable<any> {
    const req = {
      groupName,
      clientId
    };

    return this.http.post<any>(`${this.apiGroupsUrl}-client`, req);
  }

  removeClientFromGroup(groupName: string, clientId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiGroupsUrl}-client/${groupName}/${clientId}`);
  }


}

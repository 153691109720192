import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-geo-conf',
  templateUrl: './geo-conf.component.html',
  styleUrls: ['./geo-conf.component.scss']
})
export class GeoConfComponent implements OnInit {

  constructor(public languageService : LanguageService) { }

  ngOnInit() {
    // This is intentional
  }

}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { CrawlingJob } from "./../../models/crawling-job";
import { LanguageService } from "app/_services/language.service";

@Component({
  selector: "fury-gestione-crawling-web-form",
  templateUrl: "gestione-crawling-web-form.component.html",
  styleUrls: ["gestione-crawling-web-form.component.scss"]
})
export class GestioneCrawlingWebFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<GestioneCrawlingWebFormComponent>,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.pattern('^[a-z0-9_-]+$'), Validators.required])]
    });
  }

  submit(form) {
    this.dialogRef.close({ name: form.value.name, type: "web" } as CrawlingJob);
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { Injectable } from "@angular/core";
import { interval, Observable, of } from "rxjs";
import "rxjs/add/observable/of";
import { HttpClient, HttpParams } from "@angular/common/http";

// Models
import { CrawlingJob } from "../models/crawling-job";
import { CrawlingWebJobUrls } from "../models/crawling-web-job-urls";
import { Schedulazione } from "../models/schedulazione";
import { CrawlingMapping } from "../models/crawling-mapping";
import { CrawlingWebUrl } from "../models/crawling-web-url";
import { CrawlingWebPath } from "../models/crawling-web-path";
import { Mapping } from "../models/index";
import { FeedXMLFilters } from "../models/feed-xml-filters";

// Data config
import { ConfigData } from "../app.config";
import { CrawlingLogsFilters } from "app/models/crawling-logs-filters.model";
import { CrawlingLog } from "app/models/crawling-log.model";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GestioneCrawlingService {
  private readonly crawlingWebJobUrl: string = ConfigData.apiUrl + "/crawling/web/job";
  private readonly crawlingWebUrl: string = ConfigData.apiUrl + "/crawling/web";
  private readonly crawlingFeedXmlUrl: string = ConfigData.apiUrl + "/crawling/feed";
  private readonly crawlingFeedKenticoUrl: string = ConfigData.apiUrl + "/crawling/kentico/filter";
  private readonly crawlingFeedJsoningesterUrl: string = ConfigData.apiUrl + "/crawling/ingestion/filter";
  private readonly crawlingLogUrl: string = ConfigData.apiUrl + "/logs/nutch";
  private readonly indexUrl = `${ConfigData.apiUrl}/index`;
  private readonly documnetIndexUrl = `${ConfigData.apiUrl}/crawling/web/job/document/index`;

  // Job che viene selezionato dalla lista dei Runnable Jobs. Serve un riferimento a livello di servizio
  // per poter condividere le informazioni fra i vari componenti.
  selectedJob: CrawlingJob;

  constructor(private http: HttpClient) {}

  // JOB LIST
  getCrawlingWebJobs(): Observable<{
    cancelled: CrawlingJob[];
    draft: CrawlingJob[];
    runnable: CrawlingJob[];
  }> {
    return this.http.get<{
      cancelled: CrawlingJob[];
      draft: CrawlingJob[];
      runnable: CrawlingJob[];
    }>(this.crawlingWebJobUrl);
  }

  getCrawlingWebJobDetail(idJob: string): Observable<CrawlingJob> {
    return this.http.get<CrawlingJob>(`${this.crawlingWebJobUrl}/${idJob}`);
  }

  addCrawlingWebJob(job: CrawlingJob): Observable<any> {
    return this.http.post<any>(this.crawlingWebJobUrl, job);
  }

  removeCrawlingWebJob(job: CrawlingJob): Observable<any> {
    return this.http.delete<any>(`${this.crawlingWebJobUrl}/${job.id}`);
  }

  startCrawlingWebJob(job: CrawlingJob): Observable<any> {
    return this.http.get<any>(`${this.crawlingWebJobUrl}/${job.id}/start`);
  }

  stopCrawlingWebJob(job: CrawlingJob): Observable<any> {
    return this.http.get<any>(`${this.crawlingWebJobUrl}/${job.id}/stop`);
  }

  updateDeep(
    idJob: string,
    deep: number
  ): Observable<{ idJob: number; deep: number }> {
    return this.http.get<{ idJob: number; deep: number }>(
      `${this.crawlingWebJobUrl}/${idJob}/updateDeep/${deep}`
    );
  }

  setMaximumDocumentNumber(
    idJob: string,
    maxNumber: number
  ): Observable<{ idJob: number; topn: number }> {
    return this.http.get<{ idJob: number; topn: number }>(
      `${this.crawlingWebJobUrl}/${idJob}/updateTopn/${maxNumber}`
    );
  }

  // JOB URLS
  getCrawlingWebUrlsData(idJob: string): Observable<CrawlingWebJobUrls> {
    return this.http.get<CrawlingWebJobUrls>(
      `${this.crawlingWebJobUrl}/${idJob}/urls`
    );
  }

  addCrawlingJobStartUrl(
    idJob: string,
    url: string
  ): Observable<CrawlingWebUrl> {
    return this.http.post<CrawlingWebUrl>(
      `${this.crawlingWebJobUrl}/${idJob}/start-url`,
      {
        url: url,
      }
    );
  }

  deleteCrawlingJobStartUrl(idJob: string, idUrl: string): Observable<any> {
    return this.http.delete<any>(
      `${this.crawlingWebJobUrl}/${idJob}/start-url/${idUrl}`
    );
  }

  // JOB PATH
  getCrawlingJobPath(): Observable<any> {
    return this.http.get(`${this.crawlingWebJobUrl}/path`);
  }

  addCrawlingJobPath(path: string, type: string): Observable<CrawlingWebPath> {
    return this.http.post<CrawlingWebPath>(`${this.crawlingWebJobUrl}/path`, {
      path: path,
      type: type,
    });
  }

  deleteCrawlingJobPath(idPath: string): Observable<any> {
    return this.http.delete<any>(`${this.crawlingWebJobUrl}/path/${idPath}`);
  }

  // JOB SCHEDULE
  getCrawlingWebScheduleList(idJob: string): Observable<Schedulazione[]> {
    return this.http.get<Schedulazione[]>(
      `${this.crawlingWebJobUrl}/${idJob}/schedulation`
    );
  }

  addCrawlingWebSchedule(
    idJob: string,
    schedule: Schedulazione
  ): Observable<Schedulazione> {
    return this.http.post<Schedulazione>(
      `${this.crawlingWebJobUrl}/${idJob}/schedulation`,
      schedule
    );
  }

  deleteCrawlingWebSchedule(
    idJob: string,
    scheduleId: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.crawlingWebJobUrl}/${idJob}/schedulation/${scheduleId}`
    );
  }

  toggleCrawlingWebSchedule(
    idJob: string,
    scheduleId: string,
    active: boolean
  ): Observable<any> {
    return this.http.get<any>(
      `${this.crawlingWebJobUrl}/${idJob}/schedulation/${scheduleId}/${active}`
    );
  }

  // JOB MAPPING
  getCrawlingWebMapping(idJob: string): Observable<any> {
    return this.http.get<any>(`${this.crawlingWebJobUrl}/${idJob}/mapping`);
  }

  getIndexMapping(): Observable<Mapping[]> {
    return this.http.get<Mapping[]>(`${this.indexUrl}/index_web`);
  }

  saveCrawlingWebMapping(
    idJob: string,
    mapping: CrawlingMapping
  ): Observable<any> {
    return this.http.post<any>(
      `${this.crawlingWebJobUrl}/${idJob}/mapping`,
      mapping
    );
  }

  deleteCrawlingWebMapping(idJob: string, idMapping: string): Observable<any> {
    return this.http.delete<any>(
      `${this.crawlingWebJobUrl}/${idJob}/mapping/${idMapping}`
    );
  }

  // JOB OPERATION
  stopCrawlingWeb(): Observable<any> {
    return this.http.get<any>(`${this.crawlingWebUrl}/stop`);
  }

  getFeedXML(feedParams): Observable<any> {
    let currentParam = new HttpParams();
    feedParams.forEach(singleParam => {
      currentParam = currentParam.append(singleParam.key, singleParam.value);
    })

    return this.http.get<any>(this.crawlingFeedXmlUrl, {
      params : currentParam
    });
  }

  getFeedKentico(): Observable<any> {
    return this.http.get<any>(this.crawlingFeedKenticoUrl);
  }

  getFeedJsoningester(): Observable<any> {
    return this.http.get<any>(this.crawlingFeedJsoningesterUrl);
  }

  // STATO DOCUMENTO
  getDocumentState(jobName: string, id: string): Observable<any> {
    return this.http.post<any>(`${this.crawlingWebUrl}/${jobName}/state`, {
      id,
    });
  }

  getFeedXMLFiltered(feedParams: any): Observable<any> {
    let params = new HttpParams();
    params = !!feedParams.status
      ? params.append("status", feedParams.status)
      : params;
    params = !!feedParams.day
      ? params.append("day", feedParams.day || "")
      : params;
    params = !!feedParams.start
      ? params.append("start", feedParams.start.valueOf())
      : params;
    params = !!feedParams.end
      ? params.append("end", feedParams.end.valueOf())
      : params;
    params =  params.append("size", feedParams.size)
    params =  params.append("page", feedParams.page)
      
    return this.http.get<any>(`${this.crawlingFeedXmlUrl}/filter`, {
      params,
    });
  }

  getFeedKenticoFiltered(feedParams: any): Observable<any> {
    let params = new HttpParams();
    params = !!feedParams.status
      ? params.append("status", feedParams.status)
      : params;
    params = !!feedParams.documentId
      ? params.append("documentId", feedParams.documentId.trim() || "")
      : params;  
    params = !!feedParams.projectId
      ? params.append("projectId", feedParams.projectId.trim() || "")
      : params;    
    params = !!feedParams.start
      ? params.append("start", feedParams.start.valueOf())
      : params;
    params = !!feedParams.end
      ? params.append("end", feedParams.end.valueOf())
      : params;
    params = !!feedParams.day
      ? params.append("day", feedParams.day.valueOf())
      : params;

    return this.http.get<any>(`${this.crawlingFeedKenticoUrl}`, {
      params,
    });
  }

  getFeedJsoningesterFiltered(feedParams: any): Observable<any> {
    let params = new HttpParams();
    params = !!feedParams.status
      ? params.append("status", feedParams.status)
      : params;
    params = !!feedParams.documentId
      ? params.append("id", feedParams.documentId.trim() || "")
      : params;  
    params = !!feedParams.day
      ? params.append("day", feedParams.day.valueOf())
      : params;

    return this.http.get<any>(`${this.crawlingFeedJsoningesterUrl}`, {
      params,
    });
  }

  getFeedXMLDetail(feedId: number): Observable<any> {
    return this.http.get<any>(`${this.crawlingFeedXmlUrl}/${feedId}`);
  }

  // STATO CRAWLING
  getCrawlingStatus(nomeJob: string): Observable<any> {
    return this.http.get<any>(`${this.crawlingWebUrl}/${nomeJob}/statoCrawler`);
  }

  // LOGS
  getCrawlingLogs(): Observable<CrawlingLog[]> {
    return this.http.get<CrawlingLog[]>(this.crawlingLogUrl);
  }

  getFilteredCrawlingLogs(
    crawlingLogFilters: CrawlingLogsFilters
  ): Observable<CrawlingLog[]> {
    let params = new HttpParams();

    if (crawlingLogFilters.level) {
      params = params.append("level", crawlingLogFilters.level);
    }

    if (crawlingLogFilters.start) {
      params = params.append("start", crawlingLogFilters.start);
    }

    if (crawlingLogFilters.end) {
      params = params.append("end", crawlingLogFilters.end);
    }

    return this.http.get<CrawlingLog[]>(`${this.crawlingLogUrl}/filter`, {
      params,
    });
  }

  indexSingleDocument(documentUrl: string): Observable<any> {
    const params: HttpParams = new HttpParams().append("url", documentUrl);

    return this.http.get<any>(this.documnetIndexUrl, { params });
  }

  checkSingleDocument(): Observable<any> {
    return this.http.get<any>(this.crawlingWebJobUrl + "/-1");
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyService } from 'app/_services/policy.service';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { ConfirmationDialogService } from '../../../../_services/confirmation-dialog.service';
import { AlertService } from '../../../../_services/alert.service';
import { LanguageService } from '../../../../_services/language.service';

import { PolicyAliasFormComponent } from '../policy-alias-form/policy-alias-form.component';
import { PolicyMetaFormComponent } from '../policy-meta-form/policy-meta-form.component';

import { AliasBiasing } from '../../../../models/alias-biasing';
import { PolicyBiasing } from '../../../../models/policy-biasing';
import { MetaBiasing } from '../../../../models/meta-biasing';

@Component({
  selector: 'fury-policy-biasing-detail',
  templateUrl: 'policy-biasing-detail.component.html',
  styleUrls: ['policy-biasing-detail.component.scss']
})
export class PolicyBiasingDetailComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumnsAlias: string[] = ['idAlias', 'boost', 'star'];
  dataSourceAlias: MatTableDataSource<AliasBiasing>;

  displayedColumnsMeta: string[] = ['field', 'textRegex', 'boost', 'star'];
  dataSourceMeta: MatTableDataSource<MetaBiasing>;

  aliasFormRef: MatDialogRef<PolicyAliasFormComponent>;
  metaFormRef: MatDialogRef<PolicyMetaFormComponent>;

  loading = false;

  private idBiasing: string;

  public currentPolicy: PolicyBiasing;

  public decay: number;

  public offset = {
    quantity: 1,
    scale: 'd'
  };

  public scale = {
    quantity: 1,
    scale: 'd'
  };

  public scaleOptions = [
    {
      value: 'd',
      description: this.languageService.getLabel('policyBiasingDetail.Giorni')
    },
    {
      value: 'm',
      description: this.languageService.getLabel('policyBiasingDetail.Mesi')
    },
    {
      value: 'y',
      description: this.languageService.getLabel('policyBiasingDetail.Anni')
    }
  ];

  /*NOSONAR*/constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policyService: PolicyService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    private alertService: AlertService,
    public languageService: LanguageService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.idBiasing = this.route.snapshot.paramMap.get('idBiasing');
    this.getPolicyDetail();
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    return Math.round(value * 100) / 100;
  }

  dateWeightChange(event): void {
    this.decay = Math.round(event.value * 100) / 100;
  }

  public getPolicyDetail(): void {
    this.policyService.getPolicyDetail(this.idBiasing).subscribe(resp => {
      if (resp) {
        this.currentPolicy = resp;

        this.dataSourceAlias = new MatTableDataSource(
          this.currentPolicy.aliasList
        );
        this.dataSourceAlias.sort = this.sort;
        this.dataSourceAlias.paginator = this.paginator;

        this.decay = this.currentPolicy.decay;

        this.dataSourceMeta = new MatTableDataSource(
          this.currentPolicy.metaList
        );
        this.dataSourceMeta.sort = this.sort;
        this.dataSourceMeta.paginator = this.paginator;

        this.buildDataWeightInfo();
      }
    });
  }

  public saveDetail(): void {
    if (this.decay < 0 || this.decay > 1) {
      this.alertService.subj_notification.next(
        'policyBiasingDetail.ErroreBoost'
      );
      return;
    }
    this.currentPolicy.offset = this.convertInDay(this.offset.quantity,this.offset.scale);
    this.currentPolicy.scale =  this.convertInDay(this.scale.quantity, this.scale.scale);
    this.currentPolicy.decay = this.decay;
    this.policyService.editPolicyBiasing(this.currentPolicy).subscribe(resp => {
      if (resp) {
        this.alertService.subj_notification.next(
          this.languageService.getLabel('policyBiasingDetail.PolicyAggiornata')
        );
        this.getPolicyDetail();
      }
    });
  }

  convertInDay(quantity,scale){
    let computedQuantity;
    switch (scale){
      case 'm' :
        computedQuantity = quantity * 30;
        break;
      case 'y' :
        computedQuantity = quantity * 365;
        break;
      default : 
        computedQuantity = quantity;
    }
    return computedQuantity + 'd';
  }

  public addAlias(): void {
    this.aliasFormRef = this.dialog.open(PolicyAliasFormComponent, {
      width: '700px'
    });

    this.aliasFormRef.afterClosed().subscribe(alias => {
      if (!!alias) {
        this.policyService
          .addPolicyAlias(alias, this.idBiasing)
          .subscribe(response => {
            if (response) {
              this.snackBar.open(
                this.languageService.getLabel('general.OperationSuccess'),
                'OK',
                {
                  duration: 10000,
                  horizontalPosition: 'center'
                }
              );
              this.getPolicyDetail();
            }
          });
      }
    });
  }

  public deleteAlias(alias: AliasBiasing): void {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'policyBiasingDetail.EliminareAlias'
        )} ${alias.idAlias}?`
      )
      .then(res => {
        if (res) {
          this.policyService
            .deletePolicyAlias(alias, this.idBiasing)
            .subscribe(() => {
              this.showSuccessAndGetPolicyDetail();
            });
        }
      });
  }

  editAlias(alias: AliasBiasing): void {
    this.aliasFormRef = this.dialog.open(PolicyAliasFormComponent, {
      width: '700px',
      data: { alias: alias }
    });

    this.aliasFormRef.afterClosed().subscribe(aliasRes => {
      if (!!aliasRes) {
        // Aggiornamento policy
        this.policyService.editPolicyAlias(alias).subscribe(response => {
          this.showSuccessAndGetPolicyDetail();
        });
      }
      this.getPolicyDetail();
    });
  }

  private showSuccessAndGetPolicyDetail(){
    this.snackBar.open(
      this.languageService.getLabel('general.OperationSuccess'),
      'OK',
      {
        duration: 10000,
        horizontalPosition: 'center'
      }
    );
    this.getPolicyDetail();
  }

  public addMeta(): void {
    this.metaFormRef = this.dialog.open(PolicyMetaFormComponent, {
      width: '700px'
    });

    this.metaFormRef.afterClosed().subscribe(meta => {
      if (!!meta) {
        this.policyService
          .addPolicyMeta(meta, this.idBiasing)
          .subscribe(response => {
            if (response) {
              this.showSuccessAndGetPolicyDetail();
            }
          });
      }
    });
  }

  public deleteMeta(meta: MetaBiasing): void {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'policyBiasingDetail.EliminareMeta'
        )} ${meta.field}?`
      )
      .then(res => {
        if (res) {
          this.policyService.deletePolicyMeta(meta).subscribe(response => {
            this.showSuccessAndGetPolicyDetail();
          });
        }
      });
  }

  editMeta(meta: MetaBiasing): void {
    this.metaFormRef = this.dialog.open(PolicyMetaFormComponent, {
      width: '700px',
      data: { meta: meta }
    });

    this.metaFormRef.afterClosed().subscribe(metaRes => {
      if (!!metaRes) {
        // // Aggiornamento policy
        this.policyService.editPolicyMeta(metaRes).subscribe(response => {
          this.showSuccessAndGetPolicyDetail();
        });
      }
      this.getPolicyDetail();
    });
  }

  public checkPositive(event): void {
    if (event.value === null || event.target.value < 0) {
      event.target.value = 1;
    }
  }

  private buildDataWeightInfo(): void {
    if(this.currentPolicy.offset && this.currentPolicy.scale){
      this.convertDataWeightInfo();
    }
    
    if (!!this.currentPolicy.offset) {
      this.offset.quantity = +this.currentPolicy.offset.substring(
        0,
        this.currentPolicy.offset.length - 1
      );
      this.offset.scale = this.currentPolicy.offset.substr(
        this.currentPolicy.offset.length - 1,
        1
      );
    }

    if (!!this.currentPolicy.scale) {
      this.scale.quantity = +this.currentPolicy.scale.substring(
        0,
        this.currentPolicy.scale.length - 1
      );
      this.scale.scale = this.currentPolicy.scale.substr(
        this.currentPolicy.scale.length - 1,
        1
      );
    }
  }

  convertDataWeightInfo = () => {
    //Convert Offset
    let currentOffsetUnit = this.currentPolicy.offset.substring(this.currentPolicy.offset.length - 1, this.currentPolicy.offset.length)
    let currentOffset = this.currentPolicy.offset.substring(0, this.currentPolicy.offset.length - 1)
    if(currentOffsetUnit === 'd'){
      if(parseInt(currentOffset) >= 30 && parseInt(currentOffset) < 365){
        this.currentPolicy.offset = parseInt(currentOffset) / 30 + 'm'
      }else if(parseInt(currentOffset) >= 365){
        this.currentPolicy.offset = parseInt(currentOffset) / 365 + 'y'
      }
    }
    //Convert Scale
    let currentScaleUnit = this.currentPolicy.scale.substring(this.currentPolicy.scale.length - 1, this.currentPolicy.scale.length)
    let currentScale = this.currentPolicy.scale.substring(0, this.currentPolicy.scale.length - 1)
    if(currentScaleUnit === 'd'){
      if(parseInt(currentScale) >= 30 && parseInt(currentScale) < 365){
        this.currentPolicy.scale = parseInt(currentScale) / 30 + 'm'
      }else if(parseInt(currentScale) >= 365){
        this.currentPolicy.scale = parseInt(currentScale) / 365 + 'y'
      }
    }
  }

  back(): void {
    this.router.navigate(['policy-biasing']);
  }
}

export class SystemEmailTemplate {
    descrizione: string;
    template: string;
    linguaggioEditor: string;
    emailAddresses: string[];
    id: number;

    constructor() {
        this.emailAddresses = [];
    }
}

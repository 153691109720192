import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LanguageService } from 'app/_services/language.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fury-freemarker-form',
  templateUrl: './freemarker-form.component.html',
  styleUrls: ['./freemarker-form.component.scss']
})
export class FreemarkerFormComponent implements OnInit {
  freeTypes = [];
  isFull : boolean = false;

  // Editor
  languages = [
    { key: 'json', value: 'JSON' },
    { key: 'html', value: 'HTML' },
    { key: 'xml', value: 'XML' }
  ];

  editorOptions = {
    theme: 'vs',
    language: 'html',
    automaticLayout: true,
    minimap: {
      enabled: false
    }
  };

  currentTemplate = {
    name: '',
    content: ''
  };

  form: FormGroup;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FreemarkerFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    if (this.data != null) {
      this.currentTemplate = this.data.template;
    }

    this.form = this.formBuilder.group({
      content: [this.currentTemplate.content, Validators.required],
      name: [this.currentTemplate.name, Validators.required],
      languageType: [this.editorOptions.language]
    });
  }

  submit() {
    this.dialogRef.close({
      name: this.form.value.name,
      content: this.form.value.content
    });
  }

  cancel() {
    this.dialogRef.close();
  }
  zoomInModal(){
    this.document.body.classList.toggle('modal-is-full');
    this.isFull = !this.isFull;
  }
  editorLanguageChanged(value: any): void {
    const newConfig = JSON.parse(JSON.stringify(this.editorOptions));
    newConfig.language = value;

    this.editorOptions = newConfig;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ConfigData } from '../app.config';
import { DictionaryFile } from 'app/models/dictionary-file';
import { FormGroup } from '@angular/forms';
import { Type } from 'app/models/index';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {
  querySettingsUrl = `${ConfigData.apiUrl}/query-settings`;

  constructor(private http: HttpClient) {}

  getSynonymsList(): Observable<DictionaryFile[]> {
    return this.http.get<DictionaryFile[]>(`${this.querySettingsUrl}/synonyms`);
  }

  getLanguages(): Observable<Type[]> {
    return this.http.get<Type[]>(
      `${ConfigData.apiUrl}/configuration/language-settings/languages-list`
    );
  }

  saveSynonymsFile(form: FormGroup, file: File): Observable<DictionaryFile> {
    const formData = new FormData();
    formData.append('lang', form.value.language);
    formData.append('synonyms', file, file.name);

    return this.http.post<DictionaryFile>(
      `${this.querySettingsUrl}/synonyms`,
      formData
    );
  }

  getStopwordsList(): Observable<DictionaryFile[]> {
    return this.http.get<DictionaryFile[]>(
      `${this.querySettingsUrl}/stopwords`
    );
  }

  saveStopwordsFile(form: FormGroup, file: File): Observable<DictionaryFile> {
    const formData = new FormData();
    formData.append('lang', form.value.language);
    formData.append('stopwords', file, file.name);

    return this.http.post<DictionaryFile>(
      `${this.querySettingsUrl}/stopwords`,
      formData
    );
  }

  getBlacklistList(): Observable<DictionaryFile[]> {
    return this.http.get<DictionaryFile[]>(
      `${this.querySettingsUrl}/blacklist`
    );
  }

  saveBlacklistFile(form: FormGroup, file: File): Observable<DictionaryFile> {
    const formData = new FormData();
    formData.append('lang', form.value.language);
    formData.append('blacklist', file, file.name);

    return this.http.post<DictionaryFile>(
      `${this.querySettingsUrl}/blacklist`,
      formData
    );
  }

  downloadFile(idFile: number): Observable<string> {
    return this.http.get(`${this.querySettingsUrl}/file/${idFile}`, {
      responseType: 'text'
    });
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { LanguageService } from './language.service';

@Injectable()
export class NotificationsService {

    constructor(private snackBar: MatSnackBar, private languageService: LanguageService) { }

    /**
     * Fa apparire il messaggio di default "Operazione Completata" per quelle
     * operazioni che non richiedono informazioni aggiuntive una volta che sono state
     * completate.
     */
    public showDefaultSuccessMessage(): void {
        this.snackBar.open(
            this.languageService.getLabel('general.OperationSuccess'),
            'OK',
            {
                duration: 10000,
                horizontalPosition: 'center'
            }
        );
    }
}

import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LanguageService } from 'app/_services/language.service';
import { UploadFileService } from 'app/_services/upload-file.service';
import { LoadingFileDialogComponent } from '../loading-file-dialog/loading-file-dialog.component';
import { ServiceAccountCodeModalComponent } from '../service-account-code-modal/service-account-code-modal.component';

@Component({
  selector: 'fury-indexing-docs',
  templateUrl: './indexing-docs.component.html',
  styleUrls: ['./indexing-docs.component.scss']
})
export class IndexingDocsComponent implements OnInit {
  public uploadedFilesVideo: string[] = [];
  objectKeys = Object.keys;
  itaLang: string = 'it-IT';
  enLang: string = 'en-US';
  validFileExtensions = ["application/pdf", "image/tiff", "image/gif"];  

  private serviceAccountValidatorsFGStorageLink = [];

  public formGroupStorageLinkDocs = this.fb.group({
    link: [null, Validators.required],
    lang: [this.itaLang],
    isPrivateBucket: [false],
    serviceAccount: ['', this.serviceAccountValidatorsFGStorageLink]
  });

  public formGroupFiles = this.fb.group({
    file: ['', Validators.required],
  });



  @ViewChild("fileUploader")
  fileUpload: ElementRef;
  files = [];
  dialogRef;
  sendLinkIsLoading: boolean = false;
  visionApiList: string[] = [];
  constructor(private fb: FormBuilder,
    private uploadFileService: UploadFileService,
    private dialog: MatDialog,
    private snackBarDocs: MatSnackBar,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.onValueFormChange();
    this.getFeatureTypes();
  }

  getFeatureTypes(){
    this.uploadFileService.getFeatureTypesVideo().subscribe((res) =>{
      this.visionApiList = res.body;
    })
  }

  get formGroupStorageLinkLang() { return this.formGroupStorageLinkDocs.get('lang').value; }

  public onFileChange(event) {
    let isValid = this.checkDoc(event.target.files[0]);
    if (isValid) {
      const formData: any = new FormData();
      let filesNames: string[] = [];
      if (event.target.files && event.target.files.length) {
        Array.from(event.target.files).forEach((file: File) => {
          formData.append('files', file);
          filesNames.push(file.name)
        });

        this.dialogRef = this.dialog.open(LoadingFileDialogComponent, { width: '380px', disableClose: true });
        this.dialogRef.componentInstance.percentage = 0;
        this.dialogRef.componentInstance.currentFile = filesNames[0];
  
        this.uploadFileService.uploadDocFiles(formData).subscribe(
  
          eventResp => {
            switch (eventResp.type) {
              case HttpEventType.UploadProgress: {
                const percentDone = Math.round(100 * eventResp.loaded / eventResp.total);
                this.dialogRef.componentInstance.percentage = percentDone
                let currentFilesIndex = Math.round((filesNames.length * percentDone / 100))
                this.dialogRef.componentInstance.currentFile = filesNames[currentFilesIndex];
                break;
              }
              case HttpEventType.Response: {
                
                if (eventResp.status === 200) {
                  this.showSuccessToastDoc();
                  this.formGroupFiles.reset();
                } else {
                  this.showErroToast('general.serviceError');
                }
                this.dialog.closeAll();
                break;
              }
              default: {
                console.log(event);
              }
            }
          },
          err => {
            this.showErroToast('general.serviceError');
            this.dialog.closeAll();
            console.error(err)
          },
        )
      }
    }
  }

  public checkDoc(doc) {
    var isIncluded = false;
    isIncluded = this.validFileExtensions.includes(doc.type);    
    if (!isIncluded) {
        this.showErroToast('error.not_supported');
        return false;
    } else {
      if (doc.size > 20971520) {
        this.showErroToast('error.file_too_large');
        return false;
    } else {
        return true;
      }
    }

  }


  public onSubmitStorageLinkDocs() {
    const formData = new FormData();
    this.sendLinkIsLoading = true;
    formData.append('externalUrl', this.formGroupStorageLinkDocs.value.link.trim());

    formData.append('serviceAccount', this.formGroupStorageLinkDocs.value.serviceAccount);
    formData.append('isPrivateBucket', (this.formGroupStorageLinkDocs.value.isPrivateBucket !== null ? this.formGroupStorageLinkDocs.value.isPrivateBucket : false))

    this.uploadFileService.sendStorageLinkDocuments(formData).subscribe(
      resp => {
        if (resp.type === HttpEventType.Response) {
          if(resp.status === 200){
            this.formGroupStorageLinkDocs.value.link = '';
            this.sendLinkIsLoading = false;
            this.formGroupStorageLinkDocs.reset();
            this.formGroupStorageLinkDocs.controls.lang.setValue(this.itaLang);
            this.showSuccessToastDoc();
          }else{
            this.showErroToast('general.serviceError');
          }
        }
      },
      err => {
        this.sendLinkIsLoading = false;
        switch (err.status) {
          case 403: {
            this.showErroToast('index-docs.noPublicBucket');
            break;
          }
          default: {
            this.showErroToast('general.serviceError');
          }
        }
      })
  }

  private showSuccessToastDoc() {
    this.snackBarDocs.open(
      this.languageService.getLabel('index-file.operationStarted'),
      'OK',
      {
        duration: 15000,
        horizontalPosition: 'center'
      }
    );
  }
  private showErroToast(error) {
    this.snackBarDocs.open(
      this.languageService.getLabel(error),
      'OK',
      {
        duration: 10000,
        horizontalPosition: 'center'
      }
    );
  }
  showServiceAccountModal(formType) {
    const _this = this
    const serviceAccountDialog = this.dialog.open(ServiceAccountCodeModalComponent, {
      width: '500px'
    });
    serviceAccountDialog.afterClosed().subscribe(
      data => {
        formType.controls.serviceAccount.setValue(data)
      }
    );
  }

  onValueFormChange(){
    this.formGroupStorageLinkDocs.get('isPrivateBucket').valueChanges
    .subscribe(value => {
      if(value) {
        this.formGroupStorageLinkDocs.get('serviceAccount').setValidators(this.serviceAccountValidatorsFGStorageLink.concat(Validators.required))
      } else {
        this.formGroupStorageLinkDocs.get('serviceAccount').setValidators(this.serviceAccountValidatorsFGStorageLink)
      }
      this.formGroupStorageLinkDocs.get('serviceAccount').updateValueAndValidity();
      this.formGroupStorageLinkDocs.updateValueAndValidity();
    });
  }

  setIsPrivateBucket(event){
    this.formGroupStorageLinkDocs.controls['isPrivateBucket'].setValue(event)
  }
}

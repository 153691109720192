import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// Services
import { ClientService } from '../../_services/client.service';
import { LanguageService } from '../../_services/language.service';

// Models
import { FilterMeta } from '../../models/filter-meta';
import { FilterOrder } from '../../models/filter-order';
import { FilterType } from '../../models/filter-type';
import { Filter } from '../../models/filter';

// TODO: Se si ha la possibilità fare un refactor lato .ts di questa classe (tanto codice inutile)
@Component({
  selector: 'fury-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {
  currentIdClient: string;
  filterOrders: FilterOrder[];
  filterTypes: FilterType[];
  filterMeta: FilterMeta[];
  form: FormGroup;
  filteredMetas: Observable<FilterMeta[]>;

  curMeta: FilterMeta = {
    id: 0,
    label: ''
  };
  curType: FilterType = {
    id: 0,
    label: ''
  };
  curOrder: FilterOrder = {
    orderType: 'count',
    orderSort: 'asc'
  };

  curFilter: Filter = {
    id: 0,
    label: '',
    size: null,
    meta: this.curMeta,
    order: this.curOrder
  };

  orderTypes = ['count', 'valore'];
  orderSorts = ['asc', 'desc'];

  isEdit = false;

  constructor(
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FilterFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.currentIdClient = this.data.idclient;

    if (this.data.filter) {
      this.curFilter = this.data.filter;
      this.isEdit = true;
    }

    this.form = this.formBuilder.group({
      id: [this.curFilter.id],
      label: [this.curFilter.label, Validators.required],
      meta: [this.curFilter.meta.label, Validators.required],
      size: [this.curFilter.size, Validators.required],
      orderType: [this.curFilter.order.orderType, Validators.required],
      orderSort: [this.curFilter.order.orderSort, Validators.required]
    });



    this.clientService
      .getFiltersMetaList()
      .subscribe(res => {

        this.filterMeta = res;

        this.filteredMetas = this.form
          .get('meta')
          .valueChanges.pipe(
            startWith(''),
            map(value =>
              this.filterMeta.filter(meta =>
                meta.label.toLowerCase().indexOf(value.toLowerCase()) > -1
              )
            )
          );
      });

    this.clientService
      .getFiltersTypeList()
      .subscribe(res => (this.filterTypes = res));
  }

  submit() {
    const selectedMeta = this.getMeta(this.form.get('meta').value);
    // Controllo che nell'autocomplete sia stata scelta un'opzione presente in lista
    if (!(this.filterMeta.indexOf(selectedMeta) > -1)) {
      this.form.get('meta').setValue('');
      return;
    }
    this.dialogRef.close({
      id: this.form.value.id,
      label: this.form.value.label.trim(),
      size: this.form.value.size,
      meta: this.getMeta(this.form.value.meta),
      order: {
        orderType: this.form.value.orderType,
        orderSort: this.form.value.orderSort
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  getMeta(labelMeta: string): FilterMeta {
    return this.filterMeta.find(m => m.label === labelMeta);
  }

  getType(idType: number): FilterType {
    return this.filterTypes.find(m => m.id === idType);
  }

  getOrder(idOrder: string): FilterOrder {
    return this.filterOrders.find(m => m.orderType === idOrder);
  }
}

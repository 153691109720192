import { Component, OnInit, OnDestroy } from '@angular/core';

import { NavLink } from './../../models/nav-link';
import { LanguageService } from 'app/_services/language.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { PERMISSION_CODES } from 'app/constants/permission-codes';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigData } from '../../app.config';

@Component({
  selector: 'fury-gestione-crawling',
  templateUrl: 'gestione-crawling.component.html'
})
export class GestioneCrawlingComponent implements OnInit, OnDestroy {
  navLinks: NavLink[] = [];
  selectedRoute: string;
  private configData = ConfigData;
  private destroy$ = new Subject();

  constructor(public languageService: LanguageService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }

  private buildTabs(): void {
    this.navLinks = [];

    this.selectedRoute = this.router.url.split('/')[2] || null;

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.WEB)) {
      this.navLinks.push({
        path: 'web',
        label: this.languageService.getLabel('gestioneCrawling.Web'),
        disabled: false,
        visible: true
      });

      this.selectedRoute = this.selectedRoute || 'web';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.FEED_XML)) {
      this.navLinks.push({
        path: 'feed-xml',
        label: this.languageService.getLabel('gestioneCrawling.FeedXml'),
        disabled: false,
        visible: true
      });

      this.selectedRoute = this.selectedRoute || 'feed-xml';
    }

    console.log(this.configData);
    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.MONITORING_KENTICO) && this.configData.monitoringKentico) {
      this.navLinks.push({
        path: 'monitoring-kentico',
        label: this.languageService.getLabel('gestioneCrawling.monitoringKentico'),
        disabled: false,
        visible: true
      });

      this.selectedRoute = this.selectedRoute || 'feed-xml';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.MONITORING_JSON_INGESTER)) {
      this.navLinks.push({
        path: 'monitoring-json-ingester',
        label: this.languageService.getLabel('gestioneCrawlingJsonIngester.title'),
        disabled: false,
        visible: true
      });

      this.selectedRoute = this.selectedRoute || 'monitoring-json-ingester';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.OPERAZIONI)) {
      this.navLinks.push({
        path: 'operazioni',
        label: this.languageService.getLabel('gestioneCrawling.Operazioni'),
        disabled: false,
        visible: true
      });

      this.selectedRoute = this.selectedRoute || 'operazioni';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.LOGS)) {
      this.navLinks.push({
        path: 'logs',
        label: this.languageService.getLabel('gestioneCrawling.Logs'),
        disabled: false,
        visible: true
      });

      this.selectedRoute = this.selectedRoute || 'logs';
    }


    if (this.selectedRoute) {
      this.router.navigate(['gestione-crawling/' + this.selectedRoute]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

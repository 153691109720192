export class ServerInfo {
  serverName: string;
  cpuUser: number;
  cpuSystem: number;
  cpuIdle: number;
  cpuUsagePerc: number;
  swapTotal: number;
  swapAvail: number;
  ramTotal: number;
  ramFree: number;
  ramUsed: number;
  ramUsedPerc: number;
  diskTotal: number;
  diskUsed: number;
  diskFree: number;
  diskUsedPerc: number;
  hwStatus: number;
  serviceStatus: number;
  crawlerTotJobs: number;
  crawlerRunningJobs: number;
}

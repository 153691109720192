import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PERMISSION_CODES } from 'app/constants/permission-codes';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(public auth: AuthGuard, public router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser) {
      if (this.isAuthorized(state.url, currentUser)) {
        return true;
      } else {
        this.router.navigate(this.fallBackUrl(currentUser));
        return false;
      }
    }

    this.router.navigate(['/login']);
    return false;
  }

  fallbackMap = 
    {"cluster" : "/", 
    "frontend" : "/front-ends",
    "client" : "/clients",
    "index-alias" : "/indici-alias",
    "crawling" : "/gestione-crawling",
    "user" : "/user-role",
    "report" : "/reports/globalreports",
    "configuration" : "/configuration",
    "onebox" : "/onebox",
    "biasing" : "/policy-biasing",
    "query-settings" : "/dictionaries",
    "suggestion" : "/suggestion",
    "gsa-configuration-importer" : "/gsa-configuration-importer",
    "reports" : "/reports/reports-charts",
    "frontend-preview" : "/preview-center",
    "audio-index" : "/indexing-audio",
    "doc-index": "/indexing-docs",
    "video-index" : "/indexing-video",
    "image-index" : "/indexing-images"
    }


  fallBackUrl(currentUser: any): any[] {
    const permissions = (currentUser ? currentUser.res.permissions : []);
    const role = currentUser ? currentUser.res.role : '';
    const pass: boolean = (permissions.indexOf('all') >= 0 || role == 'superadmin');
    var fallbackUrlArray = [];
    Object.keys(this.fallbackMap).forEach((singleElem) => {
      if ((pass || permissions.indexOf('cluster') >= 0)) {
        fallbackUrlArray = ['/'];
      } else if (permissions.indexOf(singleElem) >= 0) {
        fallbackUrlArray[0] = (this.fallbackMap[singleElem]);
      }
    })
    if (fallbackUrlArray.length === 0) {
      fallbackUrlArray = ['/login'];
    }
    return fallbackUrlArray;
  }
  /*NOSONAR*/getPermission(urlPaths, permissions) {
    switch (urlPaths[0]) {
      case '':
        return permissions.indexOf(PERMISSION_CODES.STATO_MACCHINE) > -1;

      case 'front-ends':
        return permissions.indexOf(PERMISSION_CODES.FRONTEND) > -1;

      case 'preview-center':
        return permissions.indexOf(PERMISSION_CODES.CENTRO_PREVIEW) > -1;

      case 'clients':
        return permissions.indexOf(PERMISSION_CODES.CLIENT) > -1;

      case 'client-detail':
        if (urlPaths[2]) {
          return this.checkClientAuthorization(permissions, urlPaths[2]);
        }
        return permissions.indexOf(PERMISSION_CODES.CLIENT) > -1;

      case 'indici-alias':
        const indexAliasAuthorized = permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS) > -1;
        if (urlPaths[1]) {
          return indexAliasAuthorized && this.checkIndiciAliasAuthorization(permissions, urlPaths[1]);
        }
        return indexAliasAuthorized;

      case 'index-detail':
        return permissions.indexOf(PERMISSION_CODES.INDICI_META) > -1;

      case 'alias-detail':
        return permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS_TABS.ALIAS) > -1;

      case 'gestione-crawling':
        const gestioneCrawlingAuthorized = permissions.indexOf(PERMISSION_CODES.GESTIONE_CRAWLING) > -1;
        if (urlPaths[1]) {
          return gestioneCrawlingAuthorized && this.checkGestioneCrawlingAuthorization(permissions, urlPaths[1]);
        }
        return gestioneCrawlingAuthorized;

      case 'user-role':
        const gestioneUtenzeAuthorized = permissions.indexOf(PERMISSION_CODES.GESTIONE_UTENZE) > -1;
        if (urlPaths[1]) {
          return gestioneUtenzeAuthorized && this.checkGestioneUtenzeAuthorization(permissions, urlPaths[1]);
        }
        return gestioneUtenzeAuthorized;

      case 'configuration':
        const configurationAuthorized = permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE) > -1;
        if (urlPaths[1]) {
          return configurationAuthorized && this.checkConfigurationAuthorization(permissions, urlPaths[1]);
        }
        return configurationAuthorized;

      case 'suggestion':
        return permissions.indexOf(PERMISSION_CODES.SUGGESTION) > -1;

      case 'onebox':
        return permissions.indexOf(PERMISSION_CODES.ONEBOX) > -1;

      case 'policy-biasing':
      case 'policy-detail':
        return permissions.indexOf(PERMISSION_CODES.DIFFERENZIAZIONE_RISULTATI) > -1;

      case 'gsa-configuration-importer':
        const gsaAuthorized = permissions.indexOf(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER) > -1;
        if (urlPaths[1]) {
          return gsaAuthorized && this.checkGsaAuthorization(permissions, urlPaths[1]);
        }
        return gsaAuthorized;
     /*  case 'reports':
        return permissions.indexOf(PERMISSION_CODES.REPORT) > -1;  */

        case 'reports':
          const reportsAuthoized = permissions.indexOf(PERMISSION_CODES.REPORT) > -1;
          if (urlPaths[1]) {
            return reportsAuthoized && this.checkReportsAuthorization(permissions, urlPaths[1]);
          }
          return reportsAuthoized;

      case 'dictionaries':
        const dictionariesAuthorized = permissions.indexOf(PERMISSION_CODES.IMPOSTAZIONI_QUERY) > -1;
        if (urlPaths[1]) {
          return dictionariesAuthorized && this.checkDictionariesAuthorization(permissions, urlPaths[1]);
        }
        return dictionariesAuthorized;

      case 'indexing-audio':
        return permissions.indexOf(PERMISSION_CODES.AUDIO_INDEX) > -1;

      case 'indexing-docs':
        return permissions.indexOf(PERMISSION_CODES.DOCS_INDEX) > -1;

      case 'indexing-images':
        return permissions.indexOf(PERMISSION_CODES.IMAGES_INDEX) > -1;
    

      default:
        return false;
    }
  }
  isAuthorized(url, currentUser): boolean {
    const permissions: string[] = (currentUser ? currentUser.res.permissions : []);
    const role = currentUser ? currentUser.res.role : '';
    
    const pass: boolean = (permissions.indexOf('all') >= 0 || role == 'superadmin' || url === '/user-area');

    if (pass) {
      return true;
    }

    // Splitto la url per / (es url: "/indici-alias/indici") e rimuovo il primo elemento che è sempre "";
    const urlPaths: string[] = url.split('/');

    urlPaths.splice(0, 1);
    return this.getPermission(urlPaths, permissions);

  }

  private checkClientAuthorization(permissions: string[], path: string): boolean {
    if (!path) {
      return false;
    }

    switch (path) {
      case 'filters':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.NAVIGAZIONE_DINAMICA) > -1;
      case 'keymatch':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.KEYMATCH) > -1;
      case 'onebox':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.ONEBOX) > -1;
      case 'related-query':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.QUERY_CORRELATE) > -1;
      case 'exclude-url':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.ESCLUDI_URL) > -1;
      case 'template-elastic':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.ASSOCIAZIONE_TEMPLATE_ELASTIC) > -1;
      case 'search-diff':
        return permissions.indexOf(PERMISSION_CODES.CLIENT_TABS.POLICY_DIFF_RISULTATI) > -1;
      default:
        return false;
    }
  }

  private checkIndiciAliasAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'indici':
        return permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS_TABS.INDICI) > -1;
      case 'alias':
        return permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS_TABS.ALIAS) > -1;
      case 'cancellazione-documenti':
        return permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS_TABS.CANCELLAZIONE_DOCUMENTI) > -1;
      case 'diagnostics':
        return permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS_TABS.DIAGNOSTICA) > -1;
      case 'meta':
        return permissions.indexOf(PERMISSION_CODES.INDICI_ALIAS_TABS.META) > -1;
      default:
        return false;
    }
  }
  private checkReportsAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'globalreports':
        return permissions.indexOf(PERMISSION_CODES.REPORT_TABS.REPORTS) > -1;
      case 'reports-history':
        return permissions.indexOf(PERMISSION_CODES.REPORT_TABS.HISTORY_REPORT) > -1;
      case 'reports-charts':
        return permissions.indexOf(PERMISSION_CODES.REPORT_TABS.REPORT_QUERY) > -1;
      case 'reports-charts-action':
        return permissions.indexOf(PERMISSION_CODES.REPORT_TABS.REPORT_CLICK) > -1;
      default:
        return false;
    }
  }

  private checkGestioneCrawlingAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'web':
        return permissions.indexOf(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.WEB) > -1;
      case 'feed-xml':
        return permissions.indexOf(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.FEED_XML) > -1;
      case 'operazioni':
        return permissions.indexOf(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.OPERAZIONI) > -1;
      case 'logs':
        return permissions.indexOf(PERMISSION_CODES.GESTIONE_CRAWLING_TABS.LOGS) > -1;
      default:
        return false;
    }
  }

  private checkGestioneUtenzeAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'user':
        return permissions.indexOf(PERMISSION_CODES.GESTIONE_UTENTI_TABS.UTENTI) > -1;
      case 'groups':
        return permissions.indexOf(PERMISSION_CODES.GESTIONE_UTENTI_TABS.GRUPPI) > -1;
      default:
        return false;
    }
  }

  private checkConfigurationAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'sysprops':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.PROPRIETA_SISTEMA) > -1;
      case 'tempsearch':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.TEMPLATE_SEARCH) > -1;
      case 'inclfree':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.INCLUDE_FREEMARKER) > -1;
      case 'inclxlst':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.INCLUDE_XLST) > -1;
      case 'language':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.LINGUA) > -1;
      case 'speech-to-text':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.SPEECH_TO_TEXT) > -1;
      case 'system-email':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.SYSTEM_EMAIL) > -1;
      case 'audio-index':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.AUDIO_INDEX) > -1;
      case 'images-index':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.IMAGES_INDEX) > -1;
      case 'video-index':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.VIDEO_INDEX) > -1;
      case 'geo-position':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.GEO) > -1;   
      case 'chronology':
        return permissions.indexOf(PERMISSION_CODES.CONFIGURAZIONE_TABS.CHRONOLOGY) > -1;   
      default:
        return false;
    }
  }

  private checkGsaAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'keymatch':
        return permissions.indexOf(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER_TABS.KEYMATCH) > -1;
      case 'xslt':
        return permissions.indexOf(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER_TABS.XSLT) > -1;
      case 'related-queries':
        return permissions.indexOf(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER_TABS.QUERY_CORRELATE) > -1;
      default:
        return false;
    }
  }

  private checkDictionariesAuthorization(permissions: string[], path: string): boolean {
    switch (path) {
      case 'sinonimi':
        return permissions.indexOf(PERMISSION_CODES.IMPOSTAZIONI_QUERY_TABS.SINONIMI) > -1;
      case 'stopwords':
        return permissions.indexOf(PERMISSION_CODES.IMPOSTAZIONI_QUERY_TABS.STOPWORDS) > -1;
      case 'blacklist':
        return permissions.indexOf(PERMISSION_CODES.IMPOSTAZIONI_QUERY_TABS.BLACKLIST) > -1;
      default:
        return false;
    }
  }
}

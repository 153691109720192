import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LanguageService } from "app/_services/language.service";

@Component({
  selector: "fury-client-related-query-form",
  templateUrl: "client-related-query-form.component.html",
  styleUrls: ["client-related-query-form.component.scss"]
})
export class ClientRelatedQueryFormComponent implements OnInit {
  form: FormGroup;

  currentQuery = {
    keyword: "",
    relatedQuery: "",
    idQuery: "",
    idClient: ""
  };

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientRelatedQueryFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    if (this.data != null) {
      this.currentQuery = this.data.query;
    }

    this.form = this.formBuilder.group({
      keyword: [this.currentQuery.keyword, Validators.required],
      relatedQuery: [this.currentQuery.relatedQuery, Validators.required]
    });
  }

  submit(form) {
    this.dialogRef.close({
      keyword: form.value.keyword ? form.value.keyword.trim() : null,
      relatedQuery: form.value.relatedQuery ? form.value.relatedQuery.trim() : null,
      idQuery: this.currentQuery.idQuery,
      idClient: this.currentQuery.idClient
    });
  }
}

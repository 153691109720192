import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AuthenticationService } from 'app/_services/authentication.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Input('quickpanel') quickpanel: MatSidenav;

  public viewNotifications: boolean;

  constructor(private authenticationService: AuthenticationService) {
    this.viewNotifications = true; //this.authenticationService.userHasPermission('notifications');
  }
}

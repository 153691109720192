import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavLink } from '../../../models/nav-link';
import { ConfigurationService } from 'app/_services/configuration.service';
import { finalize } from 'rxjs/operators';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { LanguageService } from 'app/_services/language.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'fury-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  navLinks: NavLink[];

  selectedRoute: string;

  loading = false;

  private destroy$ = new Subject();

  constructor(
    private configurationService: ConfigurationService,
    private confirmationDialogService: ConfirmationDialogService,
    public languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    //this is intentional
  }

  flushCache(): void {
    this.loading = true;
    this.configurationService
      .flushCache()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.confirmationDialogService
          .confirm(
            this.languageService.getLabel('configuration.MessaggioModaleCache'),
            this.languageService.getLabel('configuration.TitoloModaleCache'),
            this.languageService.getLabel('general.OK'),
            ''
          )
          .then(() => {
            // This is intentional
           });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UploadFileService } from 'app/_services/upload-file.service';
import { LoadingFileDialogComponent } from '../loading-file-dialog/loading-file-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HttpEventType } from '@angular/common/http';
import { LanguageService } from "app/_services/language.service";
import { ServiceAccountCodeModalComponent } from "app/n4s/service-account-code-modal/service-account-code-modal.component";
@Component({
  selector: 'fury-indexing-audio',
  templateUrl: './indexing-audio.component.html',
  styleUrls: ['./indexing-audio.component.scss']
})
export class IndexingAudioComponent implements OnInit {
  public uploadedFilesAudio: string[] = [];

  itaLang: string = 'it-IT';
  enLang: string = 'en-US';

  private serviceAccountValidatorsFGStorageLink = [];
  get formGroupStorageLinkLang() { return this.formGroupStorageLinkAudio.get('lang').value; }

  public formGroupStorageLinkAudio = this.fb.group({
    link: [null, Validators.required],
    lang: [this.itaLang],
    isTelephone: [false],
    isPrivateBucket: [false],
    serviceAccount: ['', this.serviceAccountValidatorsFGStorageLink]
  });

  public formGroupFiles = this.fb.group({
    file: ['', Validators.required],
    lang: [this.itaLang],
    isTelephone: [false]
  });



  @ViewChild("fileUploaderAudio")
  fileUpload: ElementRef;
  files = [];
  dialogRef;
  sendLinkIsLoading: boolean = false;

  constructor(private fb: FormBuilder,
    private uploadFileService: UploadFileService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.onValueFormChangeAudio();
  }

  get formGroupFilesLang() { return this.formGroupFiles.get('lang').value; }
  get formGroupStorageLinkAudioLang() { return this.formGroupStorageLinkAudio.get('lang').value; }

  public onFileChange(event) {
    const formData: any = new FormData();
    let filesNames: string[] = [];
    formData.append('lang', this.formGroupFiles.value.lang);
    formData.append('isTelephone', (this.formGroupFiles.value.lang === this.itaLang || this.formGroupFiles.value.isTelephone === null) ? false : this.formGroupFiles.value.isTelephone);
    if (event.target.files && event.target.files.length) {
      Array.from(event.target.files).forEach((file: File) => {
        formData.append('files', file);
        filesNames.push(file.name)
      });

      this.dialogRef = this.dialog.open(LoadingFileDialogComponent, { width: '380px', disableClose: true });
      this.dialogRef.componentInstance.percentage = 0;
      this.dialogRef.componentInstance.currentFile = filesNames[0];

      this.uploadFileService.uploadAudioFiles(formData).subscribe(

        eventResp => {
          switch (eventResp.type) {
            case HttpEventType.UploadProgress: {
              const percentDone = Math.round(100 * eventResp.loaded / eventResp.total);
              this.dialogRef.componentInstance.percentage = percentDone
              let currentFilesIndex = Math.round((filesNames.length * percentDone / 100))
              this.dialogRef.componentInstance.currentFile = filesNames[currentFilesIndex];
              break;
            }
            case HttpEventType.Response: {
              
              if (eventResp.status === 200) {
                this.showSuccessToastAudio();
                this.formGroupFiles.reset();
                this.formGroupFiles.controls.lang.setValue(this.itaLang);
              } else {
                this.showErroToast('general.serviceError');
              }
              this.dialog.closeAll();
              break;
            }
            default: {
              console.log(event);
            }
          }
        },
        err => {
          this.showErroToast('general.serviceError');
          this.dialog.closeAll();
          console.error(err)
        },
      )
    }
  }

  public onSubmitStorageLinkAudio() {
    const formData = new FormData();
    this.sendLinkIsLoading = true;
    formData.append('externalUrl', this.formGroupStorageLinkAudio.value.link.trim());
    formData.append('lang', this.formGroupStorageLinkAudio.value.lang);
    formData.append('serviceAccount', this.formGroupStorageLinkAudio.value.serviceAccount);
    formData.append('isPrivateBucket', (this.formGroupStorageLinkAudio.value.isPrivateBucket !== null ? this.formGroupStorageLinkAudio.value.isPrivateBucket : false))
    formData.append('isTelephone', (this.formGroupStorageLinkAudio.value.lang === this.itaLang || this.formGroupStorageLinkAudio.value.isTelephone === null) ? false : this.formGroupStorageLinkAudio.value.isTelephone);
    this.uploadFileService.sendStorageLink(formData).subscribe(
      resp => {
        if (resp.type === HttpEventType.Response) {
          if(resp.status === 200){
            this.formGroupStorageLinkAudio.value.link = '';
            this.sendLinkIsLoading = false;
            this.formGroupStorageLinkAudio.reset();
            this.formGroupStorageLinkAudio.controls.lang.setValue(this.itaLang);
            this.showSuccessToastAudio();
          }else{
            this.showErroToast('general.serviceError');
          }
        }
      },
      err => {
        this.sendLinkIsLoading = false;
        switch (err.status) {
          case 403: {
            this.showErroToast('index-audio.noPublicBucket');
            break;
          }
          default: {
            this.showErroToast('general.serviceError');
          }
        }
      })
  }

  private showSuccessToastAudio() {
    this.snackBar.open(
      this.languageService.getLabel('index-file.operationStarted'),
      'OK',
      {
        duration: 15000,
        horizontalPosition: 'center'
      }
    );
  }
  private showErroToast(error) {
    this.snackBar.open(
      this.languageService.getLabel(error),
      'OK',
      {
        duration: 10000,
        horizontalPosition: 'center'
      }
    );
  }
  showServiceAccountModal(formType) {
    const _this = this
    const serviceAccountDialog = this.dialog.open(ServiceAccountCodeModalComponent, {
      width: '500px'
    });
    serviceAccountDialog.afterClosed().subscribe(
      data => {
        formType.controls.serviceAccount.setValue(data)
      }
    );
  }

  onValueFormChangeAudio(){
    this.formGroupStorageLinkAudio.get('isPrivateBucket').valueChanges
    .subscribe(value => {
      if(value) {
        this.formGroupStorageLinkAudio.get('serviceAccount').setValidators(this.serviceAccountValidatorsFGStorageLink.concat(Validators.required))
      } else {
        this.formGroupStorageLinkAudio.get('serviceAccount').setValidators(this.serviceAccountValidatorsFGStorageLink)
      }
      this.formGroupStorageLinkAudio.get('serviceAccount').updateValueAndValidity();
      this.formGroupStorageLinkAudio.updateValueAndValidity();
    });
  }

  setIsPrivateBucket(event){
    this.formGroupStorageLinkAudio.controls['isPrivateBucket'].setValue(event)
  }
}

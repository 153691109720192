import { UserGroup } from './user-group';
export class UserData {
    username: string;
    password: string;
    email: string;
    function: any[];
    role: string;
    enabled: boolean;
    resetPassword: any;
    groups: UserGroup[];

    constructor() {
        this.groups = [];
    }
}

import { SystemEmailTemplate } from './../models/system-email-template';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ConfigData } from '../app.config';

import { SysProp } from 'app/models/sys-prop';
import { Type } from 'app/models';
import { SystemEmailConfiguration } from 'app/models/system-email-configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  configurationUrl: string = ConfigData.apiUrl + '/configuration';
  flushCacheUrl: string = ConfigData.apiUrl + '/flush-cache';

  constructor(private http: HttpClient) { }

  // Sys prop
  getProperties(): Observable<SysProp[]> {
    return this.http.get<SysProp[]>(this.configurationUrl + '/properties');
  }

  editProperty(property: SysProp): Observable<any> {
    const obj = {
      value: property.value
    };
    return this.http.put<SysProp>(
      `${this.configurationUrl}/properties/${property.id}`,
      obj
    );
  }

  // Include free
  getIncludeFreemarkers(): Observable<any> {
    return this.http.get<any>(`${this.configurationUrl}/freemarker-template`);
  }

  addIncludeFreemarker(template: any) {
    const obj = {
      name: template.name,
      content: template.content
    };
    return this.http.post<any>(
      `${this.configurationUrl}/freemarker-template`,
      obj
    );
  }

  editIncludeFreemarker(template: any): Observable<any> {
    const obj = {
      content: template.content
    };
    return this.http.put<any>(
      `${this.configurationUrl}/freemarker-template/${template.name}`,
      obj
    );
  }

  deleteIncludeFreemarker(template: any): Observable<any> {
    return this.http.delete<any>(
      `${this.configurationUrl}/freemarker-template/${template.name}`
    );
  }

  // Include xlst
  getIncludeXlsts(): Observable<any> {
    return this.http.get<any>(`${this.configurationUrl}/xsl-template`);
  }

  addIncludeXlst(template: any) {
    const obj = {
      name: template.name,
      content: template.content
    };
    return this.http.post<any>(`${this.configurationUrl}/xsl-template`, obj);
  }

  editIncludeXlst(template: any): Observable<any> {
    const obj = {
      content: template.content
    };
    return this.http.put<any>(
      `${this.configurationUrl}/xsl-template/${template.name}`,
      obj
    );
  }

  deleteIncludeXlst(template: any): Observable<any> {
    return this.http.delete<any>(
      `${this.configurationUrl}/xsl-template/${template.name}`
    );
  }

  // Search-template
  getSearchTemplates(): Observable<any> {
    return this.http.get<any>(`${this.configurationUrl}/search-template`);
  }

  getTemplateTypes(): Observable<any> {
    return this.http.get<any>(`${this.configurationUrl}/search-template-types`);
  }

  addSearchTemplate(template: any) {
    const obj = {
      descrizione: template.descrizione,
      template: template.template,
      idType: template.type.id
    };

    return this.http.post<any>(`${this.configurationUrl}/search-template`, obj);
  }

  editSearchTemplate(template: any): Observable<any> {
    const obj = {
      descrizione: template.descrizione,
      template: template.template,
      idType: template.type.id
    };
    return this.http.put<any>(
      `${this.configurationUrl}/search-template/${template.id}`,
      obj
    );
  }

  deleteSearchTemplate(template: any): Observable<any> {
    return this.http.delete<any>(
      `${this.configurationUrl}/search-template/${template.id}`
    );
  }

  // Flush cache
  flushCache(): Observable<any> {
    return this.http.get<any>(`${this.configurationUrl}/flush-cache`);
  }

  getLanguagesList(): Observable<Type[]> {
    return this.http.get<Type[]>(
      `${this.configurationUrl}/language-settings/languages-list`
    );
  }

  getLanguageConfiguration(): Observable<any> {
    return this.http.get<any>(`${this.configurationUrl}/language-settings`);
  }

  saveLanguageConfiguration(configuration: {
    autodetect: boolean;
    defaultLang: string;
  }): Observable<any> {
    return this.http.put<any>(
      `${this.configurationUrl}/language-settings`,
      configuration
    );
  }

  // EMAIL DI SISTEMA
  getEmailSendConfigurations(): Observable<SystemEmailConfiguration[]> {
    return this.http.get<SystemEmailConfiguration[]>(`${this.configurationUrl}/email-properties`);
  }

  saveEmailSendConfigurations(configurations: SystemEmailConfiguration[]): Observable<any> {
    return this.http.put<SystemEmailConfiguration[]>(`${this.configurationUrl}/email-properties`, configurations);
  }

  getEmailTemplates(): Observable<SystemEmailTemplate[]> {
    return this.http.get<SystemEmailTemplate[]>(`${this.configurationUrl}/email-template`);
  }

  createEmailTemplate(emailTemplate: SystemEmailTemplate): Observable<SystemEmailTemplate[]> {
    return this.http.post<SystemEmailTemplate[]>(`${this.configurationUrl}/email-template`, emailTemplate);
  }

  updateEmailTemplate(emailTemplate: SystemEmailTemplate): Observable<SystemEmailTemplate[]> {
    return this.http.put<SystemEmailTemplate[]>(`${this.configurationUrl}/email-template`, emailTemplate);
  }

  deleteEmailTemplate(emailTemplateID: number): Observable<SystemEmailTemplate[]> {
    return this.http.delete<SystemEmailTemplate[]>(`${this.configurationUrl}/email-template/${emailTemplateID}`);
  }
}

import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatTable,
  MatTableDataSource,
  MatSort,
  Sort,
  MatPaginator,
  MatSnackBar
} from '@angular/material';
import { FrontEnd } from '../../models/front-end';
import { FrontEndService } from '../../_services/front-end.service';
import { FrontEndFormComponent } from '../front-end-form/front-end-form.component';
import { finalize } from '../../../../node_modules/rxjs/operators';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { LanguageService } from 'app/_services/language.service';
import { Type } from 'app/models';
import { AuthenticationService } from 'app/_services/authentication.service';

@Component({
  selector: 'fury-front-ends',
  templateUrl: './front-ends.component.html',
  styleUrls: ['./front-ends.component.scss']
})
export class FrontEndsComponent implements OnInit {
  @ViewChild('feTable') feTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loading = false;

  frontEndFormRef: MatDialogRef<FrontEndFormComponent>;
  displayedColumns: string[] = ['idfrontend', 'description', 'type', 'langfrontend', 'star'];
  connectionOk = true;
  currentFrontEnd: FrontEnd = {
    idfrontend: '',
    type: '',
    lang: '',
    description: '',
    output_mime_type: '',
    content: ''
  };

  dataSource: MatTableDataSource<FrontEnd>;
  languages: Type[] = [
    { id: null, label: this.languageService.getLabel('general.Tutte') },
  ];
  selectedLanguage = '';

  isAdmin: boolean;

  constructor(
    private frontEndService: FrontEndService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.isAdmin = this.authenticationService.userIsAdmin();

    this.frontEndService.getLanguagesList().subscribe(resp => {
      this.languages = [...this.languages, ...resp];
      this.getFrontends();
    });

  }

  sortFrontEnds(a: FrontEnd, b: FrontEnd) {
    return a.idfrontend.toLowerCase() > b.idfrontend.toLowerCase() ? 1 : -1;
  }

  getFrontends(selectedLanguage?: string): void {
    this.selectedLanguage = selectedLanguage;
    this.loading = true;
    this.frontEndService
      .getFrontEnds(this.selectedLanguage)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        frontends => {
          this.dataSource = new MatTableDataSource(
            frontends.sort(this.sortFrontEnds)
          );
          this.dataSource.sort = this.sort;
          this.connectionOk = true;
          this.dataSource.paginator = this.paginator;
        },
        () => {
          alert('Impossibile collegarsi al server.');
          this.connectionOk = false;
        }
      );
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  sortData(sort: Sort) {
    this.dataSource.sort = this.sort;
  }

  addFrontEnd() {
    this.frontEndFormRef = this.dialog.open(FrontEndFormComponent, {
      width: '800px',
      data: {
        language: this.selectedLanguage
      }
    });

    this.frontEndFormRef.afterClosed().subscribe(result => {
      if (
        result !== undefined &&
        result.idfrontend !== '' &&
        result.type !== '' &&
        result.idfrontend !== undefined
      ) {
        if (this.connectionOk) {
          this.frontEndService.saveFrontEnd(result).subscribe(() => {
            this.showSuccessAndGetFrontends();
          });
        }
      }
    });
  }

  showSuccessAndGetFrontends(){
    this.snackBar.open(
      this.languageService.getLabel('general.OperationSuccess'),
      'OK',
      {
        duration: 10000,
        horizontalPosition: 'center'
      }
    );
    this.getFrontends(this.selectedLanguage);
  }

  editFrontEnd(frontEnd: FrontEnd) {
    this.currentFrontEnd = frontEnd;

    this.frontEndFormRef = this.dialog.open(FrontEndFormComponent, {
      width: '800px',
      data: {
        idfrontend: frontEnd.idfrontend,
        language: frontEnd.lang
      }
    });

    this.frontEndFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateFrontend(result);
      }
    });
  }

  deleteFrontEnd(frontEnd: FrontEnd) {
    this.confirmationDialog
      .confirm(`Eliminare il frontend ${frontEnd.idfrontend}?`)
      .then(dialogResult => {
        if (dialogResult) {
          this.frontEndService
            .deleteFrontEnd(frontEnd, frontEnd.lang)
            .subscribe(
              () => {
                this.showSuccessAndGetFrontends();
              },
              () => this.getFrontends(this.selectedLanguage)
            );
        }
      });
  }

  private updateFrontend(frontend: FrontEnd): void {
    this.frontEndService
      .editFrontEnd(frontend, frontend.lang)
      .subscribe(resp => {
        this.showSuccessAndGetFrontends()
      });
  }
}

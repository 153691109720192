import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { AlertService } from "../_services/alert.service";
import { LanguageService } from "app/_services/language.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private languageService: LanguageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jtoken = JSON.parse(localStorage.getItem("currentToken"));

    if (jtoken) {
      request = request.clone({
        setHeaders: {
          "X-Auth": jtoken.token,
        },
      });
    }

    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        // This is intentional
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              return this.handle401Errors();
            case 404:
            case 406:
            case 409:
              return this.handle40XErrors(err);
            default:
              this.alertService.subj_notification.next(
                this.languageService.getLabel("general.serviceError")
              );
          }
        }
      }
    );
  }

  /**
   * Gestione errori con codice 401 Unauthorized.
   * Si mostra la notifica di sessione scaduta e si naviga verso la login.
   */
  private handle401Errors(): void {
    this.alertService.subj_notification.next(
      this.languageService.getLabel("error.sessione_scaduta")
    );
    this.router.navigate(["/login"]);
  }

  /**
   * Quando si verifica un errore 409 (Conflitto su chiave primaria già presente)
   * il servizio restituisce una pagina HTML generata da Tomcat. Si deve quindi eseguire
   * i necessari split sulla stringa ed estrarre il codice dell'errore su cui è mappata
   * l'opportuna label.
   */
  private handle40XErrors(error: HttpErrorResponse): void {
    // il messaggio di errore è nella porzione della stringa che sta dentro
    // <p><b>Message</b> CODICE_ERRORE </p>

    // splitto per <b>Message</b>
    const messageSplit = error.error.split("<b>Message</b>");

    // prendo la parte successiva e risplitto per </p>
    const errorCodeSplit =
      messageSplit && messageSplit[1] ? messageSplit[1].split("</p>") : [];

    const errorCode = errorCodeSplit[0] ? errorCodeSplit[0].trim() : null;

    if (errorCode) {
      this.alertService.subj_notification.next(
        this.languageService.getLabel("error." + errorCode)
      );
    } else {
      this.alertService.subj_notification.next(
        this.languageService.getLabel("error.service_unreachable")
      );
    }
  }
}

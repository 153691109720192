import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceParameters'
})

export class ReplaceParametersPipe implements PipeTransform {
    transform(originalString: string, ...parameters: string[]): string {

        let modifiedString = `${originalString}`;

        parameters.forEach((par, index) => {
            modifiedString = modifiedString.replace('{' + index + '}', par);
        });

        return modifiedString;
    }
}
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatTable,
  MatTableDataSource
} from '@angular/material';

import { LanguageService } from '../../_services/language.service';

import { Diagnostic } from '../../models/diagnostic/diagnostic';

interface Metadata {
  nome: string;
  contenuto: string;
}

@Component({
  selector: 'fury-diagnostic-detail',
  templateUrl: './diagnostic-detail.component.html',
  styleUrls: ['./diagnostic-detail.component.scss']
})
export class DiagnosticDetailComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<any>;
  dataSource: MatTableDataSource<Metadata>;
  displayedColumns = ['nome', 'contenuto'];
  metaPanelStatus: boolean;
  contentPanelStatus: boolean;
  metadataArray: Metadata[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Diagnostic,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.objectToArray();
  }

  objectToArray(): void {
    for (const key in this.data.metatag) {
      if (this.data.metatag.hasOwnProperty(key)) {
        const value = this.data.metatag[key];
        this.metadataArray.push({
          nome: key,
          contenuto: value
        });
      }
    }
    this.dataSource = new MatTableDataSource(this.metadataArray);
  }
}

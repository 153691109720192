import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatSort, MatPaginator, MatDialog, MatSnackBar, MatTableDataSource, MatDialogRef, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { IndexAliasService } from 'app/_services/index-alias.service';
import { LanguageService } from 'app/_services/language.service';
import { Meta } from 'app/models';
import { IndiciMetaFormComponent } from '../indici-meta-form/indici-meta-form.component';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';

@Component({
    selector: 'fury-indici-meta',
    templateUrl: 'indici-meta.component.html',
    styleUrls: ['indici-meta.component.scss']
})

export class IndiciMetaComponent implements OnInit {
    @ViewChild('ixTable') ixTable: MatTable<any>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    loading: boolean;
    filterText: string;

    constructor(
        private router: Router,
        private indexService: IndexAliasService,
        private dialog: MatDialog,
        public languageService: LanguageService,
        private snackBar: MatSnackBar,
        private confirmationDialog: ConfirmationDialogService,
    ) { }

    displayedColumns: string[] = ['metaField', 'metaType', 'star'];
    dataSource: MatTableDataSource<Meta>;

    metaFormRef: MatDialogRef<IndiciMetaFormComponent>;

    ngOnInit() {

        this.getIndexMappings();
    }

    getIndexMappings(): void {
        this.indexService.getMetaList().subscribe(index => {
            this.dataSource = new MatTableDataSource(index);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    applyFilter(): void {
        this.dataSource.filter = this.filterText.trim().toLowerCase();
    }

    sortData(sort: Sort) {
        this.dataSource.sort = this.sort;
    }

    addMeta() {
        this.metaFormRef = this.dialog.open(IndiciMetaFormComponent, {
            width: '500px'
        });
        this.metaFormRef.afterClosed().subscribe(result => {
            if (result) {
                this.indexService
                    .createMeta(result)
                    .subscribe(mapping => {
                        this.snackBar.open(
                            this.languageService.getLabel('general.OperationSuccess'),
                            'OK',
                            {
                                duration: 10000,
                                horizontalPosition: 'center'
                            }
                        );

                        if (result.type.label === 'date') {
                            this.confirmationDialog.confirm(
                                this.languageService.getLabel('indici-meta.NecessariaReindicizzazione'),
                                '',
                                'OK',
                                null,
                                'sm',
                                null,
                                false).then(resp => {
                                    this.getIndexMappings();
                                });
                            return;
                        }

                        this.getIndexMappings();
                    });
            }
        });
    }

    editMeta(meta: Meta) {
        this.metaFormRef = this.dialog.open(IndiciMetaFormComponent, {
            width: '500px',
            data: { meta }
        });
        this.metaFormRef.afterClosed().subscribe(result => {
            if (result) {
                this.indexService
                    .updateMeta(result)
                    .subscribe(metaResponse => {
                        this.snackBar.open(
                            this.languageService.getLabel('general.OperationSuccess'),
                            'OK',
                            {
                                duration: 10000,
                                horizontalPosition: 'center'
                            }
                        );

                        if (metaResponse.type.label === 'date') {
                            this.confirmationDialog.confirm(
                                this.languageService.getLabel('indici-meta.NecessariaReindicizzazione'),
                                '',
                                'OK',
                                null,
                                'sm',
                                null,
                                false).then(resp => {
                                    this.getIndexMappings();
                                });
                            return;
                        }


                        this.getIndexMappings();
                    });
            }
        });
    }


    deleteMeta(meta: Meta) {
        this.confirmationDialog
            .confirm(this.languageService.getLabel('indici-meta.EliminazioneMeta').replace('{metaField}', meta.field))
            .then(result => {
                if (result) {
                    this.indexService.
                        deleteMeta(meta)
                        .subscribe(() => {
                            if (meta.type.label === 'date') {
                                this.confirmationDialog.confirm(
                                    this.languageService.getLabel('indici-meta.NecessariaReindicizzazione'),
                                    '',
                                    'OK',
                                    null,
                                    'sm',
                                    null,
                                    false).then(resp => {
                                        this.getIndexMappings();
                                    });
                                return;
                            }
                            this.getIndexMappings();
                        });
                }
            });
    }

    back(): void {
        this.router.navigate(['indici-alias', 'indici']);
    }
}
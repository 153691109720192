import { CrawlingLogsFilters } from './../../models/crawling-logs-filters.model';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
  MatTable,
  MatSort,
  MatPaginator,
  MatTableDataSource,

} from '@angular/material';
import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';
import { LanguageService } from 'app/_services/language.service';
import { CrawlingLog } from 'app/models/crawling-log.model';

import * as moment from 'moment';

@Component({
  selector: 'fury-gestione-crawling-logs',
  templateUrl: './gestione-crawling-logs.component.html',
  styleUrls: ['./gestione-crawling-logs.component.scss']
})
export class GestioneCrawlingLogsComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loading = false;
  logsParams: CrawlingLogsFilters = new CrawlingLogsFilters();

  displayedColumns: string[] = [
    'date',
    'logger',
    'level',
    'message'
  ];
  dataSource: MatTableDataSource<CrawlingLog>;

  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;

  constructor(
    private gestioneCrawlingService: GestioneCrawlingService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.getLogs();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  private getLogs(): void {
    this.loading = true;
    this.gestioneCrawlingService
      .getCrawlingLogs()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        feed => {
          this.updateData(feed);
        }
      );
  }


  filtraDati(): void {
    this.loading = true;
    // Controlllo che la data inizio sia valorizzata se lo è la data di fine
    if (
      !this.logsParams.end &&
      !!this.logsParams.start
    ) {
      return;
    }

    this.gestioneCrawlingService
      .getFilteredCrawlingLogs(this.logsParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        feed => {
          this.updateData(feed);
        }
      );
  }

  updateData(feed){
    this.dataSource = new MatTableDataSource(feed);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onChangeStartDate(): void {
    this.logsParams.start = moment(this.startDate).format('DD/MM/YYYY');
    if (this.startTime) {
      this.logsParams.start += ` ${this.startTime}:00`;
    } else {
      this.logsParams.start += ` 00:00:00`;
    }
  }

  onChangeEndDate(): void {
    this.logsParams.end = moment(this.endDate).format('DD/MM/YYYY');
    if (this.endTime) {
      this.logsParams.end += ` ${this.endTime}:00`;
    } else {
      this.logsParams.end += ` 00:00:00`;
    }
  }

  filterData(): void {
    this.gestioneCrawlingService.getFilteredCrawlingLogs(this.logsParams).subscribe(resp => {
      this.dataSource = new MatTableDataSource(resp);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatTable,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { finalize, tap } from '../../../../../node_modules/rxjs/operators';

import { ConfigurationService } from '../../../_services/configuration.service';
import { ConfirmationDialogService } from '../../../_services/confirmation-dialog.service';

import { TemplateSearchFormComponent } from '../template-search-form/template-search-form.component';
import { LanguageService } from 'app/_services/language.service';
import { ClientService } from 'app/_services/client.service';
import { NotificationsService } from 'app/_services/notifications.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fury-template-search',
  templateUrl: './template-search.component.html',
  styleUrls: ['./template-search.component.scss']
})
export class TemplateSearchComponent implements OnInit {
  @ViewChild('templateTable') templateTable: MatTable<any>;

  loading = false;
  displayedColumns: string[] = ['descrizione', 'default', 'star'];
  dataSource: MatTableDataSource<any>;
  templateSearchFormRef: MatDialogRef<TemplateSearchFormComponent>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configurationService: ConfigurationService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.getTemplates();
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  getTemplates(): void {
    this.loading = true;

    this.configurationService
      .getSearchTemplates()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        templates => {
          this.dataSource = new MatTableDataSource(templates);
        },
        error => {
          alert(
            `${this.languageService.getLabel('general.NoServerConnection')}: ${
            error.status
            }`
          );
        }
      );
  }

  addTemplate() {
    this.templateSearchFormRef = this.dialog.open(TemplateSearchFormComponent, {
      width: '800px'
    });

    this.templateSearchFormRef.afterClosed().subscribe(result => {
      this.document.body.classList.remove('modal-is-full');
      if (result && result.type) {
        this.configurationService
          .addSearchTemplate(result)
          .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
          .subscribe(() => this.getTemplates());
      }
    });
  }

  editTemplate(template,event) {
    if(!event.target.classList.contains('checkmark')){
      this.templateSearchFormRef = this.dialog.open(TemplateSearchFormComponent, {
        width: '800px',
        data: { template: template }
      });
  
      this.templateSearchFormRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.configurationService.editSearchTemplate(result)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getTemplates());
        }
      });
    }
  }

  deleteTemplate(template) {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'configuration.TemplateSearch.ConfermiEliminazioneTemplate'
        )}?`
      )
      .then(dialogRes => {
        if (dialogRes) {
          this.configurationService.deleteSearchTemplate(template).subscribe(
            () => {
              this.notificationsService.showDefaultSuccessMessage();
              this.getTemplates();
            });
        }
      });
  }

  toggleDefault(template): void {
    this.clientService.setTemplateDefault(template.id)
      .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
      .subscribe(() => this.getTemplates());
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';

import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatTable,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';

import { ClientDetailComponent } from '../client-detail/client-detail.component';
import { ClientExcludeUrlFormComponent } from '../client-exclude-url-form/client-exclude-url-form.component';

import { ClientService } from './../../_services/client.service';
import { ConfirmationDialogService } from './../../_services/confirmation-dialog.service';

import { UrlExclusion } from '../../models/url-exclusion';
import { Client } from '../../models/client';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-exclude-url',
  templateUrl: './client-exclude-url.component.html',
  styleUrls: ['./client-exclude-url.component.scss']
})
export class ClientExcludeUrlComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<UrlExclusion>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<UrlExclusion>;

  loading = false;
  connectionBroken = false;

  displayedColumns: string[] = ['urlPattern', 'star'];

  excludeUrlFormRef: MatDialogRef<ClientExcludeUrlFormComponent, boolean>;
  public parentClient: Client;

  constructor(
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.parentClient = this.parentComponent.currentClient;
    this.getExclusions();
  }

  private getExclusions(): void {
    this.loading = true;
    this.clientService
      .getClientUrlExclusions(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        exclusions => {
          this.dataSource = new MatTableDataSource(exclusions);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (
            data: UrlExclusion,
            filter: string
          ) => {
            return (
              data.urlPattern
                .trim()
                .toLowerCase()
                .indexOf(filter.trim().toLowerCase()) !== -1
            );
          };
        },
        error => {
          this.connectionBroken = true;
        }
      );
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  addExclusion() {
    this.excludeUrlFormRef = this.dialog.open(ClientExcludeUrlFormComponent, {
      width: '500px',
      data: { idclient: this.parentClient.idclient }
    });
    this.excludeUrlFormRef.afterClosed().subscribe(result => {
      if (!!result && !this.connectionBroken) {
        this.notificationsService.showDefaultSuccessMessage();
        this.getExclusions();
      }
    });
  }

  editExclusion(exclusion: UrlExclusion) {
    this.excludeUrlFormRef = this.dialog.open(ClientExcludeUrlFormComponent, {
      width: '500px',
      data: { idclient: this.parentClient.idclient, urlExclusion: exclusion }
    });

    this.excludeUrlFormRef.afterClosed().subscribe(result => {
      if (result && !this.connectionBroken) {
        this.notificationsService.showDefaultSuccessMessage();
        this.getExclusions();
      }
    });
  }

  deleteExclusion(exclusion: UrlExclusion): void {
    this.confirmationDialog
      .confirm(
        `Confermare l'eliminazione dell'url selezionato?`,
        `Conferma eliminazione url`
      )
      .then(res => {
        if (res) {
          this.loading = true;
          this.clientService
            .deleteClientUrlExclusions(this.parentClient.idclient, exclusion)
            .pipe(finalize(() => (this.loading = false)),
              tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(response => this.getExclusions());
        }
      });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { LanguageService } from 'app/_services/language.service';

// Models
import { Client } from '../../models/client';
import { NavLink } from '../../models/nav-link';

// App config
import { ConfigData } from './../../app.config';
import { AuthenticationService } from 'app/_services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fury-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit, OnDestroy {
  navLinks: NavLink[] = [
  ];

  currentClient: Client = {
    idclient: '',
    description: ''
  };

  selectedRoute: string;

  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public languageService: LanguageService,
    private authenticationService: AuthenticationService
  ) { 
    // This is intentional
  }

  ngOnInit() {
    this.currentClient.idclient = this.route.snapshot.paramMap.get('id');
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }

  private permissionMap = {
    "client-dynamic-navigation" : {
      path: 'filters',
      label: this.languageService.getLabel('client-Details.NavigazioneDinamica'),
      visible: true,
      disabled: false,
      selectedRoute : 'filters',
      otherCondition : !ConfigData.cloudSearch
    },
    "client-keymatch" : {
      path: 'keymatch',
      label: this.languageService.getLabel('client-Details.Keymatch'),
      visible: true,
      disabled: false,
      selectedRoute : 'keymatch',
      otherCondition : true
    },
    "client-onebox" : {
      path: 'onebox',
      label: this.languageService.getLabel('client-Details.Onebox'),
      visible: true,
      disabled: false,
      selectedRoute : 'onebox',
      otherCondition : true
    },
    "client-related-query" : {
      path: 'related-query',
      label: this.languageService.getLabel('client-Details.QueryCorrelate'),
      visible: true,
      disabled: false,
      selectedRoute : 'related-query',
      otherCondition : true
    },
    "client-url-exclusion" : {
      path: 'exclude-url',
      label: this.languageService.getLabel('client-Details.EscludiURL'),
      visible: true,
      disabled: false,
      selectedRoute : 'exclude-url',
      otherCondition : true
    },
    "client-elastic-template" : {
      path: 'template-elastic',
      label: this.languageService.getLabel('client-Details.AssociazioneTemplate'),
      visible: true,
      disabled: false,
      selectedRoute : 'template-elastic',
      otherCondition : true
    },
    "client-biasing" : {
      path: 'search-diff',
      label: this.languageService.getLabel('client-Details.Policy'),
      visible: true,
      disabled: false,
      selectedRoute : "search-diff",
      otherCondition : true
    }
  }

  private buildTabs(): void {
    this.navLinks = [];
    this.selectedRoute = this.router.url.split('/')[3] || null;
   
    Object.keys(this.permissionMap).forEach((singleKey) => {
      if(this.authenticationService.userHasPermission(singleKey) && this.permissionMap[singleKey].otherCondition){
        this.navLinks.push({
          path: this.permissionMap[singleKey].path,
          label: this.permissionMap[singleKey].label,
          visible:  this.permissionMap[singleKey].visible,
          disabled: this.permissionMap[singleKey].disabled

        });
        this.selectedRoute = this.selectedRoute || this.permissionMap[singleKey].selectedRoute;
      }
    })
    if (this.selectedRoute) {
      this.router.navigate(['client-detail', this.currentClient.idclient, this.selectedRoute]);
    }
  }


  back() {
    this.router.navigate(['/clients']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

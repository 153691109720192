import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../_services/client.service';
import { UrlExclusion } from '../../models/url-exclusion';
import { finalize, tap } from 'rxjs/operators';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-exclude-url-form',
  templateUrl: './client-exclude-url-form.component.html',
  styleUrls: ['./client-exclude-url-form.component.scss']
})
export class ClientExcludeUrlFormComponent implements OnInit {
  form: FormGroup;
  formMode: number;
  elements = [];

  idclient: string;
  currentExclusion: UrlExclusion;

  public loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientExcludeUrlFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private clientService: ClientService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    if (!this.data) {
      this.dialogRef.close(false);
      return;
    }

    this.idclient = this.data.idclient;

    if (this.data.urlExclusion) {
      this.currentExclusion = this.data.urlExclusion;
    } else {
      this.currentExclusion = new UrlExclusion();
    }

    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      urlPattern: [this.currentExclusion.urlPattern, Validators.required]
    });
  }

  submit(form) {
    const saveExclusion = <UrlExclusion>{
      urlPattern: form.value.urlPattern.trim()
    };

    this.loading = true;

    if (!!this.currentExclusion.id) {
      this.clientService
        .updateClientUrlExclusion(
          this.idclient,
          this.currentExclusion.id,
          saveExclusion
        )
        .pipe(
          finalize(() => (this.loading = false)),
          tap(_ => this.notificationsService.showDefaultSuccessMessage()))
        .subscribe(resp =>
          this.dialogRef.close(true));
    } else {
      this.clientService
        .saveNewClientUrlExclusion(this.idclient, saveExclusion)
        .pipe(finalize(() => (this.loading = false)), tap(_ => this.notificationsService.showDefaultSuccessMessage()))
        .subscribe(resp => this.dialogRef.close(true));
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

declare var require: any
const appVersion = require('../../package.json').version;
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  version: appVersion
  /*
  googleMapsApiKey: '',
  backend: 'http://localhost:4200', // Put your backend here
  api_url: 'http://35.233.59.16/noovle4search-admin-rest-service/rest/auth',
  login_url: 'http://35.233.59.16/noovle4search-admin-rest-service/rest',
  */
};

import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";

import { LanguageService } from "../../_services/language.service";
import { GestioneCrawlingService } from "../../_services/gestione-crawling.service";
import { ConfirmationDialogService } from "app/_services/confirmation-dialog.service";

@Component({
  selector: "fury-gestione-crawling-operazioni-operazioni",
  templateUrl: "gestione-crawling-operazioni.component.html",
  styleUrls: ["gestione-crawling-operazioni.component.scss"],
})
export class GestioneCrawlingOperazioniComponent {
  public isIndexing = false;

  public documentToIndexUrl: string;
  public indexingResult: string;

  constructor(
    private gestioneCrawlingService: GestioneCrawlingService,
    private matSnackBar: MatSnackBar,
    public languageService: LanguageService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  stopCrawlingWeb(): void {
    this.confirmationDialogService
      .confirm(
        this.languageService.getLabel(
          "crawlingWebOperazioni.StopCrawlingWarning"
        )
      )
      .then((res) => {
        if (!res) {
          return;
        }
        this.gestioneCrawlingService.stopCrawlingWeb().subscribe((result) => {
          this.matSnackBar.open(
            this.languageService.getLabel(
              "crawlingWebOperazioni.StopCrawlingSuccess"
            ),
            "",
            {
              duration: 5000,
            }
          );
        });
      });
  }

  public indexDocument(): void {
    this.isIndexing = true;
    this.gestioneCrawlingService
      .indexSingleDocument(this.documentToIndexUrl)
      .subscribe((res) => {
        this.isIndexing = false;
        this.documentToIndexUrl = null;
        this.matSnackBar.open(this.languageService.getLabel('general.OperationSuccess'), "", {
          duration: 5000,
        });
        this.checkDocumentStatus();

      }, err => this.isIndexing = false);
  }

  public checkDocumentStatus(): void {
    this.gestioneCrawlingService
      .checkSingleDocument()
      .subscribe(res => this.indexingResult = res.runningStatus);
  }
}

import { Type } from './../../models/index';
import { Component, OnInit, Inject } from '@angular/core';
import { MetaBiasing } from 'app/models/meta-biasing';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { LanguageService } from 'app/_services/language.service';
import { IndexAliasService } from 'app/_services/index-alias.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'fury-alias-meta-form',
  templateUrl: './alias-meta-form.component.html'
})
export class AliasMetaFormComponent implements OnInit {

  public metaList: Type[] = [];

  public idMeta: number;
  public regexMeta: string;

  public form: FormGroup;
  public filterMeta: Type[];
  public filteredMetas: Observable<Type[]>;

  constructor(
    private indexAliasService: IndexAliasService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AliasMetaFormComponent>,
    public languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.getMetaList();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      idMeta: [
        this.idMeta,
        Validators.required
      ],
      text: [this.regexMeta]
    });
  }


  getMetaList(): void {
    this.indexAliasService.getMetaForAlias().subscribe(list => {
      if (list) {
        this.metaList = list;

        // Compongo i dati per l'autocomplete dei meta
        this.filterMeta = this.metaList;
        this.buildForm();
        this.setupAutocomplete();
      }
    });
  }

  private setupAutocomplete(): void {
    this.filteredMetas = this.form
      .get('idMeta')
      .valueChanges.pipe(
        startWith(''),
        map(val => <string>val),
        map(value =>
          this.filterMeta.filter(meta =>
            meta.label.toLowerCase().includes(value.toLowerCase())
          )
        )
      );

    this.form.get('idMeta').valueChanges.subscribe(value => {
      const metaSrc = this.filterMeta.find(meta => meta.label === value);
      this.idMeta = metaSrc ? +metaSrc.id : null;
    });
  }

  submit() {
    this.dialogRef.close({
      idMeta: this.idMeta,
      textRegex: this.form.get('text').value ? this.form.get('text').value.trim() : null,
    });
  }

}

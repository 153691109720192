import { UserGroup } from "./../../models/user-group";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTable,
  MatTableDataSource,
  MatDialog,
  MatSnackBar,
  MatTabGroup,
} from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { UserRoleService } from "./../../_services/user-role.service";
import { LanguageService } from "app/_services/language.service";

import { UserData } from "app/models/userData";

@Component({
  selector: "fury-user-form",
  templateUrl: "user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
})
export class UserFormComponent implements OnInit {
  form: FormGroup;

  @ViewChild("ixTable") ixTable: MatTable<UserGroup>;
  @ViewChild("tabs") tabs: MatTabGroup;

  dataSource: MatTableDataSource<UserGroup>;
  displayedColumns: string[] = ["groupName", "star"];

  currentUser: UserData;

  groupList: UserGroup[];

  userGroups: any[];

  /**
   * Flag che indica se l'utente appartiene ad un gruppo con privilegi di admin.
   * Se è così non può essere possibile scegliere altri gruppi.
   */
  includesAdminGroup: boolean;

  private isEdit: boolean;

  /**
   * Flag che indica se è stata svolta una modifica e salvata correttamente
   */
  public edited = false;

  private validPasswordRegexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-()[\]])[A-Za-z\d@$!%*?&()\-[\]]{8,}$/;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private userRoleService: UserRoleService,
    private dialog: MatDialog,
    public languageService: LanguageService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.userRoleService.getGroups().subscribe((response) => {
      if (!!response) {
        this.groupList = response;
        this.isEdit = this.data && !!this.data.user;

        if (this.isEdit) {
          this.currentUser = this.data.user;
          this.getUserDetails();
        } else {
          this.setNewUser();
        }
      }
    });
  }

  private setNewUser(): void {
    this.currentUser = new UserData();
    this.buildForm();
  }

  private getUserDetails(): void {
    this.userRoleService
      .getUserDetail(this.currentUser.username)
      .subscribe((resp) => {
        if (!!resp) {
          this.currentUser = resp;

          this.dataSource = new MatTableDataSource(this.currentUser.groups);

          this.includesAdminGroup = this.checkIfUserHasAdminGroups();

          this.buildForm();
        }
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      username: [
        {
          value: this.currentUser.username,
          disabled: !!this.currentUser.username,
        },
        Validators.required,
      ],
      email: [
        this.currentUser.email,
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [this.currentUser.password],
      selectedGroup: [],
    });

    this.form
      .get("selectedGroup")
      .valueChanges.subscribe((group: UserGroup) => {
        if (!group) {
          return;
        }

        if (this.includesAdminGroup) {
          this.snackBar.open(
            this.languageService.getLabel("userForm.GiaGruppoAdmin"),
            "OK",
            {
              duration: 5000,
              horizontalPosition: "center",
            }
          );
          this.form.get("selectedGroup").patchValue("");
          return;
        }

        this.assignGroup(group.groupName);
      });

    this.form.get("password").valueChanges.subscribe((value) => {
      if (!!value && !this.validPasswordRegexPattern.test(value)) {
        this.form.get("password").setErrors({ regex: true });
        return;
      }

      this.form.get("password").setErrors(null);
    });
  }

  submit(form) {
    const saveUser = <UserData>{
      email: form.value.email.trim(),
      password: form.value.password,
      role: "n4s-user",
      enabled: true,
      groups: this.currentUser.groups,
    };

    if (!!this.currentUser.username) {
      saveUser.username = this.currentUser.username;

      this.userRoleService
        .updateUser(this.currentUser.username, saveUser)
        .subscribe((resp) => {
          this.edited = true;

          this.snackBar.open(
            this.languageService.getLabel("general.OperationSuccess"),
            "OK",
            {
              duration: 10000,
              horizontalPosition: "center",
            }
          );

          this.getUserDetails();
        });
    } else {
      saveUser.username = form.value.username.trim();

      this.userRoleService.saveNewUser(saveUser).subscribe((resp) => {
        this.currentUser = resp;
        this.edited = true;

        this.snackBar.open(
          this.languageService.getLabel("general.OperationSuccess"),
          "OK",
          {
            duration: 10000,
            horizontalPosition: "center",
          }
        );

        this.getUserDetails();
        // Dopo aver creato l'utente vado subito alla tab di associazione del gruppo
        this.tabs.selectedIndex = 1;
      });
    }
  }

  assignGroup(groupName): void {
    this.userRoleService
      .addUserToGroup(this.currentUser.username, groupName)
      .subscribe((resp) => {
        this.snackBar.open(
          this.languageService.getLabel("userForm.AssociazioneEffettuata"),
          "OK",
          {
            duration: 10000,
            horizontalPosition: "center",
          }
        );
        this.getUserDetails();
      });
  }

  removeGroup(group: UserGroup): void {
    this.userRoleService
      .deleteUserGroup(this.currentUser.username, group.groupName)
      .subscribe((resp) => {
        this.snackBar.open(
          this.languageService.getLabel("userForm.DissociazioneEffettuata"),
          "OK",
          {
            duration: 10000,
            horizontalPosition: "center",
          }
        );
        this.getUserDetails();
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  /**
   * Controllo se nella lista di tutti i gruppi, quelli associati all'utente sono di tipo admin
   */
  private checkIfUserHasAdminGroups(): boolean {
    const userGroups = this.currentUser.groups.map((group) => group.groupName);

    return userGroups.some(
      (group) => !!this.groupList.find((g) => g.groupName === group && g.admin)
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Giorni } from 'app/models/giorni';
import { Schedulazione } from 'app/models/schedulazione';


@Component({
  selector: 'fury-crawling-web-schedulazione-form',
  templateUrl: 'crawling-web-schedulazione-form.component.html',
  styleUrls: ['crawling-web-schedulazione-form.component.scss']
})
export class CrawlingWebSchedulazioneFormComponent implements OnInit {
  
  giorniList: Giorni[] = [
    {label: 'Lunedì', value: 'LUNEDI'},
    {label: 'Martedì', value: 'MARTEDI'},
    {label: 'Mercoledì', value: 'MERCOLEDI'},
    {label: 'Giovedì', value: 'GIOVEDI'},
    {label: 'Venerdì', value: 'VENERDI'},
    {label: 'Sabato', value: 'SABATO'},
    {label: 'Domenica', value: 'DOMENICA'},
    {label: 'Tutti', value: 'TUTTI'},
  ];

  oreList: number[] = [];
  minutiList: number[] = [];

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CrawlingWebSchedulazioneFormComponent>
  ) { }

  ngOnInit() {
    for (let i = 1; i < 24; i++) {
      this.oreList.push(i);
    }

    for (let i = 0; i < 60; i++) {
      this.minutiList.push(i);
    }

    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      giorno: [null, Validators.required],
      ora: [null, Validators.required],
      minuto: [null, Validators.required],
      active: []
    });
  }

  submit(form) {
    const saveSchedule = <Schedulazione>{
      giorno: form.value.giorno,
      oraInizio: form.value.ora.toString(),
      minutoInizio: form.value.minuto.toString(),
      active: form.value.active || false
    };

    this.dialogRef.close(saveSchedule);
  }

  cancel() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { FeedKentico } from 'app/models/feed-kentico';
import { FeedKenticoFilters } from 'app/models/feed-kentico-filters';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';
import { LanguageService } from 'app/_services/language.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fury-gestione-monitoraggio-kentico',
  templateUrl: './gestione-monitoraggio-kentico.component.html',
  styleUrls: ['./gestione-monitoraggio-kentico.component.scss']
})
export class GestioneMonitoraggioKenticoComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loading = false;
  connectionBroken = false;
  feedParams: FeedKenticoFilters = new FeedKenticoFilters();

  displayedColumns: string[] = [
    'projectId',
    'documentId',
    'status',
    'webhookDate',
    'message',
    'operation'

  ];
  dataSource: MatTableDataSource<FeedKentico>;
  changeDetectorRef: any;

  constructor(private gestioneCrawlingService : GestioneCrawlingService,
              private confirmationDialogService: ConfirmationDialogService,
              public languageService: LanguageService


    ) { }

  ngOnInit() {
    this.getFeedKentico();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  private getFeedKentico(){
      this.loading = true;
      this.gestioneCrawlingService
        .getFeedKentico()
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(
          feed => {
            this.updateFeed(feed);
          },
          error => {
            this.connectionBroken = true;
          }
        );
  }


  filtraDati(): void {
    this.loading = true;
    // Controlllo che la data inizio sia valorizzata se lo è la data di fine
    if (
      !this.feedParams.end &&
      !!this.feedParams.start
    ) {
      this.confirmationDialogService.confirm(
        this.languageService.getLabel('gestioneCrawlingKentico.InsertStartDate'),
        this.languageService.getLabel('general.Attenzione'),
        'OK',
        ''
      );
      this.loading = false;
      return;
    }

    this.gestioneCrawlingService
      .getFeedKenticoFiltered(this.feedParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        feed => {
          this.updateFeed(feed);
        },
        () => {
          this.connectionBroken = true;
        }
      );
  }
  updateFeed(feed){
    this.dataSource = new MatTableDataSource(feed);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.changeDetectorRef.detectChanges();
  }

  onChangeDay(): void {
    this.feedParams.start = null;
    this.feedParams.end = null;
  }

  onChangeDate(): void {
    this.feedParams.day = null;
  }

}

import {Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { FeedJsoningester } from 'app/models/feed-json-ingester';
import { FeedJsoningesterFilters } from 'app/models/feed-json-ingester-filters';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { GestioneCrawlingService } from 'app/_services/gestione-crawling.service';
import { LanguageService } from 'app/_services/language.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fury-gestione-crawling-json-ingester',
  templateUrl: './gestione-crawling-json-ingester.component.html',
  styleUrls: ['./gestione-crawling-json-ingester.component.scss']
})
export class GestioneCrawlingJsoningesterComponent implements OnInit {

  @ViewChild('ixTable') ixTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loading = false;
  connectionBroken = false;
  feedParams: FeedJsoningesterFilters = new FeedJsoningesterFilters();

  displayedColumns: string[] = [
    'id',
    'processed',
    'failed',
    'message',
    'received',
    'status'
  ];
  dataSource: MatTableDataSource<FeedJsoningester>;  

  constructor(private gestioneCrawlingService : GestioneCrawlingService,
              private confirmationDialogService: ConfirmationDialogService,
              public languageService: LanguageService


    ) { }

  ngOnInit() {
    this.getFeedJsoningester();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  private getFeedJsoningester(){
      this.loading = true;
      this.gestioneCrawlingService
        .getFeedJsoningester()
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(
          feed => {
           this.updateData(feed);
          },
          error => {
            this.connectionBroken = true;
          }
        );
  }

  updateData(feed){
    this.dataSource = new MatTableDataSource(feed);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  filtraDati(): void {
    this.loading = true;

    this.gestioneCrawlingService
      .getFeedJsoningesterFiltered(this.feedParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        feed => {
          this.updateData(feed);
         },
        () => {
          this.connectionBroken = true;
        }
      );

  }

  onChangeDay(): void {
    this.feedParams.start = null;
    this.feedParams.end = null;
  }

  onChangeDate(): void {
    this.feedParams.day = null;
  }

}

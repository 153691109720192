import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { SidenavService } from '../core/sidenav/sidenav.service';
import { SidenavItem } from '../core/sidenav/sidenav-item/sidenav-item.interface';

import { ConfigData } from '../app.config';
import { LanguageService } from './language.service';
import { Router } from '@angular/router';
import { PERMISSION_CODES } from 'app/constants/permission-codes';
import { ConfigurationService } from 'app/_services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loginApiUrl: string = ConfigData.loginUrl + '/login';
  logoutApiUrl: string = ConfigData.loginUrl + '/auth/logout';
  recoveryApiUrl: string = ConfigData.loginUrl + '/recovery-password';
  sideNav: SidenavService;
  navLinks: SidenavItem[];
  selectedRoute: string;
  private permissions: string[] = [];
  private excludedAreas: string[] = [];
  private menuMap;
  private userRole: string;
  private subItems;  

  constructor(
    private http: HttpClient,
    private sidenavService: SidenavService,
    private languageService: LanguageService,
    private router: Router,
    private configService : ConfigurationService
  ) { }

  login(username: string, password: string) {
    return this.http
      .post<any>(this.loginApiUrl, { username: username, password: password })
      .map((res: any) => {
        if (res && res.token) {
          localStorage.setItem('currentUser', JSON.stringify({ res }));
          localStorage.setItem(
            'currentToken',
            JSON.stringify({ token: res.token })
          );
          localStorage.setItem(
            'loginDate',
            JSON.stringify({ timestamp: new Date().getTime() })
          );
          this.configureMenu();
        }
      });
  }

  private setWindowTitle(): void {
    document.title = `${ConfigData.clientName}`;
  }

  private populateMenuMap(){
    this.menuMap = {
      "PERMISSION_CODES.STATO_MACCHINE" : {
        value : PERMISSION_CODES.STATO_MACCHINE,
        name: this.languageService.getLabel('menu.statoMacchine'),
        routeOrFunction: '/',
        icon: 'far fa-history',
        position: 10,
        pathMatchExact: true
      },
      "PERMISSION_CODES.CENTRO_PREVIEW" : {
        value : PERMISSION_CODES.CENTRO_PREVIEW,
        name: this.languageService.getLabel('menu.centro-preview'),
        routeOrFunction: '/preview-center',
        icon: 'far fa-desktop',
        position: 16,
        pathMatchExact: true
      },
      "PERMISSION_CODES.FRONTEND" : {
        value : PERMISSION_CODES.FRONTEND,
        name: this.languageService.getLabel('menu.frontEnd'),
        routeOrFunction: 'front-ends',
        icon: 'far fa-laptop-code',
        position: 15,
        pathMatchExact: true
      },
      "PERMISSION_CODES.CLIENT" : {
        value : PERMISSION_CODES.CLIENT,
        name: this.languageService.getLabel('menu.client'),
        routeOrFunction: '/clients',
        icon: 'far fa-phone-laptop',
        position: 20,
        pathMatchExact: false
      },
      "PERMISSION_CODES.INDICI_ALIAS" : {
        value : PERMISSION_CODES.INDICI_ALIAS,
        name: this.languageService.getLabel('menu.indiciAlias'),
        routeOrFunction: '/indici-alias',
        icon: 'far fa-globe',
        position: 21,
        pathMatchExact: false
      },
      "PERMISSION_CODES.GESTIONE_CRAWLING" : {
        value : PERMISSION_CODES.GESTIONE_CRAWLING,
        name: this.languageService.getLabel('menu.gestioneCrawling'),
        routeOrFunction: '/gestione-crawling',
        icon: 'far fa-tasks-alt',
        position: 22,
        pathMatchExact: false
      },
      "PERMISSION_CODES.GESTIONE_UTENZE" : {
        value : PERMISSION_CODES.GESTIONE_UTENZE,
        name: this.languageService.getLabel('menu.gestioneUtenze'),
        routeOrFunction: '/user-role',
        icon: 'far fa-users',
        position: 23,
        pathMatchExact: false
      },
      "PERMISSION_CODES.CONFIGURAZIONE" : {
        value : PERMISSION_CODES.CONFIGURAZIONE,
        name: this.languageService.getLabel('menu.configurazione'),
        routeOrFunction: '/configuration',
        icon: 'far fa-cog',
        position: 26,
        pathMatchExact: false
      },
      "PERMISSION_CODES.ONEBOX" : {
        value : PERMISSION_CODES.ONEBOX,
        name: this.languageService.getLabel('menu.onebox'),
        routeOrFunction: '/onebox',
        icon: 'far fa-window-maximize',
        position: 27,
        pathMatchExact: true
      },
      "PERMISSION_CODES.DIFFERENZIAZIONE_RISULTATI" : {
        value : PERMISSION_CODES.DIFFERENZIAZIONE_RISULTATI,
        name: this.languageService.getLabel('menu.differenziazioneRisultati'),
        routeOrFunction: '/policy-biasing',
        icon: 'far fa-crop',
        position: 28,
        pathMatchExact: true
      },
      "PERMISSION_CODES.IMPOSTAZIONI_QUERY" : {
        value : PERMISSION_CODES.IMPOSTAZIONI_QUERY,
        name: this.languageService.getLabel('menu.impostazioniQuery'),
        routeOrFunction: '/dictionaries',
        icon: 'g_translate',
        position: 40,
        pathMatchExact: false
      },
      "PERMISSION_CODES.SUGGESTION" : {
        value : PERMISSION_CODES.SUGGESTION,
        name: this.languageService.getLabel('menu.suggestion'),
        routeOrFunction: '/suggestion',
        icon: 'far fa-lightbulb',
        position: 42,
        pathMatchExact: true
      },
      "PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER" : {
        value : PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER,
        name: this.languageService.getLabel('menu.gsaConfigurationImporter'),
        routeOrFunction: '/gsa-configuration-importer',
        icon: 'far fa-download',
        position: 45,
        pathMatchExact: false
      },
      "PERMISSION_CODES.REPORT" : {
        value :PERMISSION_CODES.REPORT,
        name: this.languageService.getLabel('menu.report'),
        routeOrFunction: '/reports/globalreports',
        icon: 'far fa-analytics',
        position: 50,
        pathMatchExact: true
      },
      "PERMISSION_CODES.AUDIO_INDEX" : {
        value : PERMISSION_CODES.AUDIO_INDEX,
        name: this.languageService.getLabel('menu.indexAudio'),
        routeOrFunction: '/indexing-audio',
        icon: 'far fa-volume-up',
        position: 23,
        pathMatchExact: true
      },
      "PERMISSION_CODES.IMAGES_INDEX" : {
        value : PERMISSION_CODES.IMAGES_INDEX,
        name: this.languageService.getLabel('menu.indexImages'),
        routeOrFunction: '/indexing-images',
        icon: 'far fa-image',
        position: 24,
        pathMatchExact: true
      },
      "PERMISSION_CODES.DOCS_INDEX" : {
        value : PERMISSION_CODES.DOCS_INDEX,
        name: this.languageService.getLabel('menu.indexDocs'),
        routeOrFunction: '/indexing-docs',
        icon: 'far fa-file',
        position: 25,
        pathMatchExact: true
      },
      "PERMISSION_CODES.VIDEO_INDEX" : {
        value : PERMISSION_CODES.VIDEO_INDEX,
        name: this.languageService.getLabel('menu.indexVideo'),
        routeOrFunction: '/indexing-video',
        icon: 'far fa-video',
        position: 25,
        pathMatchExact: true
      },
    }
  }
 
  configureMenu() {
    this.setWindowTitle();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.permissions = currentUser
      ? currentUser.res.permissions
      : [];

    if (!this.permissions.length) {
      return;
    }
    if (ConfigData.excludeAreas.length === 0) {
      this.getConfigurationSubItems();
    }
    this.userRole = currentUser ? currentUser.res.role : '';

    if (ConfigData.excludeAreas && ConfigData.excludeAreas.length) {
      ConfigData.excludeAreas.forEach(area => {
        const index = this.permissions.indexOf(area);
        if (index >= 0) {
          this.permissions.splice(index, 1);
        }
      });
      this.excludedAreas = ConfigData.excludeAreas;
      this.getConfigurationSubItems();
    }

    let menu: SidenavItem[] = [];
    const pass: boolean = this.permissions.indexOf('all') > -1 || this.userRole === 'superadmin';

    this.sidenavService.items = [];
    this.populateMenuMap();
    menu = this.criticLoop(pass,menu);

    // Send all created Items to SidenavService
    menu.forEach(item => this.sidenavService.addItem(item));
  }

  criticLoop(pass,menu){
    Object.keys(this.menuMap).forEach((singlePermissionCode) => {
      if((pass || this.permissions.indexOf(this.menuMap[singlePermissionCode].value) > -1)
        && !(this.excludedAreas.indexOf(this.menuMap[singlePermissionCode].value) > -1)){
          menu.push({
            name: this.menuMap[singlePermissionCode].name,
            routeOrFunction: this.menuMap[singlePermissionCode].routeOrFunction,
            icon: this.menuMap[singlePermissionCode].icon,
            position: this.menuMap[singlePermissionCode].position,
            pathMatchExact: this.menuMap[singlePermissionCode].pathMatchExact,
            subItems : singlePermissionCode === 'PERMISSION_CODES.CONFIGURAZIONE' ? this.subItems : []
          })
        }
    })
    return menu;
  }

  logout(): void {
    this.http.post(this.logoutApiUrl, {}).subscribe(resp => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentToken');
    });

    this.router.navigate(['/login']);

  }

  retrivePass(username: string) {
    return this.http.post<any>(
      this.recoveryApiUrl,
      { username: username },
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  userHasPermission(permissionCode: string): boolean {
    // L'utente ha il permesso se:
    // - Ha il permesso all che consente di accedere a tutte le aree dell'applicazione
    // - Ha il permesso passato alla funzione nella lista dei suoi permessi (presa dal token) e
    //   allo stesso tempo questo non è presente nella lista dei permessi da escludere
    //   (che viene settata nel env.json)
    return (this.permissions.indexOf('all') > -1 ||
      this.permissions.indexOf(permissionCode) > -1)
      && !(this.excludedAreas.indexOf(permissionCode) > -1);
  }

  userIsAdmin(): boolean {
    return this.userRole === 'admin' || this.userRole === 'superadmin';
  }

  /**
   * Controlla se lìuser è configurato correttamente ovvero se ha almeno una permission associata
   */
  userIsEnabled(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const permissions = currentUser
      ? currentUser.res.permissions
      : [];
    return permissions.length > 0;
  }

  needUpdatePassword() : boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const needUpdatePassword = currentUser ? currentUser.res.updatepassword : false;
    return needUpdatePassword;
  }

  getConfigurationSubItems() : SidenavItem[] {
      this.navLinks = []
      this.selectedRoute = this.router.url.split('/')[2] || null;

      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.PROPRIETA_SISTEMA)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/sysprops',
          name: this.languageService.getLabel('configuration.ProprietaDiSistema'),
        });

      }

      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.TEMPLATE_SEARCH)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/tempsearch',
          name: this.languageService.getLabel('configuration.TemplateSearch')
        });

      }

      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.LINGUA)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/language',
          name: this.languageService.getLabel('configuration.Language')
        });

      }

      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.INCLUDE_FREEMARKER)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/inclfree',
          name: this.languageService.getLabel('configuration.IncludeFreemarker')
        });

      }

      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.INCLUDE_XLST)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/inclxlst',
          name: this.languageService.getLabel('configuration.IncludeXslt')
        });

      }


      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.SPEECH_TO_TEXT)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/speech-to-text',
          name: this.languageService.getLabel('configuration.SpeechToText')
        });

      }

      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.SYSTEM_EMAIL)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/system-email',
          name: this.languageService.getLabel('configuration.SystemEmail')
        });

      }
      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.AUDIO_INDEX)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/audio-index',
          name: this.languageService.getLabel('configuration.audio-index')
        });

      }
      
      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.PREVIEW_DOC)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/preview-doc',
          name: this.languageService.getLabel('configuration.preview-doc')
        });

      }
      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.VIDEO_INDEX)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/video-index',
          name: this.languageService.getLabel('configuration.video-index')
        });

      }
      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.GEO)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/geo-position',
          name: this.languageService.getLabel('configuration.geo-position')
        });

      }
      if (this.userHasPermission(PERMISSION_CODES.CONFIGURAZIONE_TABS.CHRONOLOGY)) {
        this.navLinks.push({
          routeOrFunction: 'configuration/chronology',
          name: this.languageService.getLabel('configuration.chronology')
        });

      }
      this.subItems = this.navLinks;
      return this.navLinks;
    }
  }




import { Client } from './../../models/client';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTable,
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialogRef,
  MatDialog,
  MatSnackBar
} from '@angular/material';
import { ClientRelatedQuery } from 'app/models/client-related-query';
import { ClientService } from 'app/_services/client.service';
import { ClientDetailComponent } from '../client-detail/client-detail.component';
import { finalize, tap } from 'rxjs/operators';
import { ClientRelatedQueryFormComponent } from '../client-related-query-form/client-related-query-form.component';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-related-query',
  templateUrl: 'client-related-query.component.html',
  styleUrls: ['client-related-query.component.scss']
})
export class ClientRelatedQueryComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<ClientRelatedQuery>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<ClientRelatedQuery>;
  relatedQueryFormRef: MatDialogRef<ClientRelatedQueryFormComponent>;
  displayedColumns: string[] = ['keyword', 'relatedQuery', 'star'];

  loading = false;

  public parentClient: Client;
  constructor(
    private clientService: ClientService,
    private dialog: MatDialog,
    private parentComponent: ClientDetailComponent,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();

    this.parentClient = this.parentComponent.currentClient;

    this.getRelatedQuery();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  getRelatedQuery(): void {
    this.loading = true;
    this.clientService
      .getRelatedQuery(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(result => {
        this.dataSource.data = result;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (
          data: ClientRelatedQuery,
          filter: string
        ) => {
          return (
            data.keyword
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) !== -1 ||
            data.relatedQuery
              .trim()
              .toLowerCase()
              .indexOf(filter.trim().toLowerCase()) !== -1
          );
        };
      });
  }

  openQueryDetail(query: ClientRelatedQuery): void {
    this.relatedQueryFormRef = this.dialog.open(
      ClientRelatedQueryFormComponent,
      { width: '530px', data: { query: query } }
    );

    this.relatedQueryFormRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.loading = true;
        this.clientService
          .updateRelatedQuery(this.parentClient.idclient, result)
          .pipe(
            finalize(() => this.loading = false),
            tap(_ => this.notificationsService.showDefaultSuccessMessage())
          )
          .subscribe(() => this.getRelatedQuery());
      }
    });
  }

  addQuery(): void {
    this.relatedQueryFormRef = this.dialog.open(
      ClientRelatedQueryFormComponent,
      { width: '530px' }
    );

    this.relatedQueryFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.clientService
          .addRelatedQuery(this.parentClient.idclient, result)
          .pipe(
            finalize(() => this.loading = false),
            tap(_ => this.notificationsService.showDefaultSuccessMessage())
          )
          .subscribe(() => this.getRelatedQuery());
      }
    });
  }

  deleteQuery(query: ClientRelatedQuery): void {
    this.loading = true;

    this.clientService
      .deleteRelatedQuery(this.parentClient.idclient, query)
      .pipe(
        finalize(() => this.loading = false),
        tap(_ => this.notificationsService.showDefaultSuccessMessage())
      )
      .subscribe(() => this.getRelatedQuery());
  }

  /**
   * Chiama il servizio per scaricare il CSV
   */
  exportRelatedQuery(): void {
    this.loading = true;
    this.clientService
      .exportRelatedQuery(this.parentClient.idclient)
      .pipe(finalize(() => this.loading = false))
      .subscribe(response => {
        this.downloadFile(
          response,
          `${this.parentClient.idclient}-relatedquery.csv`
        );
      });
  }

  /**
   * Crea un bottone e simula il click per effettuare il download
   * @param stream Stream del CSV
   * @param filename Nome del file
   */
  private downloadFile(stream: any, filename: string): void {
    const downloadURL = window.URL.createObjectURL(stream);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    link.click();
  }
}

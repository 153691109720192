import { OneboxActivation } from './onebox-activation';
import { OneboxProvider } from './onebox-provider';

export class Onebox {
  activation = new OneboxActivation();
  description = '';
  id = null;
  includeMeta = false;
  name = '';
  provider = new OneboxProvider();
  size = null;
}

import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
import { SystemEmailTemplate } from './../../../models/system-email-template';
import { ConfigurationService } from 'app/_services/configuration.service';
import { LanguageService } from 'app/_services/language.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SystemEmailConfiguration } from 'app/models/system-email-configuration';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { SystemEmailTemplateFormComponent } from '../system-email-template-form/system-email-template-form.component';
import { finalize } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'fury-system-email',
    templateUrl: 'system-email.component.html',
    styleUrls: ['system-email.component.scss']
})

export class SystemEmailComponent implements OnInit {
    public emailTemplateFormRef: MatDialogRef<SystemEmailTemplateFormComponent>;
    public emailConfigurations: SystemEmailConfiguration[];

    @ViewChild('ixTable') ixTable: MatTable<SystemEmailTemplate>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource: MatTableDataSource<SystemEmailTemplate>;

    loading = false;
    connectionBroken = false;

    displayedColumns: string[] = ['descrizione', 'linguaggioEditor', 'star'];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public languageService: LanguageService,
        public configurationService: ConfigurationService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private confirmationDialog: ConfirmationDialogService
    ) { }

    ngOnInit() {
        this.getEmailConfigurations();
        this.getEmailTemplates();
    }

    private getEmailConfigurations(): void {
        this.configurationService.getEmailSendConfigurations().subscribe(res =>
            this.emailConfigurations = res
        );
    }

    public selectConfiguration(configuration: SystemEmailConfiguration, e: Event): void {
        e.stopPropagation();
        this.emailConfigurations.forEach(x => {
            if (x.label !== configuration.label) {
                x.enabled = false;
            }
        })
    }

    public saveConfigurations(): void {
        this.configurationService.saveEmailSendConfigurations(this.emailConfigurations).subscribe(() => this.getEmailConfigurations());
    }

    private getEmailTemplates(): void {
        this.loading = true;
        this.configurationService.getEmailTemplates()
            .pipe(finalize(() => this.loading = false))
            .subscribe(res => {
                this.dataSource = new MatTableDataSource(res);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            })
    }

    public editTemplate(template: SystemEmailTemplate): void {
        this.emailTemplateFormRef = this.dialog.open(SystemEmailTemplateFormComponent, {
            width: '800px',
            data: {
                template
            }
        });

        this.emailTemplateFormRef.afterClosed().subscribe(result => {

            this.document.body.classList.remove('modal-is-full');
            if (result) {
                this.configurationService.updateEmailTemplate(result).subscribe(() => {
                    this.showSuccessMessageAndGetEmailTemplate();
                });
            }
        });
    }

    public addTemplate(): void {
        this.emailTemplateFormRef = this.dialog.open(SystemEmailTemplateFormComponent, {
            width: '800px',
            data: {
                template: new SystemEmailTemplate()
            }
        });

        this.emailTemplateFormRef.afterClosed().subscribe(result => {
            if (result) {
                this.configurationService.createEmailTemplate(result).subscribe(() => {
                    this.showSuccessMessageAndGetEmailTemplate();
                });
            }
        });
    }

    showSuccessMessageAndGetEmailTemplate(){
        this.snackBar.open(
            this.languageService.getLabel('general.OperationSuccess'),
            'OK',
            {
                duration: 10000,
                horizontalPosition: 'center'
            }
        );
        this.getEmailTemplates();
    }

    deleteTemplate(template: SystemEmailTemplate): void {
        this.confirmationDialog
            .confirm(
                `${this.languageService.getLabel('configuration.SystemEmail.messaggioEliminaTemplate')}`,
                this.languageService.getLabel('configuration.SystemEmail.TitoloEliminaTemplate')
            )
            .then(res => {
                if (res) {
                    this.loading = true;
                    this.configurationService.deleteEmailTemplate(template.id).subscribe(() => {
                        this.getEmailTemplates();
                    });
                }
            });
    }
}
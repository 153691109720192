import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTable,
  MatTableDataSource,
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort
} from '@angular/material';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

// Services
import { OneboxService } from '../../../_services/onebox.service';
import { ConfirmationDialogService } from '../../../_services/confirmation-dialog.service';
import { LanguageService } from '../../../_services/language.service';

// Components
import { OneboxFormComponent } from '../../onebox/onebox-form/onebox-form.component';

// Models
import {
  OneboxTimeout,
  OneboxMaxResults,
  OneboxList
} from '../../../models/onebox';

@Component({
  selector: 'fury-onebox-list',
  templateUrl: './onebox-list.component.html',
  styleUrls: ['./onebox-list.component.scss']
})
export class OneboxListComponent implements OnInit {
  @ViewChild('oneboxTable') oneboxTable: MatTable<OneboxList>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loading = false;
  displayedColumns: string[] = ['name', 'activation', 'description', 'star'];
  dataSource: MatTableDataSource<OneboxList>;
  oneboxFormRef: MatDialogRef<OneboxFormComponent>;
  numbers: string[] = [];

  // Variabili per configurazione generale onebox
  timeout = new OneboxTimeout();
  maxResults = new OneboxMaxResults();

  constructor(
    private oneboxService: OneboxService,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.setMaxResultsArray();
    this.refreshData();
  }

  private refreshData(): void {
    this.loading = true;

    forkJoin([
      this.getOneboxList(),
      this.getOneBoxMaxResults(),
      this.getOneBoxTimeout()
    ])
      .pipe(finalize(() => (this.loading = false)))
      .subscribe();
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  /**
   * Chiama il servizio per ricevere la
   * lista dei onebox e riempie la tabella
   */
  private getOneboxList(): Observable<OneboxList[]> {
    return this.oneboxService.getOneboxList().do(response => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  /**
   * Chiama il servizio del timeout e setta al variabile
   */
  private getOneBoxTimeout(): Observable<OneboxTimeout> {
    return this.oneboxService
      .getOneBoxTimeout()
      .do(response => (this.timeout = response));
  }

  /**
   * Chiama il servizio dei risultati massimi e setta la variabile
   */
  private getOneBoxMaxResults() {
    return this.oneboxService
      .getOneBoxMaxResults()
      .do(response => (this.maxResults = response));
  }

  /**
   * Setta i valori per la select numero massimo risultati
   */
  private setMaxResultsArray(): void {
    this.numbers = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20'
    ];
  }

  sortData() {
    this.dataSource.sort = this.sort;
  }

  addOnebox() {
    this.oneboxFormRef = this.dialog.open(OneboxFormComponent, {
      width: '730px'
    });

    this.oneboxFormRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.name !== undefined) {
        this.oneboxService.addOnebox(result).subscribe(onebox => {
          this.refreshData();
        });
      }
    });
  }

  editOnebox(onebox) {
    this.oneboxService.getOneboxDetail(onebox.id).subscribe(ob => {
      this.oneboxFormRef = this.dialog.open(OneboxFormComponent, {
        width: '730px',
        data: ob
      });

      this.oneboxFormRef.afterClosed().subscribe(result => {
        if (result && result.activation) {
          this.oneboxService.editOnebox(result).subscribe(resp => {
            this.refreshData();
          });
        }
      });
    });
  }

  deleteOnebox(onebox) {
    this.confirmationDialog
      .confirm(
        `${this.languageService
          .getLabel('onebox.List.WarningMessageDeleteOnebox')
          .replace('$1', onebox.name)}?`
      )
      .then(res => {
        if (res) {
          this.oneboxService.deleteOnebox(onebox).subscribe(resp => {
            this.refreshData();
          });
        }
      });
  }

  setTimeout() {
    this.oneboxService.setOneBoxTimeout(this.timeout).subscribe(() => {
      this.confirmationDialog.confirm(
        this.languageService.getLabel('onebox.List.TimeoutMessaggio'),
        this.languageService.getLabel('onebox.List.TimeoutTitolo'),
        this.languageService.getLabel('general.OK'),
        ''
      );
    });
  }

  setMaxResults() {
    this.oneboxService.setOneBoxMaxResults(this.maxResults).subscribe(() => {
      this.confirmationDialog.confirm(
        this.languageService.getLabel('onebox.List.RisultatiMassimiMessaggio'),
        this.languageService.getLabel('onebox.List.RisultatiMassimiTitolo'),
        this.languageService.getLabel('general.OK'),
        ''
      );
    });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavLink } from 'app/models/nav-link';

import { LanguageService } from '../../_services/language.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { Router } from '@angular/router';
import { PERMISSION_CODES } from 'app/constants/permission-codes';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fury-gsa-configuration-importer',
  templateUrl: './gsa-configuration-importer.component.html'
})
export class GsaConfigurationImporterComponent implements OnInit, OnDestroy {
  navLinks: NavLink[];

  selectedRoute: string;

  private destroy$ = new Subject();

  constructor(public languageService: LanguageService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.buildTabs();

    this.languageService.languageChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.buildTabs());
  }

  private buildTabs(): void {
    this.navLinks = [];

    this.selectedRoute = this.router.url.split('/')[2] || null;

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER_TABS.KEYMATCH)) {
      this.navLinks.push({
        path: 'keymatch',
        label: this.languageService.getLabel('gsaConfigurationImporter.Keymatch'),
        visible: true,
        disabled: false
      });

      this.selectedRoute = this.selectedRoute || 'keymatch';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER_TABS.XSLT)) {
      this.navLinks.push({
        path: 'xslt',
        label: 'XSLT',
        visible: true,
        disabled: false
      });

      this.selectedRoute = this.selectedRoute || 'xslt';
    }

    if (this.authenticationService.userHasPermission(PERMISSION_CODES.GSA_CONFIGURATION_IMPORTER_TABS.QUERY_CORRELATE)) {
      this.navLinks.push({
        path: 'related-queries',
        label: this.languageService.getLabel(
          'gsaConfigurationImporter.RelatedQueries'
        ),
        visible: true,
        disabled: false
      });

      this.selectedRoute = this.selectedRoute || 'related-queries';
    }

    if (this.selectedRoute) {
      this.router.navigate(['gsa-configuration-importer/' + this.selectedRoute]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

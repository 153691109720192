import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/_services/language.service';
import { UserRoleService } from 'app/_services/user-role.service';
import { UserData } from 'app/models/userData';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Role } from 'app/models/role';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'fury-user-area',
  templateUrl: 'user-area.component.html',
  styleUrls: ['user-area.component.scss']
})
export class UserAreaComponent implements OnInit {
  isLoading: boolean;
  currentUser: UserData;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public languageService: LanguageService,
    private userRoleService: UserRoleService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getUserDetails();
  }

  getUserDetails(): void {
    this.userRoleService.getCurrentUserDetail().subscribe(resp => {
      if (!!resp) {
        this.currentUser = resp;
        this.buildForm();
        this.isLoading = false;
      }
    });
  }


  private buildForm(): void {
    this.form = this.formBuilder.group({
      username: [
        {
          value: this.currentUser.username,
          disabled: true
        }
      ],
      email: [
        this.currentUser.email,
        Validators.compose([Validators.required, Validators.email])
      ],
      password: [this.currentUser.password]
    });
  }

  updateUserData(form): void {
    const request: { password: string; email: string } = {
      password: form.value.password,
      email: form.value.email
    };

    this.userRoleService.updateCurrentUserData(request).subscribe(resp => {
      this.snackBar.open(
        this.languageService.getLabel('userArea.Success'),
        this.languageService.getLabel('general.OK')
      );
      this.isLoading = true;
      this.getUserDetails();
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { LanguageService } from 'app/_services/language.service';

import { SysProp } from '../../../models/sys-prop';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fury-props-form',
  templateUrl: './props-form.component.html',
  styleUrls: ['./props-form.component.scss']
})
export class PropsFormComponent implements OnInit {
  currentProp = {
    id: '',
    value: '',
    editable: ''
  };

  form: FormGroup;
  isFull : boolean = false;

  editorOptions = {
    theme: 'vs',
    language: 'json',
    automaticLayout: true,
    minimap: {
      enabled: false
    }
  };

  needsEditor = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PropsFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.currentProp = this.data.prop;

    switch (this.currentProp.id) {
      case 'XML_GSA':
        this.editorOptions.language = 'xml';
        this.needsEditor = true;
        break;
      case 'JSON_GSA':
        this.editorOptions.language = 'json';
        this.needsEditor = true;
        break;
      default:
        this.needsEditor = false;
    }

    this.form = this.formBuilder.group({
      id: [this.currentProp.id, Validators.required],
      value: [this.currentProp.value, Validators.required]
    });
  }

  submit() {
    this.dialogRef.close({
      id: this.currentProp.id,
      value: this.form.value.value,
      editable: this.currentProp.editable
    });
  }
  zoomInModal(){
    this.document.body.classList.toggle('modal-is-full');
    this.isFull = !this.isFull;
  }
}

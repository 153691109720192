import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../_services/client.service';
import { Mapping, Meta } from '../../models/index';
import { LanguageService } from 'app/_services/language.service';

@Component({
  selector: 'fury-indici-meta-form',
  templateUrl: './indici-meta-form.component.html',
  styleUrls: ['./indici-meta-form.component.scss']
})
export class IndiciMetaFormComponent implements OnInit {

  isEdit: boolean;

  metaTypes = [];

  form: FormGroup;

  currentMeta: Meta = {
    id: '',
    field: '',
    type: {
      id: '',
      label: ''
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<IndiciMetaFormComponent>,
    private clientService: ClientService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.clientService.getFiltersTypeList().subscribe(response => {
      if (response) {
        this.metaTypes = response;
      }
    });

    this.isEdit = this.data && this.data.meta;

    if (this.isEdit) {
      this.currentMeta = this.data.meta;
    }

    this.buildForm();

  }

  private buildForm() {
    this.form = this.formBuilder.group({
      field: [this.currentMeta.field, Validators.required],
      metaType: [this.currentMeta.type.id, Validators.required]
    });
  }

  submit(form) {
    const request: any = {
      field: this.form.value.field,
      type: {
        id: this.form.value.metaType,
        label: this.getMetaTypeLabel(this.form.value.metaType)
      }
    };

    if (this.isEdit) {
      request.id = this.currentMeta.id;
    }


    this.dialogRef.close(request);
  }

  cancel() {
    this.dialogRef.close();
  }

  getMetaTypeLabel(idmeta) {
    return this.metaTypes.find(m => m.id === idmeta).label;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  visible: boolean = true;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigData } from 'app/app.config';

import { Suggestion } from '../models/suggestion';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  suggestionUrl = `${ConfigData.apiUrl}/suggest`;

  constructor(private http: HttpClient) {}

  getSuggestions(): Observable<Suggestion[]> {
    return this.http.get<Suggestion[]>(`${this.suggestionUrl}`);
  }

  getSuggestion(suggestionId: number): Observable<Suggestion> {
    return this.http.get<Suggestion>(`${this.suggestionUrl}/${suggestionId}`);
  }

  saveSuggestion(suggestion: Suggestion): Observable<any> {
    return this.http.post<any>(`${this.suggestionUrl}`, suggestion);
  }

  editSuggestion(suggestion: Suggestion): Observable<any> {
    return this.http.put<any>(
      `${this.suggestionUrl}/${suggestion.id}`,
      suggestion
    );
  }

  deleteSuggestion(suggestionId: number): Observable<any> {
    return this.http.delete<any>(`${this.suggestionUrl}/${suggestionId}`);
  }

  resetSuggestion(): Observable<any> {
    return this.http.post<any>(`${this.suggestionUrl}/reset`, {});
  }
}

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MatTable,
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatSnackBar
} from '@angular/material';
import { finalize } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { GestioneCrawlingService } from '../../_services/gestione-crawling.service';

import { GestioneCrawlingWebFormComponent } from '../gestione-crawling-web-form/gestione-crawling-web-form.component';
import { RestoreCrawlingWebFormComponent } from '../restore-crawling-web-form/restore-crawling-web-form.component';
import { CrawlingWebPathsFormComponent } from '../crawling-web-paths-form/crawling-web-paths-form.component';

import { CrawlingJob } from '../../models/crawling-job';
import { CrawlingWebPath } from '../../models/crawling-web-path';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ErrorCrawlingWebFormComponent } from '../error-crawling-web-form/error-crawling-web-form.component';

@Component({
  selector: 'fury-gestione-crawling-web',
  templateUrl: 'gestione-crawling-web.component.html',
  styleUrls: ['gestione-crawling-web.component.scss']
})
export class GestioneCrawlingWebComponent implements OnInit {
  @ViewChild('runnableTable') runnableTable: MatTable<CrawlingJob>;
  @ViewChild('runnableMatSort') runnableMatSort: MatSort;
  @ViewChild('runnableMatPaginator') runnableMatPaginator: MatPaginator;
  runnableList: CrawlingJob[] = [];
  runnableSource: MatTableDataSource<CrawlingJob>;
  runnableDisplayedColumns = ['name', 'status', 'star'];

  @ViewChild('draftTable') draftTable: MatTable<CrawlingJob>;
  @ViewChild('draftMatSort') draftMatSort: MatSort;
  @ViewChild('draftMatPaginator') draftMatPaginator: MatPaginator;
  draftList: CrawlingJob[] = [];
  draftSource: MatTableDataSource<CrawlingJob>;
  draftDisplayedColumns = ['name', 'star'];

  @ViewChild('includePathsTable') includePathsTable: MatTable<CrawlingWebPath>;
  @ViewChild('includePathsSort') includePathsSort: MatSort;
  @ViewChild('includePathsPaginator') includePathsPaginator: MatPaginator;
  includePathList: CrawlingWebPath[] = [];
  includePathsSource: MatTableDataSource<CrawlingWebPath>;
  includePathsDisplayedColumns: string[] = ['path', 'star'];

  @ViewChild('excludePathsTable') excludePathsTable: MatTable<CrawlingWebPath>;
  @ViewChild('excludePathsSort') excludePathsSort: MatSort;
  @ViewChild('excludePathsPaginator') excludePathsPaginator: MatPaginator;
  excludePathList: CrawlingWebPath[] = [];
  excludePathsSource: MatTableDataSource<CrawlingWebPath>;
  excludePathsDisplayedColumns: string[] = ['path', 'star'];

  /**
   * Primo index per i jobs, secondo per i path
   */
  private readonly loading = [false, false];

  connectionBroken = false;

  gestioneCrawlingWebFormRef: MatDialogRef<GestioneCrawlingWebFormComponent>;
  restoreCrawlingWebComponentRef: MatDialogRef<RestoreCrawlingWebFormComponent>;
  errorCrawlingWebComponentRef: MatDialogRef<ErrorCrawlingWebFormComponent>;
  crawlingWebPathsFormRef: MatDialogRef<CrawlingWebPathsFormComponent>;

  constructor(
    private gestioneCrawlingService: GestioneCrawlingService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectionRef: ChangeDetectorRef,
    public languageService: LanguageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getJobs();
    this.getPaths();
  }

  private getPaths(): void {
    this.loading[1] = true;
    this.gestioneCrawlingService
      .getCrawlingJobPath()
      .pipe(finalize(() => (this.loading[1] = false)))
      .subscribe(
        response => {
          if (response) {
            // Set configuration for include table
            this.includePathList = response.includePath;
            this.includePathsSource = new MatTableDataSource(
              this.includePathList
            );
            this.includePathsSource.sort = this.includePathsSort;
            this.includePathsSource.paginator = this.includePathsPaginator;
            // Set configuration for exclude table
            this.excludePathList = response.excludePath;
            this.excludePathsSource = new MatTableDataSource(
              this.excludePathList
            );
            this.excludePathsSource.sort = this.excludePathsSort;
            this.excludePathsSource.paginator = this.excludePathsPaginator;
          }
        }
      );
  }

  private getJobs(): void {
    this.loading[0] = true;
    this.gestioneCrawlingService
      .getCrawlingWebJobs()
      .pipe(
        finalize(() => {
          this.loading[0] = false;
        })
      )
      .subscribe(
        jobs => {
          if (jobs) {
            // Set configuration for runnable table
            this.runnableList = jobs.runnable;
            this.runnableSource = new MatTableDataSource(this.runnableList);
            this.runnableSource.sort = this.runnableMatSort;
            this.runnableSource.paginator = this.runnableMatPaginator;
            // Set configuration for draft table
            this.draftList = jobs.draft;
            this.draftSource = new MatTableDataSource(this.draftList);
            this.draftSource.sort = this.draftMatSort;
            this.draftSource.paginator = this.draftMatPaginator;

            this.changeDetectionRef.detectChanges();

            // resetto il job precedentemente selezionato
            this.gestioneCrawlingService.selectedJob = null;
          }
        }
      );
  }

  /**
   * Ritorna true se tutti i booleani contenuti nell'array sono true
   */
  isLoading(): boolean {
    return this.loading.reduce((prev, curr) => prev && curr, true);
  }

  refreshList(): void {
    this.getJobs();
    this.getPaths();
  }

  applyRunnJobFilter(filter: string): void {
    this.runnableSource.filter = filter.trim().toLowerCase();
  }

  applyExclPathFilter(filter: string): void {
    this.excludePathsSource.filter = filter.trim().toLowerCase();
  }

  applyInclPathFilter(filter: string): void {
    this.includePathsSource.filter = filter.trim().toLowerCase();
  }

  applyDraftFilter(filter: string): void {
    this.draftSource.filter = filter.trim().toLowerCase();
  }

  addJob(): void {
    this.gestioneCrawlingWebFormRef = this.dialog.open(
      GestioneCrawlingWebFormComponent,
      {
        width: '500px'
      }
    );
    this.gestioneCrawlingWebFormRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (!this.connectionBroken) {
          this.gestioneCrawlingService.addCrawlingWebJob(result).subscribe(
            index => {
              this.showSuccessAndGetJobs();
            },
            error => {
              console.error(error);
              console.error('POST **KO** impossibile salvare il dato.');
            }
          );
        }
      }
    });
  }

  sortDataRunnable() {
    this.runnableSource.sort = this.runnableMatSort;
  }

  sortDataDraft() {
    this.draftSource.sort = this.draftMatSort;
  }

  goToDetail(job: CrawlingJob): void {
    if (job.id) {
      this.router.navigate([job.id], {
        relativeTo: this.activatedRoute
      });
    }
  }

  startJob(job: CrawlingJob): void {
    job.waiting = true;

    this.gestioneCrawlingService
      .startCrawlingWebJob(job)
      .pipe(finalize(() => {
        delete job.waiting;
        this.snackBar.open(this.languageService.getLabel('gestioneCrawlingWeb.JobAvviato'), 'OK');
      }))
      .subscribe(resp =>
        // Una volta ricevuta risposta positiva metto lo stato a iniziato solo lato FE
        // senza rifare la chiamata per recuperare la lista
        job.runningStatus = 'start'
      );
  }

  stopJob(job: CrawlingJob): void {
    job.waiting = true;

    this.gestioneCrawlingService
      .stopCrawlingWebJob(job)
      .pipe(finalize(() => {
        delete job.waiting;
        this.snackBar.open(this.languageService.getLabel('gestioneCrawlingWeb.JobInterrotto'), 'OK');
      }))
      .subscribe((resp) =>
        // Una volta ricevuta risposta positiva metto lo stato a fermo solo lato FE
        // senza rifare la chiamata per recuperare la lista
        job.runningStatus = 'stop'
      );
  }

  deleteJob(job: CrawlingJob): void {
    this.gestioneCrawlingService.removeCrawlingWebJob(job).subscribe(() => {
      this.showSuccessAndGetJobs();
    });
  }

  showSuccessAndGetJobs(){
    this.snackBar.open(
      this.languageService.getLabel('general.OperationSuccess'),
      'OK',
      {
        duration: 10000,
        horizontalPosition: 'center'
      }
    );
    this.getJobs();
  }

  openModalErrorJob(job: CrawlingJob): void {
    this.errorCrawlingWebComponentRef = this.dialog.open(
      ErrorCrawlingWebFormComponent,
      {
        width: '500px'
      }
    );
    this.errorCrawlingWebComponentRef.afterClosed().subscribe(response => {
      this.ifResponseStartJob(response,job);
    });
  }

  openModalWarningJob(job: CrawlingJob): void {
    this.restoreCrawlingWebComponentRef = this.dialog.open(
      RestoreCrawlingWebFormComponent,
      {
        width: '500px'
      }
    );
    this.restoreCrawlingWebComponentRef.afterClosed().subscribe(response => {
     this.ifResponseStartJob(response,job);
    });
  }

  ifResponseStartJob(response, job): void {
    if (response) {
      this.startJob(job);
    }
  }

  includePathsSortData(): void { 
    //This is intentional
  }

  openModalAddIncludePath(): void {
    this.crawlingWebPathsFormRef = this.dialog.open(
      CrawlingWebPathsFormComponent,
      {
        width: '500px'
      }
    );
    this.crawlingWebPathsFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.gestioneCrawlingService
          .addCrawlingJobPath(result, 'include')
          .subscribe(() => {
            const confirmModalRef = this.dialog.open(
              ConfirmationDialogComponent
            );

            confirmModalRef.componentInstance.showCancelButton = false;
            confirmModalRef.componentInstance.message = this.languageService.getLabel(
              'gestioneCrawlingWeb.IncludePath.AddedInfo'
            );

            confirmModalRef.afterClosed().subscribe(() => {
              this.getPaths();
            });
          });
      }
    });
  }

  deleteIncludePath(url: CrawlingWebPath): void {
    this.deletePath(url);
  }

  excludePathsSortData(): void { 
     //This is intentional
  }

  openModalAddExcludePath(): void {
    this.crawlingWebPathsFormRef = this.dialog.open(
      CrawlingWebPathsFormComponent,
      {
        width: '500px'
      }
    );
    this.crawlingWebPathsFormRef.afterClosed().subscribe(result => {
      if (result) {
        this.gestioneCrawlingService
          .addCrawlingJobPath(result, 'exclude')
          .subscribe(() => {
            const confirmModalRef = this.dialog.open(
              ConfirmationDialogComponent
            );
            confirmModalRef.componentInstance.showCancelButton = false;
            confirmModalRef.componentInstance.message = this.languageService.getLabel(
              'gestioneCrawlingWeb.ExcludePath.AddedInfo'
            );

            confirmModalRef.afterClosed().subscribe(() => {
              this.getPaths();
            });
          });
      }
    });
  }

  deleteExcludePath(url: CrawlingWebPath): void {
    this.deletePath(url);
  }

  deletePath(url : CrawlingWebPath){
    this.gestioneCrawlingService
    .deleteCrawlingJobPath(url.id.toString())
    .subscribe(() => {
      this.snackBar.open(
        this.languageService.getLabel('general.OperationSuccess'),
        'OK',
        {
          duration: 10000,
          horizontalPosition: 'center'
        }
      );
      this.getPaths();
    });
  }

  /**
   * Ritorna true se lo stato del job contiene la parola error
   */
  statusIsInError(job: CrawlingJob): boolean {
    return job.runningStatus.indexOf('error') >= 0;
  }
}

import { Component, OnInit } from '@angular/core';

import { ClientService } from '../../../../_services/client.service';
import { LanguageService } from '../../../../_services/language.service';

import { Client } from '../../../../models/client';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fury-gsa-configuration-importer-xslt',
  templateUrl: './gsa-configuration-importer-xslt.component.html',
  styleUrls: ['./gsa-configuration-importer-xslt.component.scss']
})
export class GsaConfigurationImporterXsltComponent implements OnInit {
  clients: Client[];
  file: File;
  client: Client;
  inProgress = false;

  constructor(
    private clientService: ClientService,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.getClients();
  }

  private getClients(): void {
    this.clientService.getClients().subscribe(response => {
      this.clients = response;
    });
  }

  onFileChanged(event): void {
    if (event.target.files && event.target.files.length) {
      this.file = <File>event.target.files[0];
    }
  }

  importXslt(): void {
    this.inProgress = true;
    this.clientService
      .importXslt(this.file)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe(() => {
        this.client = null;
        this.file = null;
      });
  }
}

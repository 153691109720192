import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { ClientFiltersComponent } from './n4s/client-filters/client-filters.component';
import { PolicyBiasingDetailComponent } from './n4s/policy-biasing/components/policy-biasing-detail/policy-biasing-detail.component';
import { LayoutComponent } from './core/layout/layout.component';
import { Dash4searchComponent } from './n4s/dash4search/dash4search.component';
import { FrontEndsComponent } from './n4s/front-ends/front-ends.component';
import { ClientsComponent } from './n4s/clients/clients.component';
import { ClientDetailComponent } from './n4s/client-detail/client-detail.component';
import { ReportsComponent } from './n4s/reports/reports/reports.component';
import { GlobalReportsComponent } from './n4s/reports/global-reports/global-reports.component';
import { ClientExcludeUrlComponent } from './n4s/client-exclude-url/client-exclude-url.component';
import { ClientTemplateElasticComponent } from './n4s/client-template-elastic/client-template-elastic.component';
import { ClientKeymatchComponent } from './n4s/client-keymatch/client-keymatch.component';
import { ClientOneboxComponent } from './n4s/client-onebox/client-onebox.component';
import { IndiciAliasComponent } from './n4s/indici-alias/indici-alias.component';
import { IndiciComponent } from './n4s/indici/indici.component';
import { AliasComponent } from './n4s/alias/alias.component';
import { AliasDetailComponent } from './n4s/alias-detail/alias-detail.component';
import { DiagnosticComponent } from './n4s/diagnostic/diagnostic.component';
import { ConfigurationComponent } from './n4s/configuration/configuration/configuration.component';
import { SysPropsComponent } from './n4s/configuration/sys-props/sys-props.component';
import { TemplateSearchComponent } from './n4s/configuration/template-search/template-search.component';
import { IncludeFreemarkerComponent } from './n4s/configuration/include-freemarker/include-freemarker.component';
import { IncludeXlstComponent } from './n4s/configuration/include-xlst/include-xlst.component';
import { OneboxListComponent } from './n4s/onebox/onebox-list/onebox-list.component';
import { UserRoleComponent } from './n4s/user-role/user-role.component';
import { UserComponent } from './n4s/user/user.component';
import { TemplateFreeHelpComponent } from './n4s/template-free-help/template-free-help.component';
import { ClientRelatedQueryComponent } from './n4s/client-related-query/client-related-query.component';
import { PolicyBiasingComponent } from './n4s/policy-biasing/policy-biasing.component';
import { ClientPolicyComponent } from './n4s/client-policy/client-policy.component';
import {
  DictionariesComponent,
  SinonimiComponent,
  StopwordsComponent,
  BlacklistComponent
} from './n4s/dictionaries';
import { GestioneCrawlingComponent } from './n4s/gestione-crawling/gestione-crawling.component';
import { GestioneCrawlingWebDetailComponent } from './n4s/gestione-crawling-web-detail/gestione-crawling-web-detail.component';
import { GestioneCrawlingWebComponent } from './n4s/gestione-crawling-web/gestione-crawling-web.component';
import { GestioneCrawlingDbComponent } from './n4s/gestione-crawling-db/gestione-crawling-db.component';
import { CrawlingWebUrlsComponent } from './n4s/crawling-web-urls/crawling-web-urls.component';
import { CrawlingWebSchedulazioneComponent } from './n4s/crawling-web-schedulazione/crawling-web-schedulazione.component';
import { CrawlingWebMappingComponent } from './n4s/crawling-web-mapping/crawling-web-mapping.component';
import { GestioneCrawlingFeedXMLComponent } from './n4s/gestione-crawling-feed-xml/gestione-crawling-feed-xml.component';
import { GestioneCrawlingOperazioniComponent } from './n4s/gestione-crawling-operazioni/gestione-crawling-operazioni.component';
import {
  GsaConfigurationImporterComponent,
  GsaConfigurationImporterKeymatchComponent
} from './n4s/gsa-configuration-importer';
import { SuggestionComponent } from './n4s/suggestion/suggestion.component';
import { GsaConfigurationImporterXsltComponent } from './n4s/gsa-configuration-importer/components/gsa-configuration-importer-xslt/gsa-configuration-importer-xslt.component';
import { LanguageConfigurationComponent } from './n4s/configuration/language-configuration/language-configuration.component';
import { CrawlingWebStatoDocumentoComponent } from './n4s/crawling-web-stato-documento/crawling-web-stato-documento.component';
import { CrawlingWebStatoCrawlingComponent } from './n4s/crawling-web-stato-crawling/crawling-web-stato-crawling.component';
import { UserAreaComponent } from './n4s/user-area/user-area.component';
import { GsaConfigurationImporterRelatedQueriesComponent } from './n4s/gsa-configuration-importer/components/gsa-configuration-importer-related-queries/gsa-configuration-importer-related-queries.component';
import { IndiciCancellazioneDocumentiComponent } from './n4s/indici-cancellazione-documenti/indici-cancellazione-documenti.component';
import { CentroPreviewComponent } from './n4s/centro-preview/centro-preview.component';
import { UserGroupsComponent } from './n4s/user-groups/user-groups.component';
import { IndiciMetaComponent } from './n4s/indici-meta/indici-meta.component';
import { SpeechToTextComponent } from './n4s/configuration/speech-to-text/speech-to-text.component';
import { ReportsHistoryComponent } from './n4s/reports/reports-history/reports-history.component';
import { GestioneCrawlingLogsComponent } from './n4s/gestione-crawling-logs/gestione-crawling-logs.component';
import { SystemEmailComponent } from './n4s/configuration/system-email/system-email.component';
import { GestioneMonitoraggioKenticoComponent } from './n4s/gestione-monitoraggio-kentico/gestione-monitoraggio-kentico.component';
import { GestioneCrawlingJsoningesterComponent } from './n4s/gestione-crawling-json-ingester/gestione-crawling-json-ingester.component';
import { IndexingAudioComponent } from './n4s/indexing-audio/indexing-audio.component';
import { AudioConfComponent } from './n4s/configuration/audio-conf/audio-conf.component';
import { DocPreviewComponent } from './n4s/configuration/doc-preview/doc-preview.component';
import { IndexingImagesComponent } from './n4s/indexing-images/indexing-images.component';
import { IndexingDocsComponent } from './n4s/indexing-docs/indexing-docs.component';
import { AnalyticsReportComponent } from './reports/analytics-report/analytics-report.component';
import { ReportsChartsComponent } from './n4s/reports/reports-charts/reports-charts.component';
import { IndexingVideoComponent } from './n4s/indexing-video/indexing-video.component';
import { VideoConfComponent } from './n4s/configuration/video-conf/video-conf.component';
import { GeoConfComponent } from './n4s/configuration/geo-conf/geo-conf.component';
import { ChronologyConfComponent } from './n4s/configuration/chronology-conf/chronology-conf.component';
import { VersionPageComponent } from './n4s/version-page/version-page.component';
import { NotificationHistoryComponent } from './n4s/notification-history/notification-history.component';
const routes: Routes = [
  {
    path: 'login',
    loadChildren: './n4s/login/login.module#LoginModule'
  },

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: Dash4searchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'front-ends',
        component: FrontEndsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'indexing-audio',
        component: IndexingAudioComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'indexing-video',
        component: IndexingVideoComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'indexing-images',
        component: IndexingImagesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'indexing-docs',
        component: IndexingDocsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'client-detail/:id',
        component: ClientDetailComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'filters',
            component: ClientFiltersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'search-diff',
            component: ClientPolicyComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'keymatch',
            component: ClientKeymatchComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'onebox',
            component: ClientOneboxComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'exclude-url',
            component: ClientExcludeUrlComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'template-elastic',
            component: ClientTemplateElasticComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'related-query',
            component: ClientRelatedQueryComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'search-diff',
            component: ClientPolicyComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'suggestion',
        component: SuggestionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'preview-center',
        component: CentroPreviewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user-area',
        component: UserAreaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'gsa-configuration-importer',
        component: GsaConfigurationImporterComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'keymatch',
            component: GsaConfigurationImporterKeymatchComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'xslt',
            component: GsaConfigurationImporterXsltComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'related-queries',
            component: GsaConfigurationImporterRelatedQueriesComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'globalreports',
            component: GlobalReportsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'analytics-report',
            component: AnalyticsReportComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'reports-history',
            component: ReportsHistoryComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'reports-charts',
            component: ReportsChartsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'reports-charts-action',
            component: ReportsChartsComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'alias-detail/:id',
        component: AliasDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'indici-alias',
        component: IndiciAliasComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'indici',
            component: IndiciComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'alias',
            component: AliasComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'diagnostics',
            component: DiagnosticComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'cancellazione-documenti',
            component: IndiciCancellazioneDocumentiComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'meta',
            component: IndiciMetaComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'onebox',
        component: OneboxListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'sysprops',
            component: SysPropsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tempsearch',
            component: TemplateSearchComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'inclfree',
            component: IncludeFreemarkerComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'audio-index',
            component: AudioConfComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'preview-doc',
            component: DocPreviewComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'video-index',
            component: VideoConfComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'geo-position',
            component: GeoConfComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'chronology',
            component: ChronologyConfComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'inclxlst',
            component: IncludeXlstComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'language',
            component: LanguageConfigurationComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'speech-to-text',
            component: SpeechToTextComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'system-email',
            component: SystemEmailComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'user-role',
        component: UserRoleComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'user',
            component: UserComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'groups',
            component: UserGroupsComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'template-freemaker-help',
        component: TemplateFreeHelpComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'policy-biasing',
        component: PolicyBiasingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'policy-detail',
        canActivate: [AuthGuard],
        children: [
          {
            path: ':idBiasing',
            component: PolicyBiasingDetailComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'dictionaries',
        component: DictionariesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'sinonimi',
            component: SinonimiComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'stopwords',
            component: StopwordsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'blacklist',
            component: BlacklistComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'gestione-crawling',
        component: GestioneCrawlingComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'web',
            component: GestioneCrawlingWebComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'web/:idJob',
            component: GestioneCrawlingWebDetailComponent,
            canActivate: [AuthGuard],
            children: [
              { path: '', redirectTo: 'urls', pathMatch: 'full' },
              {
                path: 'urls',
                component: CrawlingWebUrlsComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'schedulazione',
                component: CrawlingWebSchedulazioneComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'mapping',
                component: CrawlingWebMappingComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'stato-documento',
                component: CrawlingWebStatoDocumentoComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'stato-crawling',
                component: CrawlingWebStatoCrawlingComponent,
                canActivate: [AuthGuard]
              }
            ]
          },
          {
            path: 'db',
            component: GestioneCrawlingDbComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'feed-xml',
            component: GestioneCrawlingFeedXMLComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'monitoring-kentico',
            component: GestioneMonitoraggioKenticoComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'monitoring-json-ingester',
            component: GestioneCrawlingJsoningesterComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'operazioni',
            component: GestioneCrawlingOperazioniComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'logs',
            component: GestioneCrawlingLogsComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'notifications-history',
        component: NotificationHistoryComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'template-freemaker-help',
    component: TemplateFreeHelpComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'version',
    component: VersionPageComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';

import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatTable,
  MatDialog,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';

import { ClientService } from './../../_services/client.service';
import { ConfirmationDialogService } from './../../_services/confirmation-dialog.service';
import { Client } from '../../models/client';

import { TemplateElasticRequest } from '../../models/template-elastic-request';
import { ClientDetailComponent } from '../client-detail/client-detail.component';
import { ClientTemplateElasticFormComponent } from '../client-template-elastic-form/client-template-elastic-form.component';
import { ClientTemplateElasticDetailComponent } from '../client-template-elastic-detail/client-template-elastic-detail.component';
import { LanguageService } from 'app/_services/language.service';
import { NotificationsService } from 'app/_services/notifications.service';

@Component({
  selector: 'fury-client-template-elastic',
  templateUrl: './client-template-elastic.component.html',
  styleUrls: ['./client-template-elastic.component.scss']
})
export class ClientTemplateElasticComponent implements OnInit {
  @ViewChild('ixTable') ixTable: MatTable<TemplateElasticRequest>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<TemplateElasticRequest>;
  templateElasticFormRef: MatDialogRef<
    ClientTemplateElasticFormComponent,
    boolean
  >;
  templateElasticDetailRef: MatDialogRef<
    ClientTemplateElasticDetailComponent,
    boolean
  >;

  loading = false;
  connectionBroken = false;

  displayedColumns: string[] = ['template', 'star'];

  public parentClient: Client;

  constructor(
    private clientService: ClientService,
    private parentComponent: ClientDetailComponent,
    private dialog: MatDialog,
    private confirmationDialog: ConfirmationDialogService,
    public languageService: LanguageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.parentClient = this.parentComponent.currentClient;
    this.getTemplates();
  }

  private getTemplates(): void {
    this.loading = true;
    this.clientService
      .getClientTemplateElastic(this.parentClient.idclient)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        templates => {
          this.dataSource = new MatTableDataSource(templates);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = (
            data: TemplateElasticRequest,
            filter: string
          ) => {
            return (
              data.template.descrizione
                .trim()
                .toLowerCase()
                .indexOf(filter) >= 0
            );
          };
        },
        error => {
          this.connectionBroken = true;
        }
      );
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  sortData(): void {
    this.dataSource.sort = this.sort;
  }

  addTemplate() {
    this.templateElasticFormRef = this.dialog.open(
      ClientTemplateElasticFormComponent,
      {
        width: '500px',
        data: { idclient: this.parentClient.idclient }
      }
    );
    this.templateElasticFormRef.afterClosed().subscribe(result => {
      if (result && !this.connectionBroken) {
        this.notificationsService.showDefaultSuccessMessage();
        this.getTemplates();
      }
    });
  }

  deleteTemplate(template: TemplateElasticRequest) {
    this.confirmationDialog
      .confirm(
        `${this.languageService.getLabel(
          'templateElastic-Form.EliminaAssociazione'
        )} ${template.template.descrizione}?`
      )
      .then(result => {
        if (result) {
          this.clientService
            .deleteClientTemplateElastic(template.id)
            .pipe(tap(_ => this.notificationsService.showDefaultSuccessMessage()))
            .subscribe(() => this.getTemplates());
        }
      });
  }

  editTemplate(templateRequest: TemplateElasticRequest) {
    this.templateElasticFormRef = this.dialog.open(
      ClientTemplateElasticFormComponent,
      {
        width: '500px',
        data: {
          idclient: this.parentClient.idclient,
          templateId: templateRequest.template.id,
          templateType: templateRequest.template.type,
          requestId: templateRequest.id
        }
      }
    );
    this.templateElasticFormRef.afterClosed().subscribe(result => {
      if (result && !this.connectionBroken) {
        this.getTemplates();
      }
    });
  }

  showTemplate(selectedAssociation: TemplateElasticRequest): void {
    this.templateElasticDetailRef = this.dialog.open(
      ClientTemplateElasticDetailComponent,
      {
        width: '900px',
        data: { idElasticRequest: selectedAssociation.id }
      }
    );
    this.templateElasticDetailRef.afterClosed().subscribe();
  }
}

import { UserGroup } from "app/models/user-group";
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { GroupAreaPermission } from "app/models/group-area-permission";
import { LanguageService } from "app/_services/language.service";
import { UserRoleService } from "app/_services/user-role.service";

@Component({
  selector: "fury-user-groups-permissions-subareas",
  templateUrl: "./user-groups-permissions-subareas.component.html",
  styleUrls: ["./user-groups-permissions-subareas.component.scss"],
})
export class UserGroupsPermissionsSubareasComponent implements OnInit {
  currentArea: GroupAreaPermission;
  userSubAreas: GroupAreaPermission[] = [];
  currentGroup: UserGroup;

  constructor(
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<UserGroupsPermissionsSubareasComponent>,
    private userRoleService: UserRoleService
  ) {}

  ngOnInit() {
    this.currentArea = this.data.area;
    this.userSubAreas = this.data.area.childList;
    this.currentGroup = this.data.group;
  }

  close(): void {
    this.dialogRef.close();
  }

  toggleArea(area: GroupAreaPermission): void {
    if (area.enabled) {
      // Se ancora non è abilitata la macroarea relativa, la abilito lato front end
      // perchè il servizio lo fa in maniera indipendente.
      this.currentArea.enabled = true;

      this.userRoleService
        .enableGroupArea(this.currentGroup.groupName, area.function)
        .subscribe();
      return;
    }

    this.userRoleService
      .disableGroupArea(this.currentGroup.groupName, area.function)
      .subscribe();
  }
}

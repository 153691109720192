import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { LanguageService } from '../../_services/language.service';
import { GestioneCrawlingService } from '../../_services/gestione-crawling.service';

// Models
import { CrawlingJob } from '../../models/crawling-job';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CrawlingWebUrl } from 'app/models/crawling-web-url';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fury-crawling-web-stato-documento',
  templateUrl: 'crawling-web-stato-documento.component.html'
})
export class CrawlingWebStatoDocumentoComponent implements OnInit {
  loading: boolean;

  documentId: string;
  paramIDJob: string;
  job: CrawlingJob;

  lastModifiedTs: number;
  score: number;
  stato: string;

  hasResults = false;

  startUrlsList: CrawlingWebUrl[] = [];

  constructor(
    public languageService: LanguageService,
    private gestioneCrawlingService: GestioneCrawlingService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.activatedRoute.parent.paramMap.subscribe(res => {
      const paramIDJob = res.get('idJob');
      if (paramIDJob) {
        this.paramIDJob = paramIDJob;
        this.getCrawlingWebJobDetail();
        this.loadUrls();
      }
    });
  }

  private getCrawlingWebJobDetail(): void {
    this.gestioneCrawlingService
      .getCrawlingWebJobDetail(this.paramIDJob)
      .subscribe(response => (this.job = response));
  }

  private loadUrls(): void {
    this.gestioneCrawlingService
      .getCrawlingWebUrlsData(this.paramIDJob)
      .subscribe(result => {
        if (result) {
          this.startUrlsList = result.startUrl;
        }
      });
  }

  /**
   * Controlla nella stringa inserita c'è uno dei domini presenti nello startUrl
   */
  private isInStartUrl(): boolean {
    return this.startUrlsList.some(url => {
      // Prendo la parte (www).dominio.ext dell'url
      let domain = url.url.split('/')[2];
      // Se c'è il www lo tolgo
      if (domain.indexOf('www.') > -1) {
        domain = domain.split('www.')[1];
      }
      // Ritorna true se il dominio è presente nella stringa inserita
      return this.documentId.indexOf(domain) > -1;
    });
  }

  verificaStato(): void {
    // Se non finisce con / lo aggiungo all'url
    // if (!this.documentId.endsWith('/')) {
    //   this.documentId += '/';
    // }

    if (!this.isInStartUrl()) {
      const confirmModalRef = this.dialog.open(ConfirmationDialogComponent);
      confirmModalRef.componentInstance.showCancelButton = false;
      confirmModalRef.componentInstance.title = this.languageService.getLabel(
        'general.Attenzione'
      );
      confirmModalRef.componentInstance.message = this.languageService.getLabel(
        'gestioneCrawlingWeb-Details.StatoDocumento.Warning'
      );
      return;
    }

    this.hasResults = false;
    this.loading = true;
    this.gestioneCrawlingService
      .getDocumentState(this.job.name, this.documentId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(resp => {
        if (resp && resp.stato) {
          this.hasResults = true;
          this.lastModifiedTs = resp.modifiedTime;
          this.score = resp.score;
          this.stato = resp.stato;
        } else {
          const confirmModalRef = this.dialog.open(ConfirmationDialogComponent);
          confirmModalRef.componentInstance.showCancelButton = false;
          confirmModalRef.componentInstance.title = this.languageService.getLabel(
            'general.Attenzione'
          );
          confirmModalRef.componentInstance.message = this.languageService.getLabel(
            'gestioneCrawlingWeb-Details.StatoDocumento.NessunaCorrispondenza'
          );
        }
      });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigurationService } from '../../../_services/configuration.service';
import { LanguageService } from 'app/_services/language.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'fury-template-search-form',
  templateUrl: './template-search-form.component.html',
  styleUrls: ['./template-search-form.component.scss']
})
export class TemplateSearchFormComponent implements OnInit {
  templateTypes = [];

  form: FormGroup;
  isFull : boolean = false;

  // Editor
  languages = [
    { key: 'json', value: 'JSON' },
    { key: 'html', value: 'HTML' },
    { key: 'xml', value: 'XML' }
  ];

  editorOptions = {
    theme: 'vs',
    language: 'json',
    automaticLayout: true,
    minimap: {
      enabled: false
    }
  };

  currentTemplate = {
    id: null,
    descrizione: '',
    template: '',
    type: {
      id: 1,
      label: 'SEARCH'
    }
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private dialogRef: MatDialogRef<TemplateSearchFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    if (this.data != null) {
      this.currentTemplate = this.data.template;
    }

    this.form = this.formBuilder.group({
      descrizione: [this.currentTemplate.descrizione, Validators.required],
      template: [this.currentTemplate.template, Validators.required],
      languageType: [this.editorOptions.language]
    });
  }

  submit(form) {
    this.dialogRef.close({
      id: this.currentTemplate.id,
      descrizione: form.value.descrizione,
      template: form.value.template,
      // Forzo sempre il tipo a "SEARCH"
      type: {
        label: 'SEARCH',
        id: 1
      }
    });
  }

  getTypeLabel(ll) {
    if (ll !== undefined) {
      return this.templateTypes.find(m => m.id === ll).label;
    } else {
      return undefined;
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  zoomInModal(){
    this.document.body.classList.toggle('modal-is-full');
    this.isFull = !this.isFull;
  }
  editorLanguageChanged(value: any): void {
    const newConfig = JSON.parse(JSON.stringify(this.editorOptions));
    newConfig.language = value;

    this.editorOptions = newConfig;
  }
}

import { LanguageService } from 'app/_services/language.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import {
    MatPaginator,
    MatSnackBar,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { ReportService } from 'app/_services/report.service';
import { ReportHistory } from 'app/models/report-history';
import { ConfirmationDialogService } from 'app/_services/confirmation-dialog.service';
@Component({
    selector: 'fury-reports-history',
    templateUrl: 'reports-history.component.html',
    styleUrls: ['reports-history.component.scss'],
})
export class ReportsHistoryComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = [
        'title',
        'startDate',
        'endDate',
        'alias',
        'creationDate',
        'exportMediaType',
        'star',
    ];

    dataSource: MatTableDataSource<ReportHistory>;
    loading = false;

    constructor(
        public languageService: LanguageService,
        private reportService: ReportService,
        public snackBar: MatSnackBar,
        private confirmationDialog: ConfirmationDialogService
    ) { }

    ngOnInit() {
        this.getReportHistory();
    }

    getReportHistory(): void {
        this.reportService.getReportsHistory().subscribe((resp) => {
            this.dataSource = new MatTableDataSource(resp);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    downloadReportHistory(element: ReportHistory): void {
        this.reportService
            .downloadHistoryReport(
                element.id
            )
            .subscribe(response => {
                this.downloadFile(response, `${element.title}.${element.exportMediaType.split('/')[1]}`);
            });

    }

    deleteReportHistory(element: ReportHistory): void {
        this.confirmationDialog
            .confirm(this.languageService.getLabel('globalReports.ConfermaEliminazione').replace('{reportTitle}', element.title))
            .then((result) => {
                if (result) {
                    this.reportService
                        .deleteHistoryReport(element.id)
                        .subscribe((resp) => {
                            this.snackBar.open(
                                this.languageService.getLabel('general.OperationSuccess'),
                                'OK',
                                { duration: 3000 }
                            );
                            this.getReportHistory();
                        });
                }
            });
    }

    sortData(): void {
        this.dataSource.sort = this.sort;
    }

    applyFilter(filter: string): void {
        this.dataSource.filter = filter.trim().toLowerCase();
    }

    private downloadFile(stream: any, filename: string): void {
        const downloadURL = window.URL.createObjectURL(stream);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }
}
